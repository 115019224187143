import User from '../models/User';

/**
 * Identify FullStory user during sign in
 */
export const FSidentifyUser = (user: User): void | null => {
  const APP_MODE = process.env.REACT_APP_MODE;
  if (!user || (APP_MODE === 'development' || APP_MODE === 'staging')) {
    return null;
  }

  return (window as any).FS.identify(user.id, {
    displayName: _generateDisplayName(user),
    email: user.email,
  });
};

/**
 * Anonymize FullStory user after sign out
 */
export const FSanonymizeUser = (): void | null => {
  const APP_MODE = process.env.REACT_APP_MODE;
  if (APP_MODE === 'development' || APP_MODE === 'staging') {
    return null;
  }

  return (window as any).FS.identify(false);
};

const _generateDisplayName = (user: User): string => {
  if (!user) {
    return '';
  }

  return `${user.firstname} ${user.surname}`;
};
