import * as React from 'react';
import CancelIcon from '../../Icons/CancelIcon';
import * as S from './SelectedFilter.styles';

interface ISelectedFilterProps {
  value: string;
  onClick: () => void;
}

const SelectedFilter: React.SFC<ISelectedFilterProps> = ({ value, onClick }) => (
  <S.Wrapper onClick={onClick}>
    {value}
    <S.CancelIconWrapper>
      <CancelIcon />
    </S.CancelIconWrapper>
  </S.Wrapper>
);

export default SelectedFilter;
