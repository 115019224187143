import styled from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  position: relative;
`;

export const SectionContent = styled.div`
  padding: 25px 0 0 0;
`;

export const TitleContainer = styled.div`
  margin: 0 0 35px 0;

  ${mediaQuery.medium} {
    margin: 35px 0;
  }
`;
