import * as React from 'react';
import styled from 'styled-components/macro';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import { withSpaces } from '../../utils/number';
import * as S from './StatisticsCharts.styles';
import AreaChart from '../Charts/AreaChart/AreaChart';

const TooltipWrapper = styled('div')({
  color: 'black',
  border: '1px solid black',
  padding: '10px',
});

export interface IStatisticsChartsValue {
  value: number;
  date: string;
}

interface IStatisticsChartsProps {
  priceHistory?: any[];
  kmHistory?: any[];
  ageHistory?: any[];
  discountHistory?: any[];
  discountPercentHistory?: any[];
  equipmentHistory?: any[];
  carsAddedHistory?: any[];
  carsSoldHistory?: any[];
}

class StatisticsCharts extends React.Component<IStatisticsChartsProps> {
  public render() {
    return (
      <>
        <SegmentedControl
          tabsContent={this.getChartsTabContentPrimary(this.props)}
          initialSelectedIndex={0}
        />
        <SegmentedControl
          tabsContent={this.getChartsTabContentSecondary(this.props)}
          initialSelectedIndex={0}
        />
        <SegmentedControl
          tabsContent={this.getChartsTabContentTertiary(this.props)}
          initialSelectedIndex={0}
        />
      </>
    );
  }

  private getChartsTabContentPrimary = ({
    priceHistory,
    kmHistory,
  }: IStatisticsChartsProps): any[] => {
    const chartsTabContent = [
      {
        label: 'Průměrná cena',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.priceHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' Kč'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, ' Kč')}
            />
          </S.ChartWrapper>
        ),
      },
      {
        label: 'Průměrně najeto',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.kmHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' km'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, ' km')}
            />
          </S.ChartWrapper>
        ),
      },
      {
        label: 'Průměrné stáří inzerátu',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.ageHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' dní'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, 'dní')}
            />
          </S.ChartWrapper>
        ),
      },
    ];

    return chartsTabContent;
  };

  private getChartsTabContentSecondary = ({
    discountHistory,
    discountPercentHistory,
    equipmentHistory,
  }: IStatisticsChartsProps): any[] => {
    const chartsTabContent = [
      {
        label: 'Průměrná sleva',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.discountHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' Kč'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, 'Kč')}
            />
          </S.ChartWrapper>
        ),
      },
      {
        label: 'Podíl zlevněných vozů',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.discountPercentHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' %'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, '%')}
            />
          </S.ChartWrapper>
        ),
      },
      {
        label: 'Průměrná vybavenost',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={this.props.equipmentHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' prvků'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, 'prvků')}
            />
          </S.ChartWrapper>
        ),
      },
    ];

    return chartsTabContent;
  };

  private getChartsTabContentTertiary = ({
    carsAddedHistory,
    carsSoldHistory,
  }: IStatisticsChartsProps): any[] => {
    const chartsTabContent = [
      {
        label: 'Počet přidaných vozů',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={carsAddedHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' vozů'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, '')}
            />
          </S.ChartWrapper>
        ),
      },
      {
        label: 'Počet prodaných vozů',
        content: (
          <S.ChartWrapper>
            <AreaChart
              data={carsSoldHistory || []}
              lineDataKey='value'
              xAxisDataKey='date'
              yAxisUnit=' vozů'
              yAxisTickFormatter={this.formatAxisValue}
              tooltipContent={e => this.formatTooltipValue(e, '')}
            />
          </S.ChartWrapper>
        ),
      },
    ];

    return chartsTabContent;
  };

  private formatAxisValue(value: any, unit: any): string {
    return `${withSpaces(value)}` || '';
  }

  private formatTooltipValue(e: any, unit: string): any {
    const hasTooltipData = e.active && e.payload != null && e.payload[0] != null;
    const value =
      (e &&
        e.payload &&
        e.payload.length &&
        e.payload[0] &&
        e.payload[0].payload &&
        e.payload[0].payload.value) ||
      0;
    if (hasTooltipData) {
      return (
        <TooltipWrapper className='recharts'>
          <span className='recharts'>{withSpaces(value) || 0}</span>
          <span className='recharts'> {unit}</span>
        </TooltipWrapper>
      );
    }
    return '';
  }
}

export default StatisticsCharts;
