declare var FB: any;
declare global {
  // tslint:disable-next-line
  interface Window {
    fbAsyncInit: () => void;
  }
}

export function fbLoadApi() {
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_FB_API_VERSION,
      });

      FB.AppEvents.logPageView();

      resolve('ok');
    };
    // tslint:disable-next-line
    (function(d, s, id) {
      let js,
        // tslint:disable-next-line
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      if (fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');
  });
}

export function fbApiLogin() {
  return new Promise((resolve, reject) => {
    FB.login(
      response => {
        if (response.authResponse) {
          resolve(response);
        } else {
          reject(new Error('Facebook login was not finished successfully'));
        }
      },
      { scope: 'public_profile,email' }
    );
  });
}

export function fbApiLoginStatus() {
  return new Promise(resolve => {
    FB.getLoginStatus(response => {
      resolve(response.status);
    });
  });
}
