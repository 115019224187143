import styled from 'styled-components/macro';
import Dialog from '../Dialog';
import Input from '../FormElements/Input';
import ErrorMessage from '../ErrorMessage';
import { mediaQuery } from '../../../styles/shared.styles';

export const StyledDialog = styled(Dialog)({
  maxWidth: '800px',
});

export const DialogContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  [mediaQuery.medium]: {
    flexDirection: 'row',
  },
});

export const InfoContainer = styled('div')({
  padding: '40px 20px',
  backgroundColor: '#F4F4F4',
  width: '100%',
  display: 'inline-block',
  lineHeight: '19px',
  [mediaQuery.medium]: {
    padding: '50px 50px 40px 50px',
  },
});

export const FormContainer = styled('div')({
  padding: '40px 20px',
  width: '100%',
  display: 'inline-block',
  lineHeight: '19px',
  [mediaQuery.medium]: {
    minWidth: '420px',
    padding: '50px 50px 40px 50px',
  },
});

export const StyledInput = styled(Input)({
  marginBottom: '10px',
});

export const ButtonContainer = styled('div')({
  marginBottom: '50px',
  textAlign: 'left',
});

export const GoogleButtonContainer = styled('div')({
  marginBottom: '10px',
});

export const DialogDivider = styled('hr')({
  backgroundColor: '#CDCDCD',
  border: 'none',
  color: '#CDCDCD',
  height: '2px',
  margin: '30px 0 30px 0',
});

export const StyledErrorMessage = styled(ErrorMessage)({
  marginBottom: '5px',
});

export const FakeLinkContainer = styled.div`
  line-height: 72px;
  margin: 5px 0;
`;
