import * as React from 'react';

import SectionHeader from 'app/components/SectionHeader';
import { Container, ContentWrapper } from 'app/components/_shared_';
import { TableLayout } from 'app/components/Table';
import { SectionHeaderContainer } from 'app/components/_shared_';
import FormattedValue from 'app/components/FormattedValue';
import { VerifyVinBaseLayout } from 'app/components/VerifyVin/components/VerifyVinBaseLayout';
import {
  VerifyVinTableHeader,
  VerifyVinTableRow,
} from 'app/components/VerifyVin/components/VerifyVinTable';
import { VerifyAgainMessage } from 'app/components/VerifyVin/components/VerifyAgainMessage';

import { ILeasingCheckResult } from 'app/models/Api';
import { useVerifyVinRoute } from 'app/hooks';
import { LeasingNoLookupsError } from './LeasingNoLookupsError';

import leasingCheckBlurredImg from 'assets/images/leasing-check-blurred.png';

interface IVerifyVinLeasingCheckProps {
  data: ILeasingCheckResult;
  vin: string;
  isAuthorized: boolean;
  dataIsLoading: boolean;
  dataError: string;
}

export const VerifyVinLeasingCheck: React.SFC<IVerifyVinLeasingCheckProps> = props => {
  const sectionHeaderText = 'Databáze úvěrových společností a leasingů';
  const { data, dataError } = props;
  const { isPremiumVersion, isPremiumRoute } = useVerifyVinRoute();

  if (dataError) return <LeasingNoLookupsError />;

  return (
    <VerifyVinBaseLayout
      sectionHeaderText={sectionHeaderText}
      blurredImgSrc={leasingCheckBlurredImg}
      {...props}
    >
      <Container>
        <ContentWrapper>
          <SectionHeaderContainer>
            <SectionHeader title={sectionHeaderText} />
          </SectionHeaderContainer>
          {isPremiumRoute && data?.lastCheckedAt && (
            <VerifyAgainMessage lastCheckedAt={data?.lastCheckedAt} vin={props.vin} />
          )}
          <TableLayout data-cy='leasing-check-table'>
            <VerifyVinTableHeader primaryTitle='Společnost' secondaryTitle='Záznam' />
            {!!data?.data?.length &&
              data?.data?.map((item, idx) => (
                <VerifyVinTableRow
                  rowTitle={item?.label}
                  key={item?.label}
                  striped={idx % 2 === 0}
                  dataCy='leasing-check-table-row'
                >
                  <FormattedValue
                    value={item?.value === 'leased' ? 'Ano' : 'Ne'}
                    fontSize='base'
                    blurred={!isPremiumVersion}
                    iconType={
                      !isPremiumVersion ? null : item?.value === 'leased' ? 'fail' : 'success'
                    }
                  />
                </VerifyVinTableRow>
              ))}
          </TableLayout>
        </ContentWrapper>
      </Container>
    </VerifyVinBaseLayout>
  );
};

VerifyVinLeasingCheck.defaultProps = {};
