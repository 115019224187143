import { colors, flex, fontFamily, fontSize, mediaQuery } from 'styles/shared.styles';
import styled from 'styled-components/macro';

export const ChartMargin = {
  top: 20,
  right: 10,
  bottom: 30,
  left: 10,
};

export const ChartMarginPrint = {
  top: 10,
  right: 0,
  bottom: 0,
  left: 10,
};

export const ChartTitle = styled('div')({
  ...flex.flex,
  ...flex.centerHorizontalH,
  margin: '0 20px 0 0',
});

export const ChartContainer = styled('div')(
  {
    padding: '20px 0',
    backgroundColor: colors.white,
    borderRadius: '9px',
    boxShadow: '0 2px 20px 0 rgba(0,0,0,0.04)',
    [mediaQuery.medium]: {
      padding: '20px 40px 50px 20px',
    },
    [mediaQuery.print]: {
      width: '100%',
      padding: '20px 0 50px 0',
    },
    // Axis font
    '.recharts-cartesian-axis-tick-value': {
      color: colors.textGrey,
    },
    // XAxis font
    '.recharts-cartesian-axis-tick-value[text-anchor="middle"]': {
      fontFamily: fontFamily.medium,
      fontSize: fontSize.mini,
      letterSpacing: '-0.26px',
    },
    // YAxis font
    '.recharts-cartesian-axis-tick-value[text-anchor="end"]': {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.small,
      letterSpacing: '-0.26px',
    },
    // legend
    '.recharts-legend-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      top: '0 !important',
      [mediaQuery.print]: {
        right: '20px !important',
      },
    },
    '.recharts-default-legend': {
      display: 'flex',
    },
    '.recharts-legend-item ': {
      display: 'flex !important',
      marginRight: '20px !important',
      fontFamily: fontFamily.medium,
      fontSize: fontSize.small,
      '&:first-child': {
        color: colors.primary,
      },
      '&:last-child': {
        marginRight: '0 !important',
        color: colors.purple,
      },
    },
    // legend icon
    '.recharts-wrapper': {
      [mediaQuery.print]: {
        textAlign: 'center',
        width: '100% !important',
      },
    },
    '.recharts-surface': {
      [mediaQuery.medium]: {
        marginRight: '8px !important',
      },
      [mediaQuery.print]: {
        marginRight: '0 !important',
      },
    },
  },
  ({ theme }) => ({
    [mediaQuery.print]: {
      border: theme.borders.base,
    },
  })
);
