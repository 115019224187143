import React from 'react';
import styled from 'styled-components/macro';

import { Row } from 'app/components/Row';
import {
  cardsTextsMap,
  cardsStateIconsMap,
  cardsThumbnailsMap,
  checkIsStolenCar,
  checkHasKmManipulated,
  thumbBgColorMap,
} from './SmartCards.utils';
import { SmartCard } from '../SmartCard';
import JsonView from 'app/components/_DEV_/JsonView';
import { useTypedSelector } from 'app/store';
import { mediaQuery } from 'styles/shared.styles';
import { useVerifyVinRoute, useAuthenticated } from 'app/hooks';

export const SmartCards: React.FC = () => {
  const { isAuthenticated } = useAuthenticated();
  const ad = useTypedSelector(({ ads }) => ads.adsByVinList?.ad);
  const stolenCars = useTypedSelector(({ ads }) => ads.stolenCars);
  const kmHistory = useTypedSelector(({ ads }) => ads.kmHistory || ads.kmHistoryNotFoundVin);
  const leasingCheck = useTypedSelector(({ ads }) => ads.vinLeasingCheck);
  const { isPremiumVersion } = useVerifyVinRoute();

  const isStolenCar = isAuthenticated ? checkIsStolenCar(stolenCars) : false;
  const isCzOrigin = ad ? ad?.countryOrigin === 'Česká republika' : false;
  const hasAdInDb = !!ad;
  const kmDataLength = kmHistory?.data?.tableDataLength;
  const kmManipulation = kmHistory?.kmManipulation;
  const hasKmManipulated = isAuthenticated ? checkHasKmManipulated(kmHistory) : false;
  const hasLeasingRecord = leasingCheck?.data?.some(i => i.value === 'leased');

  const { stolen, czOrigin, hasAd, km, leasing } = cardsTextsMap;
  const leasingMap = leasing(leasingCheck?.data);
  const mr = '10px';

  return (
    <>
      <CardsContainer
        width={1}
        overflowX='auto'
        justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
      >
        <SmartCard
          titleEnabled={!!isAuthenticated}
          descEnabled={!!isAuthenticated}
          category={czOrigin.category}
          title={
            isCzOrigin
              ? czOrigin.titleSuccess
              : ad?.countryOrigin
              ? czOrigin.titleFail
              : czOrigin.titleNeutral
          }
          description={
            isCzOrigin
              ? czOrigin.descSuccess
              : ad?.countryOrigin
              ? czOrigin.descFail
              : czOrigin.descNeutral
          }
          thumb={
            isCzOrigin ? cardsThumbnailsMap.czOrigin.success : cardsThumbnailsMap.czOrigin.fail
          }
          thumbBgColor={
            isCzOrigin
              ? thumbBgColorMap.success
              : ad?.countryOrigin
              ? thumbBgColorMap.important
              : thumbBgColorMap.neutral
          }
          stateIcon={
            !isAuthenticated
              ? cardsStateIconsMap.neutral
              : isCzOrigin
              ? cardsStateIconsMap.success
              : ad?.countryOrigin
              ? cardsStateIconsMap.important
              : cardsStateIconsMap.neutral
          }
          mr={mr}
        />
        <SmartCard
          titleEnabled={!!isAuthenticated}
          descEnabled={!!isAuthenticated}
          category={km.category}
          title={
            !isAuthenticated || kmDataLength <= 1 || kmManipulation === null
              ? km.titleNeutral
              : hasKmManipulated
              ? km.titleFail
              : km.titleSuccess
          }
          description={
            !isAuthenticated || kmDataLength <= 1 || kmManipulation === null
              ? km.descNeutral
              : hasKmManipulated
              ? km.descFail
              : km.descSuccess
          }
          thumb={hasKmManipulated ? cardsThumbnailsMap.km.fail : cardsThumbnailsMap.km.success}
          thumbBgColor={
            !isAuthenticated || kmDataLength <= 1 || kmManipulation === null
              ? thumbBgColorMap.neutral
              : hasKmManipulated
              ? thumbBgColorMap.fail
              : thumbBgColorMap.success
          }
          stateIcon={
            !isAuthenticated || kmDataLength <= 1 || kmManipulation === null
              ? cardsStateIconsMap.neutral
              : hasKmManipulated
              ? cardsStateIconsMap.important
              : cardsStateIconsMap.success
          }
          mr={mr}
        />
        <SmartCard
          titleEnabled={!!isAuthenticated}
          descEnabled={!!isAuthenticated}
          category={hasAd.category}
          title={hasAdInDb ? hasAd.titleFail : hasAd.titleSuccess}
          description={hasAdInDb ? hasAd.descFail : hasAd.descSuccess}
          thumb={hasAdInDb ? cardsThumbnailsMap.hasAd.fail : cardsThumbnailsMap.hasAd.success}
          thumbBgColor={
            !isAuthenticated
              ? thumbBgColorMap.neutral
              : hasAdInDb
              ? thumbBgColorMap.important
              : thumbBgColorMap.success
          }
          stateIcon={
            !isAuthenticated
              ? cardsStateIconsMap.neutral
              : hasAdInDb
              ? cardsStateIconsMap.important
              : cardsStateIconsMap.success
          }
          mr={mr}
        />
        <SmartCard
          titleEnabled={!!isAuthenticated}
          descEnabled={!!isAuthenticated}
          category={stolen.category}
          title={
            !isPremiumVersion
              ? stolen.titleNeutral
              : isStolenCar
              ? stolen.titleFail
              : stolen.titleSuccess
          }
          description={
            !isPremiumVersion
              ? stolen.descNeutral
              : isStolenCar
              ? stolen.descFail
              : stolen.descSuccess
          }
          thumb={
            !isPremiumVersion
              ? cardsThumbnailsMap.stolen.neutral
              : isStolenCar
              ? cardsThumbnailsMap.stolen.fail
              : cardsThumbnailsMap.stolen.success
          }
          thumbBgColor={
            !isPremiumVersion
              ? thumbBgColorMap.neutral
              : isStolenCar
              ? thumbBgColorMap.fail
              : thumbBgColorMap.success
          }
          stateIcon={
            !isPremiumVersion
              ? cardsStateIconsMap.neutral
              : isStolenCar
              ? cardsStateIconsMap.fail
              : cardsStateIconsMap.success
          }
          mr={mr}
        />
        <SmartCard
          titleEnabled={!!isAuthenticated}
          descEnabled={!!isAuthenticated}
          category={leasingMap.category}
          title={
            !isPremiumVersion
              ? leasingMap.titleNeutral
              : hasLeasingRecord
              ? leasingMap.titleFail
              : leasingMap.titleSuccess
          }
          description={
            !isPremiumVersion
              ? leasingMap.descNeutral
              : hasLeasingRecord
              ? leasingMap.descFail
              : leasingMap.descSuccess
          }
          thumb={
            !isPremiumVersion
              ? cardsThumbnailsMap.leasing.neutral
              : hasLeasingRecord
              ? cardsThumbnailsMap.leasing.fail
              : cardsThumbnailsMap.leasing.success
          }
          thumbBgColor={
            !isPremiumVersion
              ? thumbBgColorMap.neutral
              : hasLeasingRecord
              ? thumbBgColorMap.fail
              : thumbBgColorMap.success
          }
          stateIcon={
            !isPremiumVersion
              ? cardsStateIconsMap.neutral
              : hasLeasingRecord
              ? cardsStateIconsMap.fail
              : cardsStateIconsMap.success
          }
        />
      </CardsContainer>
      {isAuthenticated && (
        <JsonView
          data={{ ad, stolenCars, kmManipulation: kmHistory?.kmManipulation }}
          showMainTitle={true}
          collapsed={1}
        />
      )}
    </>
  );
};

const CardsContainer = styled(Row)`
  ${mediaQuery.print} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
