import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Track page views in google analytics
 */
export const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    if (typeof ga === 'function') {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview');
    }
    // (ga as any).send(['pageview', location.pathname]);
  }, [location]);
};
