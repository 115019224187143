import React from 'react';

import { Loader } from 'app/components/Loader';
import { Row } from 'app/components/Row';
import { Column, TColumnProps } from 'app/components/Column';

interface ILoadingContainerProps extends TColumnProps {
  isLoading: boolean;
  children: React.ReactNode;
  hasNoData?: boolean;
  NoDataComponent?: React.ReactNode;
  overlayEnabled?: boolean;
}

export const LoadingContainer: React.FC<ILoadingContainerProps> = ({
  isLoading,
  children,
  hasNoData = false,
  NoDataComponent = null,
  overlayEnabled = true,
  ...props
}) => {
  if (isLoading) {
    return (
      <Row position='relative' flexDirection='inherit' width='100%' minHeight='60vh'>
        <Column
          width='100%'
          height='100%'
          minHeight='100%'
          position='absolute'
          zIndex={1000}
          alignItems='center'
          justifyContent='center'
          background='rgba(240, 244, 248, 0.6)'
          left={0}
          top={0}
          right={0}
          bottom={0}
          {...props}
        >
          <Loader size='52px' />
        </Column>
        {overlayEnabled ? children : null}
      </Row>
    );
  }

  const noData = !isLoading && hasNoData && NoDataComponent;
  if (noData) return <>{NoDataComponent}</>;

  return <>{children}</>;
};
