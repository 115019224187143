import * as React from 'react';
import gtmParts from 'react-google-tag-manager';

interface IGoogleTagManagerProps {
  gtmId?: string;
  dataLayerName?: string;
  additionalEvents?: object;
  previewVariables?: string;
  scriptId?: string;
  scheme?: string;
}

export default class GoogleTagManager extends React.Component<IGoogleTagManagerProps> {
  public componentDidMount() {
    const dataLayerName = this.props.dataLayerName || 'dataLayer';
    const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm';

    if (!window[dataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId);

      // eslint-disable-next-line
      gtmScriptNode && eval(gtmScriptNode.textContent as string);
    }
  }

  public render() {
    const { gtmId } = this.props;
    if (!gtmId) return null;

    const gtm = gtmParts({
      id: gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
      scheme: this.props.scheme || 'https:',
    });

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}>{gtm.scriptAsReact()}</div>
      </div>
    );
  }
}
