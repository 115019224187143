import * as React from 'react';

import SectionHeader from 'app/components/SectionHeader';
import { CompactAdsList } from 'app/components/CompactAdsList';
import { DataLoading } from 'app/components/DataLoading';
import { DataLoadingError } from 'app/components/DataLoadingError';
import { DataNoResults } from 'app/components/DataNoResults';
import { LoginBanner } from 'app/components/LoginBanner';
import { IGenericEndpointResult } from 'app/models/Api';

import { colors } from 'styles/shared.styles';
import {
  Container,
  ContentWrapper,
  BlurryImageContainer,
  SectionHeaderContainer,
} from 'app/components/_shared_';

import adsListsBlurredImg from 'assets/images/generic-ads-list-blurred.png';

interface IGenericAdsListProps {
  badgeValuePropName: string;
  data: IGenericEndpointResult;
  dataError: string;
  dataIsLoading: boolean;
  isAuthorized: boolean;
  badgeBgColor?: string;
  blurryImgSrc?: string;
  sectionHeaderText?: string;
  sectionSubheaderText?: string;
  showLoginDialog: () => void;
  badgeValueFormatter?: (value) => void;
}

export const GenericAdsList: React.SFC<IGenericAdsListProps> = ({
  badgeBgColor = colors.primary,
  badgeValueFormatter,
  badgeValuePropName,
  data,
  dataError,
  dataIsLoading,
  isAuthorized,
  sectionHeaderText,
  sectionSubheaderText,
  showLoginDialog,
  blurryImgSrc = adsListsBlurredImg,
}) => {
  if (dataIsLoading) {
    return <DataLoading sectionHeaderText={sectionHeaderText} />;
  }

  if (dataError) {
    return <DataLoadingError sectionHeaderText={sectionHeaderText} />;
  }

  if (!data.total) {
    return <DataNoResults sectionHeaderText={sectionHeaderText} />;
  }

  const { docs } = data;
  return (
    <Container>
      <ContentWrapper>
        <>
          {!isAuthorized ? (
            <>
              {sectionHeaderText && (
                <Header
                  sectionHeaderText={sectionHeaderText}
                  sectionSubheaderText={sectionSubheaderText}
                />
              )}
              <BlurryTableImage showLoginDialog={showLoginDialog} src={blurryImgSrc} />
            </>
          ) : (
            <>
              {sectionHeaderText && (
                <Header
                  sectionHeaderText={sectionHeaderText}
                  sectionSubheaderText={sectionSubheaderText}
                />
              )}
              <CompactAdsList
                data={docs}
                badgeBgColor={badgeBgColor}
                badgeValuePropName={badgeValuePropName}
                badgeValueFormatter={badgeValueFormatter}
                name='generic'
              />
            </>
          )}
        </>
      </ContentWrapper>
    </Container>
  );
};

GenericAdsList.defaultProps = {
  data: { docs: [], total: 0 },
  dataError: '',
};

const BlurryTableImage = ({ showLoginDialog, src }) => {
  return (
    <BlurryImageContainer>
      <LoginBanner text='Pro ocenění vozu se musíte' btnText='přihlásit' />
      <img src={src} alt='blurry-img' />
    </BlurryImageContainer>
  );
};

const Header = ({ sectionHeaderText, sectionSubheaderText }) => {
  return (
    <SectionHeaderContainer>
      <SectionHeader title={sectionHeaderText} subheaderText={sectionSubheaderText} />
    </SectionHeaderContainer>
  );
};
