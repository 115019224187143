import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { colors } from '../../styles/shared.styles';

const StyledCard = styled('div')({
  border: '1px solid #4A5560',
  backgroundColor: colors.white,
  boxShadow: '0 0 14px 0 #4A5560',
});

const CardPadding = css({
  padding: '25px 25px 35px 25px',
});

const CardFlexible = css({
  flex: 1,
});

interface ICardProps {
  bem: any;
  children: React.ReactNode;
  padding?: boolean;
  flexible?: boolean;
}

const Card: React.SFC<ICardProps> = ({ padding, flexible, children, ...other }) => {
  const cardClass = css(padding ? CardPadding : null, flexible ? CardFlexible : null);
  return (
    <StyledCard className={cardClass} {...other}>
      {children}
    </StyledCard>
  );
};

const defaultProps: Partial<ICardProps> = {
  padding: false,
  flexible: false,
};

Card.defaultProps = defaultProps;

export default Card;
