import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { VerifyVinPublic } from './VerifyVinPublic';
import Ad from 'app/models/Ad';

interface IOwnProps {
  vin: string;
  isValidVin: boolean;
}

interface IStateProps {
  adsByVinListIsLoading: boolean;
  adDetails: Ad;
  adsImages: Ad['images'];
}

type Props = IOwnProps & IStateProps & RouteComponentProps;

class VerifyVinPublicContainerComponent extends React.Component<Props> {
  public static defaultProps = {
    vin: '',
  };

  public render() {
    const { vin, adDetails, adsByVinListIsLoading, adsImages, isValidVin } = this.props;
    return (
      <VerifyVinPublic
        vin={vin}
        isValidVin={isValidVin}
        adDetails={adDetails}
        adDetailsIsLoading={adsByVinListIsLoading}
        adsImages={adsImages}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    adDetails: state.ads.adsByVinList.ad,
    adsImages: state.ads.adsByVinList.adsImages,
    adsByVinListIsLoading: state.ads.adsByVinListIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export const VerifyVinPublicContainer = withRouter(
  connect<IStateProps, {}, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyVinPublicContainerComponent)
);
