import * as React from 'react';
import { LoginBanner } from 'app/components/LoginBanner';
import { BlurryImageContainer, FullWidthImage } from 'app/components/_shared_';

interface IPremiumSignInProps {
  signInText?: string;
  signInTextBtn?: string;
  bgSrc?: string;
}

export const PremiumSignIn: React.FC<IPremiumSignInProps> = ({
  bgSrc,
  signInText = 'Dostupné údaje se zobrazují pouze přihlášeným uživatelům',
  signInTextBtn = 'Přihlásit se',
}) =>
  bgSrc ? (
    <BlurryImageContainer>
      <LoginBanner text={signInText} btnText={signInTextBtn} />
      <FullWidthImage src={bgSrc} alt='image' />
    </BlurryImageContainer>
  ) : (
    <LoginBanner text={signInText} btnText={signInTextBtn} />
  );
