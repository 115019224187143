import * as React from 'react';

import {
  Container,
  ParametersRow,
  ParametersColumn,
  ParametersTitleTypography,
  ParametersValueTypography,
  ParametersTitle,
  ParametersValue,
} from 'app/components/_shared_';
import NotSpecifiedValue from 'app/components/NotSpecifiedValue';
import Typography from 'app/components/Typography';
import { safe } from 'app/utils/safe';

import Ad from 'app/models/Ad';

interface IAdParametersMiniProps {
  ad: Ad;
}

export const AdParametersMini: React.SFC<IAdParametersMiniProps> = ({ ad }) => (
  <Container>
    <ParametersRow>
      <ParametersColumn>
        <ParametersTitle>
          <ParametersTitleTypography text={'VIN'} />
        </ParametersTitle>
        <ParametersValue>
          <ParametersValueTypography
            text={safe(() => ad.vin, <NotSpecifiedValue />)}
            weight='medium'
          />
        </ParametersValue>
      </ParametersColumn>
    </ParametersRow>

    <ParametersRow striped={true}>
      <ParametersColumn>
        <ParametersTitle>
          <ParametersTitleTypography text={'Modelový rok'} />
        </ParametersTitle>
        <ParametersValue>
          <ParametersValueTypography
            text={safe(() => ad.year, <NotSpecifiedValue />)}
            weight='medium'
          />
        </ParametersValue>
      </ParametersColumn>
    </ParametersRow>

    <ParametersRow>
      <ParametersColumn>
        <ParametersTitle>
          <ParametersTitleTypography text={'Palivo'} />
        </ParametersTitle>
        <ParametersValue>
          <ParametersValueTypography
            text={safe(() => ad.fuel, <NotSpecifiedValue />)}
            weight='medium'
          />
        </ParametersValue>
      </ParametersColumn>
    </ParametersRow>

    <ParametersRow striped={true}>
      <ParametersColumn>
        <ParametersTitle>
          <ParametersTitleTypography text={'Výkon'} />
        </ParametersTitle>
        <ParametersValue>
          <Typography
            text={safe(() => (ad.kw ? `${ad.kw} kW` : null), <NotSpecifiedValue />)}
            weight='medium'
          />
        </ParametersValue>
      </ParametersColumn>
    </ParametersRow>

    <ParametersRow>
      <ParametersColumn>
        <ParametersTitle>
          <ParametersTitleTypography text={'Barva'} />
        </ParametersTitle>
        <ParametersValue>
          <ParametersValueTypography
            text={safe(() => ad.color && ad.color.replace(/,/g, ''), <NotSpecifiedValue />)}
            weight='medium'
          />
        </ParametersValue>
      </ParametersColumn>
    </ParametersRow>
  </Container>
);
