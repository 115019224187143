import { flex, mediaQuery } from '../../../../styles/shared.styles';
import styled from 'styled-components/macro';

export const JsonViewContainer = styled.div`
  ${{ ...(flex.vertical as any) }};
  width: 100%;
  position: relative;

  .react-json-view {
    width: 100%;
    padding: 20px;
    user-select: text;
  }

  .copy-icon svg {
    height: 12px !important;
    width: 12px !important;
  }

  ${mediaQuery.print} {
    display: none;
  }
`;

export const MainTitleContainer = styled.div`
  ${{ ...flex.flex }};
  width: 100%;
  padding: 30px 0;
`;

export const TitleContainer = styled.div`
  ${{ ...flex.flex }};
  width: 100%;
  padding: 20px 0;
`;
