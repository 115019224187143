import * as React from 'react';
import { noop } from 'lodash-es';
import styled from 'styled-components/macro';

import PremiumBanner from 'app/components/PremiumBanner';
import { RoundedBlurryImageContainer, ResponsiveImage } from 'app/components/_shared_';
import { LoginBanner } from 'app/components/LoginBanner';

import adHistoryChartBlurryImg from 'assets/images/ad-history-chart.jpg';

interface IDummyChartProps {
  showLoginDialog?: () => void;
  overlayContent?: React.ReactNode;
}

export const DummyChart: React.SFC<IDummyChartProps> = ({
  showLoginDialog = noop,
  overlayContent,
}) => {
  return (
    <Container>
      <PremiumBanner type='dark' overlay={true}>
        {overlayContent || (
          <LoginBanner
            text='Dostupné údaje se zobrazují pouze přihlášeným uživatelům'
            btnText='Přihlásit se'
          />
        )}
      </PremiumBanner>
      <ResponsiveImage src={adHistoryChartBlurryImg} alt='ad-history-chart' />
    </Container>
  );
};

const Container = styled(RoundedBlurryImageContainer)`
  overflow: initial;
`;
