import * as React from 'react';
import SectionHeader from 'app/components/SectionHeader';
import Typography from 'app/components/Typography';
import {
  Container,
  ContentWrapper,
  NoResultsContainer,
  SectionHeaderContainer,
} from 'app/components/_shared_';
import { colors } from 'styles/shared.styles';

interface IDataNoResultsProps {
  text?: string;
  sectionHeaderText?: string;
  sectionSubheaderText?: string;
  sectionSubheaderValue?: string | number;
  sectionSubheaderSuffixText?: string;
}

export const DataNoResults: React.SFC<IDataNoResultsProps> = ({
  text = 'Litujeme, ale neevidujeme žádné záznamy.',
  sectionHeaderText = '',
  sectionSubheaderText = '',
  sectionSubheaderValue = '',
  sectionSubheaderSuffixText = '',
}) => {
  return (
    <Container>
      <ContentWrapper>
        {sectionHeaderText && (
          <SectionHeaderContainer>
            <SectionHeader
              title={sectionHeaderText}
              subheaderText={sectionSubheaderText}
              subheaderValue={sectionSubheaderValue}
              subheaderSuffixText={sectionSubheaderSuffixText}
            />
          </SectionHeaderContainer>
        )}
        <NoResultsContainer>
          <Typography text={text} size='base' weight='regular' color={colors.textDefault} />
        </NoResultsContainer>
      </ContentWrapper>
    </Container>
  );
};

DataNoResults.defaultProps = {};
