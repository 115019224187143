import * as React from 'react';

import Filter from '../Filter';
import Dialog from 'app/components/Dialog';
import * as S from './FilterDialog.styles';

interface IFilterDialogProps {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

interface IFilterDialogState { }

class FilterDialog extends React.Component<IFilterDialogProps, IFilterDialogState> {
  public static defaultProps: Partial<IFilterDialogProps> = {
    visible: false,
  };

  public render() {
    const {
      visible,
      onClose,
      onConfirm,
    } = this.props;

    return (
      <Dialog
        visible={visible}
        onClose={onClose}
      >
        <S.DialogContentWrapper>
          <Filter onSubmitFilter={onConfirm} />
        </S.DialogContentWrapper>
      </Dialog>
    )
  }
}

export default FilterDialog;
