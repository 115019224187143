import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { colors } from '../../styles/shared.styles';

const StyledCheckbox = styled('label')({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const checkedStyle = css({
  backgroundColor: colors.primary,
  border: 0,
});

const CheckMark = styled('div')(
  {
    border: '1px solid #979797',
    color: colors.white,
    display: 'inline-block',
    maxHeight: '30px',
    maxWidth: '30px',
    minHeight: '30px',
    minWidth: '30px',
    overflow: 'hidden',
    padding: '4px',
    transition: 'background-color .2s ease-in-out, border .2s ease-in-out',
  },
  (props: ICheckboxProps) => (props.checked ? checkedStyle : null)
);

const iconStyle = css({
  width: '100%',
  height: '100%',
  fill: colors.white,
});

const StyledCheckLabel = styled('label')({
  marginLeft: '8px',
  color: '#546074',
  fontSize: '0.9rem',
});

interface ICheckboxProps {
  checked?: boolean;
  label?: string;
  name?: string;
  onChange?: (value: boolean) => void;
}

class Checkbox extends React.PureComponent<ICheckboxProps> {
  public static defaultProps: Partial<ICheckboxProps> = {
    checked: false,
  };

  public render() {
    const { checked, label, name } = this.props;

    return (
      <StyledCheckbox onClick={this.toggle}>
        <CheckMark checked={checked} className={`checkbox-${name}`}>
          {checked && (
            <svg aria-hidden='true' viewBox='0 0 512 512' className={iconStyle}>
              <path
                fill='#ffffff'
                d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
              />
            </svg>
          )}
        </CheckMark>
        {label && <StyledCheckLabel dangerouslySetInnerHTML={{ __html: label }} />}
      </StyledCheckbox>
    );
  }

  private toggle = () => {
    const { checked, onChange } = this.props;

    if (onChange) {
      onChange(!checked);
    }
  };
}

export default Checkbox;
