import * as React from 'react';

import Typography from 'app/components/Typography';
import Heading from 'app/components/Heading';
import Button from 'app/components/Button';
import Badge from 'app/components/Badge';
import { Row } from 'app/components/Row';
import {
  MyCarItemStyled,
  MyCarItemKeyStyled,
  MyCarItemKeyValueStyled,
  MyCarItemCarNameStyled,
  MyCarItemColorStyled,
  MyCarItemColorWhiteStyled,
  MyCarItemTimeStyled,
  MyCarItemButtonRemoveStyled,
  MyCarButtonPlace,
  MyCarItemFavoriteBox,
  IconActive,
  IconInactive,
} from './MyCars.styles';
import { IMyCar } from './MyCarsList';
import * as FaStar from '@fortawesome/fontawesome-free-solid/faStar';
import * as FaStarO from '@fortawesome/fontawesome-free-regular/faStar';

import { removeDiacritics } from 'app/utils/string';
import { withRouter, RouteComponentProps } from 'react-router';
import { safe, mustBeSet } from 'app/utils/safe';
import { ago } from 'app/utils/ago';
import { formatNumber } from 'app/utils/numbers';
import { log } from 'app/utils/log';

interface IMyCarsItemProps {
  vin: string;
  data: IMyCar;
  onRemove: (vin: string) => void;
  onSetFavorite: (vin: string, favorite: boolean) => void;
  size: '4' | '2' | '1';
}

const carColorsMap: { [name: string]: string } = {
  cerna: '#373739',
  modra: '#203AC5',
  zlata: '#FFD700',
  zelena: '#0f0',
  seda: '#ccc',
  oranzova: '#FF7400',
  fialova: '#9400D3',
  cervena: '#f00',
  stribrna: '#D3D3D3',
  bila: '#fff',
  zluta: '#ff0',
};

class MyCarsItem extends React.Component<IMyCarsItemProps & RouteComponentProps<any>> {
  protected static convertColor(name: string | undefined) {
    if (typeof name === 'string') {
      const colorName = removeDiacritics(name.toLowerCase());
      if (carColorsMap[colorName]) {
        return carColorsMap[colorName];
      }

      const names = Object.keys(carColorsMap);
      for (const kName of names) {
        if (colorName.indexOf(kName) === 0) {
          return carColorsMap[kName];
        }
      }
    }
    return '#fff';
  }

  protected static convertTranssmision(type: string): string {
    if (!type) {
      throw new Error('Type is not set');
    }
    const typel = type.toLowerCase();
    if (typel === 'automatic') {
      return 'Automatická';
    }

    return type;
  }

  protected static convertFuel(type: string): string {
    if (!type) {
      throw new Error('Type is not set');
    }
    const typel = type.toLowerCase();
    if (typel === 'electric') {
      return 'Elektřina';
    }

    return type;
  }

  public render() {
    const createdDate = safe(
      () => ago(new Date(this.props.data.createdAt).getTime()),
      'Nedostupné'
    );
    const itemColor = MyCarsItem.convertColor(this.props.data.color);
    const nameArray = [this.props.data.brand, this.props.data.model].filter(i => !!i);
    const isPremium = this.props.data?.payment;

    return (
      <MyCarItemStyled className={`size-${this.props.size}`} data-cy='my-cars-item'>
        {itemColor === '#fff' ? (
          <MyCarItemColorWhiteStyled
            style={{ backgroundColor: MyCarsItem.convertColor(this.props.data.color) }}
          />
        ) : (
          <MyCarItemColorStyled
            style={{ backgroundColor: MyCarsItem.convertColor(this.props.data.color) }}
          />
        )}

        <Row justifyContent='space-between'>
          <MyCarItemTimeStyled>{createdDate}</MyCarItemTimeStyled>

          <Row position='absolute' right={isPremium ? 0 : '20px'} alignItems='center'>
            <MyCarItemFavoriteBox onClick={this.toggleFavorite}>
              {this.props.data && this.props.data.favorite ? (
                <IconActive icon={FaStar} />
              ) : (
                <IconInactive icon={FaStarO} />
              )}
            </MyCarItemFavoriteBox>
            {isPremium && (
              <Badge
                text='PREMIUM'
                type='positive'
                textSize='mini'
                size='small'
                borderRadius='4px 0 0 4px'
                height='22px'
                ml='10px'
                data-cy='my-cars-item-premium-badge'
              />
            )}
          </Row>
        </Row>

        <MyCarItemCarNameStyled>
          <Heading level={3} weight='medium' mb='0'>
            {nameArray.length ? nameArray.join(' ') : 'Nedostupné'}
          </Heading>
        </MyCarItemCarNameStyled>
        <Typography
          text={this.props.vin}
          weight='regular'
          size='small'
          color='textDefault'
          mb='30px'
        />

        <MyCarItemKeyValueStyled>
          <MyCarItemKeyStyled>Motor</MyCarItemKeyStyled>
          <Typography
            text={safe(() => mustBeSet(this.props.data.engine) + ' kw', 'Nedostupné')}
            weight='medium'
            size='base'
            color='textDefault'
            lineHeight='27px'
          />
        </MyCarItemKeyValueStyled>

        <MyCarItemKeyValueStyled>
          <MyCarItemKeyStyled>Palivo</MyCarItemKeyStyled>
          <Typography
            text={safe(() => MyCarsItem.convertFuel(this.props.data.fuel), 'Nedostupné')}
            weight='medium'
            textTransform='uppercase'
            size='base'
            color='textDefault'
            lineHeight='27px'
          />
        </MyCarItemKeyValueStyled>

        <MyCarItemKeyValueStyled>
          <MyCarItemKeyStyled>Převodovka</MyCarItemKeyStyled>
          <Typography
            text={safe(
              () => MyCarsItem.convertTranssmision(this.props.data.transmission),
              'Nedostupné'
            )}
            weight='medium'
            textTransform='uppercase'
            size='base'
            color='textDefault'
            lineHeight='27px'
          />
        </MyCarItemKeyValueStyled>

        <MyCarItemKeyValueStyled>
          <MyCarItemKeyStyled>Nájezd</MyCarItemKeyStyled>
          <Typography
            text={safe(
              () => formatNumber(mustBeSet(this.props.data.mileage)) + ' km',
              'Nedostupné'
            )}
            weight='medium'
            size='base'
            color='textDefault'
            lineHeight='27px'
          />
        </MyCarItemKeyValueStyled>
        <MyCarButtonPlace>
          <Button onClick={this.onVinSubmit} fullWidth={true}>
            Zobrazit vozidlo
          </Button>
        </MyCarButtonPlace>
        <div style={{ textAlign: 'center' }}>
          <MyCarItemButtonRemoveStyled onClick={this.onRemove}>
            Odstranit
          </MyCarItemButtonRemoveStyled>
        </div>
      </MyCarItemStyled>
    );
  }

  protected onVinSubmit = async () => {
    const vin = this.props.vin;
    try {
      const { history } = this.props;
      history.push(`/vin/${vin}`);
    } catch (error) {
      log.error(error);
    }
  };

  protected onRemove = () => {
    this.props.onRemove(this.props.vin);
  };

  protected toggleFavorite = () => {
    if (this.props.data) {
      this.props.onSetFavorite(this.props.vin, !this.props.data.favorite);
    }
  };
}

export default withRouter(MyCarsItem);
