import preval from 'preval.macro';

export const buildInfo = preval`
  const GitRevisionPlugin = require('git-revision-webpack-plugin')
  const gitRevisionPlugin = new GitRevisionPlugin();

  const info = {
    BRANCH: JSON.stringify(gitRevisionPlugin.branch()),
    BUILD_TIME: new Date().toISOString(),
    COMMITHASH: JSON.stringify(gitRevisionPlugin.commithash()),
    ENV: process.env.NODE_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL,
    VERSION: JSON.stringify(gitRevisionPlugin.version()),
    APP_MODE: process.env.REACT_APP_MODE
  };

  module.exports = info;
`;
