import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { VerifyVinNavBar } from './components/VerifyVinNavBar';
import { VerifyVinPublicContainer } from './components/VerifyVinPublic';
import { VerifyVinPrivate } from './components/VerifyVinPrivate';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { checkViewMode, checkVinValidity } from 'app/utils';
import { OG_META_IMAGE } from 'app/constants/meta-tags';
import { useTypedSelector } from 'app/store';

export const VerifyVin: React.FC = props => {
  const { vin } = useParams<{ vin: string }>();
  const isPdfMode = checkViewMode('pdf');
  const pdfExportIsLoading = useTypedSelector(state => state.ads.pdfExportIsLoading);
  const isAuthorized = useTypedSelector(state => !!state.auth.accessToken);

  if (pdfExportIsLoading) return <Loader vin={vin} />;

  return (
    <>
      <Helmet defer={false}>
        <title>Ověření VIN kódu</title>
        <meta name='description' content='Ověříme historii vybraného vozu dle vloženého VIN kódu' />
        <meta
          property='og:description'
          content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
        />
        <meta
          property='twitter:description'
          content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
        />
        <meta property='og:title' content='Ověření VIN kódu | OvěřAuto.cz' />
        <meta property='og:url' content={window.location.href} />
        <meta property='og:image' content={OG_META_IMAGE} />
      </Helmet>
      {isAuthorized ? (
        <VerifyVinPrivate vin={vin} isValidVin={checkVinValidity(vin)} isPdfMode={isPdfMode} />
      ) : (
        <VerifyVinPublicContainer vin={vin} isValidVin={checkVinValidity(vin)} />
      )}
    </>
  );
};

const Loader = ({ vin }) => (
  <>
    <VerifyVinNavBar vin={vin} />
    <AdLoader text={EnumAdLoaderTexts.PDF_IN_PROGRESS} fullscreen={true} />
  </>
);
