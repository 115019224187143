import styled from 'styled-components/macro';
import { fontFamily, flex, mediaQuery } from 'styles/shared.styles';

export const FormWrapper = styled.div``;

export const FormRow = styled.div`
  ${{ ...(flex.flex as any) }};
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 10px 0;
  flex-direction: column;
  position: relative;
`;

export const FormLabel = styled.div`
  margin: 0 5px 0 0;
  font-family: ${fontFamily.medium};
  ${mediaQuery.medium} {
    margin: 0 20px 0 0;
  }
`;
