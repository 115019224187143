import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
