import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as S from './Page.styles';

interface IPageProps {
  componentName: string;
  textAlign?: 'left' | 'center';
  itemsAlign?: 'left' | 'center';
  documentTitle?: string;
}

const defaultProps: Partial<IPageProps> = {
  textAlign: 'left',
  itemsAlign: 'left',
  documentTitle: 'Ověříme historii vozu podle VIN',
};

class Page extends React.Component<IPageProps> {
  public static defaultProps = defaultProps;

  public render() {
    const { componentName, children, textAlign, itemsAlign, documentTitle, ...other } = this.props;

    return (
      <S.StyledPage padding={true} {...other}>
        <Helmet
          defaultTitle='Ověříme historii vozu podle VIN'
          titleTemplate='%s | OvěřAuto.cz'
          defer={false}
        >
          <title>{`${documentTitle} `}</title>
          <meta property='og:title' content={`${documentTitle} | OvěřAuto.cz`} />
          <meta property='og:url' content={window.location.href} />
        </Helmet>
        <div className='row middle-xs'>
          <div className='col-xs-12'>{children}</div>
        </div>
      </S.StyledPage>
    );
  }
}

export default Page;
