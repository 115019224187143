export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';
export const LOAD_USER_PAYMENTS = 'LOAD_USER_PAYMENTS';
export const LOAD_USER_PAYMENTS_SUCCESS = 'LOAD_USER_PAYMENTS_SUCCESS';
export const LOAD_USER_PAYMENTS_FAIL = 'LOAD_USER_PAYMENTS_FAIL';
export const LOAD_AVAILABLE_PACKAGES = 'LOAD_AVAILABLE_PACKAGES';
export const LOAD_AVAILABLE_PACKAGES_SUCCESS = 'LOAD_AVAILABLE_PACKAGES_SUCCESS';
export const LOAD_AVAILABLE_PACKAGES_FAIL = 'LOAD_AVAILABLE_PACKAGES_FAIL';
export const LOAD_USER_PAYMENT_LIMITS = 'LOAD_USER_PAYMENT_LIMITS';
export const LOAD_USER_PAYMENT_LIMITS_SUCCESS = 'LOAD_USER_PAYMENT_LIMITS_SUCCESS';
export const LOAD_USER_PAYMENT_LIMITS_FAIL = 'LOAD_USER_PAYMENT_LIMITS_FAIL';
export const RESET_USER_PAYMENTS = 'RESET_USER_PAYMENTS';
export const RESET_USER_PAYMENT_LIMITS = 'RESET_USER_PAYMENT_LIMITS';
