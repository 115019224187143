import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { PageTemplate as PageTemplateComponent } from 'app/templates/PageTemplate';

/**
 * Public route
 */

interface IPublicRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ReactType;
  pageTemplate?: React.ReactType;
}

export const PublicRoute: React.FC<IPublicRouteProps> = ({
  component: Component,
  pageTemplate: PageTemplate = PageTemplateComponent,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <PageTemplate>
        <Component {...props} />
      </PageTemplate>
    )}
  />
);
