import * as React from 'react';
import Tabs from 'react-accessible-tabs';
import * as S from './SegmentedControl.styles';

interface ISegmentedControlProps {
  tabsContent: {
    label: string;
    content: any;
  }[];
  initialSelectedIndex: number;
  tablistClickEnabled?: boolean;
}

interface ISegmentedControlState {}

const defaultProps: Partial<ISegmentedControlProps> = {
  tabsContent: [],
  initialSelectedIndex: 0,
  tablistClickEnabled: true,
};

class SegmentedControl extends React.Component<ISegmentedControlProps, ISegmentedControlState> {
  public static defaultProps: Partial<ISegmentedControlProps> = defaultProps;

  public render() {
    const { tabsContent, initialSelectedIndex, tablistClickEnabled } = this.props;

    return (
      <S.SegmentedControlContainer tablistClickEnabled={tablistClickEnabled}>
        <Tabs data={tabsContent} initialSelectedIndex={initialSelectedIndex} />
      </S.SegmentedControlContainer>
    );
  }
}

export default SegmentedControl;
