import styled, { css } from 'styled-components/macro';
import { colors, fontFamily, fontSize, mediaQuery } from '../../../styles/shared.styles';

import arrowPrev from '../../../assets/icons/arrow-prev.svg';
import arrowNext from '../../../assets/icons/arrow-next.svg';

export const styleSwipperButton = css({
  backgroundPositionY: '107px',
  height: '100%',
  outline: 'none',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0',
  width: '143px',
  zIndex: 2,
  '&::before': {
    content: `' '`,
    width: '143px',
    height: '100%',
    top: '0',
    position: 'absolute',
    pointerEvents: 'none',
  },
  '&::after': {
    content: `' '`,
    width: '143px',
    height: '175px',
    top: '15px',
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    pointerEvents: 'auto',
    left: 0,
    cursor: 'pointer',
    opacity: 0.9,
  },
  ':hover::after': {
    opacity: 1,
  },
  '&.swiper-button-disabled::after': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});

export const SwipperButtonPrev = styled('div')(styleSwipperButton, {
  left: 0,
  [mediaQuery.medium]: {
    '&::before': {
      background: 'linear-gradient(270deg, rgba(249,250,251,0) 0%, #F0F2F6 100%)',
    },
  },
  '&::after': {
    backgroundImage: `url(${arrowPrev})`,
  },
});

export const SwipperButtonNext = styled('div')(styleSwipperButton, {
  right: 0,
  [mediaQuery.medium]: {
    '&::before': {
      background: 'linear-gradient(90deg, rgba(249,250,251,0) 0%, #F0F2F6 100%)',
    },
  },
  '&::after': {
    backgroundImage: `url(${arrowNext})`,
  },
});

export const StyledSlider = css({
  margin: '0 auto',
  maxWidth: '100%',
  [mediaQuery.medium]: {
    margin: 'inherit',
  },
  '.swiper-slide': {
    maxWidth: '305px',
    minWidth: '285px',
    overflow: 'visible',
  },
  '.swiper-wrapper': {
    width: '100%',
    margin: '0 auto',
    padding: '0',
  },
  '.swiper-container': {
    paddingTop: '15px',
    marginBottom: '0',
    maxWidth: '100%',
    width: '100%',
    [mediaQuery.medium]: {
      padding: 0,
      maxWidth: 'inherit',
      width: 'inherit',
    },
  },
  '.swiper-pagination-bullets': {
    display: 'none',
    position: 'relative',
    bottom: 0,
    padding: '0 16px',
    [mediaQuery.medium]: {
      padding: 0,
      margin: '50px 0',
    },
  },
  '.swiper-button': {
    width: '143px',
    height: '100%',
    top: '0',
    zIndex: 2,
  },
  '.swiper-pagination-bullet-searched-ad': {
    background: colors.highlight,
    color: colors.white,
  },
  '.swiper-pagination-bullet': {
    marginBottom: '8px !important',
  },
  '.swiper-pagination-bullet-active ~ .swiper-pagination-bullet:not(.swiper-pagination-bullet-searched-ad)': {
    background: '#D8D8D8',
    color: colors.textGrey,
  },
});

export const PaginationBulletCss = css`
  background: #4a5560;
  border-radius: 2px;
  color: ${colors.white};
  display: inline-block;
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.small};
  height: initial;
  line-height: 23px;
  margin-right: 8px;
  opacity: 1;
  text-align: center;
  touch-action: manipulation;
  width: 23px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;
