import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { StolenCars } from './StolenCars';
import { loadStolenCars } from 'app/actions/ads';
import { get } from 'lodash-es';
import { IStolenCarsResult } from 'app/models/Api';

interface IOwnProps {}

interface IStateProps {
  isAuthorized: boolean;
  vin: string;
  stolenCars: IStolenCarsResult;
  stolenCarsIsLoading: boolean;
  stolenCarsError: string;
}

interface IDispatchProps {
  loadStolenCars: (vin: string) => Promise<IStolenCarsResult>;
}

type TProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps;

class StolenCarsComponent extends React.Component<TProps> {
  public componentDidMount() {
    const vinUrl = get(this.props, 'match.params.vin', null);
    this.loadStolenCars(vinUrl);
  }

  public render() {
    const {
      stolenCars,
      stolenCarsIsLoading,
      stolenCarsError,
      vin,
      isAuthorized,
    } = this.props;
    return (
      <StolenCars
        data={stolenCars}
        vin={vin}
        dataIsLoading={stolenCarsIsLoading}
        dataError={stolenCarsError}
        isAuthorized={isAuthorized}
      />
    );
  }

  private loadStolenCars = (vin: string): Promise<any> => {
    if (!vin) return Promise.reject(null);
    return this.props.loadStolenCars(vin);
  };
}

const mapStateToProps = state => ({
  isAuthorized: !!state.auth.accessToken,
  vin: state.ads.vin,
  stolenCars: state.ads.stolenCars,
  stolenCarsError: state.ads.stolenCarsError,
  stolenCarsIsLoading: state.ads.stolenCarsIsLoading,
});

const mapDispatchToProps = dispatch => ({
  loadStolenCars: (vin: string) => dispatch(loadStolenCars(vin)),
});

export const StolenCarsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StolenCarsComponent)
);
