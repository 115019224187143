import styled from 'styled-components/macro';
import PlusIcon from '../Icons/PlusIcon';
import { mediaQuery, colors } from 'styles/shared.styles';

export const Wrapper = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '100%',
  padding: '0',
  marginBottom: '35px',
  [mediaQuery.medium]: {
    maxWidth: '82%',
  },
});

export const AddFilter = styled('div')({
  border: `1px solid ${colors.primary}`,
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color .2s ease-in-out',
  '.svg-plus-color': {
    transition: 'fill .2s ease-in-out',
  },
  ':hover': {
    backgroundColor: colors.primary,
    '.svg-plus-color': {
      fill: 'white',
    },
  },
});

export const StyledPlusIcon = styled(PlusIcon)({
  width: '16px',
  height: '16px',
});
