import * as React from 'react';

import { Column } from 'app/components/Column';
import Container from 'app/components/Container';
import Heading from 'app/components/Heading';
import SearchForm from 'app/components/SearchForm';
import { FormSchema } from './VerifyVinForm.schema';
import * as S from './VerifyVinForm.styles';

interface IVerifyVinFormProps {
  isAuthorized: boolean;
  onSubmit: (vin: string, from?: number, limit?: number) => void;
}

const VerifyVinForm: React.SFC<IVerifyVinFormProps> = ({ isAuthorized, onSubmit }) => {
  return (
    <React.Fragment>
      <S.Container>
        <S.ContentWrapper>
          <Container>
            <S.TitleContainer>
              <Heading level={1} centered={true}>
                Ověříme historii vozu
                <br />
                podle VIN
              </Heading>
            </S.TitleContainer>
            <Column px={['10px', 0]}>
              <SearchForm
                onSubmit={onSubmit}
                placeholderText='Zadejte VIN kód'
                buttonText={'Ověřit VIN'}
                validationSchema={FormSchema}
              />
            </Column>
          </Container>
        </S.ContentWrapper>
      </S.Container>
    </React.Fragment>
  );
};

VerifyVinForm.defaultProps = {
  onSubmit: () => {},
};

export default VerifyVinForm;
