import * as React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'reflexbox/styled-components';
import MDSpinner from 'react-md-spinner';

import SectionHeader from '../SectionHeader';
import { loadMyCars, removeMyCar, resetMyCars, setFavoriteMyCar } from 'app/actions/ads';
import MyCarsItem from './MyCarsItem';
import { FilterBox } from 'app/components/FilterBox';
import {
  MyCarListStyled,
  MyCarButtonMoreStyled,
  MyCarButtonPlace,
  MyCarListEmptyHeaderStyled,
  MyCarListEmptyTextStyled,
  MyCarListSpinnerStyled,
} from './MyCars.styles';

export interface IMyCar {
  _id?: string;
  createdAt: Date;
  vin: string;
  brand: string;
  model: string;
  engine: number;
  color: string;
  mileage: number; // in KM :)
  fuel: string;
  transmission: string;
  favorite: boolean;
  payment?: {
    activeSince: string;
    activeTo: string;
  };
}

interface IMyCarsListProps {
  myCarsFirstLoadDone: boolean;
  isAuthorized: boolean;

  setFavoriteMyCar: (vin: string, favorite: boolean) => void;
  resetMyCars?: () => void;
  loadMyCars?: (skip: number, limit: number, paymentType: TPaymentType) => void;
  removeMyCar: (vin: string) => void;
  myCars?: IMyCar[];
  myCarsCount: number;
  myCarsIsLoading: boolean;
}

interface IMyCarsListState {
  paymentType: TPaymentType;
}

type TPaymentType = 'all' | 'free' | 'premium';

class MyCarsList extends React.Component<IMyCarsListProps> {
  paymentType: TPaymentType = 'all';

  public state: IMyCarsListState = {
    paymentType: 'all',
  };

  public componentDidMount() {
    if (this.props.isAuthorized) this.initialize();
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    if (authHasChanged && nextProps.isAuthorized) this.initialize();
    if (authHasChanged && !nextProps.isAuthorized) this.props.resetMyCars?.();
  }

  protected initialize = () => {
    this.props.resetMyCars?.();
    this.props.loadMyCars?.(0, 8, 'all');
  };

  protected onCarRemove = (vin: string) => {
    if (window.confirm('Opravdu chcete smazat vůz?')) this.props.removeMyCar(vin);
  };

  protected onSetFavorite = (vin: string, favorite: boolean) =>
    this.props.setFavoriteMyCar(vin, favorite);

  protected showMore = () => {
    if (this.props.loadMyCars && !this.props.myCarsIsLoading) {
      const countNow = this.props.myCars ? this.props.myCars.length : 0;
      this.props.loadMyCars?.(countNow, 8, this.state.paymentType);
    }
  };

  protected setPaymentType = (paymentType: TPaymentType) => this.setState({ paymentType });

  protected onFilterChange = (paymentType: TPaymentType = 'all') => {
    this.props.resetMyCars?.();
    this.setPaymentType(paymentType);
    this.props.loadMyCars?.(0, 8, paymentType);
  };

  protected getItems = () => {
    const myCars = Array.isArray(this.props.myCars) ? this.props.myCars : [];

    let size = '4';
    let countInRow = 4;
    if (window.innerWidth < 600) {
      countInRow = 1;
      size = '1';
    } else if (window.innerWidth < 1200) {
      countInRow = 2;
      size = '2';
    }

    const items: any[] = [];
    let i = 0;
    let lineNumber = 0;
    for (; i < myCars.length; ) {
      const line: any[] = [];
      for (let j = 0; j < countInRow && i < myCars.length; j++, i++) {
        const item = myCars[i];
        line.push(
          <MyCarsItem
            onSetFavorite={this.onSetFavorite}
            onRemove={this.onCarRemove}
            data={item}
            vin={item.vin}
            key={item.vin}
            size={size as any}
          />
        );
      }
      items.push(
        <div key={`line-${lineNumber++}`}>
          {line}
          <div style={{ clear: 'both' }} />
        </div>
      );
    }
    return items;
  };

  public render() {
    const items = this.getItems();

    return (
      <Flex flexDirection='column' margin='0 auto' maxWidth='1200px'>
        <SectionHeader title={'Moje vozy'} />
        {this.props.isAuthorized && (
          <FilterBox
            filters={myCarsFilters}
            title='Typ reportu'
            my='20px'
            onFilterChange={this.onFilterChange}
          />
        )}
        {!this.props.isAuthorized ? (
          <Unathorized />
        ) : !this.props.myCarsFirstLoadDone ? (
          <LoadingData />
        ) : (
          <DataFetched
            items={items}
            loading={this.props.myCarsIsLoading}
            myCars={this.props.myCars}
            myCarsCount={this.props.myCarsCount}
            showMore={this.showMore}
          />
        )}
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  isAuthorized: !!state.auth.accessToken,
  myCars: state.ads.myCars,
  myCarsCount: state.ads.myCarsCount,
  myCarsFirstLoadDone: state.ads.myCarsFirstLoadDone,
  myCarsIsLoading: state.ads.myCarsIsLoading,
});

const mapDispatchToProps = dispatch => ({
  loadMyCars: (skip: number = 0, limit: number = 50, paymentType: TPaymentType = 'all') =>
    dispatch(loadMyCars(skip, limit, paymentType)),
  removeMyCar: (vin: string) => dispatch(removeMyCar(vin)),
  resetMyCars: () => dispatch(resetMyCars()),
  setFavoriteMyCar: (vin: string, favorite: boolean) => dispatch(setFavoriteMyCar(vin, favorite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCarsList);

const LoadingData = () => (
  <MyCarListStyled>
    <MyCarListSpinnerStyled>
      <MDSpinner size={40} />
    </MyCarListSpinnerStyled>
  </MyCarListStyled>
);

const Unathorized = () => (
  <MyCarListStyled>
    <MyCarListEmptyHeaderStyled>Nejste přihlášen</MyCarListEmptyHeaderStyled>
    <MyCarListEmptyTextStyled>
      Pro přístup do této sekce se musíte přihlásit.
    </MyCarListEmptyTextStyled>
  </MyCarListStyled>
);

const NoData = () => (
  <MyCarListStyled>
    <MyCarListEmptyHeaderStyled>Žádné vozy</MyCarListEmptyHeaderStyled>
    <MyCarListEmptyTextStyled>
      Pro vybraný filtr nemáte uložené žádné vozidlo.
    </MyCarListEmptyTextStyled>
  </MyCarListStyled>
);

const DataFetched = ({ items, myCars, myCarsCount, showMore, loading }) => {
  if (!myCarsCount && loading) return <LoadingData />;
  return (
    <>
      <MyCarListStyled>
        {items?.length ? items : <NoData />}
        <div style={{ clear: 'both' }} />
      </MyCarListStyled>
      {!myCars || myCarsCount > myCars.length ? (
        <MyCarButtonPlace>
          <MyCarButtonMoreStyled onClick={showMore}>Zobrazit další</MyCarButtonMoreStyled>
        </MyCarButtonPlace>
      ) : null}
    </>
  );
};

const myCarsFilters = [
  {
    name: 'Vše',
    value: 'all',
  },
  {
    name: 'Premium',
    value: 'premium',
  },
  {
    name: 'Zdarma',
    value: 'free',
  },
];
