import * as React from 'react';
import SectionHeader from 'app/components/SectionHeader';
import { Flex, Box } from 'reflexbox/styled-components';

import { TableLayout } from 'app/components/Table';
import Typography from 'app/components/Typography';

import {
  VerifyVinTableHeader,
  VerifyVinTableRow,
} from 'app/components/VerifyVin/components/VerifyVinTable';

import { SectionHeaderContainer } from 'app/components/_shared_';

export const LeasingCheck: React.SFC = () => (
  <>
    <SectionHeaderContainer>
      <SectionHeader
        title={'Databáze úvěrových společností a leasingů'}
        subheaderValue={23}
        subheaderSuffixText={'záznamů'}
      />
    </SectionHeaderContainer>

    <Flex justifyContent='space-between' flexDirection='row'>
      <Box width={[1]}>
        <TableLayout data-cy='leasing-check-table'>
          <VerifyVinTableHeader primaryTitle='Stát' secondaryTitle='Ověření' />
          {leasingCompanies.map((company, idx) => (
            <VerifyVinTableRow
              rowTitle={company}
              key={company}
              striped={idx % 2 === 0}
              dataCy='leasing-check-table-row'
            >
              <Typography text={'zatím nedostupné'} blurred={true} />
            </VerifyVinTableRow>
          ))}
        </TableLayout>
      </Box>
    </Flex>
  </>
);

const leasingCompanies = [
  'Agro Leasing',
  'ALD Automotiv',
  'Autozastavárna',
  'ČSOB',
  'D.S. Leasing',
  'Essox',
  'FCE Credit',
  'GMAC',
  'Hellobank',
  'Home Credit',
  'HoppyGo',
  'Impuls Leasing',
  'Mercedes-Benz Leasing',
  'Moneta Auto',
  'Moneta Leasing',
  'Raiffeisen Leasing',
  'sAuto Leasing',
  'Toyota Financial Services',
  'Unicredit Leasing',
  'UniLeasing',
  'Vltavín Leasing',
  'VW Financial Services',
  'Zastav To',
];
