import * as React from 'react';
import NavigationLink from 'app/components/NavigationLink';

interface ITabsNavigationItemProps {
  title: string;
  tabName: string;
  onClick?: () => void;
  currentAdId: number;
  activeClassName?: string;
  to?: string;
  target?: string;
  navigationContent?: React.ReactNode;
}

const TabsNavigationItem: React.SFC<ITabsNavigationItemProps> = ({
  title,
  tabName,
  onClick,
  currentAdId,
  to,
  target,
  navigationContent,
}) => {
  return navigationContent ? (
    <>{navigationContent}</>
  ) : (
    <NavigationLink
      onClick={onClick}
      to={to ? to : `/auto/${currentAdId}/${tabName}`}
      activeClassName='active'
      target={target || null}
    >
      {title}
    </NavigationLink>
  );
};

export default TabsNavigationItem;
