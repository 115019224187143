export const routes = {
  HOME: '/',
  ABOUT: '/about',
  CONTACT: '/contact',
  KONTAKT: '/kontakt',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  RESET_PASSWORD: '/reset-password',
  FEEDBACK: '/feedback',
  PRICE_ADVISOR: '/ocenit-vuz',
  MEMBERSHIP: '/clenstvi',
  USER_PAYMENTS: '/clenstvi/platby',
  MY_CARS: '/moje-vozy',
  VERSION: '/version',

  // verify vin
  VERIFY_VIN: '/vin/:vin',
  VERIFY_VIN_PAY_REPORT: '/vin/:vin/platba',
  VERIFY_VIN_PREMIUM: '/vin/:vin/premium',
  VERIFY_VIN_REGISTERS: '/vin/:vin/registry',
  VERIFY_VIN_REGISTERS_PAYMENT: '/vin/:vin/registry/platba',

  // mobile
  MOBILE_VERIFY_VIN: '/mobile-app-vin/:vin',
};
