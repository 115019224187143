import * as React from 'react';
import { Box } from 'reflexbox/styled-components';
import { DataLoading } from 'app/components/DataLoading';
import { DataLoadingError } from 'app/components/DataLoadingError';
import { DataNoResults } from 'app/components/DataNoResults';
import SectionHeader from 'app/components/SectionHeader';
import Typography from 'app/components/Typography';
import { DummyContent } from 'app/components/DummyContent';
import { Container, ContentWrapper } from 'app/components/_shared_';
import { chunk, fill, slice } from 'lodash-es';
import { IVinDecodeResult } from 'app/models/Api';
import { checkVinValidity } from 'app/utils';
import { EnumAdLoaderTexts } from 'app/components/AdLoader';
import FormattedValue from 'app/components/FormattedValue';
import { Column } from 'app/components/Column';
import {
  ParametersRow,
  ParametersColumn,
  ParametersTitleTypography,
  ParametersTitle,
  ParametersValue,
  SectionHeaderContainer,
} from 'app/components/_shared_';
import Ad from 'app/models/Ad';
import { checkViewMode } from 'app/utils';
import * as S from './VerifyVinDecode.styles';

import manufacturersDataBlurredImg from 'assets/images/manufacturers-data-blurred.png';
import technicalParametersBlurredImg from 'assets/images/technical-parameters-blurred.png';
import dimensionsBlurredImg from 'assets/images/dimensions-blurred.jpg';
import { LoginBanner } from 'app/components/LoginBanner';

interface IVerifyVinDecodeProps {
  data: IVinDecodeResult;
  vin: string;
  isAuthorized: boolean;
  dataIsLoading: boolean;
  dataError: string;
}

const invalidVinText = 'Vložený VIN kód je neúplný, vložte prosím kompletní VIN kód.';

const sectionHeaderTexts = {
  manufacturersData: 'Údaje o vozidle',
  technicalParameters: 'Technické parametry',
  dimensions: 'Rozměry',
};

export const VerifyVinDecode: React.SFC<IVerifyVinDecodeProps> = ({
  vin,
  data,
  isAuthorized,
  dataIsLoading,
  dataError,
}) => {
  const isValidVin = checkVinValidity(vin);
  if (!isValidVin) {
    return <InvalidVinContent />;
  }
  if (dataIsLoading) {
    return <DataLoadingContent />;
  }
  if (dataError) {
    return <DataErrorContent />;
  }
  if (!data) {
    return <DataNoResultsContent />;
  }

  return <ParameterTables data={data} isValidVin={isValidVin} isAuthorized={isAuthorized} />;
};

const ManufacturerData = ({ data, isAuthorized, title }) => {
  if (!data || (data && !data.length)) {
    return null;
  }

  const COLUMNS_PER_ROW = 3;
  const rows = chunk(data, COLUMNS_PER_ROW);
  return (
    <S.VerifyVinDecodeContainer data-cy={`manufacturer-data-${title}`}>
      {rows.map((row, idx) => {
        const rowWith3columns =
          row.length === 3 ? row : slice([...row, ...fill(new Array(3), '', 0, 2)], 0, 3);
        return (
          <ParametersRow key={idx} striped={idx % 2 !== 0}>
            {rowWith3columns.map(
              (columnData, iidx) =>
                columnData && (
                  <VinDecodeParametersColumn
                    value={columnData.value}
                    label={columnData.label}
                    unit={columnData.unit}
                    key={iidx}
                    available={columnData.available}
                    isAuthorized={isAuthorized}
                  />
                )
            )}
          </ParametersRow>
        );
      })}
      {!isAuthorized ? (
        <Column position='absolute' left={0} right={0} top={0} bottom={0}>
          <LoginBanner
            text='Dostupné údaje se zobrazují pouze přihlášeným uživatelům'
            btnText='Přihlásit se'
          />
        </Column>
      ) : null}
    </S.VerifyVinDecodeContainer>
  );
};

const VinDecodeParametersColumn = ({ label, value, unit, available, isAuthorized, ...props }) => {
  const getIconType = (isAuthorized, available, value) => {
    if (!isAuthorized) return available ? 'success' : null;
    if (available) return 'success';
    if (value) return 'fail';
    return null;
  };

  return (
    <ParametersColumn {...props}>
      <ParametersTitle>
        <ParametersTitleTypography text={label} />
      </ParametersTitle>
      <ParametersValue maxWidth='80%'>
        <FormattedValue
          formattedWithSpaces={false}
          size='base'
          textAlign='right'
          unit={isAuthorized ? unit : ''}
          value={isAuthorized ? value : available ? 'dostupné' : 'nedostupné'}
          iconType={getIconType(isAuthorized, available, value)}
          data-cy={`vindecode-value-${label}`}
        />
      </ParametersValue>
    </ParametersColumn>
  );
};

const ParameterTables = ({ data, isValidVin, isAuthorized }) => {
  const isPdfMode = checkViewMode('pdf');
  return !data ? null : (
    <Container data-cy='vindecode-table'>
      <ContentWrapper>
        {Object.keys(data)
          .sort((a, b) => data[a].order - data[b].order)
          .map((category, idx) => {
            const categoryData = data[category];
            if (typeof categoryData !== 'object') {
              return null;
            }
            return (
              <Box key={categoryData.title} mb={idx < 2 ? (isPdfMode ? '40px' : '70px') : 0}>
                <SectionHeaderContainer>
                  <SectionHeader
                    title={categoryData.title}
                    subheaderValue={categoryData.availableValues.toString() || '0'}
                    subheaderSuffixText={Ad.getNumberOfRecordsTitle(categoryData.availableValues)}
                  />
                </SectionHeaderContainer>
                <S.VerifyVinDecodeContainer>
                  {isValidVin ? (
                    <ManufacturerData
                      data={categoryData.values}
                      isAuthorized={isAuthorized}
                      title={categoryData.title}
                    />
                  ) : (
                    <Typography text={invalidVinText} />
                  )}
                </S.VerifyVinDecodeContainer>
              </Box>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

const InvalidVinContent = () => (
  <Container>
    <ContentWrapper>
      <SectionHeaderContainer>
        <SectionHeader title={sectionHeaderTexts.manufacturersData} />
      </SectionHeaderContainer>
      <DummyContent bgSrc={manufacturersDataBlurredImg} overlayContent={<>{invalidVinText}</>} />
    </ContentWrapper>
    <ContentWrapper>
      <SectionHeaderContainer>
        <SectionHeader title={sectionHeaderTexts.technicalParameters} />
      </SectionHeaderContainer>
      <DummyContent bgSrc={technicalParametersBlurredImg} overlayContent={<>{invalidVinText}</>} />
    </ContentWrapper>
    <ContentWrapper>
      <SectionHeaderContainer>
        <SectionHeader title={sectionHeaderTexts.dimensions} />
      </SectionHeaderContainer>
      <DummyContent bgSrc={dimensionsBlurredImg} overlayContent={<>{invalidVinText}</>} />
    </ContentWrapper>
  </Container>
);

const DataLoadingContent = () => (
  <>
    <DataLoading
      sectionHeaderText={sectionHeaderTexts.manufacturersData}
      loaderText={EnumAdLoaderTexts.LOADING_DATA}
    />
    <DataLoading
      sectionHeaderText={sectionHeaderTexts.technicalParameters}
      loaderText={EnumAdLoaderTexts.LOADING_DATA}
    />
    <DataLoading
      sectionHeaderText={sectionHeaderTexts.dimensions}
      loaderText={EnumAdLoaderTexts.LOADING_DATA}
    />
  </>
);

const DataErrorContent = () => (
  <>
    <DataLoadingError sectionHeaderText={sectionHeaderTexts.manufacturersData} />
    <DataLoadingError sectionHeaderText={sectionHeaderTexts.technicalParameters} />
    <DataLoadingError sectionHeaderText={sectionHeaderTexts.dimensions} />
  </>
);

const DataNoResultsContent = () => (
  <>
    <DataNoResults sectionHeaderText={sectionHeaderTexts.manufacturersData} />
    <DataNoResults sectionHeaderText={sectionHeaderTexts.technicalParameters} />
    <DataNoResults sectionHeaderText={sectionHeaderTexts.dimensions} />
  </>
);
