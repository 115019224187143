import styled from 'styled-components/macro';
import Heading from 'app/components/Heading';
import { flex, colors, mediaQuery } from 'styles/shared.styles';

export const Container = styled.div`
  min-height: 64px;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.01);
`;

export const NavWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 64px;
  padding: 10px 20px;
  ${{ ...(flex.vertical as any) }};
  ${{ ...flex.centerHorizontalV }};
  ${{ ...flex.centerVerticalV }};

  ${mediaQuery.medium} {
    height: 64px;
    padding: 0;
    ${{ ...flex.spaceBetween }};
    ${{ ...(flex.horizontal as any) }};
  }
`;

export const StyledHeading = styled(Heading)``;

export const PdfExportBtnsContainer = styled.div`
  ${{ ...(flex.vertical as any) }};
  ${{ ...flex.justifyEnd }};

  button {
    padding: 0 15px;
    margin: 0 0 15px 0;
  }

  ${mediaQuery.medium} {
    ${{ ...(flex.horizontal as any) }};

    button {
      margin: 0 5px;
    }
  }
`;
