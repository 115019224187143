import { css } from 'styled-components/macro';
import { colors, fontFamily, fontSize, mediaQuery } from './shared.styles';

const common = css`
  * {
    box-sizing: border-box;
  }

  * {
    -webkit-overflow-scrolling: touch;
  }

  html,
  #root,
  .app__container {
    height: 100%;
  }

  body {
    height: 100%;
    font-family: ${fontFamily.regular};
    margin: 0;
    color: ${colors.textDefault};
    font-size: ${fontSize.base};
  }

  .list__basic {
    list-style: inside;
    line-height: 1.6;
  }

  a {
    display: inline-block; // antd override
  }

  p {
    line-height: 1.5;
    user-select: text;
  }

  strong {
    font-weight: normal;
    font-family: ${fontFamily.bold};
  }

  /**
   * Form elements
   */
  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 5px;
  }

  /**
   * icons, font-awesome icons && svg icons
   */
  .svg-inline--fa,
  .icon {
    display: inline-block;
    color: $icon-color-base;
    // margin-right: 10px;
  }

  /**
   * images
   */
  img {
    max-width: 100%;
    height: auto;
  }

  .app__main--container {
    align-items: stretch;
    background-color: ${colors.bodyColor};
    display: flex;
    flex-direction: column;
    min-height: 100%;

    ${mediaQuery.print} {
      padding: 10px;
    }
  }

  .app__main--content {
    flex: 1;
    position: relative;
    user-select: none;
  }

  .margin-top {
    margin-top: 10px;
  }

  .space-left {
    margin-left: 6px;
  }

  // ant overrides
  .ant-dropdown {
    top: 43px !important;

    ${mediaQuery.medium} {
      top: 76px !important;
    }
  }

  .ant-menu-item > a {
    display: inline-block;
  }

  .ant-select-dropdown {
    z-index: 100000;
  }

  .ant-select,
  .ant-input-number {
    line-height: 30px;
  }

  .ant-select-sm .ant-select-selection--single,
  .ant-select-selection--single,
  .ant-select-selection__rendered {
    line-height: inherit !important;
    height: auto !important;
  }

  .ant-select-dropdown-menu-item {
    text-transform: capitalize;
  }

  .ant-select-selection-selected-value {
    text-transform: capitalize;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px !important;
  }

  .ant-input-number-sm input {
    height: inherit;
  }

  .ant-input-number-sm {
    height: inherit;
  }

  .recharts-tooltip-wrapper {
    pointer-events: auto !important;
  }

  // zen-scroll
  .smooth-container {
    scroll-behavior: smooth;
  }

  /**
   * react-table overrides
   */
  .ReactTable {
    border: none;
  }

  // header container
  .ReactTable .rt-thead.-header {
    box-shadow: none;
    height: 80px;
    background: transparent;
  }

  // header row
  .ReactTable .rt-thead .rt-th {
    border-right: none;
    box-shadow: none;
    text-align: left;
    padding: 10px 10px 10px 28px;
    display: flex;
    align-items: center;
    background: transparent;
  }

  // body/header cell
  .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 13px 10px 13px 28px;
    border-right: none;
    user-select: text;
  }

  // only table cell
  .ReactTable .rt-tbody .rt-td {
    border: none;
  }

  // row container
  .ReactTable .rt-tbody .rt-tr-group {
    border: none;
  }

  // striped row
  .ReactTable.-striped .rt-tr.-odd {
    background: white;
  }

  // non-striped row
  .rt-tr -even {
    background: transparent;
  }

  // pagination
  .ReactTable .-pagination {
    box-shadow: none;
    border: 1px solid #eaeaeb;
  }

  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
  }

  // lime talk
  #limetalk-chat-widget {
    ${mediaQuery.print} {
      display: none !important;
    }
  }
`;

export default common;
