import * as React from 'react';
import Heading from '../components/Heading';
import Page from '../components/Page/Page';

class TermsOfService extends React.Component {
  public render() {
    return (
      <Page
        textAlign='left'
        itemsAlign='left'
        componentName='terms-of-service'
        documentTitle='Obchodní podmínky pro internetové aplikace'
      >
        <div className='row'>
          <div className='col-xs-offset-2 col-xs-8'>
            <Heading level={1}>Obchodní podmínky pro internetové aplikace</Heading>
            <div className='page__section'>
              <h3 className='page__h3'>Vzájemná práva a povinnosti Uživatele a Provozovatele pramenící z užívání Aplikace se řídí těmito obchodními podmínkami:</h3>
              <p className='page__paragraph'><strong className='page__strong'>1. Definice</strong></p>
              <p className='page__paragraph'>1.1. V těchto obchodních podmínkách:</p>
              <p className='page__paragraph'>1.1.1. <strong className='page__strong'>„Provozovatel“</strong> je Společnost Galli Company s.r.o., IČO: 04522818, se sídlem Radimovická 1773/15, 149 00 Praha 4.</p>
              <p className='page__paragraph'>1.1.2. <strong className='page__strong'>„Aplikace“</strong> znamená internetovou aplikaci s názvem OvěřAuto.cz, mezi jejíž hlavní funkcionality patří analýza a agregace informací z internetu nebo informací  od  třetích  stran  a  která  je  dostupná  z  internetové  adresy <a href='https://www.overauto.cz' className='page__link'>www.overauto.cz</a></p>
              <p className='page__paragraph'>1.1.3. <strong className='page__strong'>„VOP“</strong> znamená tyto Všeobecné obchodní podmínky.</p>
              <p className='page__paragraph'>1.1.4. <strong className='page__strong'>„Smlouva“</strong>  znamená  smlouvu  uzavřenou  mezi  Uživatelem  a Provozovatelem,  jejímž  předmětem  je  poskytnutí  Licence  k  užívání Aplikace Uživateli za podmínek uvedených v těchto VOP.</p>
              <p className='page__paragraph'>1.1.5. <strong className='page__strong'>„Licence“</strong> znamená nevýhradní licenci k užívání Aplikace za podmínek uvedených v těchto VOP.</p>
              <p className='page__paragraph'>1.1.6. <strong className='page__strong'>„Uživatel“</strong> znamená jakoukoliv právnickou nebo fyzickou osobu odlišnou od Provozovatele, která užívá Aplikaci.</p>
              <p className='page__paragraph'>1.1.7. <strong className='page__strong'>„Uživatelský účet“</strong> znamená neveřejnou část Aplikace, která je uživateli přístupná po zadání Přihlašovacích údajů.</p>
              <p className='page__paragraph'>1.1.8. <strong className='page__strong'>„Přihlašovací údaje“</strong> znamenají unikátní kombinaci přihlašovacího jména Uživatele a hesla zvoleného Uživatelem, které Uživatel uloží do databáze Aplikace při zřizování Uživatelského účtu prostřednictvím Aplikace a/nebo Uživateli automaticky vygenerovaného Aplikací.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>2. Proces uzavření smlouvy o užívání Aplikace</h3>
              <p className='page__paragraph'>2.1. Zobrazení  tlačítka  s  názvem „Registrovat“  v  uživatelském  prostředí Aplikace je návrhem na uzavření Smlouvy ze strany Provozovatele.</p>
              <p className='page__paragraph'>2.2. Kliknutí Uživatele na tlačítko „Registrovat“ je bezvýhradným přijetím návrhu na uzavření Smlouvy ze strany Uživatele a tímto úkonem je uzavřena Smlouva.</p>
              <p className='page__paragraph'>2.3. Kliknutím  na  pole (tzv.  checkbox),  které  obsahuje  popis  ve  znění „Souhlasím  se  zpracováním  osobních  údajů  dle  podmínek  používání aplikace“ a následným kliknutím na tlačítko ve smyslu 2.2 Uživatel vyjadřuje souhlas s těmito VOP.</p>
              <p className='page__paragraph'>2.4. Uzavřením Smlouvy vstupují v účinnost následující ustanovení těchto VOP.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>3. Užívání Aplikace</h3>
              <p className='page__paragraph'>3.1. Provozovatel  uděluje  Uživateli  Licenci  v  rozsahu  a  za  podmínek uvedených  v  uživatelském  prostředí Aplikace,  zejména  uvedených  u daného typu Uživatelského účtu.</p>
              <p className='page__paragraph'>3.2. Uživatel se za udělení Licence zavazuje Provozovateli zaplatit odměnu ve výši uvedené v uživatelském prostředí Aplikace, a není-li u konkrétního způsobu použití (zejm. u daného Uživatelského účtu) odměna uvedena, platí, že je Licence poskytnuta bezúplatně.</p>
              <p className='page__paragraph'>3.3. V případě, že je Licence udělena za odměnu, má Provozovatel právo poskytnout  plnění  Smlouvy (např.  zpřístupnit  danou  část  nebo funkcionalitu Aplikace) až v momentě, kdy dojde k zaplacení odměny za poskytnutí Licence a nezaplatí-li Uživatel za Licenci odměnu v plné výši v Provozovatelem stanovené lhůtě, jinak ihned, ruší se Smlouva bez dalšího od počátku.</p>
              <p className='page__paragraph'>3.4. Provozovatel je po uplynutí doby Licence oprávněn plně a/nebo částečně znepřístupnit Uživatelský účet Uživateli.</p>
              <p className='page__paragraph'>3.5. Uživatel má právo zřídit více než jeden Uživatelský účet.</p>
              <p className='page__paragraph'>3.6. Uživatel nemá právo udělit třetí osobě podlicenci k užívání Aplikace.</p>
              <p className='page__paragraph'>3.7. Veškerá finanční plnění realizovaná prostřednictví Aplikace nebo platební brány propojené s Aplikací se považují za zaplacené připsáním celé příslušné částky na příslušný bankovní účet Provozovatele.</p>
              <p className='page__paragraph'>3.8. Provozovatel má právo Aplikaci nebo její část kdykoliv odstavit, změnit a/ nebo ji znepřístupnit z příslušné internetové adresy, a to z jakéhokoliv důvodu.</p>
              <p className='page__paragraph'>3.9. Uživatel má právo na užívání Aplikace za podmínek Smlouvy v aktuální verzi dostupné z příslušné internetové adresy.</p>
              <p className='page__paragraph'>3.10. Uživatel  se  zavazuje  při  užívání Aplikace  jednat  tak,  aby  jemu  ani Provozovateli užíváním Aplikace nevznikla žádná škoda.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>4. Databáze</h3>
              <p className='page__paragraph'>4.1. Uživatel nemá právo prostřednictvím Aplikace strojově (zejm. pomocí tzv. softwarových robotů) vytěžovat databázi spojenou s Aplikací.</p>
              <p className='page__paragraph'>4.2. Uživatel a Provozovatel tímto sjednávají, že jakékoliv údaje vložené Uživatelem  do  databáze  Aplikace  jsou  části  databáze  shromážděné Provozovatelem a stávají se součástí databáze Provozovatele, aniž by Uživateli jeho jednáním popsaným v tomto odstavci vzniklo jakékoliv právo k databázi Aplikace.</p>
              <p className='page__paragraph'>4.3. Smluvní strany jsou si vědomy, shodně prohlašují a činí nesporným, že Aplikace splňuje podmínky ust. § 562 odst. 2 občanského zákoníku, tedy že záznamy údajů v Aplikaci a její databázi jakožto elektronickém systému jsou  spolehlivé  a  jsou  prováděny  systematicky  a  posloupně  a  jsou chráněny proti změnám.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>5. Odpovědnost za škodu</h3>
              <p className='page__paragraph'>5.1. Provozovatel plně odpovídá Uživateli za jakoukoliv škodu způsobenou Provozovatelem  Uživateli  jakýmkoliv  porušením  povinnosti Provozovatele uvedené ve Smlouvě nebo VOP.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>6. Změna VOP</h3>
              <p className='page__paragraph'>6.1. Uživatel bere na vědomí, že Provozovatel uzavírá Smlouvu v běžném obchodním styku s větším počtem osob a Smlouvy jsou podle svého charakteru zavazující dlouhodobě k opětovným plněním stejného druhu s odkazem na tyto VOP. Smluvní strany činí nesporným, že z povahy závazků Provozovatele podle těchto VOP vyplývá a již před uzavřením Smlouvy vyplývala rozumná potřeba pozdější změny těchto VOP.</p>
              <p className='page__paragraph'>6.2. Smluvní strany tímto ujednávají, že Provozovatel může tyto VOP v přiměřeném rozsahu změnit, a zároveň ujednávají, že změna VOP bude Uživateli, jako druhé straně, oznámena prostřednictvím e-mailové zprávy odeslané na e-mailovou adresu zadanou Uživatelem do Aplikace. Uživatel má v takovém případě právo změny VOP odmítnout a předmětný závazek mezi ním a Provozovatelem z tohoto důvodu vypovědět ve výpovědní době jeden (1) kalendářní měsíc, která začne běžet den následující po odeslání oznámení, o které Smluvní strany tímto shodně prohlašují, že je dostatečná k obstarání obdobných plnění od jiného subjektu.</p>
              <p className='page__paragraph'>6.3. V  případě,  že  dojde  k  uzavření  nové  Smlouvy  k  již  existujícímu Uživatelskému účtu (tj. k obnovení nebo prodloužení Uživatelského účtu), řídí se taková Smlouva VOP ve znění ke dni uzavření takové Smlouvy.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>7. Osobní údaje</h3>
              <p className='page__paragraph'>7.1. Informace  o  ochraně  osobních  údajů  naleznete  zde: <a href='https://www.overauto.cz/privacy-policy' className='page__link'>www.overauto.cz/privacy-policy</a></p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>8. Povinné informace pro spotřebitele</h3>
              <p className='page__paragraph'>8.1. Tento článek VOP podmínek je účinný pouze vůči Uživateli, který je spotřebitelem. Ustanovení uvedená v tomto článku VOP se nevztahují zejména na podnikatele - živnostníky nebo obchodní společnosti.</p>
              <p className='page__paragraph'>8.2. Provozovatel tímto informuje Uživatele, že:</p>
              <p className='page__paragraph'>8.2.1. adresa  pro  doručování  písemností  Provozovateli  je  shodná  s  výše uvedenou adresou sídla Provozovatele;</p>
              <p className='page__paragraph'>8.2.2. telefonický kontakt Provozovatele je <a href='tel:+420608049563' className='page__link'>+420 608 049 563</a>;</p>
              <p className='page__paragraph'>8.2.3. adresa  Provozovatele  pro  doručování  elektronické  pošty  je <a href='mailto:info@overauto.cz' className='page__link'>info@overauto.cz</a>;</p>
              <p className='page__paragraph'>8.2.4. odměna  Provozovatele  za  Licenci  je  stanovena  včetně  všech  daní  a poplatků a její výše a/nebo způsob výpočtu je stanoven v Aplikaci;</p>
              <p className='page__paragraph'>8.2.5. Uživatel je povinen zaplatit Provozovateli odměnu za Licenci, není-li Licence poskytována bezúplatně, jedním ze způsobů, který je u příslušné možnosti uveden v uživatelském prostředí Aplikace;</p>
              <p className='page__paragraph'>8.2.6. Uživateli nevznikají náklady na dodání;</p>
              <p className='page__paragraph'>8.2.7. ve vztahu k Provozovateli nevznikají Uživateli žádné náklady za použití komunikačních prostředků na dálku;</p>
              <p className='page__paragraph'>8.2.8. Uživatel má možnost zjistit chyby kontrolou v Uživatelském účtu;</p>
              <p className='page__paragraph'>8.2.9. opravování  chyb  vzniklých  při  zadávání  dat  lze  provádět  rovněž prostřednictvím  Aplikace  a  tam,  kde  to  Aplikace  neumožňuje, prostřednictvím technické podpory, jejíž kontakt je uveden v Aplikaci;</p>
              <p className='page__paragraph'>8.2.10. Provozovatel dodržuje všechny platné zákony České republiky; žádné další kodexy nejsou pro Provozovatele závazné;</p>
              <p className='page__paragraph'>8.2.11. Provozovatel nevyužívá žádné prostředky mimosoudního řešení sporů;</p>
              <p className='page__paragraph'>8.2.12. předmětem Smlouvy není dodání zboží, na Aplikace se tak nevztahují ustanovení zákona o reklamaci; odpovědnost za zjevné nebo skryté vady Aplikace, které měla Aplikace v době jejího zpřístupnění Uživateli, lze uplatnit u Provozovatele za podmínek stanovených zákonem, Smlouvou a těmito VOP;</p>
              <p className='page__paragraph'>8.2.13. Uživatel je povinen dodržovat tyto VOP, které jsou též součástí Smlouvy, a platné a účinné právní předpisy České republiky;</p>
              <p className='page__paragraph'>8.2.14. Uživatel se může obrátit se stížností na orgán dohledu nebo státního dozoru.  Česká  obchodní  inspekce  vyřizuje  mimosoudní  stížnosti spotřebitelů způsobem a za podmínek stanovených příslušnými právními předpisy.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>9. Rozhodné právo</h3>
              <p className='page__paragraph'>9.1. Tyto VOP, jakož i Smlouva, se řídí právním řádem České republiky, zejména zák. č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.</p>
              <p className='page__paragraph'>9.2. Jakékoliv spory vzniklé na základě Smlouvy a/nebo těchto VOP budou rozhodovat věcně a místně příslušné soudy České republiky.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>10. Účinnost</h3>
              <p className='page__paragraph'>10.1.  Tyto VOP nabývají platnosti a účinnosti dne 10.07.2018.</p>
            </div>
          </div>

          <div className='page__content col-xs-offset-2 col-xs-8'>
            <Heading level={2}>Souhlas se zpracováním souborů cookies</Heading>
            <div className='page__section'>
              <h3 className='page__h3'>PŘÍLOHA Č. 1</h3>
              <h3 className='page__h3'>1. Co jsou soubory cookies</h3>
              <p className='page__paragraph'>Soubory cookies jsou krátké textové soubory, které internetová stránka odešle do Vašeho  prohlížeče.  Umožňují internetové stránce  zaznamenat informace  o  Vaší návštěvě, například zvolený jazyk a podobně, následující návštěva stránek tak pro Vás může být snazší a příjemnější. Soubory cookies jsou důležité, neboť bez nich by procházení sítě Internet bylo mnohem složitější. Soubory cookies umožňují lepší  využití  naší  internetové  stránky  a  přizpůsobení  jejího  obsahu  Vašim potřebám;  používá je téměř každá internetová stránka na světě. Soubory cookies jsou  užitečné,  protože  zvyšují  uživatelskou  přívětivost  opakovaně  navštívené internetové stránky.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>2. Druhy souborů cookies</h3>
              <p className='page__paragraph'>Relační (tedy dočasné) soubory cookies nám umožňují propojovat Vaše jednotlivé aktivity po  dobu  prohlížení  těchto  internetových  stránek.  V okamžiku  otevření okna Vašeho prohlížeče se tyto soubory aktivují a deaktivují se po zavření okna Vašeho  prohlížeče.  Relační  cookies  jsou  dočasné  a  po  zavření  prohlížeče  se všechny tyto soubory vymažou. Trvalé soubory cookies nám pomáhají Váš počítač identifikovat, jestliže opětovně navštívíte naši internetovou stránku. Další výhodou trvalých cookies je to, že nám umožňují přizpůsobit naše internetové stránky Vašim potřebám.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>3. Využívání souborů cookies</h3>
              <p className='page__paragraph'>V souladu s ustanovením § 89 odst. 3 zák. č. 127/2005 Sb., o elektronických komunikacích,  v  účinném znění,  si Vás  tímto  dovolujeme  informovat,  že  naše internetové  stránky  využívají  pro  svoji  činnost  soubory  cookies,  tedy  že  Vaše soubory cookies, včetně trvalých, zpracováváme. Tento souhlas je udělen na dobu 10 let. Internetové  prohlížeče  obvykle  obsahují  správu  souborů  cookies.  V  rámci nastavení  Vašeho  prohlížeče  tak  pravděpodobně  můžete  jednotlivé  soubory cookies  ručně  mazat,  blokovat  či  zcela  zakázat  jejich  používání.  Pro  více informací  použijte nápovědu svého internetového prohlížeče.</p>
            </div>
            <div className='page__section'>
              <h3 className='page__h3'>4. Účel použití souborů cookies</h3>
              <p className='page__paragraph'>K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší  návštěvnosti  využíváme  soubory  cookies.  Informace  o  tom,  jak  náš  web používáte,  sdílíme  se  svými  partnery  působícími  v  oblasti  sociálních  médií, inzerce a analýz. Soubory  cookies  využíváme,  kromě  účelu  uvedeného  v  předchozím  odstavci, pouze pro účely stanovené v obchodních podmínkách.</p>
            </div>
          </div>
        </div>
      </Page >
    )
  }
}

export default TermsOfService;
