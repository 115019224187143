import * as React from 'react';
import { useState } from 'react';
import ReactJson from 'react-json-view';

import Heading from '../../Heading';

import * as S from './JsonView.styles';
import { Column } from 'app/components/Column';

interface IJsonViewProps {
  data: any[] | object;
  showMainTitle?: boolean;
  title?: string;
  collapsed?: number;
  [key: string]: any;
}

const JsonView: React.SFC<IJsonViewProps> = ({
  data,
  title,
  showMainTitle = false,
  collapsed = 3,
  ...props
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const APP_MODE = process.env.REACT_APP_MODE;
  if ((!isHidden && APP_MODE === 'development') || APP_MODE === 'staging') {
    return (
      <S.JsonViewContainer>
        {showMainTitle && (
          <S.MainTitleContainer>
            <Heading level={2} weight='medium' size='big'>
              Source data
            </Heading>
          </S.MainTitleContainer>
        )}
        <Column onClick={() => setIsHidden(true)} top='5px' right='10px' withHover={true} mb={2}>
          Hide source data
        </Column>
        {title && (
          <S.TitleContainer>
            <Heading level={3} weight='regular' size='medium'>
              {title}
            </Heading>
          </S.TitleContainer>
        )}
        <ReactJson
          src={data}
          theme='monokai'
          indentWidth={2}
          collapsed={collapsed}
          enableClipboard={true}
          displayObjectSize={false}
          displayDataTypes={false}
          {...props}
        />
      </S.JsonViewContainer>
    );
  }

  return null;
};

export default JsonView;
