import React from 'react';
import { Formik } from 'formik';
import { Box, Flex } from 'reflexbox/styled-components';
import { get } from 'lodash-es';
import { RouteComponentProps } from 'react-router';

import Input from 'app/components/FormElements/Input';
import Button from 'app/components/Button';
import PremiumBanner from 'app/components/PremiumBanner';
import Form, { FormRow } from 'app/components/FormElements/Form';
import RadioGroup from 'app/components/FormElements/RadioGroup';
import CheckboxGroup from 'app/components/FormElements/CheckboxGroup';
import Select from 'app/components/FormElements/Select';
import Typography from 'app/components/Typography';
import { FormSchema, formConfig, defaultFormValues } from './Form.EstimateCar.config';
import { IEnums, IEnumValue, IModelEnums } from 'app/models/Enums';
import { IEstimateCarHistoryLog } from 'app/models/EstimateCarHistory';
import { IFormState } from '../EstimateCar';
import { colors } from 'styles/shared.styles';

interface IFormEstimateCarProps {
  enums: IEnums;
  modelEnums: IModelEnums;
  initialValues: IFormState;
  highestEquipmentList: string[];
  loadModelEnums: (brand: string) => Promise<IModelEnums>;
  loadHighestEquipmentList: (brand: string, model: string, year: number) => Promise<any>;
  createEstimateCarHistoryLog: (filter: string) => Promise<any>;
  setFormValues: (setFormValues: IFormState) => void;
  history: RouteComponentProps['history'];
}

class FormEstimateCar extends React.Component<IFormEstimateCarProps, any> {
  public render() {
    const { enums, modelEnums, initialValues } = this.props;

    if (!enums) {
      return null;
    }

    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={FormSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Flex
                width='100%'
                alignItems='flexStart'
                flexDirection={['column', 'row', 'row']}
                pl={[10, 10, 0]}
                pr={[10, 10, 0]}
              >
                <Box width={[1, 1 / 2, 1 / 3]} pr={[0, 10, 10]}>
                  {/* brand */}
                  <FormRow>
                    <Select
                      error={errors.brand}
                      handleChange={option =>
                        this.onBrandSelectChange(
                          option,
                          formConfig.names.brand,
                          setFieldValue,
                          resetForm,
                          values
                        )
                      }
                      label={formConfig.labels.brand}
                      name={formConfig.names.brand}
                      options={enums.brand}
                      placeholder={formConfig.placeholders.brand}
                      value={enums.brand.filter(item => item.value === values.brand)}
                      touched={touched.brand}
                    />
                  </FormRow>
                  {/* model */}
                  <FormRow>
                    <Select
                      error={errors.model}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.model,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!values.brand}
                      label={formConfig.labels.model}
                      name={formConfig.names.model}
                      options={modelEnums || []}
                      placeholder={formConfig.placeholders.model}
                      value={modelEnums && modelEnums.filter(item => item.value === values.model)}
                      touched={touched.model}
                    />
                  </FormRow>
                  {/* year */}
                  <FormRow>
                    <Select
                      error={errors.year}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.year,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.year}
                      name={formConfig.names.year}
                      options={enums.year}
                      placeholder={formConfig.placeholders.year}
                      value={enums.year.filter(item => item.value === values.year)}
                      touched={touched.year}
                    />
                  </FormRow>
                  {/* km */}
                  <FormRow>
                    <Input
                      type='number'
                      name={formConfig.names.km}
                      label={formConfig.labels.km}
                      placeholder={formConfig.placeholders.km}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.km}
                      error={errors.km}
                      touched={touched.km}
                      disabled={!(values.brand && values.model)}
                    />
                  </FormRow>
                  {/* kw */}
                  <FormRow>
                    <Input
                      type='number'
                      name={formConfig.names.kw}
                      label={formConfig.labels.kw}
                      placeholder={formConfig.placeholders.kw}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.kw}
                      error={errors.kw}
                      touched={touched.kw}
                      disabled={!(values.brand && values.model)}
                    />
                  </FormRow>
                  {/* fuel */}
                  <FormRow>
                    <Select
                      error={errors.fuel}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.fuel,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.fuel}
                      name={formConfig.names.fuel}
                      options={enums.fuel}
                      placeholder={formConfig.placeholders.fuel}
                      value={enums.fuel.filter(item => item.value === values.fuel)}
                      touched={touched.fuel}
                    />
                  </FormRow>
                  {/* STK */}
                  <FormRow>
                    <Select
                      error={errors.stk}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.stk,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.stk}
                      name={formConfig.names.stk}
                      options={enums.stk}
                      placeholder={formConfig.placeholders.stk}
                      value={enums.stk.filter(item => item.value === values.stk)}
                      touched={touched.stk}
                    />
                  </FormRow>
                  {/* transmission */}
                  <FormRow>
                    <Select
                      error={errors.transmission}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.transmission,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.transmission}
                      name={formConfig.names.transmission}
                      options={enums.transmission}
                      placeholder={formConfig.placeholders.transmission}
                      value={enums.transmission.filter(item => item.value === values.transmission)}
                      touched={touched.transmission}
                    />
                  </FormRow>
                  {/* ac */}
                  <FormRow>
                    <Select
                      error={errors.ac}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.ac,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.ac}
                      name={formConfig.names.ac}
                      options={enums.ac}
                      placeholder={formConfig.placeholders.ac}
                      value={enums.ac.filter(item => item.value === values.ac)}
                      touched={touched.ac}
                    />
                  </FormRow>
                  {/* karoserie */}
                  <FormRow>
                    <Select
                      error={errors.karoserie}
                      handleChange={option =>
                        this.onFormElementChange(
                          option.value,
                          formConfig.names.karoserie,
                          setFieldValue,
                          values
                        )
                      }
                      isDisabled={!(values.brand && values.model)}
                      label={formConfig.labels.karoserie}
                      name={formConfig.names.karoserie}
                      options={enums.karoserie}
                      placeholder={formConfig.placeholders.karoserie}
                      value={enums.karoserie.filter(item => item.value === values.karoserie)}
                      touched={touched.karoserie}
                    />
                  </FormRow>

                  {/* firstOwner */}
                  <FormRow>
                    <RadioGroup
                      error={errors.firstOwner}
                      label={formConfig.labels.firstOwner}
                      name={formConfig.names.firstOwner}
                      values={[
                        { value: true, label: 'Ano' },
                        { value: false, label: 'Ne' },
                      ]}
                      selectedValue={values.firstOwner}
                      onChange={value =>
                        this.onFormElementChange(
                          value,
                          formConfig.names.firstOwner,
                          setFieldValue,
                          values
                        )
                      }
                      touched={touched.firstOwner}
                    />
                  </FormRow>
                  {/* serviceBook */}
                  <FormRow>
                    <RadioGroup
                      error={errors.serviceBook}
                      label={formConfig.labels.serviceBook}
                      name={formConfig.names.serviceBook}
                      values={[
                        { value: true, label: 'Ano' },
                        { value: false, label: 'Ne' },
                      ]}
                      selectedValue={values.serviceBook}
                      onChange={value =>
                        this.onFormElementChange(
                          value,
                          formConfig.names.serviceBook,
                          setFieldValue,
                          values
                        )
                      }
                      touched={touched.serviceBook}
                    />
                  </FormRow>
                </Box>
                <Box width={[1, 1 / 2, 2 / 3]}>
                  {/* equipment */}
                  <FormRow>
                    {!(values.brand && values.model && values.year) && (
                      <PremiumBanner
                        type='dark'
                        overlay={true}
                        overlayColor={colors.transparentLightGrey}
                      >
                        Vyberte nejdříve značku, model a rok výroby.
                      </PremiumBanner>
                    )}
                    <CheckboxGroup
                      error={errors.equipment}
                      label={formConfig.labels.equipment}
                      name={formConfig.names.equipment}
                      values={enums.equipment}
                      selectedValue={values.equipment}
                      onChange={value =>
                        this.onFormElementChange(
                          value,
                          formConfig.names.equipment,
                          setFieldValue,
                          values
                        )
                      }
                      disabled={!(values.brand && values.model && values.year)}
                      touched={touched.equipment}
                    />
                  </FormRow>
                </Box>
              </Flex>
              <Flex pl={[10, 10, 0]} pr={[10, 10, 0]}>
                <Box width={[1, 1, 1]} my='20px'>
                  <Typography text='* položky označené hvězdičkou jsou povinné.' weight='medium' />
                </Box>
              </Flex>
              <Flex pl={[10, 10, 0]} pr={[10, 10, 0]}>
                <Box width={[1, 1, 1]}>
                  <FormRow>
                    <Button kind='submit' disabled={!isValid || isSubmitting} spin={isSubmitting}>
                      Potvrdit a zobrazit cenu
                    </Button>
                  </FormRow>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  public onSubmit = async (values, { setSubmitting }) => {
    const { setFormValues, createEstimateCarHistoryLog } = this.props;
    setFormValues(values);
    if (!values) {
      setSubmitting(false);
      return;
    }
    const filterString = JSON.stringify(values);
    const estimateCarHistoryLog: IEstimateCarHistoryLog = await createEstimateCarHistoryLog(
      filterString
    );
    const logId = get(estimateCarHistoryLog, 'payload.data.data._id', null);
    if (logId) {
      this.redirectToResults(logId);
    }
    setSubmitting(false);
  };

  private onBrandSelectChange = (
    option: IEnumValue,
    fieldName: string,
    setFieldValue: (fieldName: string, value: any) => void,
    resetForm: (values: any) => void,
    formValues: IFormState
  ) => {
    resetForm(defaultFormValues);
    const { loadModelEnums } = this.props;
    loadModelEnums(option.value);
    this.onFormElementChange(option.value, fieldName, setFieldValue, formValues);
  };

  private onFormElementChange = (
    value: any,
    fieldName: string,
    setFieldValue: (fieldName: string, value: any) => void,
    formValues: IFormState
  ) => {
    setFieldValue(fieldName, value);
    const values = fieldName === formConfig.names.brand ? defaultFormValues : formValues;
    this.loadHighestEquipmentList(values, fieldName, value, setFieldValue);
  };

  private loadHighestEquipmentList = async (
    formValues: IFormState,
    changedFieldName: string,
    changedFieldValue: any,
    setFieldValue: (fieldName: string, value: any) => void
  ) => {
    const formValuesCopy = { ...formValues, ...{ [changedFieldName]: changedFieldValue } };
    const { brand, model, year } = formValuesCopy;
    const { names } = formConfig;
    const { brand: brandName, model: modelName, year: yearName } = names;
    const supportedFields = [brandName, modelName, yearName];
    const isSupportedField = supportedFields.indexOf(changedFieldName) > -1;
    if (isSupportedField && brand && model && year) {
      const { payload } = await this.props.loadHighestEquipmentList(brand, model, Number(year));
      const {
        data: { data: equipmentList },
      } = payload;
      setFieldValue('equipment', equipmentList || []);
    }
  };

  private redirectToResults = (logId: string) => {
    const { history } = this.props;
    history.push(`/oceneni/${logId}`);
  };
}

export default FormEstimateCar;
