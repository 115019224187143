export const fontFamily = {
  thin: "'core_sans_a_15_thinregular', 'Helvetica', 'Arial', sans-serif",
  extraLight: "'core_sans_a_25_extralightRg', 'Helvetica', 'Arial', sans-serif",
  light: "'core_sans_a_35_lightregular', 'Helvetica', 'Arial', sans-serif",
  regular: "'core_sans_a_45_regularregular', 'Helvetica', 'Arial', sans-serif",
  medium: "'core_sans_a_55_mediumregular', 'Helvetica', 'Arial', sans-serif",
  bold: "'core_sans_a_65_boldregular', 'Helvetica', 'Arial', sans-serif",
  extraBold: "'core_sans_a_75_extraboldRg', 'Helvetica', 'Arial', sans-serif",
  heavy: "'core_sans_a_85_heavyregular', 'Helvetica', 'Arial', sans-serif",
};

export const fontSize = {
  nano: '11px', // 0.812rem
  mini: '13px', // 0.812rem
  small: '14px', // 0.875rem
  base: '16px', // 1rem
  medium: '18px', // 1.125rem
  big: '20px', // 1.25rem
  titleBase: '24px', // 1.5rem
  titleSmall: '28px', // 1.75rem
  titleMedium: '36px', // 2.25rem
  titleBig: '48px', //3rem
};
