import { flex, mediaQuery } from '../../../styles/shared.styles';
import styled from 'styled-components/macro';

export const NotesContainer = styled.div({
  lineHeight: '1.5',
  padding: '0 10px',
  letterSpacing: '-0.32px',
  width: '100%',
  maxWidth: '350px',
  ...flex.horizontal,
  [mediaQuery.medium]: {
    padding: '0 20px',
    ...flex.vertical,
    ...flex.alignChildrensLeft,
    maxWidth: 'inherit',
  },
} as any);

export const ContentSection = styled.div({
  margin: '20px 0 40px 0',
  [mediaQuery.print]: {
    margin: '20px 0',
  },
});

export const LoginMessage = styled.div({
  padding: '0 10px',
  [mediaQuery.medium]: {
    padding: '0 20px',
  },
});
