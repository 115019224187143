import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { get, isEmpty } from 'lodash-es';

import { resetAdsByVin, resetKmHistoryByVin, resetKmHistoryForNotFoundVin } from 'app/actions/ads';

import { VerificationSummary } from './VerificationSummary';

import { IKmHistory } from 'app/components/AdHistory/AdHistory.container';
import {
  IResidualValueResult,
  IVinDecodeResult,
  IStolenCarsResult,
  ILeasingCheckResult,
} from 'app/models/Api';
import Ad from 'app/models/Ad';
import { IInfoCardProps } from 'app/components/InfoCard';

interface IVerificationSummaryComponentOwnProps extends RouteComponentProps {
  isValidVin: boolean;
}

interface IVerificationSummaryComponentStateProps {
  vin: string;
  adDetails: Ad;
  adDetailsIsLoading: boolean;
  kmHistory: IKmHistory;
  kmHistoryNotFoundVin: IKmHistory;
  kmHistoryIsLoading: boolean;
  kmHistoryNotFoundVinIsLoading: boolean;
  residualValueByVin: IResidualValueResult;
  residualValueByVinIsLoading: boolean;
  hasHistoryChanges: boolean;
  vinDecode: IVinDecodeResult;
  vinDecodeIsLoading: boolean;
  stolenCars: IStolenCarsResult;
  stolenCarsIsLoading: boolean;
  leasingCheckData: ILeasingCheckResult;
  leasingCheckIsLoading: boolean;
  isValidVin: boolean;
  vehicleRegisterLoading: boolean;
  vehicleRegisterFetched: boolean;
  vehicleRegisterData: IInfoCardProps[];
  resetAdsByVin: () => void;
  resetKmHistoryByVin: () => void;
  resetKmHistoryForNotFoundVin: () => void;
}

type Props = IVerificationSummaryComponentOwnProps & IVerificationSummaryComponentStateProps;

class VerificationSummaryComponent extends React.Component<
  Props & RouteComponentProps<{ history: any }, any>
> {
  public componentWillUnmount() {
    this.resetStateData();
  }

  public render() {
    const {
      vin,
      adDetails,
      adDetailsIsLoading,
      kmHistory,
      kmHistoryNotFoundVin,
      kmHistoryIsLoading,
      kmHistoryNotFoundVinIsLoading,
      hasHistoryChanges,
      residualValueByVin,
      residualValueByVinIsLoading,
      vinDecode,
      vinDecodeIsLoading,
      isValidVin,
      stolenCars,
      stolenCarsIsLoading,
      leasingCheckData,
      leasingCheckIsLoading,
      vehicleRegisterLoading,
      vehicleRegisterFetched,
      vehicleRegisterData,
    } = this.props;
    const hasStolenCarsData =
      stolenCars && stolenCars.policeDbs && !!Object.keys(stolenCars.policeDbs).length;
    return (
      <VerificationSummary
        vin={vin}
        adDetails={adDetails}
        adDetailsIsLoading={adDetailsIsLoading}
        hasAdDetailsData={!isEmpty(adDetails, true)}
        hasHistoryChanges={hasHistoryChanges}
        historyChangesIsLoading={adDetailsIsLoading}
        adImagesIsLoading={adDetailsIsLoading}
        hasAdImages={(adDetails && adDetails.images && !!adDetails.images.length) || false}
        priceAdvisorIsLoading={residualValueByVinIsLoading}
        hasPriceAdvisorData={residualValueByVin && !!residualValueByVin.searchedAd}
        residualValueIsLoading={residualValueByVinIsLoading}
        hasResidualValueData={residualValueByVin && !!residualValueByVin.searchedAd}
        vinDecodeIsLoadingIsLoading={vinDecodeIsLoading}
        hasManufacturersData={!!get(vinDecode, 'manufacturersData.availableValues')}
        hasTechnicalParametersData={!!get(vinDecode, 'technicalParameters.availableValues')}
        hasDimensionsParametersData={!!get(vinDecode, 'dimensions.availableValues')}
        hasStolenCarsData={hasStolenCarsData}
        stolenCarsIsLoading={stolenCarsIsLoading}
        isValidVin={isValidVin}
        kmHistoryIsLoading={kmHistoryIsLoading || kmHistoryNotFoundVinIsLoading}
        hasKmHistoryData={
          get(kmHistory, 'data.tableDataLength') ||
          get(kmHistoryNotFoundVin, 'data.tableDataLength')
        }
        hasLeasingCheckData={
          (Array.isArray(leasingCheckData?.data) && !!leasingCheckData?.data.length) || false
        }
        leasingCheckIsLoading={leasingCheckIsLoading}
        vehicleRegisterLoading={vehicleRegisterLoading}
        vehicleRegisterFetched={vehicleRegisterFetched}
        vehicleRegisterData={vehicleRegisterData}
      />
    );
  }

  private resetStateData = (): void => {
    this.props.resetAdsByVin();
    this.props.resetKmHistoryByVin();
    this.props.resetKmHistoryForNotFoundVin();
  };
}

const mapStateToProps = state => {
  return {
    adDetails: state.ads.adsByVinList.ad,
    adDetailsIsLoading: state.ads.adsByVinListIsLoading,
    hasHistoryChanges: state.ads.adsByVinList.meta?.hasHistoryChanges,
    kmHistory: state.ads.kmHistory,
    kmHistoryIsLoading: state.ads.kmHistoryIsLoading,
    kmHistoryNotFoundVin: state.ads.kmHistoryNotFoundVin,
    kmHistoryNotFoundVinIsLoading: state.ads.kmHistoryNotFoundVinIsLoading,
    residualValueByVin: state.ads.residualValueByVin,
    residualValueByVinIsLoading: state.ads.residualValueByVinIsLoading,
    stolenCars: state.ads.stolenCars,
    stolenCarsIsLoading: state.ads.stolenCarsIsLoading,
    leasingCheckData: state.ads.vinLeasingCheck,
    leasingCheckIsLoading: state.ads.vinLeasingCheckIsLoading,
    vin: state.ads.vin,
    vinDecode: state.ads.vinDecode,
    vinDecodeIsLoading: state.ads.vinDecodeIsLoading,
    vehicleRegisterLoading: state.ads.vehicleRegisterLoading,
    vehicleRegisterFetched: state.ads.vehicleRegisterFetched,
    vehicleRegisterData: state.ads.vehicleRegisterData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetAdsByVin: () => dispatch(resetAdsByVin()),
    resetKmHistoryByVin: () => dispatch(resetKmHistoryByVin()),
    resetKmHistoryForNotFoundVin: () => dispatch(resetKmHistoryForNotFoundVin()),
  };
};

export const VerificationSummaryContainer = withRouter<IVerificationSummaryComponentOwnProps, any>(
  connect(mapStateToProps, mapDispatchToProps)(VerificationSummaryComponent)
);
