import * as React from 'react';
import AnimateHeightLib from 'react-animate-height';
import Button from 'app/components/Button';
import { Flex } from 'reflexbox/styled-components';
import { colors } from 'styles/shared.styles';

interface IAnimatedHeightProps {
  minHeight: number;
  enabled?: boolean;
  btnTextOpen?: string;
  btnTextClose?: string;
}

interface IAnimatedHeightState {
  height: number | string;
}

export class AnimatedHeight extends React.Component<IAnimatedHeightProps> {
  public state: IAnimatedHeightState;

  constructor(props) {
    super(props);
    const { minHeight } = props;
    this.state = {
      height: minHeight ? minHeight : 'auto',
    };
  }

  public componentDidMount() {
    this.setState({ height: this.props.minHeight });
  }

  public toggle = () => {
    const { height } = this.state;
    const { minHeight } = this.props;

    this.setState({
      height: height === minHeight ? 'auto' : minHeight,
    });
  };

  public render() {
    const { height } = this.state;
    const {
      children,
      btnTextOpen = 'Otevříť',
      btnTextClose = 'Zavřít',
      minHeight,
      enabled = true,
    } = this.props;

    if (!enabled) {
      return <>{children}</>;
    }

    const isClosed = height === minHeight;
    return (
      <>
        <AnimateHeightLib duration={500} height={height}>
          {children}
        </AnimateHeightLib>
        <Flex
          justifyContent='center'
          alignItems='center'
          my='30px'
          sx={{
            boxShadow: isClosed ? `0 -42px 17px 8px ${colors.bodyColor}` : 'none',
            position: 'relative',
          }}
        >
          <Button
            text={isClosed ? btnTextOpen : btnTextClose}
            onClick={this.toggle}
            bgColor='transparent'
            textColor={colors.textDefault}
            border={`1px solid ${colors.textDefault}`}
          />
        </Flex>
      </>
    );
  }
}
