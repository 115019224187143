import * as React from 'react';

interface ITabPaneProps {
  title: string;
  key: string | number;
  children?: React.ReactNode;
  to?: string;
  target?: string;
  navigationContent?: React.ReactNode;
}

class TabPane extends React.PureComponent<ITabPaneProps> {
  public render() {
    return null;
  }
}

export default TabPane;
