import styled from 'styled-components/macro';
import Paragraph from 'app/components/Paragraph';
import { mediaQuery } from 'styles/shared.styles';

export const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes.small};

  ${mediaQuery.small} {
    font-size: ${({ theme }) => theme.fontSizes.base};
  }
`;
