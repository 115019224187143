import * as React from 'react';
import { connect } from 'react-redux';

import Typography from 'app/components/Typography';
import { checkViewMode, checkVinValidity, dates } from 'app/utils';
import * as S from './VerifyVinNavBar.styles';

interface IVerifyVinNavbarStateProps {
  isAuthorized: boolean;
}

interface IVerifyVinNavbarOwnProps {
  vin: string;
}

type IVerifyVinNavbarProps = IVerifyVinNavbarStateProps & IVerifyVinNavbarOwnProps;

class VerifyVinNavbar extends React.Component<IVerifyVinNavbarProps> {
  public static defaultProps: Partial<IVerifyVinNavbarProps> = {
    vin: '',
  };

  public render() {
    const { vin } = this.props;
    const isPdfMode = checkViewMode('pdf');
    const isValidVin = checkVinValidity(vin);
    const now = dates.getDateForTimezone('Europe/Prague');
    return (
      <S.Container>
        <S.NavWrapper>
          <S.StyledHeading level={2}>{vin.toUpperCase()}</S.StyledHeading>
          {isPdfMode && <Typography text={now} />}
          {!isPdfMode && !isValidVin && (
            <Typography text={'Vložený VIN je nekompletní, doplňte ho pro kompletní report.'} />
          )}
        </S.NavWrapper>
      </S.Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyVinNavbar);
