import styled from 'styled-components/macro';

export const AdAge = styled('div')({
  marginBottom: '40px',
});

export const Table = styled.div``;

export const TooltipWrapper = styled('div')({
  color: 'black',
  border: '1px solid black',
  padding: '10px',
});

export const SectionContent = styled.div`
  position: relative;
  padding: 25px 0 0 0;
`;

export const TableSectionContent = styled.div`
  position: relative;
`;
