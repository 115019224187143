import * as React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import styled from 'styled-components/macro';
import Typography from './Typography';

import 'antd/lib/slider/style/index.css';
import 'antd/lib/input-number/style/index.css';

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  lineHeight: '30px',
});

const RangeInputLabel = styled(Typography)({
  marginBottom: '12px',
  fontSize: '13px',
  marginRight: '8px',
});

const StyledInput = styled(InputNumber)({
  width: '130px',
});

interface IRangeInputProps {
  minValue: number;
  maxValue: number;
  onChange: (args: { minValue?: number; maxValue?: number }) => void;
  inputNumberProps: Partial<InputNumberProps>;
  disabled?: boolean;
}

class RangeInput extends React.PureComponent<IRangeInputProps> {
  public render() {
    const { minValue, maxValue, inputNumberProps } = this.props;

    return (
      <Wrapper>
        <div>
          <RangeInputLabel text={'od'} />
          {/*
               // @ts-ignore */}
          <StyledInput
            size={'small'}
            onChange={this.handleMinValueChange}
            value={minValue}
            {...inputNumberProps}
          />
        </div>
        <div>
          <RangeInputLabel text={'do'} />
          {/*
               // @ts-ignore */}
          <StyledInput
            size={'small'}
            onChange={this.handleMaxValueChange}
            value={maxValue}
            {...inputNumberProps}
          />
        </div>
      </Wrapper>
    );
  }

  private handleMinValueChange = value => {
    const sanitizedValue = this.getInputValue(value);
    if (sanitizedValue === null) {
      return;
    }

    this.props.onChange({
      minValue: sanitizedValue,
    });
  };

  private handleMaxValueChange = value => {
    const sanitizedValue = this.getInputValue(value);
    if (sanitizedValue === null) {
      return;
    }
    this.props.onChange({
      maxValue: sanitizedValue,
    });
  };

  private getInputValue = (input: string): number | null => {
    let value = 0;
    if (input === '') {
      return value;
    }
    value = parseFloat(input);

    if (isNaN(value)) {
      return null;
    }
    return value;
  };
}

export default RangeInput;
