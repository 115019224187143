import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { colors } from '../../styles/shared.styles';

const disabledStyle = css({
  '::after': {
    content: "' '",
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    opacity: 0.7,
  },
});

const StyledListItem = styled('div')(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    overflow: 'hidden',
  },
  (props: IListItemProps) => (props.bordered ? { borderBottom: '1px solid #e1e1e1' } : null),
  (props: IListItemProps) => (props.disabled ? disabledStyle : null)
);

const StyledListItemRight = styled(StyledListItem)({
  flex: 1,
  textAlign: 'right',
});

const StyledListItemLeft = styled(StyledListItem)({
  flex: 1,
  textAlign: 'left',
});

const StyledListItemCenter = styled(StyledListItem)({
  flex: 1,
  textAlign: 'center',
});

const ListItemRight: React.SFC<IListItemProps> = ({ children }) => {
  return <StyledListItemRight>{children}</StyledListItemRight>;
};

const ListItemLeft: React.SFC<IListItemProps> = ({ children }) => {
  return <StyledListItemLeft>{children}</StyledListItemLeft>;
};

const ListItemCenter: React.SFC<IListItemProps> = ({ children }) => {
  return <StyledListItemCenter>{children}</StyledListItemCenter>;
};

interface IListItemProps {
  children: React.ReactNode;
  bordered?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

const listItemDefaultProps: Partial<IListItemProps> = {
  disabled: false,
};

class ListItem extends React.Component<IListItemProps> {
  public static defaultProps = listItemDefaultProps;

  public render() {
    const { children, disabled, bordered, ...other } = this.props;

    return (
      <StyledListItem bordered={bordered} disabled={disabled} {...other}>
        {children}
      </StyledListItem>
    );
  }
}

export { ListItemRight, ListItemLeft, ListItemCenter };

export default ListItem;
