import styled, { css } from 'styled-components/macro';
import { flex, mediaQuery } from '../../../styles/shared.styles';

export const HeaderContainer = styled.div`
  ${{ ...flex.vertical } as any};
  margin: 10px;
  ${mediaQuery.medium} {
    ${{ ...flex.horizontal } as any};
    ${{ ...flex.spaceBetween }};
  }
  ${mediaQuery.large} {
    margin: 0 0 20px 0;
  }
`;

export const ResultContainer = styled.div``;

export const FormContainer = styled.div`
  ${({ blurred }: any) =>
    blurred &&
    css`
      filter: blur(6px);
    `}
`;
