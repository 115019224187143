import * as React from 'react';
import { connect } from 'react-redux';
import { snakeCase, replace } from 'lodash-es';

import Ad from 'app/models/Ad';
import AdPreviewItem from './components/AdPreviewItem';
import Badge from 'app/components/Badge';
import Benchmark from 'app/models/Benchmark';
import CarImage from 'app/components/CarImage';
import Link from 'app/components/Link';
import BrandLogo from 'app/components/BrandLogo';

import { withSpaces, round } from 'app/utils/number';
import * as S from './AdPreview.styles';

interface IAdPreviewStateProps {
  ad: Ad;
  benchmark: Benchmark;
  isAuthorized: boolean;
  isPdfMode?: boolean;
}

class AdPreview extends React.PureComponent<IAdPreviewStateProps> {
  public render() {
    const { ad, benchmark, isAuthorized, isPdfMode } = this.props;
    return (
      <S.AdPreviewContainer>
        <S.AdPreviewContent>
          <S.LeftSideWrapper>
            <S.ImageContainer>
              <CarImage src={`https://${ad.img}`} />
            </S.ImageContainer>
            <S.TitleWrapper>
              <div>
                {ad.brand && (
                  <S.BrandLogoContainer>
                    <BrandLogo brand={snakeCase(ad.brand)} />
                  </S.BrandLogoContainer>
                )}
                {ad.isActive === false ? (
                  <S.CappedHeading level={2}>{ad.titleShort}</S.CappedHeading>
                ) : (
                  <S.StyledHeading level={2}>{ad.titleShort}</S.StyledHeading>
                )}
              </div>
              <S.BadgesWrapper>
                {ad.isActive === false && (
                  <S.BadgeWrapper>
                    <Badge text={'NEAKTIVNÍ INZERÁT'} type='negative' textSize='nano' />
                  </S.BadgeWrapper>
                )}
                {ad.discount && ad.discount.value > 0 && (
                  <S.BadgeWrapper>
                    <Badge text={`SLEVA ${ad.discount.percent}%`} type='positive' textSize='nano' />
                  </S.BadgeWrapper>
                )}
              </S.BadgesWrapper>
              <S.AdLink>
                <Link external={true} showExternalIcon={true} size='small' to={ad.url}>
                  {isAuthorized && isPdfMode
                    ? replace(ad.url, 'https://www.', '')
                    : 'Odkaz na inzerát'}
                </Link>
              </S.AdLink>
            </S.TitleWrapper>
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <AdPreviewItem
              title={'Hodnocení vozidla'}
              value={round(benchmark.total, 0)}
              units={'%'}
              indexValue={round(benchmark.total, 0)}
            />
            <AdPreviewItem
              title={'Najeto'}
              value={withSpaces(ad.km)}
              units={'km'}
              indexValue={round(benchmark.km, 0)}
            />
            <AdPreviewItem
              title={'Cena'}
              value={withSpaces(ad.price)}
              units={'Kč'}
              indexValue={round(benchmark.price, 0)}
              badgeText={ad.discount && ad.discount.value > 0 ? 'PO SLEVĚ' : ''}
            />
            <AdPreviewItem
              title={'Vyrobeno'}
              value={ad.year}
              units={''}
              indexValue={round(benchmark.year, 0)}
            />
          </S.RightSideWrapper>
        </S.AdPreviewContent>
      </S.AdPreviewContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    ad: state.ads.adDetails.ad,
    benchmark: state.ads.adDetails.benchmark,
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdPreview);
