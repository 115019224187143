import styled /*, { css }*/ from 'styled-components/macro';
import { SoldBadge } from 'app/components/AdsList/components/AdsListItem.styles';
import Link from 'app/components/Link';
import CarImage from 'app/components/CarImage';
import { fontFamily, fontSize, colors, mediaQuery } from 'styles/shared.styles';

export const Container: any = styled.div`
  border-radius: ${({ thumbnail }: any) => (thumbnail ? '9px 0 0 4pxx' : 'unset')};
  background-color: ${({ withBackground }: any) => (withBackground ? colors.white : 'transparent')};
  width: 100%;

  ${mediaQuery.print} {
    border-radius: inherit;
  }
`;

export const StyledCarImage = styled(CarImage)`
  border-radius: 9px 9px 4px 4px;
`;

export const ImageContainer = styled.div`
  border-radius: ${({ thumbnail }: any) => (thumbnail ? '9px 9px 4px 4px' : 'unset')};
  min-height: ${({ thumbnail }: any) => (thumbnail ? 'auto' : '25px')};
  height: ${({ thumbnail }: any) => (thumbnail ? '108px' : 'auto')};
  min-width: 144px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mediaQuery.print} {
    border-radius: inherit;
  }
`;

export const StyledBadge = styled(SoldBadge)`
  align-items: ${({ thumbnail }: any) => (!thumbnail ? 'flex-start' : 'initial')};
  background-color: ${({ bgColor }: any) => (bgColor ? bgColor : colors.darkRed)};
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.mini};
  height: ${({ thumbnail }: any) => (!thumbnail ? '100%' : 'auto')};
  justify-content: ${({ thumbnail }: any) => (!thumbnail ? 'flex-start' : 'center')};
  line-height: 18px;
  padding: ${({ thumbnail }: any) => (!thumbnail ? '5px 0 5px 25px' : 0)};
  position: ${({ thumbnail }: any) => (!thumbnail ? 'relative' : 'absolute')};
  flex-direction: column;

  ${mediaQuery.print} {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
  }
`;

export const StyledLink = styled(Link)`
  font-family: ${fontFamily.medium};
  font-size: ${fontSize.small};
  letter-spacing: -0.28px;

  ${mediaQuery.print} {
    display: none;
  }
`;

// visible in print mode
export const PrintLink = styled(Link)`
  display: none;

  ${mediaQuery.print} {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`;
