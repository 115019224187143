import { flex, colors, fontFamily } from '../../../styles/shared.styles';
import styled, { css } from 'styled-components/macro';
import { IAdLoaderProps } from './AdLoader';

const fullScreenContainerStyle = css({
  position: 'absolute',
  height: '100%',
  width: '100%',
  userSelect: 'none',
  left: 0,
});

export const AdLoaderContainer = styled('div')(
  {
    userSelect: 'none',
  },
  ({ fullscreen }: Partial<IAdLoaderProps>) => (fullscreen ? fullScreenContainerStyle : null),
  {
    ...flex.flex,
    ...flex.vertical,
    ...flex.centerHorizontal,
  } as any
);

export const LoaderWrapper = styled('div')(
  {
    userSelect: 'none',
  },
  ({ fullscreen }: Partial<IAdLoaderProps>) => ({ maxWidth: fullscreen ? '300px' : 'auto' }),
  {
    ...flex.flex,
    ...flex.centerHorizontal,
    backgroundColor: colors.white,
    padding: '20px',
    width: '100%',
  } as any
);

export const TextWrapper = styled('div')({
  lineHeight: '60px',
  fontFamily: fontFamily.medium,
  letterSpacing: '-0.26px',
});
