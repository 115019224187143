import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Flex } from 'reflexbox/styled-components';

import FormEstimateCar from './components/Form.EstimateCar';
import { defaultFormValues } from './components/Form.EstimateCar.config';
import Heading from 'app/components/Heading';
import Typography from 'app/components/Typography';
import Container from 'app/components/Container';
import { LoginBanner } from 'app/components/LoginBanner';

import { loadHighestEquipmentList } from 'app/actions/ads';
import { showLoginDialog } from 'app/actions/app';
import { loadEnums, loadModelEnums } from 'app/actions/enums';
import { createEstimateCarHistoryLog } from 'app/actions/user';
import { IEnums, IModelEnums } from 'app/models/Enums';
import { log } from 'app/utils/log';
import User from 'app/models/User';

import * as S from './EstimateCar.styles';

export interface IFormState {
  brand: string;
  model: string;
  year: number | string;
  km: number | string;
  kw: number | string;
  fuel: string;
  firstOwner: boolean | string;
  serviceBook: boolean | string;
  stk: number | string;
  transmission: string;
  ac: string | null;
  karoserie: string;
  equipment: string[];
}

interface IStatePros {
  isAuthorized: boolean;
  enums: IEnums;
  user: User;
  modelEnums: IModelEnums;
  highestEquipmentList: string[];
}

interface IStateDispatchProps {
  showLoginDialog: () => void;
  loadEnums: () => Promise<any>;
  loadModelEnums: (brand: string) => Promise<any>;
  loadHighestEquipmentList: (brand: string, model: string, year: number) => Promise<any>;
  createEstimateCarHistoryLog: (filter: string, userId: string) => Promise<any>;
}

type TProps = IStatePros & IStateDispatchProps & RouteComponentProps;

interface IEstimateCarState {
  formValues: IFormState;
}

class EstimateCar extends React.Component<TProps, IEstimateCarState> {
  public static defaultProps: Partial<TProps> = {};

  public state: IEstimateCarState = {
    formValues: defaultFormValues,
  };

  public componentDidMount() {
    this.props.loadEnums();
  }

  public setFormValues = (formValues: IFormState): void => {
    this.setState({ formValues });
  };

  public createEstimateCarHistoryLog = async (filter: string): Promise<any> => {
    try {
      const { user } = this.props;
      const result = await this.props.createEstimateCarHistoryLog(filter, user.id);
      return result;
    } catch (err) {
      log.error('createEstimateCarHistoryLog:: err', err);
    }
  };

  public render() {
    const { enums, modelEnums, isAuthorized, highestEquipmentList } = this.props;

    const { formValues } = this.state;

    return (
      <Container>
        <Flex width='100%' alignItems='center' pl={[10, 10, 0]} pr={[10, 10, 0]} mb={2} mt={4}>
          <Box my={48} width='100%' alignItems='center'>
            <Heading level={1} centered={true}>
              Prodáváte auto?
              <br />
              Určíme jeho cenu.
            </Heading>
          </Box>
        </Flex>
        <Flex width='100%' alignItems='center' mb={2} mt={4}>
          <Box width={1}>
            <>
              {!isAuthorized && (
                <LoginBanner text='Pro ocenění vozu se musíte' btnText='přihlásit' />
              )}
              <S.FormContainer blurred={!isAuthorized}>
                <S.HeaderContainer>
                  <Typography text='* položky označené hvězdičkou jsou povinné.' weight='medium' />
                </S.HeaderContainer>
                <FormEstimateCar
                  enums={enums}
                  initialValues={formValues}
                  modelEnums={modelEnums}
                  setFormValues={this.setFormValues}
                  loadModelEnums={this.props.loadModelEnums}
                  loadHighestEquipmentList={this.props.loadHighestEquipmentList}
                  highestEquipmentList={highestEquipmentList}
                  createEstimateCarHistoryLog={this.createEstimateCarHistoryLog}
                  history={this.props.history}
                />
              </S.FormContainer>
              `
            </>
          </Box>
        </Flex>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    user: state.auth.user,
    enums: state.enums.enums,
    modelEnums: state.enums.modelEnums,
    highestEquipmentList: state.ads.highestEquipmentList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
    loadEnums: () => dispatch(loadEnums()),
    loadModelEnums: (brand: string) => dispatch(loadModelEnums(brand)),
    loadHighestEquipmentList: (brand: string, model: string, year: number) =>
      dispatch(loadHighestEquipmentList(brand, model, year)),
    createEstimateCarHistoryLog: (filter: string, userId: string) =>
      dispatch(createEstimateCarHistoryLog(filter, userId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimateCar));
