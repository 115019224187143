import React from 'react';

import { Column } from 'app/components/Column';
import { Row } from 'app/components/Row';
import { IInfoCardProps, InfoCard } from 'app/components/InfoCard';

interface IInfoCardsProps {
  data: IInfoCardProps[];
}

export const InfoCards: React.FC<IInfoCardsProps> = ({ data }) => (
  <Row width={1} justifyContent='flex-start' flexWrap='wrap' flexDirection={['column', 'row']}>
    {data?.map((item, idx, arr) => (
      <Column mb='10px' width={1} maxWidth={['100%', 'calc(100%/3)']} px={[0, '5px']} key={idx}>
        <InfoCard {...item} />
      </Column>
    ))}
  </Row>
);
