import styled from 'styled-components/macro';

export const StyledLabel = styled.label`
  display: inline-block;
  margin: 0 20px 10px 0;
  cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
  input[type='checkbox'] {
    margin: 0px 5px 0 0;
    vertical-align: bottom;
    cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
  }
`;
