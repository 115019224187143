import * as Constants from 'app/constants/app';
import Notification from 'app/models/Notification';

interface IAppReducer {
  waiting: string[];
  waitingCount: number;
  notifications: {
    [key: string]: Notification;
  };
  loginDialogVisible: boolean;
  signupDialogVisible: boolean;
  forgotPasswordDialogVisible?: boolean;
  selectedTab?: string;
}

const initialState: IAppReducer = {
  waiting: [],
  waitingCount: 0,
  notifications: {},
  loginDialogVisible: false,
  signupDialogVisible: false,
};

const app = (state = initialState, action: any): IAppReducer => {
  switch (action.type) {
    case Constants.PUSH_NOTIFICATION:
      const { key, text, type, timeout } = action.payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [key]: {
            key,
            text,
            type,
            timeout,
          },
        },
      };

    case Constants.POP_NOTIFICATION:
      const notifications = {
        ...state.notifications,
      };
      delete notifications[action.payload.key];
      return {
        ...state,
        notifications,
      };
    case Constants.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: {},
      };
    case Constants.SHOW_LOGIN_DIALOG:
      return {
        ...state,
        loginDialogVisible: true,
      };
    case Constants.HIDE_LOGIN_DIALOG:
      return {
        ...state,
        loginDialogVisible: false,
      };
    case Constants.SHOW_SIGNUP_DIALOG:
      return {
        ...state,
        signupDialogVisible: true,
      };
    case Constants.HIDE_SIGNUP_DIALOG:
      return {
        ...state,
        signupDialogVisible: false,
      };
    case Constants.SHOW_FORGOT_PASSWORD_DIALOG:
      return {
        ...state,
        forgotPasswordDialogVisible: true,
      };
    case Constants.HIDE_FORGOT_PASSWORD_DIALOG:
      return {
        ...state,
        forgotPasswordDialogVisible: false,
      };
    case Constants.SET_SELECTED_TAB:
      const { selectedTab } = action.payload;
      return {
        ...state,
        selectedTab,
      };
    default:
      return state;
  }
};

export default app;
