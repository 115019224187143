import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { VerifyVinDecode } from './components';
import { loadVinDecode } from 'app/actions/ads';
import { IVinDecodeResult } from 'app/models/Api';
import { get } from 'lodash-es';

interface IStateProps {
  vin: string;
  isAuthorized: boolean;
  vinDecode: IVinDecodeResult;
  vinDecodeIsLoading: boolean;
  vinDecodeError: string;
}

interface IStateDispatchProps {
  loadVinDecode: (vin: string) => Promise<any>;
}

type TProps = IStateProps & IStateDispatchProps & RouteComponentProps;

class VerifyVinDecodeComponent extends React.Component<TProps> {
  public componentDidMount() {
    const vinUrl = get(this.props, 'match.params.vin', null);
    this.loadVinDecode(vinUrl);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const { vin } = nextProps;
    if (authHasChanged) {
      this.loadVinDecode(vin);
    }
  }

  public render() {
    const { vinDecode, vinDecodeIsLoading, vinDecodeError, vin, isAuthorized } = this.props;
    return (
      <VerifyVinDecode
        data={vinDecode}
        vin={vin}
        dataIsLoading={vinDecodeIsLoading}
        dataError={vinDecodeError}
        isAuthorized={isAuthorized}
      />
    );
  }

  private loadVinDecode = (vin: string): Promise<any> => {
    if (!vin) {
      return Promise.reject(null);
    }
    return this.props
      .loadVinDecode(vin)
      .then(response => response)
      .catch(err => err);
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    vin: state.ads.vin,
    vinDecode: state.ads.vinDecode,
    vinDecodeError: state.ads.vinDecodeError,
    vinDecodeIsLoading: state.ads.vinDecodeIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadVinDecode: (vin: string) => dispatch(loadVinDecode(vin)),
  };
};

export const VerifyVinDecodeContainer = withRouter(
  connect<IStateProps, IStateDispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyVinDecodeComponent)
);
