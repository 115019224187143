import { css } from 'styled-components/macro';
import { colors, fontFamily } from '../../../../styles/shared.styles';

export const ButtonInputCss = css({
  alignItems: 'center',
  border: `1px solid ${colors.primary}`,
  borderRadius: '16px',
  color: colors.textDefault,
  cursor: 'pointer',
  display: 'flex',
  fontFamily: fontFamily.medium,
  fontSize: '14px',
  height: '32px',
  marginRight: '8px',
  padding: '0 16px',
  textAlign: 'center',
  transition: 'background-color .2s ease-in-out, color .2s ease-in-out',
  userSelect: 'none',
  '.svg-cancel-color': {
    transition: 'fill .2s ease-in-out',
  },
  ':hover': {
    backgroundColor: colors.primary,
    color: 'white',
    '.svg-cancel-color': {
      fill: 'white',
    },
  },
});
