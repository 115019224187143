import * as React from 'react';
import * as S from './AuthBox.styles';
import { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown } from 'antd';

import GdprDialog from 'app/components/AuthDialog/GdprDialog';
import LoginDialog from 'app/components/AuthDialog/LoginDialog';
import SignupDialog from 'app/components/AuthDialog/SignupDialog';
import ForgotPasswordDialog from 'app/components/AuthDialog/ForgotPasswordDialog';
import User from 'app/models/User';
import AuthMenu from '../AuthMenu';
import { ListType } from 'app/components/ListSwitcher';
import FakeLink from 'app/components/FakeLink';
import { IconContainer } from 'app/components/IconContainer';
import {
  hideLoginDialog,
  hideSignupDialog,
  pushNotification,
  showLoginDialog,
  showSignupDialog,
  showForgotPasswordDialog,
  hideForgotPasswordDialog,
} from 'app/actions/app';
import { fbLogin, fbSignup, googleLogin, googleSignup, login, logout } from 'app/actions/auth';
import { loadAdDetails, resetFilter } from 'app/actions/ads';
import { createUser, forgotPassword } from 'app/actions/user';
import { fbApiLogin, fbLoadApi } from 'app/api/fbApi';
import { googleAuthorize } from 'app/api/googleApi';
import { FSidentifyUser, FSanonymizeUser } from 'app/utils/fullStory';

import iconAccount from 'assets/icons/account.svg';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';
import * as faBars from '@fortawesome/fontawesome-free-solid/faBars';
import { log } from 'app/utils/log';
import { scrollToTop } from 'app/utils/browser.utils';

import 'antd/lib/dropdown/style/css';

import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
} from 'app/constants/notifications';
import NotificationType from 'app/models/NotificationType';

type SocialSignupType = 'fb' | 'google' | '';

interface IAuthBoxContainerProps {
  user?: User;
  isAuthorized: boolean;
  loginInProgress?: boolean;
  logoutInProgress?: boolean;
  signupInProgress: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  signup: (
    firstname: string,
    surname: string,
    email: string,
    password: string,
    gdprConfirmed?: boolean
  ) => Promise<any>;
  fbLoginInProgress?: boolean;
  fbSignupInProgress?: boolean;
  fbLogin: (accessToken: string) => Promise<any>;
  fbSignup: (accessToken: string, gdprConfirmed: boolean) => Promise<any>;
  googleLoginInProgress?: boolean;
  googleSignupInProgress?: boolean;
  googleLogin: (code: string) => Promise<any>;
  googleSignup: (code: string | null, gdprConfirmed: boolean, accessToken?: string) => Promise<any>;
  showLoginDialog: () => void;
  hideLoginDialog: () => void;
  showSignupDialog: () => void;
  hideSignupDialog: () => void;
  showForgotPasswordDialog: () => void;
  hideForgotPasswordDialog: () => void;
  loadAdDetails: (listType: ListType) => Promise<any>;
  loginDialogVisible: boolean;
  signupDialogVisible: boolean;
  listType: ListType;
  adDetails?: any;
  filter?: any;
  notifyLoginSuccess: () => void;
  notifySignupSuccess: () => void;
  notifyForgotPasswordSuccess: () => void;
  forgotPasswordDialogVisible: boolean;
  forgotPasswordInProgress: boolean;
  forgotPasswordSuccess: boolean;
  forgotPassword: (email: string) => Promise<any>;
  resetFilter: () => void;
}

interface IAuthBoxContainerState {
  fbApiIsLoaded: boolean;
  fbLoginErrorMsg: string;
  googleLoginErrorMsg: string;
  fbSignupErrorMsg: string;
  googleSignupErrorMsg: string;
  gdprDialogVisible: boolean;
  socialSignupType: SocialSignupType;
  googleCode: string;
  fbAccessToken: string;
  dropdownVisible: boolean;
}

class AuthBoxContainer extends React.Component<
  IAuthBoxContainerProps & RouteComponentProps<any, StaticContext>,
  IAuthBoxContainerState
> {
  public state: IAuthBoxContainerState = {
    fbApiIsLoaded: false,
    fbLoginErrorMsg: '',
    googleLoginErrorMsg: '',
    fbSignupErrorMsg: '',
    googleSignupErrorMsg: '',
    gdprDialogVisible: false,
    socialSignupType: '',
    googleCode: '',
    fbAccessToken: '',
    dropdownVisible: false,
  };

  public componentDidMount() {
    fbLoadApi().then(() => {
      this.setState({
        fbApiIsLoaded: true,
      });
    });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IAuthBoxContainerProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    if (authHasChanged) {
      this.props.resetFilter();
      this.reloadAdDetail(nextProps);
    }
  }

  public handleVisibleChange = () => {
    this.setState({ dropdownVisible: !this.state.dropdownVisible });
  };

  public render() {
    const { user } = this.props;

    return (
      <S.AuthBoxContainer>
        {!user && (
          <span>
            <FakeLink
              onClick={this.onLoginBtnClick}
              color='white'
              fontSize='1.2rem'
              data-cy='authbox-login-user'
            >
              Přihlásit
            </FakeLink>
          </span>
        )}
        {user && (
          <S.DropdownContainer className='auth-box__container--js'>
            <Dropdown
              overlay={
                <AuthMenu
                  user={user}
                  onMenuItemClick={this.handleVisibleChange}
                  onLogoutBtnClick={this.onLogoutBtnClick}
                />
              }
              trigger={['click']}
              placement='bottomCenter'
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.dropdownVisible}
              getPopupContainer={(): any => document.querySelector('.auth-box__container--js')}
            >
              <FakeLink color='white' fontSize='1.2rem'>
                <S.UsernameContainer>
                  <IconContainer>
                    <img src={iconAccount} alt='icon-account' />
                  </IconContainer>
                  {user && user.firstname && (
                    <span data-cy='authbox-username'>{user.firstname}</span>
                  )}
                  <S.DropdownIconContainer>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </S.DropdownIconContainer>
                </S.UsernameContainer>
                <S.ResponsiveIconContainer>
                  <FontAwesomeIcon icon={faBars} size='lg' />
                </S.ResponsiveIconContainer>
              </FakeLink>
            </Dropdown>
          </S.DropdownContainer>
        )}

        <GdprDialog
          visible={this.state.gdprDialogVisible}
          onClose={this.onGdprDialogClose}
          onConfirm={() => this.onGdprDialogConfirm(this.state.socialSignupType)}
        />
        <LoginDialog
          fbLoginErrorMsg={this.state.fbLoginErrorMsg}
          fbLoginInProgress={this.props.fbLoginInProgress}
          googleLoginErrorMsg={this.state.googleLoginErrorMsg}
          googleLoginInProgress={this.props.googleLoginInProgress}
          loginInProgress={this.props.loginInProgress}
          onClose={this.onLoginDialogClose}
          onForgotPassword={this.switchToForgotPassword}
          onFbLogin={this.fbLogin}
          onGoogleLogin={this.googleLogin}
          onLogin={this.login}
          onSwitchToSignup={this.switchToSignup}
          visible={this.props.loginDialogVisible}
        />
        <SignupDialog
          fbLoginInProgress={this.props.fbSignupInProgress}
          fbSignupErrorMsg={this.state.fbSignupErrorMsg}
          googleLoginInProgress={this.props.googleSignupInProgress}
          googleSignupErrorMsg={this.state.googleSignupErrorMsg}
          onClose={this.onSignupDialogClose}
          onFbLogin={() => this.showGdprDialog('fb')}
          onGoogleLogin={() => this.showGdprDialog('google')}
          onSignup={this.signup}
          onSwitchToLogin={this.switchToLogin}
          signupInProgress={this.props.signupInProgress}
          visible={this.props.signupDialogVisible}
        />
        <ForgotPasswordDialog
          onClose={this.onForgotPasswordDialogClose}
          onSwitchToLogin={this.switchToLogin}
          onForgotPassword={this.forgotPassword}
          forgotPasswordInProgress={this.props.forgotPasswordInProgress}
          forgotPasswordSuccess={this.props.forgotPasswordSuccess}
          visible={this.props.forgotPasswordDialogVisible}
        />
      </S.AuthBoxContainer>
    );
  }

  private login = (email: string, password: string) => {
    return this.props.login(email, password).then(({ payload, error }) => {
      if (error) {
        throw error;
      }
      const { data: user } = payload;
      FSidentifyUser(user);
      this.props.hideLoginDialog();
      this.onLoginSuccess();
    });
  };

  private signup = (
    firstname: string,
    surname: string,
    email: string,
    password: string,
    gdprConfirmed: boolean
  ) => {
    return this.props
      .signup(firstname, surname, email, password, gdprConfirmed)
      .then(({ payload, error }) => {
        if (error) {
          throw error;
        }
        const {
          data: { user },
        } = payload;
        FSidentifyUser(user);
        this.props.hideSignupDialog();
        this.props.hideLoginDialog();
        this.props.notifySignupSuccess();
      });
  };

  private logout = () => {
    return this.props
      .logout()
      .then(({ error }) => {
        if (error) {
          throw error;
        }
        FSanonymizeUser();
      })
      .catch(err => log.debug(err));
  };

  private forgotPassword = (email: string) => {
    return this.props
      .forgotPassword(email)
      .then(({ payload, error }) => {
        if (error) {
          throw error;
        }
        this.props.hideForgotPasswordDialog();
        this.props.notifyForgotPasswordSuccess();
        return payload;
      })
      .catch(err => {
        throw err;
      });
  };

  private onLoginBtnClick = () => {
    this.props.showLoginDialog();
  };

  private onLoginDialogClose = () => {
    this.props.hideLoginDialog();
  };

  private onForgotPasswordDialogClose = () => {
    this.props.hideForgotPasswordDialog();
  };

  private onSignupDialogClose = () => {
    this.setState({
      fbSignupErrorMsg: '',
      googleSignupErrorMsg: '',
    });
    this.props.hideSignupDialog();
  };

  private onLogoutBtnClick = (evt: SyntheticEvent) => {
    evt.preventDefault();
    this.logout();
  };

  private fbLogin = () => {
    this.setState({
      fbLoginErrorMsg: '',
      googleLoginErrorMsg: '',
    });

    if (this.state.fbApiIsLoaded) {
      fbApiLogin()
        .then(response => {
          const accessToken = (response as any).authResponse.accessToken;
          this.setState({ fbAccessToken: accessToken });
          return this.props.fbLogin(accessToken);
        })
        .then(({ payload }) => {
          const { data: user } = payload;
          FSidentifyUser(user);
          this.props.hideLoginDialog();
          this.props.hideSignupDialog();
          this.onLoginSuccess();
        })
        .catch(err => {
          const { error } = err;
          const responseStatus =
            error && error.response && error.response.data && error.response.data.status;
          const isUserNotFoundError = responseStatus === 404;
          if (isUserNotFoundError) {
            this.showGdprDialog('fb');
            return;
          }
          log.debug(err);
        });
    }
  };

  private googleLogin = () => {
    this.setState({
      googleLoginErrorMsg: '',
      fbLoginErrorMsg: '',
    });

    googleAuthorize()
      .then((response: any) => {
        const code = response.code;
        this.setState({ googleCode: code });
        return this.props.googleLogin(code);
      })
      .then(({ payload }) => {
        const { data: user } = payload;
        FSidentifyUser(user);
        this.props.hideLoginDialog();
        this.props.hideSignupDialog();
        this.onLoginSuccess();
      })
      .catch(err => {
        const { error } = err;
        const responseStatus =
          error && error.response && error.response.data && error.response.data.status;
        const isUserNotFoundError = responseStatus === 404;

        if (isUserNotFoundError) {
          this.showGdprDialog('google');
          return;
        }
        log.debug(err);
      });
  };

  private onLoginSuccess = () => {
    this.props.notifyLoginSuccess();
    scrollToTop();
  };

  private fbSignup = (gdprConfirmed: boolean) => {
    this.setState({
      fbSignupErrorMsg: '',
      googleSignupErrorMsg: '',
    });
    if (this.state.fbApiIsLoaded) {
      fbApiLogin()
        .then(response =>
          this.props.fbSignup((response as any).authResponse.accessToken, gdprConfirmed)
        )
        .then(({ payload, error }) => {
          if (error) {
            this.props.showSignupDialog();
            const responseMsg =
              error && error.response && error.response.data && error.response.data.message;
            const isUserAlreadyExistsError =
              responseMsg && responseMsg.indexOf('E11000 duplicate key error') > -1;
            if (isUserAlreadyExistsError) {
              this.setState({
                fbSignupErrorMsg: 'Chyba, uživatel se stejným emailem již existuje.',
              });
              return;
            }
            return;
          }
          const { data: user } = payload;
          FSidentifyUser(user);
          this.props.hideLoginDialog();
          this.props.hideSignupDialog();
          this.props.notifySignupSuccess();
        })
        .catch(err => log.debug(err));
    }
  };

  private googleSignup = (gdprConfirmed: boolean) => {
    this.setState({
      fbSignupErrorMsg: '',
      googleSignupErrorMsg: '',
    });
    googleAuthorize()
      .then((response: any) => this.props.googleSignup(response.code, gdprConfirmed))
      .then(({ payload, error }) => {
        if (error) {
          this.props.showSignupDialog();
          const responseMsg = error?.response?.data?.message;
          const isUserAlreadyExistsError =
            responseMsg && responseMsg.indexOf('E11000 duplicate key error') > -1;
          if (isUserAlreadyExistsError) {
            this.setState({
              googleSignupErrorMsg: 'Chyba, uživatel se stejným emailem již existuje.',
            });
            return;
          }
          return;
        }
        const { data: user } = payload;
        FSidentifyUser(user);
        this.props.hideLoginDialog();
        this.props.hideSignupDialog();
        this.props.notifySignupSuccess();
      })
      .catch(err => log.debug(err));
  };

  private googleSignupNotFoundUser = async (gdprConfirmed: boolean) => {
    try {
      const silent = true;
      const googleAuthorizeResponse = await googleAuthorize(silent);
      const signupResponse = await this.props.googleSignup(
        null,
        gdprConfirmed,
        (googleAuthorizeResponse as any).access_token
      );
      const {
        payload: { data: user },
      } = signupResponse;
      FSidentifyUser(user);
      this.onLoginSuccess();
      return signupResponse;
    } catch (error) {
      log.debug('googleSignupNotFoundUser error', error);
      const responseMsg = (error as any)?.response?.data?.message;
      return responseMsg;
    }
  };

  private fbSignupNotFoundUser = async (accessToken: string, gdprConfirmed: boolean) => {
    try {
      const signupResponse = await this.props.fbSignup(accessToken, gdprConfirmed);
      const {
        payload: { data: user },
      } = signupResponse;
      FSidentifyUser(user);
      this.onLoginSuccess();
      return signupResponse;
    } catch (error) {
      log.debug('fbSignupNotFoundUser error', error);
      const responseMsg = (error as any)?.response?.data?.message;
      return responseMsg;
    }
  };

  private switchToSignup = (evt: SyntheticEvent) => {
    evt.preventDefault();
    this.props.hideLoginDialog();
    this.props.showSignupDialog();
  };

  private switchToLogin = (evt: SyntheticEvent) => {
    evt.preventDefault();
    this.props.hideSignupDialog();
    this.props.hideForgotPasswordDialog();
    this.props.showLoginDialog();
  };

  private switchToForgotPassword = (evt: SyntheticEvent) => {
    evt.preventDefault();
    this.props.hideSignupDialog();
    this.props.hideLoginDialog();
    this.props.showForgotPasswordDialog();
  };

  private reloadAdDetail = (nextProps: IAuthBoxContainerProps) => {
    const ad = nextProps.adDetails && nextProps.adDetails.ad;
    const { listType } = nextProps;
    if (ad && ad.url) {
      this.props.loadAdDetails(listType);
    }
  };

  private onGdprDialogClose = () => {
    this.setState({
      gdprDialogVisible: false,
      socialSignupType: '',
      googleCode: '',
      fbAccessToken: '',
    });
  };

  private showGdprDialog = (socialSignupType: SocialSignupType) => {
    this.props.hideSignupDialog();
    this.props.hideLoginDialog();
    this.setState({
      gdprDialogVisible: true,
      socialSignupType,
    });
  };

  private onGdprDialogConfirm = (socialSignupType: SocialSignupType): void => {
    if (socialSignupType === 'google') {
      this.setState(
        {
          gdprDialogVisible: false,
          socialSignupType: '',
        },
        () => {
          const gdprConfirmed = true;
          const { googleCode } = this.state;
          if (googleCode) {
            this.googleSignupNotFoundUser(gdprConfirmed);
            this.setState({
              googleCode: '',
              fbAccessToken: '',
            });
            return;
          }
          this.googleSignup(gdprConfirmed);
        }
      );
      return;
    }

    if (socialSignupType === 'fb') {
      this.setState(
        {
          gdprDialogVisible: false,
          socialSignupType: '',
        },
        () => {
          const gdprConfirmed = true;
          const { fbAccessToken } = this.state;
          if (fbAccessToken) {
            this.fbSignupNotFoundUser(fbAccessToken, gdprConfirmed);
            this.setState({
              googleCode: '',
              fbAccessToken: '',
            });
            return;
          }
          this.fbSignup(gdprConfirmed);
        }
      );
    }
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    loginInProgress: state.auth.loginInProgress,
    logoutInProgress: state.auth.logoutnInProgress,
    signupInProgress: state.auth.signupInProgress,
    fbLoginInProgress: state.auth.fbLoginInProgress,
    fbSignupInProgress: state.auth.fbSignupInProgress,
    googleLoginInProgress: state.auth.googleLoginInProgress,
    googleSignupInProgress: state.auth.googleSignupInProgress,
    loginDialogVisible: state.app.loginDialogVisible,
    signupDialogVisible: state.app.signupDialogVisible,
    forgotPasswordDialogVisible: state.app.forgotPasswordDialogVisible,
    adDetails: state.ads.adDetails,
    filter: state.ads.filter,
    user: state.auth.user,
    listType: state.ads.listType,
    forgotPasswordInProgress: state.user && state.user.forgotPasswordInProgress,
    forgotPasswordSuccess: state.user && state.user.forgotPasswordSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  const NOTIFICATION_DISMISS_DELAY = 7000;

  return {
    login: (email: string, password: string) => dispatch(login(email, password)),
    logout: () => dispatch(logout()),
    signup: (
      firstname: string,
      surname: string,
      email: string,
      password: string,
      gdprConfirmed: boolean
    ) => dispatch(createUser(firstname, surname, email, password, gdprConfirmed)),
    fbLogin: (accessToken: string) => dispatch(fbLogin(accessToken)),
    fbSignup: (accessToken: string, gdprConfirmed: boolean) =>
      dispatch(fbSignup(accessToken, gdprConfirmed)),
    googleLogin: (code: string) => dispatch(googleLogin(code)),
    googleSignup: (code: string, gdprConfirmed: boolean, accessToken?: string) =>
      dispatch(googleSignup(code, gdprConfirmed, accessToken)),
    showLoginDialog: () => dispatch(showLoginDialog()),
    hideLoginDialog: () => dispatch(hideLoginDialog()),
    showSignupDialog: () => dispatch(showSignupDialog()),
    hideSignupDialog: () => dispatch(hideSignupDialog()),
    showForgotPasswordDialog: () => dispatch(showForgotPasswordDialog()),
    hideForgotPasswordDialog: () => dispatch(hideForgotPasswordDialog()),
    loadAdDetails: (listType: ListType) => dispatch(loadAdDetails(listType)),
    resetFilter: () => dispatch(resetFilter()),
    notifyLoginSuccess: () =>
      dispatch(
        pushNotification(
          LOGIN_SUCCESS,
          'Byli jste úspěšně přihlášeni.',
          NotificationType.info,
          NOTIFICATION_DISMISS_DELAY
        )
      ),
    notifySignupSuccess: () =>
      dispatch(
        pushNotification(
          SIGNUP_SUCCESS,
          'Registrace byla úspěšná. Nyní jste úspěšně přihlášeni.',
          NotificationType.info,
          NOTIFICATION_DISMISS_DELAY
        )
      ),
    notifyForgotPasswordSuccess: () =>
      dispatch(
        pushNotification(
          FORGOT_PASSWORD_SUCCESS,
          'Žádost o obnovu hesla byla úspěšná. Na vaši e-mailovou adresu vám dorazí další informace.',
          NotificationType.info,
          NOTIFICATION_DISMISS_DELAY
        )
      ),
    forgotPassword: (email: string) => dispatch(forgotPassword(email)),
  };
};

export default withRouter(
  connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AuthBoxContainer)
);
