import { redirectTo } from 'app/utils/url.utils';

// sometimes payment confirmation takes longer
// so we repeat payment status check 10 times
export const onPaymentRetry = (vin, orderId) => (failureCount, error) => {
  const MAX_RETRIES = 10;
  const shouldVerifyPayment = failureCount < MAX_RETRIES && orderId;
  const cancelRetries = failureCount === MAX_RETRIES;
  if (cancelRetries) {
    redirectTo(`/vin/${vin}`);
    return false;
  }
  return !!shouldVerifyPayment;
};

export const checkIsPremiumReport = (isSuccess, isPaidVin) => isSuccess && isPaidVin;

export const checkIsFreeReport = (isAuthenticated, isPaidVin, error) => {
  if (!isAuthenticated) return false;
  if (error?.response?.status === 404) return true;
  if (!isPaidVin) return true;
  return false;
};

export const redirectToFreeReport = (
  isPremiumRoute,
  isSuccess,
  isPaidVin,
  isAuthenticated
): boolean => {
  if (isPremiumRoute && !isAuthenticated) return true;
  if (isPremiumRoute && !checkIsPremiumReport(isSuccess, isPaidVin)) return true;
  return false;
};

export const redirectToPremiumReport = (isPremiumRoute, isSuccess, isPaidVin): boolean => {
  if (!isPremiumRoute && checkIsPremiumReport(isSuccess, isPaidVin)) return true;
  return false;
};
