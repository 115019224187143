import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { VerifyVinLeasingCheck } from './components';
import { loadVinLeasingCheck } from 'app/actions/ads';
import { get } from 'lodash-es';

interface IStateProps {
  isAuthorized: boolean;
  vin: string;
  vinLeasingCheck: any;
  vinLeasingCheckIsLoading: boolean;
  vinLeasingCheckError: string;
}

interface IStateDispatchProps {
  loadVinLeasingCheck: (vin: string) => Promise<any>;
}

type TProps = IStateProps & IStateDispatchProps & RouteComponentProps;

class VerifyVinLeasingCheckComponent extends React.Component<TProps> {
  public componentDidMount() {
    const vinUrl = get(this.props, 'match.params.vin', null);
    this.loadVinLeasingCheck(vinUrl);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const { vin } = nextProps;
    if (authHasChanged) {
      this.loadVinLeasingCheck(vin);
    }
  }

  public render() {
    const {
      vinLeasingCheck,
      vinLeasingCheckIsLoading,
      vinLeasingCheckError,
      vin,
      isAuthorized,
    } = this.props;
    return (
      <VerifyVinLeasingCheck
        data={vinLeasingCheck}
        vin={vin}
        dataIsLoading={vinLeasingCheckIsLoading}
        dataError={vinLeasingCheckError}
        isAuthorized={isAuthorized}
      />
    );
  }

  private loadVinLeasingCheck = (vin: string): Promise<any> => {
    if (!vin) {
      return Promise.reject(null);
    }
    return this.props
      .loadVinLeasingCheck(vin)
      .then(response => response)
      .catch(err => err);
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    vin: state.ads.vin,
    vinLeasingCheck: state.ads.vinLeasingCheck,
    vinLeasingCheckError: state.ads.vinLeasingCheckError,
    vinLeasingCheckIsLoading: state.ads.vinLeasingCheckIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadVinLeasingCheck: (vin: string) => dispatch(loadVinLeasingCheck(vin)),
  };
};

export const VerifyVinLeasingCheckContainer = withRouter(
  connect<IStateProps, IStateDispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyVinLeasingCheckComponent)
);
