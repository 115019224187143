import * as React from 'react';
import styled from 'styled-components/macro';
import { fontFamily } from '../../styles/shared.styles';
import { color, ColorProps, typography, TypographyProps } from 'styled-system';

interface IOwnProps {
  onClick?: () => void;
  size?: string;
  activeClassName?: string;
}

type TFakeLinkProps = IOwnProps & ColorProps & TypographyProps;

const StyledLink = styled.span(
  {
    height: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
      color: 'white',
    },
    fontFamily: fontFamily.medium,
  },
  (props: TFakeLinkProps) =>
    props.textAlign === 'left'
      ? { justifyContent: 'flex-start' }
      : null || props.textAlign === 'center'
      ? { justifyContent: 'center' }
      : null || props.textAlign === 'right'
      ? { justifyContent: 'flex-end' }
      : null,
  color,
  typography
);

const FakeLink: React.SFC<TFakeLinkProps> = ({ onClick, size, children, ...rest }) => (
  <StyledLink onClick={onClick} size={size} {...rest}>
    {children}
  </StyledLink>
);

export default FakeLink;
