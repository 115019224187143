import * as React from 'react';
import styled from 'styled-components/macro';

import Typography from 'app/components/Typography';
import { LoadingIcon } from 'app/components/Icons/LoadingIcon';
import { Column } from 'app/components/Column';
import { Row, TRowProps } from 'app/components/Row';

import { withSpaces } from 'app/utils/number';
import { FontSize } from 'app/components/Typography';
import { mediaQuery } from 'styles/shared.styles';

import { IconSuccess, IconCancel, IconQuestionMarkSmall } from 'app/utils/icons';

interface IFormattedValueProps {
  value: any;
  unit?: any;
  color?: string;
  fontSize?: FontSize;
  formattedWithSpaces?: boolean;
  blurred?: boolean;
  iconType?: 'success' | 'fail' | 'loading' | 'neutral' | null;
  constainerProps?: TRowProps;
  [key: string]: any;
}

const iconsMap = {
  success: <IconSuccess />,
  fail: <IconCancel />,
  loading: <LoadingIcon />,
  neutral: <IconQuestionMarkSmall />,
};

const format = (value: any, unit: any, formattedWithSpaces?: boolean) => {
  if (unit) {
    return formattedWithSpaces ? `${withSpaces(value)} ${unit}` : `${value} ${unit}`;
  }

  return typeof value === 'number' && formattedWithSpaces ? `${withSpaces(value)}` : value;
};

const FormattedValue: React.SFC<IFormattedValueProps> = ({
  value,
  unit,
  fontSize,
  formattedWithSpaces,
  blurred = false,
  iconType = '',
  constainerProps,
  ...props
}) => {
  if (value === '' || value === null)
    return <Typography text='Neuvedeno' size={fontSize} blurred={blurred} {...props} />;

  const formattedValue = format(value, unit, formattedWithSpaces);
  const icon = iconType && iconsMap[iconType];

  return (
    <Row alignItems='center' {...constainerProps}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <Typography text={formattedValue} size={fontSize} blurred={blurred} {...props} />
    </Row>
  );
};

FormattedValue.defaultProps = {
  fontSize: 'micro',
  formattedWithSpaces: true,
};

const IconContainer = styled(Column)`
  margin-right: 9px;

  ${mediaQuery.print} {
    margin-right: 3px;
  }
`;

export default FormattedValue;
