import * as React from 'react';
import { snakeCase } from 'lodash-es';
import Container from '../../Container';
import BrandLogo from '../../BrandLogo';
import * as S from './TabsNavigation.styles';

interface ITabsNavigationProps {
  title?: string;
  brand?: string;
  selectedTab?: { pathname: string; title: string };
  navigationShown?: boolean;
  toggleNavigation: () => void;
}

const TabsNavigation: React.SFC<ITabsNavigationProps> = ({
  title,
  brand,
  children,
  selectedTab,
  toggleNavigation,
  navigationShown = false,
}) => (
  <S.Wrapper>
    <Container>
      <S.CarWrapper>
        <S.BrandLogoTitleWrapper>
          {brand && <BrandLogo brand={snakeCase(brand)} />}
          <S.StyledHeading level={2}>{title}</S.StyledHeading>
        </S.BrandLogoTitleWrapper>
        {selectedTab && (
          <S.SelectedTabTitleContainer>
            <S.SelectedTabTitle onClick={() => toggleNavigation()}>
              {selectedTab.title}
              <S.SelectedTabIcon />
            </S.SelectedTabTitle>
          </S.SelectedTabTitleContainer>
        )}
        <S.NavigationWrapper
          role='navigation'
          style={{ display: navigationShown ? 'flex' : 'none' }}
        >
          {children}
        </S.NavigationWrapper>
      </S.CarWrapper>
    </Container>
  </S.Wrapper>
);

export default TabsNavigation;
