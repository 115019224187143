import * as React from 'react';
import Typography from 'app/components/Typography';
import { TableRow } from 'app/components/Table';
import * as S from './VerifyVinTable.styles';
import { IconContainer } from 'app/components/_shared_';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import * as faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';

import { colors } from 'styles/shared.styles';

interface IVerifyVinTableHeaderProps {
  rowTitle: string;
  isSuccess?: boolean;
  isSuccessText?: string;
  isDangerText?: string;
  striped?: boolean;
  dataCy?: string;
  children?: React.ReactNode;
}

export const VerifyVinTableRow: React.SFC<IVerifyVinTableHeaderProps> = ({
  rowTitle,
  isSuccess,
  isSuccessText,
  isDangerText,
  striped,
  children,
  dataCy,
}) => {
  return (
    <TableRow fluid={true} striped={striped} data-cy={dataCy}>
      <S.StyledTableColumn>
        <Typography text={rowTitle || ''} />
      </S.StyledTableColumn>
      <S.StyledTableColumn>{children ? children : <TableValueWithIcon />}</S.StyledTableColumn>
    </TableRow>
  );
};

VerifyVinTableRow.defaultProps = {
  striped: false,
};

const TableValueWithIcon: React.SFC<Partial<IVerifyVinTableHeaderProps>> = ({
  isSuccessText,
  isDangerText,
}) => {
  if (isSuccessText) {
    return (
      <>
        <Typography text={isSuccessText} weight='medium' color={colors.darkGreen} />
        <IconContainer>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: colors.darkGreen }} />
        </IconContainer>
      </>
    );
  }

  if (isDangerText) {
    return (
      <>
        <Typography text={isDangerText} color={colors.red} />
        <IconContainer>
          <FontAwesomeIcon icon={faExclamationCircle} style={{ color: colors.red }} />
        </IconContainer>
      </>
    );
  }

  return null;
};
