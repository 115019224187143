import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import app from './app';
import user from './user';
import auth from './auth';
import ads from './ads';
import enums from './enums';
import payments from './payments';
import { sockets } from './sockets';

const appReducer = combineReducers({
  app,
  auth,
  ads,
  user,
  enums,
  payments,
  sockets,
  routing: routerReducer,
});

export type TRootState = ReturnType<typeof appReducer>;

export default appReducer;
