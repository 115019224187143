import * as React from 'react';
import * as S from './Nav.styles';
import { useTypedSelector } from 'app/store';

const Nav = () => {
  const isAuthorized = useTypedSelector(state => !!state.auth.accessToken);
  return (
    <S.Wrapper>
      {isAuthorized ? (
        <S.LinkWithBackground to='/moje-vozy' size='1rem' exact={true}>
          Moje vozy
        </S.LinkWithBackground>
      ) : null}
      <S.LinkWithBackground to='/#overit-vin' size='1rem' exact={true}>
        Ověřit VIN
      </S.LinkWithBackground>
      <S.LinkWithBackground to='/ocenit-vuz' size='1rem' exact={true}>
        Ocenit vůz
      </S.LinkWithBackground>
      <S.LinkWithBackground to='/kontakt' size='1rem' exact={true}>
        Kontakt
      </S.LinkWithBackground>
    </S.Wrapper>
  );
};

export default Nav;
