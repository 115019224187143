import * as React from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { PdfExportDownloadBtn } from './PdfExportDownloadBtn';
import { loadPdfExport } from 'app/actions/ads';
import { formatDate, DATES_PDF_FORMAT } from 'app/utils/dates';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

interface IStateDispatchProps {
  loadPdfExport: (url: string, filename?: string) => Promise<void>;
}

interface IOwnProps {
  visible: boolean;
  btnText?: string;
  filename?: string;
  urlToExport?: string;
}

type IPdfExportDownloadBtnProps = IOwnProps & IStateDispatchProps;

export class PdfExportDownloadBtnContainer extends React.Component<IPdfExportDownloadBtnProps> {
  public render() {
    const { visible, filename, btnText = 'Stáhnout PDF', urlToExport } = this.props;
    const isMobileApp = MobileAppUtils.isInApp();

    if (isMobileApp || !visible) return null;

    return (
      <PdfExportDownloadBtn
        btnText={btnText}
        onClick={e => this.onPdfExportClick(filename, urlToExport)}
      />
    );
  }

  private onPdfExportClick = async (filename: string = 'export', urlToExport?: string) => {
    const url = urlToExport
      ? `${process.env.REACT_APP_BASE_URL}${urlToExport}?mode=pdf`
      : `${window.location.href}?mode=pdf`;
    const response = await this.props.loadPdfExport(url);
    const pdfExport = get(response, 'payload.data', null);
    if (pdfExport) {
      const blob = new Blob([pdfExport], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}-${formatDate(new Date(), '', DATES_PDF_FORMAT)}.pdf`;
      link.click();
    }
  };
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    loadPdfExport: (url: string, filename?: string) => dispatch(loadPdfExport(url, filename)),
  };
};

export default connect<{}, IStateDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PdfExportDownloadBtnContainer);
