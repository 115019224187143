import * as React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import { size } from 'lodash-es';

import { Column } from 'app/components/Column';
import { SectionHeaderContainer } from 'app/components/_shared_';
import { CompactAdsList } from 'app/components/CompactAdsList';
import { LoginBanner } from 'app/components/LoginBanner';
import SectionHeader from 'app/components/SectionHeader';
import Heading from 'app/components/Heading';

import Ad from 'app/models/Ad';
import { IResidualValueResult } from 'app/models/Api';
import { colors } from 'styles/shared.styles';
import { checkViewMode } from 'app/utils';

import lastSoldCarsMiniBlurredImg from 'assets/images/last-sold-cars-mini-blurred.png';
import lastAddedCarsMiniBlurredImg from 'assets/images/last-added-cars-mini-blurred.png';

interface IOwnProps {
  data: {
    lastSoldCars: IResidualValueResult['lastSoldCars'];
    lastAddedCars: IResidualValueResult['lastAddedCars'];
  };
  isAuthorized: boolean;
  sectionTitle: string;
  itemsPerRow?: number;
}

export const LastAddedAndSoldCars: React.SFC<IOwnProps> = ({
  data,
  sectionTitle,
  isAuthorized,
  itemsPerRow = 6,
}) => {
  const isPdfMode = checkViewMode('pdf');
  const noOfRecords = size([...data?.lastAddedCars, ...data?.lastSoldCars]);

  return (
    <div>
      <Flex width='100%' flexDirection='row'>
        <SectionHeaderContainer>
          <SectionHeader
            title={sectionTitle}
            subheaderValue={String(noOfRecords) || '0'}
            subheaderSuffixText={Ad.getNumberOfRecordsTitle(noOfRecords)}
          />
        </SectionHeaderContainer>
      </Flex>

      <Flex width='100%' flexDirection='column'>
        <Flex width='100%' flexDirection='column'>
          <Box width={1} mb={10}>
            <Heading level={3} weight='medium' size='small'>
              Poslední prodané vozy
            </Heading>
          </Box>
          <Box width={1} mb={35}>
            {isAuthorized ? (
              <CompactAdsList
                allowRedirectButton={false}
                badgeText='Prodáno'
                badgeBgColor={colors.darkRed}
                badgeValuePropName={'lastRun'}
                data={data.lastSoldCars}
                itemLayout='vertical'
                itemsPerRow={itemsPerRow}
                thumbnail={false}
                withBackground={true}
                name='last-sold-cars'
              />
            ) : (
              <Column position='relative'>
                <LoginBanner />
                <img src={lastSoldCarsMiniBlurredImg} alt='last sold cars blurred' />
              </Column>
            )}
          </Box>
        </Flex>

        <Flex
          width='100%'
          flexDirection='column'
          mb={['10px', '30px', isPdfMode ? '50px' : '70px']}
        >
          <Box width={1} mb={10}>
            <Heading level={3} weight='medium' size='small'>
              Nově přidané vozy
            </Heading>
          </Box>
          <Box width={1}>
            {isAuthorized ? (
              <CompactAdsList
                allowRedirectButton={false}
                badgeBgColor={colors.highlight}
                badgeText='Přidáno'
                badgeValuePropName={'firstRun'}
                data={data.lastAddedCars}
                itemLayout='vertical'
                itemsPerRow={itemsPerRow}
                thumbnail={false}
                withBackground={true}
                name='last-added-cars'
              />
            ) : (
              <Column position='relative'>
                <LoginBanner />
                <img src={lastAddedCarsMiniBlurredImg} alt='last added cars blurred' />
              </Column>
            )}
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

LastAddedAndSoldCars.defaultProps = {};
