import styled from 'styled-components/macro';
import { mediaQuery } from '../../../styles/shared.styles';

export const Container = styled('div')({
  textAlign: 'center',
  padding: '70px 20px',
  [mediaQuery.medium]: {
    padding: '50px',
  },
});

export const Content = styled('div')({
  marginBottom: '30px',
});

export const ButtonContainer = styled('div')({
  marginBottom: '10px',
  '&:last-child': {
    marginBottom: 0,
  },
});

export const ButtonContainers = styled('div')({
  margin: '0 auto',
  maxWidth: '300px',
});
