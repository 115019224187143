import * as React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../styles/shared.styles';

const StyledParagraph = styled('p')({
  fontSize: '1rem',
  lineHeight: 1.4,
  color: colors.textDefault,
});

const Paragraph = props => <StyledParagraph {...props} />;

export default Paragraph;
