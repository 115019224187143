import * as React from 'react';
import { css } from 'styled-components/macro';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import { fontFamily } from 'styles/shared.styles';

export const AntOption = Select.Option;

export const StyledOptionCss = css({
  fontFamily: fontFamily.medium,
  fontSize: '17px',
  '.ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item-selected:hover': {
    fontWeight: 'normal',
    fontFamily: fontFamily.medium,
    fontSize: '17px',
  },
});

// does not work
const Option = props => <AntOption {...props} style={StyledOptionCss} />;

export default Option;
