import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  letterSpacing,
  LetterSpacingProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { colors, fontFamily, mediaQuery } from '../../styles/shared.styles';

interface IOwnProps {
  level: number;
  size?: string | string[];
  weight?: string;
  centered?: boolean;
  theme?: string;
  minHeight?: string;
  lineHeight?: string | number;
  margin?: string;
  color?: string;
}

const dynamicStyle = props =>
  css({
    textAlign: 'center',
    color: props.theme === 'light' ? 'white' : colors.textDefault,
    userSelect: 'text',
    minHeight: props.minHeight || 'auto',
    lineHeight: props.lineHeight || 1.4,
    size: props.size,
    margin: props.margin,
    [mediaQuery.small]: {
      textAlign: props.centered ? 'center' : 'left',
    },
  });

// prettier-ignore
const weightStyle = (props: any) => (
  props.weight === 'thin' ? { fontFamily: fontFamily.thin } : null ||
  props.weight === 'extra-light' ? { fontFamily: fontFamily.extraLight } : null ||
  props.weight === 'light' ? { fontFamily: fontFamily.light } : null ||
  props.weight === 'regular' ? { fontFamily: fontFamily.regular } : null ||
  props.weight === 'medium' ? { fontFamily: fontFamily.medium } : null ||
  props.weight === 'bold' ? { fontFamily: fontFamily.bold } : null ||
  props.weight === 'extra-bold' ? { fontFamily: fontFamily.extraBold } : null ||
  props.weight === 'heavy' ? { fontFamily: fontFamily.heavy } : null
)

// prettier-ignore
const sizeStyle = (props: any) => (
  props.size === 'micro' ? { fontSize: '0.875rem' } : null || // 14px
  props.size === 'mini' ? { fontSize: '1rem' } : null || // 16px
  props.size === 'small' ? { fontSize: '1.25rem' } : null || // 20px
  props.size === 'normal' ? { fontSize: '1.5rem' } : null || //24px
  props.size === 'medium' ? { fontSize: '1.875rem' } : null || //30px
  props.size === 'big' ? { fontSize: '2rem' } : { fontSize: props.size } //32px
)

const H1 = styled('h1')(
  {
    fontSize: '3.5rem',
    fontFamily: fontFamily.bold,
    marginBottom: '24px',
  },
  weightStyle,
  sizeStyle,
  dynamicStyle,
  letterSpacing,
  space,
  color,
  typography
);

const H2 = styled('h2')(
  {
    fontSize: '2.187rem',
    fontFamily: fontFamily.bold,
    marginBottom: '8px',
  },
  weightStyle,
  sizeStyle,
  dynamicStyle,
  letterSpacing,
  space,
  color,
  typography
);

const H3 = styled('h3')(
  {
    fontSize: '1.25rem',
    fontFamily: fontFamily.bold,
    marginBottom: '8px',
  },
  weightStyle,
  sizeStyle,
  dynamicStyle,
  letterSpacing,
  space,
  color,
  typography
);

type THeadingProps = IOwnProps & LetterSpacingProps & SpaceProps & ColorProps & TypographyProps;

const Heading: React.SFC<THeadingProps> = ({ centered, level, weight, size, ...props }) => {
  let Output;
  switch (level) {
    case 1:
      Output = <H1 centered={centered} weight={weight} size={size} lineHeight='53px' {...props} />;
      break;
    case 2:
      Output = <H2 centered={centered} weight={weight} size={size} lineHeight={1.2} {...props} />;
      break;
    case 3:
      Output = <H3 centered={centered} weight={weight} size={size} {...props} />;
      break;
    default:
      Output = <H1 centered={centered} weight={weight} size={size} {...props} />;
      break;
  }

  return Output;
};

export default Heading;
