import styled from 'styled-components/macro';
import { flex, mediaQuery } from 'styles/shared.styles';

export const AuthBoxContainer = styled.div``;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const ResponsiveIconContainer = styled.div`
  ${mediaQuery.medium} {
    display: none;
  }
`;

export const DropdownIconContainer = styled.div`
  margin-left: 10px;
`;

export const IconAccountContainer = styled('div')({
  ...flex.flex,
  marginRight: '12px',
});

export const UsernameContainer = styled.div`
  display: none;
  ${mediaQuery.medium} {
    ${{ ...flex.flex }};
    ${{ ...flex.justifyEnd }};
    ${{ ...flex.centerHorizontalV }};
    padding: 16px 8px;
    width: 175px;
  }
`;
