import * as React from 'react';

import PremiumBanner from 'app/components/PremiumBanner';
import { BlurryImageContainer, FullWidthImage } from 'app/components/_shared_';

interface IDummyContentProps {
  overlayContent?: React.ReactNode;
  bgSrc?: string;
}

export const DummyContent: React.SFC<IDummyContentProps> = ({ overlayContent, bgSrc }) => {
  return (
    <BlurryImageContainer>
      {overlayContent && (
        <PremiumBanner type='dark' overlay={true}>
          {overlayContent}
        </PremiumBanner>
      )}
      {bgSrc && <FullWidthImage src={bgSrc} alt='image' />}
    </BlurryImageContainer>
  );
};

DummyContent.defaultProps = {};
