import * as React from 'react';
import memoize from 'memoize-one';
import styled from 'styled-components/macro';
import Typography from './Typography';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import AdFilter from '../models/AdFilter';

import 'antd/lib/select/style/index.css';
import { roundWithRange, withSpaces } from '../utils/number';

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  lineHeight: '30px',
});

const RangeLabel = styled(Typography)({
  marginBottom: '12px',
  fontSize: '13px',
  marginRight: '8px',
});

interface IRangeSelectProps {
  isEnabled: boolean;
  maxValue: number | undefined;
  minValue: number | undefined;
  rangeName: string;
  selectProps: Partial<SelectProps>;
  onChange: (args: { minValue?: number; maxValue?: number }) => void;
}

const Option = Select.Option;

class RangeSelectInput extends React.PureComponent<IRangeSelectProps> {
  public calcRoundedValues = memoize((min: number, max: number, range: number[]) => {
    const roundedMin = roundWithRange(min, range, true);
    const roundedMax = roundWithRange(max, range, true);
    return [roundedMin, roundedMax];
  });
  private UNDEFINED_VALUE = 'Neuvedeno';

  public render() {
    const { minValue, maxValue, selectProps, isEnabled, rangeName } = this.props;

    const rangeValues = AdFilter.rangeValues[rangeName];

    const options = rangeValues.map((item, idx) => {
      return (
        <Option key={idx.toString()} value={item}>
          {item === 0 ? this.UNDEFINED_VALUE : withSpaces(item)}
        </Option>
      );
    });

    const [roundedMin, roundedMax] = this.calcRoundedValues(minValue, maxValue, rangeValues);

    return (
      <Wrapper>
        <div>
          <RangeLabel text={'od'} />
          <Select
            size={'small'}
            onChange={this.handleMinValueChange}
            value={isEnabled && roundedMin ? roundedMin : this.UNDEFINED_VALUE}
            style={{ width: 130 }}
            {...selectProps}
          >
            {options}
          </Select>
        </div>
        <div>
          <RangeLabel text={'do'} />
          <Select
            size={'small'}
            onChange={this.handleMaxValueChange}
            value={isEnabled && roundedMax ? roundedMax : this.UNDEFINED_VALUE}
            style={{ width: 130 }}
            {...selectProps}
          >
            {options}
          </Select>
        </div>
      </Wrapper>
    );
  }

  private handleMinValueChange = value => {
    this.props.onChange({
      minValue: value === 'neuvedeno' ? 0 : value,
    });
  };

  private handleMaxValueChange = value => {
    this.props.onChange({
      maxValue: value === 'neuvedeno' ? 0 : value,
    });
  };
}

export default RangeSelectInput;
