import React from 'react';

import Notification from 'app/components/Notification';
import { formatToDateUtc } from 'app/utils/dates';
import { fontFamily } from 'styles/shared.styles';

interface IPaidVinNotificationProps {
  activeTo: string;
}

export const PaidVinNotification: React.FC<IPaidVinNotificationProps> = ({ activeTo }) => (
  <Notification
    id='verify-vin-vin-paid-report'
    text={`Tento PREMIUM report je pro vás dostupný do ${formatToDateUtc(activeTo)}.`}
    type='default'
    size='small'
    textFontFamily={fontFamily.regular}
    bgColor='primary'
    centered={false}
    sticky={true}
  />
);
