import { createGlobalStyle } from 'styled-components/macro';

// import { injectGlobal } from 'styled-components';
import reset from './reset.styles';
import fonts from './fonts.styles';
import common from './common.styles';
import print from './print.styles';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${fonts};
  ${common};
  ${print};
`;
