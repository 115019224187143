import styled from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const Wrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [mediaQuery.medium]: {
    flexBasis: '80px',
    padding: '10px 8px',
    alignItems: 'initial',
  },
});
