import * as CONSTANTS from 'app/constants/sockets';

interface ISocketsReducer {
  info: any;
}

const initialState: ISocketsReducer = {
  info: null,
};

export const sockets = (state = initialState, action: any): ISocketsReducer => {
  switch (action.type) {
    case CONSTANTS.WS_EVENT_MESSAGE: {
      return {
        ...state,
        info: action.payload && action.payload.data,
      };
    }
    case CONSTANTS.WS_EVENT_CONNECT: {
      return {
        ...state,
        // connect: data,
      };
    }
    default:
      return state;
  }
};
