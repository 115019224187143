import * as React from 'react';
import Button from 'app/components/Button';
import { PdfExportDownloadBtn } from 'app/components/PdfExport';
import * as S from '../SimilarAds.styles';

interface INavDownloadReportBtnProps {
  isAuthorized: boolean;
  adId: number;
  showLoginDialog: () => void;
}

export const NavDownloadReportBtn: React.SFC<INavDownloadReportBtnProps> = ({
  isAuthorized,
  adId,
  showLoginDialog,
}) => {
  return isAuthorized ? (
    <S.NavButtonContainer>
      <PdfExportDownloadBtn
        visible={true}
        btnText={'Stáhnout report'}
        filename={'karta-vozidla-export'}
        urlToExport={`/auto/${adId}/detail`}
      />
    </S.NavButtonContainer>
  ) : (
    <S.NavButtonContainer>
      <Button onClick={showLoginDialog}>Stáhnout report</Button>
    </S.NavButtonContainer>
  );
};
