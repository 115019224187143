import styled from 'styled-components/macro';
import { colors } from 'styles/shared.styles';

export const Container = styled.div`
  position: fixed;
  bottom: 1px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 12px;
`;

export const DateContainer = styled.span`
  color: ${colors.primary};
`;
