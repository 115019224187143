import appReducer from '../reducers/appReducers';
import * as _ from 'lodash-es';
import { applyMiddleware, compose, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { rest } from 'app/api';
import NotificationType from '../models/NotificationType';
import { expireAuthSession } from '../actions/auth';
import { pushNotification } from '../actions/app';
import { AD_DETAILS_NOT_FOUND, AD_DETAILS_ERROR } from '../constants/notifications';
import { socketMiddleware } from './socketMiddleware';
const NOTIFICATION_DISMISS_DELAY = 7000;

export const configureStore = (initialState: any) => {
  const middlewareConfig = {
    returnRejectedPromiseOnError: true,
    interceptors: {
      request: [
        {
          success({ getState, dispatch, getSourceAction }, req) {
            if (getState().auth.accessToken) {
              req.headers.Authorization = getState().auth.accessToken;
            }

            return req;
          },
        },
      ],
      response: [
        {
          success({ getState, dispatch, getSourceAction }, res) {
            return res;
          },
          error({ getState, dispatch, getSourceAction }, error) {
            const status = _.get(error, 'response.status');
            const errMessage =
              _.get(error, 'response.data.message') ||
              _.get(error, 'response.data.data') ||
              (error && error.message);
            const isParamsValidationError = errMessage?.indexOf('params validation error') > -1;
            const errCode = _.get(error, 'response.data.code');
            const isTokenExpiredError = status === 401 && errMessage === 'token expired';
            const isLeasingCheckError = errMessage.includes('not enough lookups');

            if (isTokenExpiredError) {
              dispatch(expireAuthSession());
              return Promise.reject(error);
            }

            if (status === 404) {
              handleNotFoundError(errMessage, dispatch);
              return Promise.reject(error);
            }

            const errCodesWhitelist = [
              'userExistsError',
              'resetTokenExpired',
              'tokenNotFound',
              'vinAlreadyPaid',
              'faktIdMiss',
            ];
            if (
              errCodesWhitelist.includes(errCode) ||
              isParamsValidationError ||
              isLeasingCheckError ||
              status === 403 ||
              status === 401
            ) {
              return Promise.reject(error);
            }

            dispatch(
              pushNotification(
                AD_DETAILS_ERROR,
                'Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte. Děkujeme!',
                NotificationType.error,
                NOTIFICATION_DISMISS_DELAY
              )
            );

            return Promise.reject(error);
          },
        },
      ],
    },
  };

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const APP_MODE = process.env.REACT_APP_MODE;
  const loggerMiddleware = createLogger({
    collapsed: true,
    duration: true,
    predicate: (getState, action) => APP_MODE === 'development' || APP_MODE === 'staging',
  });

  const store = createStore(
    appReducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        socketMiddleware(),
        axiosMiddleware(rest, middlewareConfig),
        loggerMiddleware
      ),
      persistState('auth')
    )
  );

  return store;
};

const handleNotFoundError = (errMessage: string, dispatch: (args: any) => void) => {
  const isAdNotFoundError = errMessage && errMessage.indexOf('Ad not found') > -1;
  if (isAdNotFoundError) {
    dispatch(
      pushNotification(
        AD_DETAILS_NOT_FOUND,
        'Nebyly nalezeny žádné výsledky.',
        NotificationType.error,
        NOTIFICATION_DISMISS_DELAY
      )
    );
  }
};
