import * as React from 'react';
import { noop } from 'lodash-es';

import PremiumBanner from 'app/components/PremiumBanner';
import LinkButton from 'app/components/LinkButton';
import { ResponsiveImage } from 'app/components/_shared_';

import adHistoryTableBlurryImg from 'assets/images/ad-history-table.jpg';
import vinHistoryTableBlurryImg from 'assets/images/vin-history-table.jpg';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

export type BgImageNameType = 'adHistoryTable' | 'vinHistoryTable';

interface IDummyTableProps {
  bgImageName?: BgImageNameType;
  overlayContent?: React.ReactNode;
  showLoginDialog?: () => void;
}

export const DummyTable: React.SFC<IDummyTableProps> = ({
  showLoginDialog,
  overlayContent = DefaultOverlayContent,
  bgImageName = 'adHistoryTable',
}) => {
  return (
    <div>
      <ResponsiveImage src={bgImageMap[bgImageName]} alt='ad-history-table' />
      <PremiumBanner type='dark' overlay={true}>
        {overlayContent}
      </PremiumBanner>
    </div>
  );
};

const DefaultOverlayContent = ({ showLoginDialog }) => {
  const login = () => {
    if (MobileAppUtils.isInApp()) {
      MobileAppUtils.gotoLogin();
      return;
    }
    showLoginDialog();
  };

  return (
    <>
      Pro zobrazení grafu historie se musíte{' '}
      <LinkButton onClick={login} btnStyle='secondary'>
        přihlásit
      </LinkButton>
      .
    </>
  );
}

const bgImageMap = {
  adHistoryTable: adHistoryTableBlurryImg,
  vinHistoryTable: vinHistoryTableBlurryImg,
};

DummyTable.defaultProps = {
  showLoginDialog: noop,
  overlayContent: DefaultOverlayContent,
  bgImageName: 'adHistoryTable',
};
