import * as React from 'react';
import { SyntheticEvent } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as FaSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import styled, { css } from 'styled-components/macro';
import {
  position,
  PositionProps,
  boxShadow,
  BoxShadowProps,
  border,
  BorderProps,
  background,
  BackgroundProps,
  borderRadius,
  BorderRadiusProps,
  layout,
  LayoutProps,
} from 'styled-system';

import { IconContainer } from './IconContainer';
import { redirectTo } from 'app/utils/url.utils';
import { colors, fontFamily, mediaQuery } from '../../styles/shared.styles';

interface IOwnProps {
  active?: boolean;
  bgColor?: string;
  border?: string;
  css?: any;
  disabled?: boolean;
  font?: string;
  fullWidth?: boolean;
  icon?: IconDefinition;
  kind?: string;
  onClick?: (evt: SyntheticEvent) => void;
  size?: 'normal' | 'small';
  spin?: boolean;
  text?: string;
  padding?: string;
  margin?: string;
  textColor?: string;
  to?: string;
  visible?: boolean;
  type?: string;
}

type TButtonProps = IOwnProps &
  PositionProps &
  BoxShadowProps &
  BorderProps &
  BackgroundProps &
  BorderRadiusProps &
  LayoutProps;

const disabledButtonStyle = css({
  backgroundColor: '#ddd',
  cursor: 'default',
  pointerEvents: 'none',
  opacity: 0.6,
  '&:hover': {
    backgroundColor: '#ddd !important',
    cursor: 'default',
  },
});

const fullWidthButtonStyle = css({
  display: 'block',
  width: '100% !important',
});

const activeButtonStyle = css({
  backgroundColor: 'black',
});

const smallButtonStyle = css({
  padding: '0 20px',
  height: '30px',
  lineHeight: '30px',
  fontSize: '0.9rem',
});

export const baseButtonClass = css({
  borderRadius: '4px',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '1rem',
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  height: '38px',
  letterSpacing: 'normal',
  lineHeight: 1.1,
  textAlign: 'center',
  transition: 'all .3s ease',
  userSelect: 'none',
  '&:hover': {
    color: 'red !improtant',
    textDecoration: 'underline',
  },
  '&:hover span': {
    textDecoration: 'underline',
  },
  '&:hover, &:focus': {
    outline: 'none',
  },
  '& .spinner-icon': {
    width: '20px',
    color: colors.white,
    marginLeft: 0,
  },
  [mediaQuery.medium]: {
    width: 'auto',
    lineHeight: '38px',
  },
});

const StyledButton = styled.button(
  baseButtonClass,
  ({ bgColor, theme }) =>
    bgColor
      ? { backgroundColor: theme.colors[bgColor] || bgColor }
      : { backgroundColor: colors.primary },
  ({ textColor }) => (textColor ? { color: textColor } : { color: colors.white }),
  ({ font }) => (font ? { fontFamily: font } : { fontFamily: fontFamily.bold }),
  ({ disabled }) => (disabled ? disabledButtonStyle : null),
  ({ fullWidth }) => (fullWidth ? fullWidthButtonStyle : { width: 'auto' }),
  ({ padding }) => (padding ? { padding } : { padding: '0 15px' }),
  ({ margin }) => (margin ? { margin } : null),
  ({ active }) => (active ? activeButtonStyle : null),
  ({ size }) => (size === 'small' ? smallButtonStyle : null),
  ({ border }) => ({ border: border ? border : '0' }),
  ({ visible }) =>
    typeof visible !== 'undefined' ? { visibility: visible ? 'visible' : 'hidden' } : null,
  borderRadius,
  background,
  layout,
  position,
  boxShadow,
  border
);

const Button: React.SFC<TButtonProps> = ({
  active = false,
  bgColor,
  children,
  disabled = false,
  fullWidth = false,
  icon,
  onClick,
  size = 'normal',
  spin = false,
  text,
  textColor,
  visible = true,
  to,
  padding,
  kind = 'button',
  ...other
}) => (
  <StyledButton
    onClick={disabled ? () => null : to ? () => redirectTo(to) : onClick}
    disabled={disabled}
    fullWidth={fullWidth}
    active={active}
    size={size}
    visible={visible}
    bgColor={bgColor}
    textColor={textColor}
    padding={padding}
    kind={kind}
    {...other}
  >
    {(spin || icon) && (
      <IconContainer>
        <FontAwesomeIcon
          icon={(spin && FaSpinner) || icon}
          className={'spinner-icon'}
          spin={spin}
        />
      </IconContainer>
    )}
    <span>{text || children}</span>
  </StyledButton>
);

export const ButtonSubmit = styled(Button)({
  height: '34px',
  borderRadius: '17px',
  backgroundColor: colors.highlight,
  cursor: 'pointer',
  marginRight: '0',
  marginTop: '24px',
  [mediaQuery.small]: {
    marginRight: '10px',
  },
  [mediaQuery.medium]: {
    flex: '0 0 auto',
    marginTop: '0',
    lineHeight: '34px',
    width: 'auto',
    padding: ({ padding }) => padding || '0 40px',
  },
  width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
});

export default Button;
