import styled from 'styled-components/macro';
import { colors } from 'styles/shared.styles';
import { TBadgeProps } from './Badge';
import { borderRadius } from 'styled-system';
import { layout } from 'styled-system';
import { space } from 'styled-system';

export const BadgeContainer = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: colors.highlight,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    padding: '6px 14px',
  },
  (props: Partial<TBadgeProps>) =>
    props.type === 'positive'
      ? { backgroundColor: colors.highlight }
      : null || props.type === 'positive-dark'
      ? { backgroundColor: colors.darkGreen }
      : null || props.type === 'negative'
      ? { backgroundColor: '#E2574C' }
      : null,
  (props: Partial<TBadgeProps>) => (props.size === 'small' ? { padding: '0 14px' } : null),
  (props: Partial<TBadgeProps>) =>
    props.borderRadius ? { borderRadius: props.borderRadius } : null,
  (props: Partial<TBadgeProps>) =>
    props.bgColor
      ? {
          backgroundColor: props.bgColor,
        }
      : null,
  borderRadius,
  layout,
  space
);
