import Benchmark from 'app/models/Benchmark';
import AdFilter from 'app/models/AdFilter';
import * as CONSTANTS from 'app/constants/api';
import { clearNotifications } from './app';
import { log } from 'app/utils/log';
import { IInfoCardProps } from 'app/components/InfoCard';

const getFilter = (filter?: AdFilter) => {
  if (!filter) {
    return {};
  }
  const {
    yearIncluded,
    priceIncluded,
    kmIncluded,
    fuelIncluded,
    transmissionIncluded,
    kwIncluded,
    equipmentCountIncluded,
    yearFrom,
    yearTo,
    fuel,
    kmFrom,
    kmTo,
    priceFrom,
    priceTo,
    transmission,
    kwFrom,
    kwTo,
    equipmentCountFrom,
    equipmentCountTo,
    karoserieIncluded,
    karoserie,
  } = filter;

  return {
    yearFrom: yearIncluded && yearFrom ? yearFrom : undefined,
    yearTo: yearIncluded && yearTo ? yearTo : undefined,
    priceFrom: priceIncluded && priceFrom ? priceFrom : undefined,
    priceTo: priceIncluded && priceTo ? priceTo : undefined,
    kmFrom: kmIncluded && kmFrom ? kmFrom : undefined,
    kmTo: kmIncluded && kmTo ? kmTo : undefined,
    fuel: fuelIncluded && fuel ? fuel : undefined,
    transmission: transmissionIncluded && transmission ? transmission : undefined,
    kwFrom: kwIncluded && kwFrom ? kwFrom : undefined,
    kwTo: kwIncluded && kwTo ? kwTo : undefined,
    equipmentCountFrom:
      equipmentCountIncluded && equipmentCountFrom ? equipmentCountFrom : undefined,
    equipmentCountTo: equipmentCountIncluded && equipmentCountTo ? equipmentCountTo : undefined,
    karoserie: karoserieIncluded && karoserie ? karoserie : undefined,
  };
};

export function updateAdId(adId: string) {
  return {
    type: CONSTANTS.UPDATE_AD_ID,
    payload: {
      adId,
    },
  };
}

export function loadAdDetails(listType: string = 'top') {
  return (dispatch, getState) => {
    const state = getState();
    const filter = state.ads.filter;
    const adId = state.ads.adId.trim();

    dispatch(clearNotifications());
    return dispatch(doBenchmarkRequest(adId, listType, getFilter(filter)))
      .then(({ payload, error }) => {
        if (error) {
          throw error;
        }

        const {
          data: {
            data: { ad },
          },
        } = payload;
        return ad;
      })
      .catch(error => log.error(error));
  };
}

export function doBenchmarkRequest(adId: string, listType: string, filter: any) {
  return {
    type: CONSTANTS.LOAD_AD_DETAILS,
    payload: {
      request: {
        url: '/gallibot-ads/benchmark',
        params: {
          adId,
          filter,
          listType,
        },
      },
    },
  };
}

export function loadResidualValue(adId: number, adBenchmark?: Benchmark) {
  return {
    type: CONSTANTS.LOAD_RESIDUAL_VALUE,
    payload: {
      request: {
        method: 'GET',
        url: '/gallibot-ads/residual',
        params: {
          id: adId,
          adBenchmark,
        },
      },
    },
  };
}

export function loadResidualValueByVin(vin: string) {
  return {
    type: CONSTANTS.LOAD_RESIDUAL_VALUE_BY_VIN,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/residual/${vin}`,
      },
    },
  };
}

export function loadEstimateCar(ad: any) {
  return {
    type: CONSTANTS.LOAD_ESTIMATE_CAR,
    payload: {
      request: {
        method: 'GET',
        url: '/gallibot-ads/estimate-car',
        params: {
          ...ad,
        },
      },
    },
  };
}

export function resetEstimateCar() {
  return {
    type: CONSTANTS.RESET_ESTIMATE_CAR,
  };
}

export function loadHighestEquipmentList(brand: string, model: string, year: number) {
  return {
    type: CONSTANTS.LOAD_HIGHEST_EQUIPMENT_LIST,
    payload: {
      request: {
        method: 'GET',
        url: '/gallibot-ads/highest-equipment-list',
        params: {
          brand,
          model,
          year,
        },
      },
    },
  };
}

export function resetAdDetails() {
  return {
    type: CONSTANTS.RESET_AD_DETAILS,
  };
}

export function updateFilter(filter: Partial<AdFilter>) {
  return {
    type: CONSTANTS.UPDATE_FILTER,
    payload: {
      filter,
    },
  };
}

export function resetFilter() {
  return {
    type: CONSTANTS.RESET_FILTER,
  };
}

export function loadAdsByVin(vin: string, from?: number, limit?: number) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_ADS_BY_VIN,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin${deviceId}`,
        params: {
          vin: sanitizedVin,
          from,
          limit,
        },
      },
    },
  };
}

export function loadAdByVin(vin: string, token: string) {
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_AD_BY_VIN,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${sanitizedVin}/ad`,
        params: {
          vin: sanitizedVin,
        },
        headers: {
          Authorization: token,
        },
      },
    },
  };
}

export function resetAdsByVin() {
  return {
    type: CONSTANTS.RESET_ADS_BY_VIN,
  };
}

export function setVin(vin: string) {
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.SET_VIN,
    payload: {
      vin: sanitizedVin,
    },
  };
}

export function loadKmHistoryByVin(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_KM_HISTORY,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${vin}/km-history${deviceId}`,
        params: {
          vin: sanitizedVin,
        },
      },
    },
  };
}

export function resetKmHistoryByVin() {
  return {
    type: CONSTANTS.RESET_KM_HISTORY,
  };
}

export function loadPriceHistoryByVin(vin: string) {
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_PRICE_HISTORY,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${vin}/price-history`,
        params: {
          vin: sanitizedVin,
        },
      },
    },
  };
}

export function loadKmHistoryForNotFoundVin(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_KM_HISTORY_NOT_FOUND_VIN,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${vin}/km-history-not-found-vin${deviceId}`,
        params: {
          vin: sanitizedVin,
        },
      },
    },
  };
}

export function resetKmHistoryForNotFoundVin() {
  return {
    type: CONSTANTS.RESET_KM_HISTORY_NOT_FOUND_VIN,
  };
}

export function loadPdfExport(url: string, filename?: string) {
  return {
    type: CONSTANTS.LOAD_PDF_EXPORT,
    payload: {
      request: {
        method: 'GET',
        url: `/export/pdf`,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
        params: {
          url,
          filename,
        },
      },
    },
  };
}

export function loadAdsLists(adId: string) {
  return {
    type: CONSTANTS.LOAD_ADS_LISTS,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/${adId}/ads-lists`,
      },
    },
  };
}

export function loadVinDecode(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_VIN_DECODE,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${sanitizedVin}/decode${deviceId}`,
      },
    },
  };
}

export function loadVinLeasingCheck(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_VIN_LEASING_CHECK,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${sanitizedVin}/leasing-check${deviceId}`,
      },
    },
  };
}

export function loadStolenCars(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  const sanitizedVin = vin?.trim();
  return {
    type: CONSTANTS.LOAD_STOLEN_CARS,
    payload: {
      request: {
        method: 'GET',
        url: `/gallibot-ads/vin/${sanitizedVin}/stolen-cars${deviceId}`,
      },
    },
  };
}

export function resetMyCars() {
  return {
    type: CONSTANTS.RESET_MY_CARS,
  };
}

// paymentType: 'all' | 'free' | 'premium'
export function loadMyCars(skip: number = 0, limit: number = 50, paymentType: string = '') {
  const windowDeviceId = (window as any).deviceId;
  const queries: string[] = [`skip=${skip}`, `limit=${limit}`, `paymentType=${paymentType}`];
  if (windowDeviceId) {
    queries.push(`deviceid=${windowDeviceId}`);
  }

  return {
    type: CONSTANTS.LOAD_MY_CARS,
    payload: {
      request: {
        method: 'GET',
        url: `/my-cars?${queries.join('&')}`,
      },
    },
  };
}

export function removeMyCar(vin: string) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  return {
    type: CONSTANTS.LOAD_MY_CARS,
    payload: {
      request: {
        method: 'DELETE',
        url: `/my-cars/${vin}${deviceId}`,
      },
    },
  };
}

export function setFavoriteMyCar(vin: string, favorite: boolean) {
  const windowDeviceId = (window as any).deviceId;
  const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
  return {
    type: CONSTANTS.SET_FAVORITE_MY_CARS,
    payload: {
      request: {
        method: 'POST',
        url: `/my-cars/${vin}/favorite${deviceId}`,
        data: {
          favorite,
        },
      },
    },
  };
}

export function actionSetVehicleRegisterLoading(loading: boolean) {
  return {
    type: CONSTANTS.VEHICLE_REGISTER_LOADING,
    payload: {
      loading,
    },
  };
}

export function actionSetVehicleRegisterFetched(fetched: boolean) {
  return {
    type: CONSTANTS.VEHICLE_REGISTER_FETCHED,
    payload: {
      fetched,
    },
  };
}

export function actionSetVehicleRegisterData(data: IInfoCardProps[]) {
  return {
    type: CONSTANTS.VEHICLE_REGISTER_DATA,
    payload: {
      data,
    },
  };
}
