import styled from 'styled-components/macro';
import { colors, mediaQuery } from 'styles/shared.styles';

export const StyledFooter = styled('footer')({
  padding: '10px 10px 50px 10px',
  userSelect: 'none',
  [mediaQuery.medium]: {
    padding: '24px',
  },
  [mediaQuery.print]: {
    marginBottom: '30px',
  },
});

export const FooterContent = styled('footer')({
  padding: '8px 24px',
  borderLeft: `5px solid ${colors.primary}`,
  fontSize: '1rem',
  [mediaQuery.print]: {
    display: 'none',
  },
});

export const Paragraph = styled('p')({
  marginBottom: '4px',
  userSelect: 'text',
});
