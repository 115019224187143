import loglevel from 'loglevel';

import { isLocalOrStagingEnv } from 'app/utils/env';

export type TLogLevels = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent';

const setLogLevel = () => {
  if (isLocalOrStagingEnv) {
    loglevel.setLevel('trace');
    return;
  }
  loglevel.setLevel('error');
};

setLogLevel();

export const log = loglevel;
