import * as React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';

import SectionHeader from 'app/components/SectionHeader';
import { TableLayout } from 'app/components/Table';
import FormattedValue from 'app/components/FormattedValue';
import Heading from 'app/components/Heading';
import { VerifyAgainMessage } from 'app/components/VerifyVin/components/VerifyAgainMessage';

import {
  VerifyVinTableHeader,
  VerifyVinTableRow,
} from 'app/components/VerifyVin/components/VerifyVinTable';
import { VerifyVinBaseLayout } from 'app/components/VerifyVin/components/VerifyVinBaseLayout';

import { useVerifyVinRoute } from 'app/hooks';
import { SectionHeaderContainer } from 'app/components/_shared_';
import { IStolenCarsResult } from 'app/models/Api';

interface IStolenCarsProps {
  data: IStolenCarsResult;
  vin: string;
  isAuthorized: boolean;
  dataIsLoading: boolean;
  dataError: string;
}

export const StolenCars: React.SFC<IStolenCarsProps> = props => {
  const sectionHeaderText = 'Databáze odcizených vozidel';
  const { isPremiumRoute, isPremiumVersion } = useVerifyVinRoute();
  const { data } = props;
  const { policeDbs, userDbs } = data;

  return (
    <VerifyVinBaseLayout {...props}>
      <SectionHeaderContainer>
        <SectionHeader title={sectionHeaderText} subheaderValue={14} subheaderSuffixText={'zemí'} />
      </SectionHeaderContainer>

      {isPremiumRoute && data?.lastCheckedAt && (
        <VerifyAgainMessage lastCheckedAt={data?.lastCheckedAt} vin={props.vin} />
      )}

      <Flex justifyContent='space-between' flexDirection='column'>
        <Box width={[1]} mb='35px'>
          <Heading level={3}>Policejní databáze</Heading>
          <TableLayout data-cy='stolen-cars-police-dbs'>
            <VerifyVinTableHeader primaryTitle='Stát' secondaryTitle='Evidováno' />
            {policeDbs &&
              Object.keys(policeDbs).map((country, idx) => (
                <VerifyVinTableRow rowTitle={country} key={country} striped={idx % 2 === 0}>
                  <FormattedValue
                    value={policeDbs[country] ? 'Ano' : 'Ne'}
                    fontSize='base'
                    blurred={!isPremiumVersion}
                    iconType={!isPremiumVersion ? null : policeDbs[country] ? 'fail' : 'success'}
                  />
                </VerifyVinTableRow>
              ))}
          </TableLayout>
        </Box>
        <Box width={[1]}>
          <Heading level={3}>Sdružení majitelů odcizených vozidel</Heading>
          <TableLayout data-cy='stolen-cars-association-of-owners'>
            <VerifyVinTableHeader primaryTitle='Stát' secondaryTitle='Evidováno' />
            {userDbs &&
              Object.keys(userDbs).map((country, idx) => (
                <VerifyVinTableRow rowTitle={country} key={country} striped={idx % 2 === 0}>
                  <FormattedValue
                    value={userDbs[country] ? 'Ano' : 'Ne'}
                    fontSize='base'
                    blurred={!isPremiumVersion}
                    iconType={!isPremiumVersion ? null : policeDbs[country] ? 'fail' : 'success'}
                  />
                </VerifyVinTableRow>
              ))}
          </TableLayout>
        </Box>
      </Flex>
    </VerifyVinBaseLayout>
  );
};
