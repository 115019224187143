import * as React from 'react';
import { Flex } from 'reflexbox/styled-components';
import { colors } from 'styles/shared.styles';

import { Column } from 'app/components/Column';
import CompactAdsListItem from './components/CompactAdsListItem';
import { SectionWrapperWithPageBreak } from 'app/components/_shared_';

export enum EnumCompactAdsListItemLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface IOwnProps {
  badgeValuePropName: string;
  data: any[];
  allowRedirectButton?: boolean;
  badgeBgColor?: string;
  badgeText?: string;
  itemsPerRow?: number;
  itemLayout?: 'horizontal' | 'vertical';
  thumbnail?: boolean;
  withBackground?: boolean;
  name?: string;
  badgeValueFormatter?: (value) => void;
}

const CompactAdsList: React.SFC<IOwnProps> = ({
  badgeBgColor,
  badgeValueFormatter,
  badgeValuePropName,
  data,
  withBackground,
  badgeText = '',
  allowRedirectButton = true,
  itemsPerRow = 2,
  itemLayout = EnumCompactAdsListItemLayout.HORIZONTAL,
  thumbnail = true,
  name,
}) => {
  if (!data || (data && !data.length)) return null;

  return (
    <Flex
      width='100%'
      alignItems='center'
      justifyContent={'flex-start'}
      flexWrap='wrap'
      flexDirection='row'
      data-cy={`compact-ads-list compact-ads-list-${name}`}
    >
      {data &&
        data.length &&
        data.map((item, idx) => {
          const isEvenRow = !!(Math.floor(idx / itemsPerRow) % itemsPerRow);
          return (
            <Column
              width={['49%', '32.5%', `${100 / itemsPerRow - 0.5}%`]}
              key={item._id}
              pb={12}
              flexDirection={['column', 'row', 'row']}
              mr={getBoxRightMargin(itemsPerRow, idx)}
              printStyles={{
                flexDirection: 'column',
                border: '1px solid rgba(151,151,151,0.5)',
                boxShadow: 'none',
                margin: '5px',
                width: 'calc(50% - 20px)',
                borderRadius: '10px',
                display: 'block',
              }}
            >
              <SectionWrapperWithPageBreak style={{ width: '100%', borderRadius: 'inherit' }}>
                <CompactAdsListItem
                  {...item}
                  allowRedirectButton={allowRedirectButton}
                  badgeText={badgeText}
                  badgeBgColor={badgeBgColor}
                  badgeValueFormatter={badgeValueFormatter}
                  badgeValuePropName={badgeValuePropName}
                  layout={itemLayout}
                  thumbnail={thumbnail}
                  name={name}
                  withBackground={
                    typeof withBackground !== 'undefined' ? withBackground : !isEvenRow
                  }
                />
              </SectionWrapperWithPageBreak>
            </Column>
          );
        })}
    </Flex>
  );
};

CompactAdsList.defaultProps = {
  data: [],
  badgeBgColor: colors.darkRed,
  badgeValuePropName: 'lastRun',
};

export default CompactAdsList;

const getBoxRightMargin = (itemsPerRow: number, boxIdx: number): number | string => {
  // last box in a row will have 0 margin
  const isItemsPerRowMultiplier = boxIdx !== 0 && (boxIdx + 1) % itemsPerRow === 0;
  if (isItemsPerRowMultiplier) {
    return 0;
  }

  const rightMargin = (0.5 * itemsPerRow) / (itemsPerRow - 1);
  return `${rightMargin}%`;
};
