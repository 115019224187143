import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import User from 'app/models/User';
import { IconContainer } from 'app/components/IconContainer';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as faSignOutAlt from '@fortawesome/fontawesome-free-solid/faSignOutAlt';
import { ClickParam } from 'antd/lib/menu';
import 'antd/lib/menu/style/css';
import * as S from './AuthMenu.styles';
const APP_MODE = process.env.REACT_APP_MODE;

interface IAuthMenuProps {
  user?: User;
  onLogoutBtnClick: (evt: SyntheticEvent) => void;
  onMenuItemClick: (flag: ClickParam) => void;
}

const getUserEmail = (user?: User): string => {
  if (!user) return '';
  if (user.email) return user.email;
  if (user.google) return user.google.email;
  if (user.facebook) return user.facebook.email;
  return '';
};

const AuthMenu: React.SFC<IAuthMenuProps> = ({
  user,
  onLogoutBtnClick,
  onMenuItemClick,
  ...other
}) => {
  return (
    <S.StyledMenu selectable={false} onClick={onMenuItemClick}>
      <S.FirstnameAndEmailMenuItem key='0'>
        <S.FirstnameContainer>{user && user.firstname}</S.FirstnameContainer>
        {getUserEmail(user)}
      </S.FirstnameAndEmailMenuItem>
      <S.StyledMenuItem key='1'>
        <Link to='/ocenit-vuz'>Ocenit vůz</Link>
      </S.StyledMenuItem>
      <S.StyledMenuItem key='2'>
        <Link to='/#overit-vin'>Ověřit VIN</Link>
      </S.StyledMenuItem>
      {user && APP_MODE !== 'production' && (
        <S.StyledMenuItem key='3'>
          <Link to='/clenstvi/platby'>Moje platby</Link>
        </S.StyledMenuItem>
      )}
      {user && (
        <S.StyledMenuItem key='4'>
          <Link to='/moje-vozy'>Moje vozy</Link>
        </S.StyledMenuItem>
      )}
      {user && (
        <S.StyledMenuItem key='5'>
          <Link to='/oceneni'>Moje ocenění</Link>
        </S.StyledMenuItem>
      )}
      <S.StyledMenuItem key='6'>
        <IconContainer>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </IconContainer>
        <a href='#overauto' onClick={onLogoutBtnClick}>
          Odhlásit
        </a>
      </S.StyledMenuItem>
    </S.StyledMenu>
  );
};

const defaultProps: Partial<IAuthMenuProps> = {};

AuthMenu.defaultProps = defaultProps;

export default AuthMenu;
