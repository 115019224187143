import * as React from 'react';

import { DataLoading } from 'app/components/DataLoading';
import { DataLoadingError } from 'app/components/DataLoadingError';
import SectionHeader from 'app/components/SectionHeader';
import { DataNoResults } from 'app/components/DataNoResults';
import { PremiumSignIn } from 'app/components/PremiumSignIn';
import { DummyContent } from 'app/components/DummyContent';
import { EnumAdLoaderTexts } from 'app/components/AdLoader';

import { useVerifyVinRoute } from 'app/hooks';
import { checkVinValidity } from 'app/utils';
import { Container, ContentWrapper } from 'app/components/_shared_';
import * as S from './VerifyVinBaseLayout.styles';

interface IVerifyVinBaseLayoutProps {
  vin: string;
  data: any;
  dataIsLoading: boolean;
  isAuthorized: boolean;
  sectionHeaderText?: string;
  subheaderValue?: string | number;
  subheaderSuffixText?: string;
  children: React.ReactNode;
  dataError?: string;
  blurredImgSrc?: string;
  signInText?: string;
  dataLoadingText?: string;
}

export const VerifyVinBaseLayout: React.SFC<IVerifyVinBaseLayoutProps> = ({
  data,
  dataIsLoading,
  isAuthorized,
  vin,
  dataError,
  sectionHeaderText,
  children,
  blurredImgSrc = '',
  dataLoadingText = EnumAdLoaderTexts.LOADING_DATA,
  signInText,
  subheaderValue,
  subheaderSuffixText,
}) => {
  const { isPremiumRoute, isMobileApp } = useVerifyVinRoute();
  if (!vin) return null;
  if (!isMobileApp && !isPremiumRoute && !isAuthorized)
    return (
      <ContentForUnauthorizedUser
        sectionHeaderText={sectionHeaderText}
        subheaderValue={subheaderValue}
        subheaderSuffixText={subheaderSuffixText}
        blurredImgSrc={blurredImgSrc}
        signInText={signInText}
      />
    );

  const isValidVin = checkVinValidity(vin);
  if (!isValidVin)
    return (
      <InvalidVinContent sectionHeaderText={sectionHeaderText} blurredImgSrc={blurredImgSrc} />
    );

  if (dataIsLoading)
    return <DataLoading sectionHeaderText={sectionHeaderText} loaderText={dataLoadingText} />;

  if (dataError) return <DataLoadingError sectionHeaderText={sectionHeaderText} />;

  if (!data)
    return (
      <DataNoResults
        sectionHeaderText={sectionHeaderText}
        sectionSubheaderValue={subheaderValue}
        sectionSubheaderSuffixText={subheaderSuffixText}
      />
    );

  return <>{children}</>;
};

VerifyVinBaseLayout.defaultProps = {
  vin: '',
  dataIsLoading: false,
  isAuthorized: false,
  dataError: '',
  sectionHeaderText: '',
  blurredImgSrc: '',
};

interface IContentForUnauthorizedProps {
  sectionHeaderText?: string;
  subheaderValue?: string | number;
  subheaderSuffixText?: string;
  blurredImgSrc?: string;
  signInText?: string;
  children?: React.ReactNode;
}

const ContentForUnauthorizedUser: React.FC<IContentForUnauthorizedProps> = ({
  sectionHeaderText,
  subheaderValue,
  subheaderSuffixText,
  blurredImgSrc,
  children,
  signInText,
}) => {
  return blurredImgSrc ? (
    <Container>
      <ContentWrapper>
        {sectionHeaderText && (
          <SectionHeader
            title={sectionHeaderText}
            subheaderValue={subheaderValue}
            subheaderSuffixText={subheaderSuffixText}
          />
        )}
        <S.PremiumSignInWrapper>
          <PremiumSignIn bgSrc={blurredImgSrc} signInText={signInText} />
        </S.PremiumSignInWrapper>
      </ContentWrapper>
    </Container>
  ) : (
    <Container>
      <PremiumSignIn />
      {children}
    </Container>
  );
};

const InvalidVinContent = ({ sectionHeaderText, blurredImgSrc }) => {
  return (
    <Container>
      <ContentWrapper>
        <SectionHeader title={sectionHeaderText} />
        <S.PremiumSignInWrapper>
          <DummyContent
            bgSrc={blurredImgSrc}
            overlayContent={<>Vložený VIN kód je neúplný, vložte prosím kompletní VIN kód.</>}
          />
        </S.PremiumSignInWrapper>
      </ContentWrapper>
    </Container>
  );
};
