import { WebSockets } from 'app/utils/websockets';
import { log } from 'app/utils/log';

export const socketMiddleware = () => {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return next(action);
    }

    const { event, leave, handle, emit, payload, ...rest } = action;

    // not a socket.io event, proceed to next redux middleware
    if (!event) {
      return next(action);
    }

    if (!WebSockets.socket) {
      log.debug(`socketMiddleware:: no socket, go next...`);
      return next(action);
    }

    if (leave) {
      log.debug(`socketMiddleware:: unsubscribing from ${event} event...`);
      WebSockets.socket.removeListener(event);
      return;
    }

    if (emit) {
      log.debug(`socketMiddleware:: emit from ${event} event...`);
      WebSockets.socket.emit(event, payload);
      return;
    }

    let handleEvent = handle;
    if (typeof handleEvent === 'string') {
      handleEvent = result =>
        dispatch({
          type: handle,
          ...rest,
        });
    }

    return WebSockets.socket.on(event, () => {
      log.debug(`websocket event fired: ${event} ${WebSockets.socket.id}`);
      handleEvent();
    });
  };
};
