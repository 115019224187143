import * as React from 'react';
import Container from 'app/components/Container';
import Heading from 'app/components/Heading';
import Link from 'app/components/Link';
import * as S from './Footer.styles';

const Footer = ({ ...other }) => {
  return (
    <S.StyledFooter {...other}>
      <Container>
        <S.FooterContent>
          <Heading level={3}>O projektu</Heading>
          <S.Paragraph>
            Projekt vytvořila společnost{' '}
            <Link to='https://www.gallico.cz' external={true} size='small'>
              Galli Company
            </Link>
          </S.Paragraph>
          <S.Paragraph>
            <Link to='/privacy-policy' size='small'>
              Zásady ochrany osobních údajů
            </Link>
          </S.Paragraph>
          <S.Paragraph>
            Tato stránka využívá soubory cookies. Pro více informací si přečtěte{' '}
            <Link to='/terms-of-service' size='small'>
              podmínky používání
            </Link>{' '}
            aplikace OvěřAuto.cz.
            <br />
          </S.Paragraph>
        </S.FooterContent>
      </Container>
    </S.StyledFooter>
  );
};

export default Footer;
