import * as React from 'react';
import { noop } from 'lodash-es';
// import Typography from '../Typography';
import TabsNavigation from './components/TabsNavigation';
import TabsNavigationItem from './components/TabsNavigationItem';
import Ad from '../../models/Ad';

interface ITabsProps {
  children: React.ReactNode;
  currentAd: Ad;
  defaultSelectedIndex: number;
  navExtraClass?: string;
  contentExtraClass?: string;
  selectedTabIndex?: number;
  selectedTab?: { pathname: string; title: string };
  onTabIndexChanged?: (tabIndex: number) => void;
}

interface ITabsState {
  selectedTabIndex?: number;
  selectedTabTitle?: string;
  navigationShown: boolean;
}

class Tabs extends React.PureComponent<ITabsProps, ITabsState> {
  public static defaultProps: Partial<ITabsProps> = {
    defaultSelectedIndex: 0,
  };

  public state: ITabsState = {
    selectedTabTitle: 'Podobné inzeráty',
    navigationShown: false,
  };

  public toggleNavigation = () => {
    this.setState({ navigationShown: !this.state.navigationShown });
  };

  public render() {
    const { children, currentAd, selectedTab } = this.props;

    const selectedIndex = this.state.selectedTabIndex || this.props.defaultSelectedIndex;

    const tabsNav = React.Children.map(children, (child, index) => {
      const { props, key } = child as any;
      return (
        <TabsNavigationItem
          tabName={key}
          onClick={props.to ? noop() : () => this.toggle(index)}
          title={props.title}
          currentAdId={currentAd._id}
          to={props.to}
          target={props.target || null}
          navigationContent={props.navigationContent}
        />
      );
    });

    const tabsContent = React.Children.map(children, child => {
      const { props, key } = child as React.ReactElement<any>;
      return <div key={key as string}>{props.children}</div>;
    });

    if (!tabsNav || !tabsNav.length) {
      return null;
    }

    return (
      <>
        <div>
          <TabsNavigation
            title={currentAd.titleShort}
            brand={currentAd.brand}
            selectedTab={selectedTab}
            navigationShown={this.state.navigationShown}
            toggleNavigation={this.toggleNavigation}
          >
            {tabsNav}
          </TabsNavigation>
        </div>
        {tabsContent && <div>{tabsContent[selectedIndex]}</div>}
      </>
    );
  }

  public componentDidUpdate(prevProps: ITabsProps) {
    if (prevProps.selectedTabIndex !== this.props.selectedTabIndex) {
      this.setState({
        selectedTabIndex: this.props.selectedTabIndex,
      });
    }
  }

  private toggle = tabIndex => {
    this.setState({ selectedTabIndex: tabIndex });
    if (this.props.onTabIndexChanged) {
      this.props.onTabIndexChanged(tabIndex);
    }
  };
}

export default Tabs;
