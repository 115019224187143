import * as React from 'react';
import Swiper from 'react-id-swiper';
import { floor } from 'lodash-es';
import { findIndex } from 'lodash-es';
import { withRouter, RouteComponentProps } from 'react-router';

import { ListType } from 'app/components/ListSwitcher';
import AdsList from './components/AdsList';
import AdsListItem from './components/AdsListItem';
import Ad from 'app/models/Ad';
import { ComparedObject } from 'app/models/ComparedObject';
import * as S from './AdsListSlider.styles';

interface IOwnProps {
  isAuthorized: boolean;
  list: ComparedObject[];
  listType: ListType;
  ad?: Ad;
  allowRedirectButton?: boolean;
  dataIsLoading?: boolean;
  orderBadgeEnabled?: boolean;
  thumbnail?: boolean;
  genericBadgeText?: string;
  showBenchmark?: boolean;
  showLoginDialog: () => void;
  onRowMouseEnter?: () => void;
  onRowMouseLeave?: () => void;
}

type Props = IOwnProps;

class AdsListSlider extends React.Component<Props & RouteComponentProps<{ history: any }>> {
  public static defaultProps: Partial<Props> = {
    list: [],
  };
  public slider: any = null;

  public setSliderRef = el => {
    this.slider = el;
  };

  public handleRowMouseEnter = (rowClassName: string, isAuthorized: boolean) => {
    if (!isAuthorized) {
      return;
    }
    const rowNodes = document.querySelectorAll(rowClassName);
    if (rowNodes.length) {
      [].slice.call(rowNodes).map((item: any) => item.classList.add('active'));
    }
  };

  public handleRowMouseLeave = (rowClassName: string, isAuthorized: boolean) => {
    if (!isAuthorized) {
      return;
    }
    const rowNodes = document.querySelectorAll(rowClassName);
    if (rowNodes.length) {
      [].slice.call(rowNodes).map((item: any) => item.classList.remove('active'));
    }
  };

  public renderPaginationBullet = (index: number, searchedAdIndex: number) => {
    const customClass = index === searchedAdIndex ? 'swiper-pagination-bullet-searched-ad' : '';
    return `<span class='swiper-pagination-bullet ${customClass} ${S.PaginationBulletCss}'>
        ${index + 1}
      </span>`;
  };

  public render() {
    const {
      ad,
      dataIsLoading,
      isAuthorized,
      list,
      listType,
      onRowMouseEnter,
      onRowMouseLeave,
      orderBadgeEnabled,
      showLoginDialog,
      genericBadgeText,
      showBenchmark = true,
      allowRedirectButton = true,
      thumbnail = true,
    } = this.props;
    const adListIndex = ad ? findIndex(list, listAd => listAd._id === ad._id) : 0;
    const SLIDE_WIDTH = 305;
    const SLIDES_PER_PAGE = floor(window.innerWidth / SLIDE_WIDTH);
    const _this = this;

    const swiperParams = {
      centeredSlides: false,
      dynamicBullets: false,
      initialSlide: adListIndex,
      loop: false,
      mousewheel: false,
      rebuildOnUpdate: true,
      shouldSwiperUpdate: false,
      slidesPerView: window.innerWidth / SLIDE_WIDTH,
      slideToClickedSlide: false,
      spaceBetween: 13,
      touchEventsTarget: 'swiper-wrapper',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: function(index) {
          const swiperInstance = this as Swiper;
          const { snapIndex } = swiperInstance;
          return _this.renderPaginationBullet(index, snapIndex);
        },
      },
      navigation: {
        prevEl: '.button-prev',
        nextEl: '.button-next',
      },
      renderPrevButton: () => <S.SwipperButtonPrev className='button-prev' />,
      renderNextButton: () => <S.SwipperButtonNext className='button-next' />,
    };

    return (
      <div className={S.StyledSlider}>
        {list && list.length <= SLIDES_PER_PAGE && (
          <AdsList
            allowRedirectButton={allowRedirectButton}
            ad={ad}
            isAuthorized={isAuthorized}
            showLoginDialog={showLoginDialog}
            list={list}
            listType={listType}
            orderBadgeEnabled={orderBadgeEnabled}
            showBenchmark={showBenchmark}
            thumbnail={thumbnail}
            genericBadgeText={genericBadgeText}
            onRowMouseEnter={onRowMouseEnter ? onRowMouseEnter : this.handleRowMouseEnter}
            onRowMouseLeave={onRowMouseLeave ? onRowMouseLeave : this.handleRowMouseLeave}
          />
        )}
        {list && list.length > SLIDES_PER_PAGE && (
          <Swiper {...swiperParams} ref={this.setSliderRef}>
            {list.map((listAd, idx) => (
              <AdsListItem
                className={'swiper-slide'}
                currentAdPrice={(ad && ad.price) || 0}
                discount={listAd.props.discount}
                firstRun={listAd.firstRun}
                fuel={listAd.props.fuel}
                adId={listAd._id}
                id={listAd.props.id}
                idx={idx}
                img={`https://${listAd.img}`}
                isActive={listAd.isActive}
                isAuthorized={isAuthorized}
                key={listAd._id}
                km={listAd.props.km}
                kw={listAd.props.kw}
                lastRun={listAd.lastRun}
                listType={listType}
                loading={dataIsLoading}
                genericBadgeText={genericBadgeText}
                name={`${listAd.titleShort}`}
                orderBadgeEnabled={orderBadgeEnabled}
                onRowMouseEnter={onRowMouseEnter ? onRowMouseEnter : this.handleRowMouseEnter}
                onRowMouseLeave={onRowMouseLeave ? onRowMouseLeave : this.handleRowMouseLeave}
                price={listAd.props.price}
                // @ts-ignore
                selected={ad ? listAd._id === ad._id : false}
                showLoginDialog={showLoginDialog}
                thumbnail={thumbnail}
                total={listAd.props.total}
                transmission={listAd.props.transmission}
                year={listAd.props.year}
                showBenchmark={showBenchmark}
              />
            ))}
          </Swiper>
        )}
      </div>
    );
  }
}

export default withRouter<Props & RouteComponentProps<any>, any>(AdsListSlider);
