import * as React from 'react';
import { Box } from 'reflexbox/styled-components';
import { connect } from 'react-redux';
import { isNumber } from 'lodash-es';
import { RouteComponentProps, withRouter } from 'react-router';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';

import { ListType } from 'app/components/ListSwitcher';
import AdFilter from 'app/models/AdFilter';
import Button from 'app/components/Button';
import List from 'app/components/List';
import ListItem from 'app/components/ListItem';
import RangeInput from 'app/components/RangeInput';
import RangeSelectInput from 'app/components/RangeSelectInput';
import { LoginBanner } from 'app/components/LoginBanner';
import { loadAdDetails, updateFilter } from 'app/actions/ads';
import { showLoginDialog } from 'app/actions/app';
import { loadEnums } from 'app/actions/enums';
import { IEnums, IEnumValue } from 'app/models/Enums';

import * as S from './Filter.styles';

interface IFilterProps {
  displayFullFilter: boolean;
  enums: IEnums;
  filter: AdFilter;
  isLoading: boolean;
  listType: ListType;
  loadAdDetails: (listType: ListType) => void;
  loadEnums: () => Promise<any>;
  showLoginDialog: () => void;
  updateFilter: (filter: Partial<AdFilter>) => void;
}

interface IFilterReceivedProps {
  onSubmitFilter: () => void;
}

const Option = Select.Option;

class Filter extends React.Component<
  IFilterProps & IFilterReceivedProps & RouteComponentProps<any>
> {
  public componentDidMount() {
    const { enums } = this.props;
    if (!enums) {
      this.props.loadEnums();
    }
  }

  public render() {
    const { displayFullFilter, isLoading, enums } = this.props;

    const {
      yearIncluded,
      yearFrom,
      yearTo,
      priceIncluded,
      priceFrom,
      priceTo,
      kmIncluded,
      kmFrom,
      kmTo,
      kwIncluded,
      kwFrom,
      kwTo,
      equipmentCountIncluded,
      equipmentCountFrom,
      equipmentCountTo,
      fuel,
      transmission,
      karoserie,
    } = this.props.filter;

    return (
      <React.Fragment>
        <List>
          <ListItem bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Rok výroby</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <RangeInput
                  minValue={yearFrom || 0}
                  maxValue={yearTo || 0}
                  onChange={this.updateYear}
                  inputNumberProps={{
                    disabled: !yearIncluded,
                  }}
                />
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Cena (Kč)</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <RangeSelectInput
                  minValue={priceFrom}
                  maxValue={priceTo}
                  isEnabled={priceIncluded}
                  onChange={this.updatePrice}
                  rangeName='price'
                  selectProps={{
                    getPopupContainer: () =>
                      document.querySelector('.dialog__content') as HTMLElement,
                  }}
                />
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomBox width={[1, 1 / 4]}>Palivo</S.CustomBox>
            <Box width={[1, 3 / 4]}>
              <Select
                value={fuel ? fuel : 'neuvedeno'}
                style={{ width: 130 }}
                onChange={this.updateFuel}
                getPopupContainer={(): any => document.querySelector('.dialog__content')}
              >
                <Option value='neuvedeno'>Neuvedeno</Option>
                <Option value='benzin'>Benzin</Option>
                <Option value='nafta'>Nafta</Option>
                <Option value='lpg-+-benzin'>LPG plus Benzin</Option>
                <Option value='Elektro'>Elektro</Option>
                <Option value='cng-+-benzin'>CNG plus Benzin</Option>
                <Option value='hybridni'>Hybridni</Option>
                <Option value='ethanol'>Ethanol</Option>
              </Select>
            </Box>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Najeté kilometry (km)</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <RangeSelectInput
                  minValue={kmFrom}
                  maxValue={kmTo}
                  isEnabled={kmIncluded}
                  onChange={this.updateKm}
                  rangeName='km'
                  selectProps={{
                    getPopupContainer: () =>
                      document.querySelector('.dialog__content') as HTMLElement,
                  }}
                />
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Převodovka</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <Select
                  value={transmission ? transmission : 'neuvedeno'}
                  style={{ width: 130 }}
                  onChange={this.updateTransmission}
                  getPopupContainer={(): any => document.querySelector('.dialog__content')}
                >
                  <Option value='neuvedeno'>Neuvedeno</Option>
                  <Option value='manuální'>Manuální</Option>
                  <Option value='automatická'>Automatická</Option>
                  <Option value='poloautomatická'>Poloautomatická</Option>
                  <Option value='variátor'>Variátor</Option>
                </Select>
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Výkon (kw)</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <RangeSelectInput
                  minValue={kwFrom}
                  maxValue={kwTo}
                  isEnabled={kwIncluded}
                  onChange={this.updateKw}
                  rangeName='kw'
                  selectProps={{
                    getPopupContainer: () =>
                      document.querySelector('.dialog__content') as HTMLElement,
                  }}
                />
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomFlex width='100%' alignItems='center'>
              <S.CustomBox width={[1, 1 / 4]}>Počet prvků výbavy</S.CustomBox>
              <Box width={[1, 3 / 4]}>
                <RangeSelectInput
                  minValue={equipmentCountFrom}
                  maxValue={equipmentCountTo}
                  isEnabled={equipmentCountIncluded}
                  onChange={this.updateEquipmentCount}
                  rangeName='equipmentCount'
                  selectProps={{
                    getPopupContainer: () =>
                      document.querySelector('.dialog__content') as HTMLElement,
                  }}
                />
              </Box>
            </S.CustomFlex>
          </ListItem>
          <ListItem disabled={!displayFullFilter} bordered={true}>
            <S.CustomBox width={[1, 1 / 4]}>Karoserie</S.CustomBox>
            <Box width={[1, 3 / 4]}>
              <Select
                value={karoserie ? karoserie : 'neuvedeno'}
                style={{ width: 130 }}
                onChange={this.updateKaroserie}
                getPopupContainer={(): any => document.querySelector('.dialog__content')}
              >
                <Option value='neuvedeno'>Neuvedeno</Option>
                {enums &&
                  enums.karoserie &&
                  enums.karoserie.length &&
                  enums.karoserie.map((item: IEnumValue) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Box>
          </ListItem>
          <S.SubmitBtnWrapper>
            <Button
              text={'Potvrdit'}
              onClick={this.submitFilter}
              spin={isLoading}
              disabled={isLoading}
            />
          </S.SubmitBtnWrapper>
          {!displayFullFilter && (
            <LoginBanner text='Pro úpravu dalších parametrů se musíte' btnText='přihlásit' />
          )}
        </List>
      </React.Fragment>
    );
  }

  private includeYear = (minValue, maxValue) =>
    this.props.updateFilter({ yearIncluded: !!(minValue || maxValue) });

  private includePrice = (minValue, maxValue) =>
    this.props.updateFilter({ priceIncluded: !!(minValue || maxValue) });

  private includeKm = (minValue, maxValue) =>
    this.props.updateFilter({ kmIncluded: !!(minValue || maxValue) });

  private includeKw = (minValue, maxValue) =>
    this.props.updateFilter({ kwIncluded: !!(minValue || maxValue) });

  private includeEquipmentCount = (minValue, maxValue) =>
    this.props.updateFilter({ equipmentCountIncluded: !!(minValue || maxValue) });

  private includeTransmission = value =>
    this.props.updateFilter({ transmissionIncluded: value !== 'neuvedeno' });

  private includeFuel = value => this.props.updateFilter({ fuelIncluded: value !== 'neuvedeno' });

  private includeKaroserie = value =>
    this.props.updateFilter({ karoserieIncluded: value !== 'neuvedeno' });

  private updateYear = ({ minValue, maxValue }: { minValue?: number; maxValue?: number }) => {
    if (isNumber(minValue)) {
      this.includeYear(minValue, this.props.filter.yearTo);
      this.props.updateFilter({
        yearFrom: minValue,
      });
    }
    if (isNumber(maxValue)) {
      this.includeYear(this.props.filter.yearFrom, maxValue);
      this.props.updateFilter({
        yearTo: maxValue,
      });
    }
  };

  private updatePrice = ({ minValue, maxValue }: { minValue?: number; maxValue?: number }) => {
    if (isNumber(minValue)) {
      this.includePrice(minValue, this.props.filter.priceTo);
      this.props.updateFilter({
        priceFrom: minValue,
      });
    }
    if (isNumber(maxValue)) {
      this.includePrice(this.props.filter.priceFrom, maxValue);
      this.props.updateFilter({
        priceTo: maxValue,
      });
    }
  };

  private updateKm = ({ minValue, maxValue }: { minValue?: number; maxValue?: number }) => {
    if (isNumber(minValue)) {
      this.includeKm(minValue, this.props.filter.kmTo);
      this.props.updateFilter({
        kmFrom: minValue,
      });
    }
    if (isNumber(maxValue)) {
      this.includeKm(this.props.filter.kmFrom, maxValue);
      this.props.updateFilter({
        kmTo: maxValue,
      });
    }
  };

  private updateKw = ({ minValue, maxValue }: { minValue?: number; maxValue?: number }) => {
    if (isNumber(minValue)) {
      this.includeKw(minValue, this.props.filter.kwTo);
      this.props.updateFilter({
        kwFrom: minValue,
      });
    }
    if (isNumber(maxValue)) {
      this.includeKw(this.props.filter.kwFrom, maxValue);
      this.props.updateFilter({
        kwTo: maxValue,
      });
    }
  };

  private updateEquipmentCount = ({
    minValue,
    maxValue,
  }: {
    minValue?: number;
    maxValue?: number;
  }) => {
    if (isNumber(minValue)) {
      this.includeEquipmentCount(minValue, this.props.filter.equipmentCountTo);
      this.props.updateFilter({
        equipmentCountFrom: minValue,
      });
    }
    if (isNumber(maxValue)) {
      this.includeEquipmentCount(this.props.filter.equipmentCountFrom, maxValue);
      this.props.updateFilter({
        equipmentCountTo: maxValue,
      });
    }
  };

  private updateFuel = (value: any) => {
    this.includeFuel(value);
    this.props.updateFilter({ fuel: value });
  };

  private updateKaroserie = (value: any) => {
    this.includeKaroserie(value);
    this.props.updateFilter({ karoserie: value });
  };

  private updateTransmission = (value: any) => {
    this.includeTransmission(value);
    this.props.updateFilter({ transmission: value });
  };

  private submitFilter = () => {
    const { filter, listType } = this.props;
    const searchString = AdFilter.getSanitizedQueryString(filter);
    this.props.history.push(this.props.location.pathname + '?' + searchString);
    this.props.loadAdDetails(listType);
    this.props.onSubmitFilter();
  };
}

const mapStateToProps = state => {
  return {
    displayFullFilter: !!state.auth.accessToken,
    filter: state.ads.filter,
    isLoading: state.ads.adDetailsIsLoading,
    listType: state.ads.listType,
    enums: state.enums.enums,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFilter: (filter: Partial<AdFilter>) => dispatch(updateFilter(filter)),
    loadAdDetails: (listType: ListType) => dispatch(loadAdDetails(listType)),
    loadEnums: () => dispatch(loadEnums()),
    showLoginDialog: () => dispatch(showLoginDialog()),
  };
};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(
  connect<IStateProps, IDispatchProps, IFilterReceivedProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Filter)
) as any; // TODO: fix onSubmitFilter prop typescript error (remove as any)
