import * as React from 'react';
import { Flex } from 'reflexbox/styled-components';
import { get, isNumber } from 'lodash-es';

import Typography from 'app/components/Typography';
import SectionHeader from 'app/components/SectionHeader';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { DummyTable } from 'app/components/DummyTable';
import { DummyChart } from 'app/components/DummyChart';
import { IKmHistory } from 'app/components/AdHistory/AdHistory.container';
import { DataNoResults } from 'app/components/DataNoResults';
import { AnimatedHeight } from 'app/components/AnimatedHeight';
import { LoginBanner } from 'app/components/LoginBanner';
import { useTypedSelector } from 'app/store';

import Ad from 'app/models/Ad';
import * as S from './VerifyVinKmHistory.styles';
import { checkViewMode } from 'app/utils';

import chartBlurredImg from 'assets/images/km-history-chart-blurred.png';
import tableBlurredImg from 'assets/images/km-history-table-blurred.png';

import {
  Container,
  ContentWrapper,
  LoaderContainer,
  NoResultsContainer,
  SectionHeaderContainer,
} from 'app/components/_shared_';
import { InfoMessage } from '../../../InfoMessage/InfoMessage';

interface IVerifyVinKmHistoryProps {
  data: IKmHistory;
  dataIsLoading: boolean;
  isAuthorized: boolean;
  vin: string;
  kmHistoryError: string;
  kmHistoryNotFoundVinIsLoading: boolean;
  countryOrigin?: string;
  showLoginDialog: () => void;
}

const sectionHeaderText = 'Stav tachometru';

const VerifyVinKmHistory: React.SFC<IVerifyVinKmHistoryProps> = ({
  data = {} as IKmHistory,
  dataIsLoading,
  isAuthorized,
  vin,
  showLoginDialog,
  kmHistoryError = '',
  kmHistoryNotFoundVinIsLoading,
  countryOrigin,
}) => {
  if (dataIsLoading) return <KmHistoryDataLoading />;
  if (kmHistoryNotFoundVinIsLoading) return <KmHistoryNotFoundVinDataLoading />;

  if (!isAuthorized)
    return (
      <ContentForUnauthorizedUser
        tableDataLength={data && data.data && data.data.tableDataLength}
      />
    );

  const isInvalidVin = kmHistoryError && kmHistoryError.indexOf('params validation') > -1;
  const isVinNotFoundErr = kmHistoryError && kmHistoryError === 'vinNotFoundError';
  if (isInvalidVin) return <InvalidVinContent showLoginDialog={showLoginDialog} />;
  if (kmHistoryError && (!isVinNotFoundErr || !isInvalidVin)) return <KmHistoryError />;

  const hasData = data && data.data && !!data.data.tableDataLength;
  if (!hasData) return <NoResults />;

  return (
    <MainContent
      data={data}
      countryOrigin={countryOrigin}
      dataIsLoading={dataIsLoading || kmHistoryNotFoundVinIsLoading}
    />
  );
};

export const MainContent: React.SFC<Partial<IVerifyVinKmHistoryProps>> = ({
  data,
  dataIsLoading,
  countryOrigin,
}) => {
  const tableDataLength = get(data, 'data.tableDataLength', 0);
  const hasKmManipulated =
    data?.kmManipulation && isNumber(data?.kmManipulation) && data?.kmManipulation > 1.5;
  const adMeta = useTypedSelector(state => state.ads.adsByVinList?.meta);
  const isImportedCar = adMeta?.hasAdDetails && countryOrigin !== 'Česká republika';
  const isPdfMode = checkViewMode('pdf');

  return (
    <>
      <SectionHeaderContainer>
        <SectionHeader
          title={sectionHeaderText}
          subheaderValue={tableDataLength || '0'}
          subheaderSuffixText={Ad.getNumberOfRecordsTitle(tableDataLength || 0)}
        />
      </SectionHeaderContainer>

      {hasKmManipulated && (
        <Flex>
          <InfoMessage
            colorBg='orange'
            text='Naše doporučení: Kilometrový nájezd se během historie vozu výrazně změnil. Ověřte zda
            vůz nezměnil majitele, popř. jaký byl důvod ke změně nájezdu. Buďte opatrní zda nedošlo
            k manipulaci s tachometrem.'
          />
        </Flex>
      )}

      {isImportedCar && (
        <Flex>
          <InfoMessage
            colorBg='orange'
            text='Naše doporučení: Vůz byl jako ojetý dovezen ze zahraničí. Historii tak nelze spolehlivě
            ověřit. Ptejte se důsledně na jeho minulost v zahraničí.'
          />
        </Flex>
      )}

      <Flex justifyContent='space-between' flexDirection={['column', 'row', 'row']} width='100%'>
        <Flex
          justifyContent='space-between'
          flexDirection='column'
          width={[1, 1 / 2, 7 / 12]}
          mr={20}
          mb={['40px', 0, 0]}>
          <AnimatedHeight
            minHeight={290}
            btnTextOpen='Zobrazit další data'
            btnTextClose='Skrýt'
            enabled={isPdfMode ? false : tableDataLength && tableDataLength > 6}>
            <KmTable data={data && data.data} />
          </AnimatedHeight>
        </Flex>
        <Flex
          justifyContent='space-between'
          flexDirection='row'
          width={[1, 1 / 2, 5 / 12]}
          alignItems={['center', 'flex-start', 'flex-start']}
          mb={['20px', 0, 0]}>
          <KmHistoryChart data={data && data.data} />
        </Flex>
      </Flex>
    </>
  );
};

const InvalidVinContent: React.SFC<any> = ({ showLoginDialog }) => {
  return (
    <Container>
      <ContentWrapper>
        <SectionHeader
          title={sectionHeaderText}
          subheaderValue={'?'}
          subheaderSuffixText={'záznamů'}
        />
        <NoResultsContainer>
          <DummyChart
            overlayContent={<>Vložený VIN kód je neúplný, vložte prosím kompletní VIN kód.</>}
          />
        </NoResultsContainer>
      </ContentWrapper>
      <ContentWrapper>
        <SectionHeader
          title='Historie záznamů'
          subheaderValue={'?'}
          subheaderSuffixText={'záznamů'}
        />
        <NoResultsContainer>
          <DummyTable
            showLoginDialog={showLoginDialog}
            overlayContent={<>Vložený VIN kód je neúplný, vložte prosím kompletní VIN kód.</>}
            bgImageName='vinHistoryTable'
          />
        </NoResultsContainer>
      </ContentWrapper>
    </Container>
  );
};

const KmHistoryError = () => (
  <Container>
    <ContentWrapper>
      <SectionHeader title='Historie záznamů' />
      <NoResultsContainer>
        <Typography
          text='Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte. Děkujeme!'
          size='base'
          weight='regular'
        />
      </NoResultsContainer>
    </ContentWrapper>
  </Container>
);

const KmHistoryDataLoading = () => (
  <Container>
    <ContentWrapper>
      <SectionHeader title={sectionHeaderText} />
      <LoaderContainer>
        <AdLoader text={EnumAdLoaderTexts.LOADING_DATA} fullscreen={true} />
      </LoaderContainer>
    </ContentWrapper>
  </Container>
);

const KmHistoryNotFoundVinDataLoading: React.SFC<any> = () => (
  <Container>
    <ContentWrapper>
      <SectionHeader title={sectionHeaderText} />
      <LoaderContainer>
        <AdLoader text={EnumAdLoaderTexts.SEARCHING_THIRDPARTY_SERVICES} fullscreen={true} />
      </LoaderContainer>
    </ContentWrapper>
  </Container>
);

const NoResults: React.SFC<any> = () => (
  <Container>
    <ContentWrapper>
      <ContentWrapper>
        <SectionHeader
          title={sectionHeaderText}
          subheaderValue={'0'}
          subheaderSuffixText={Ad.getNumberOfRecordsTitle(0)}
        />
        <NoResultsContainer>
          <Typography
            text='Litujeme, ale neevidujeme žádné záznamy.'
            size='base'
            weight='regular'
          />
        </NoResultsContainer>
      </ContentWrapper>
      <ContentWrapper>
        <SectionHeader
          title='Historie záznamů'
          subheaderValue={'0'}
          subheaderSuffixText={Ad.getNumberOfRecordsTitle(0)}
        />
        <NoResultsContainer>
          <Typography
            text='Litujeme, ale neevidujeme žádné záznamy.'
            size='base'
            weight='regular'
          />
        </NoResultsContainer>
      </ContentWrapper>
    </ContentWrapper>
  </Container>
);

const KmHistoryChart = ({ data }) => {
  const chartData = get(data, 'chartData.kmHistory');
  if (chartData && chartData.length)
    return (
      <S.StyledAreaChart data={chartData} lineDataKey='value' xAxisDataKey='date' yAxisUnit=' km' />
    );

  return null;
};

const KmTable = ({ data }) => {
  const tableData = get(data, 'tableData');
  if (tableData && tableData.length)
    return <S.StyledAdHistoryTable history={tableData} columnsConfig={tableColumnsConfig} />;

  return <DataNoResults />;
};

export const ContentForUnauthorizedUser = ({ tableDataLength }) => (
  <>
    <SectionHeaderContainer>
      <SectionHeader
        title={sectionHeaderText}
        subheaderValue={tableDataLength || '0'}
        subheaderSuffixText={Ad.getNumberOfRecordsTitle(tableDataLength || 0)}
      />
    </SectionHeaderContainer>

    <Flex justifyContent='space-between' flexDirection={['column', 'row', 'row']} width='100%'>
      <Flex
        justifyContent='space-between'
        flexDirection='column'
        width={[1, 1 / 2, 7 / 12]}
        mr={20}
        mb={['40px', 0, 0]}
        sx={{ position: 'relative' }}>
        <LoginBanner />
        <img src={tableBlurredImg} alt='blurred-img' />
      </Flex>
      <Flex
        justifyContent='space-between'
        flexDirection='row'
        width={[1, 1 / 2, 5 / 12]}
        alignItems={['center', 'flex-start', 'flex-start']}
        mb={['20px', 0, 0]}
        sx={{ position: 'relative' }}>
        <LoginBanner />
        <img src={chartBlurredImg} alt='blurred-img' />
      </Flex>
    </Flex>
  </>
);

const tableColumnsConfig = [
  {
    title: 'Datum',
    fieldName: 'changedAt',
  },
  {
    title: 'Nájezd [km]',
    fieldName: 'newValue',
  },
  {
    title: 'Zdroj informace',
    fieldName: 'description',
  },
];

export default VerifyVinKmHistory;
