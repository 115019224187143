import React, { useState, useEffect, useCallback } from 'react';
import { SortingRule } from 'react-table';

import { Column } from 'app/components/Column';
import { BasicTable, TTableProps } from './TableReact';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { getSortingParams, PAGINATION_CONFIG } from './Table.utils';
import { IObjectGeneric } from 'app/types';
import { log } from 'app/utils/log';

interface ITablePaginatedProps extends Omit<TTableProps, 'data'> {
  columns: IObjectGeneric[];
  defaultPage?: number;
  defaultPageSize?: number;
  NoDataComponent?: any;
  cacheBustTimestamp?: number | null; // force data reload on change
  showLoader?: boolean;
  loadRestData: (pageSize, page, sortingParams) => Promise<any>;
  onTableStateChange?: (pageSize, page, sortingParams) => any;
  onDataStatusChange?: (loadingStatus) => any;
  onDataLoading?: (isLoading) => any;
  getDataFromResponse?: (response) => unknown[];
}

export const TablePaginated: React.FC<ITablePaginatedProps> = ({
  columns,
  defaultPage = PAGINATION_CONFIG.DEFAULT_PAGE, // 0
  defaultPageSize = PAGINATION_CONFIG.DEFAULT_PAGE_SIZE, // 20
  loadRestData,
  NoDataComponent,
  onTableStateChange,
  cacheBustTimestamp,
  onDataStatusChange,
  onDataLoading,
  showLoader = false,
  getDataFromResponse,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [sortingParams, setSortingParams] = useState<IObjectGeneric>({});
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [sorted, setSorted] = useState<SortingRule[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadingFinished, setHasLoadingFinished] = useState(false);
  const [data, setData] = useState<IObjectGeneric | null>(null);

  const onDataNotFound = useCallback(() => {
    setCurrentPage(defaultPage);
    setPageSize(defaultPageSize);
  }, [setCurrentPage, setPageSize, defaultPage, defaultPageSize]);

  const loadTableData = useCallback(
    async (pageSize = 20, page = 0, sortingParams = {}) => {
      try {
        setIsLoading(true);
        onDataLoading?.(true);
        const response = await loadRestData(pageSize, page, sortingParams);
        const data = getDataFromResponse
          ? getDataFromResponse(response?.data)
          : response?.data?.data;
        setData(data);
        onTableStateChange?.(pageSize, page, sortingParams);
        onDataStatusChange?.(response?.status);
        return response;
      } catch (err) {
        const noTableData = (err as any)?.status === 404;
        onDataStatusChange?.((err as any)?.status);
        if (noTableData) onDataNotFound();
        log.debug('err', err);
      } finally {
        setIsLoading(false);
        onDataLoading?.(false);
        setHasLoadingFinished(true);
      }
    },
    [
      loadRestData,
      onTableStateChange,
      onDataNotFound,
      onDataStatusChange,
      onDataLoading,
      getDataFromResponse,
    ]
  );

  useEffect(() => {
    loadTableData(pageSize, currentPage, sortingParams);
  }, [loadTableData, currentPage, sortingParams, pageSize, cacheBustTimestamp]);

  const onPageChange = useCallback(page => setCurrentPage(page), [setCurrentPage]);

  const onSortedChange = useCallback(
    newSorted => {
      setSorted(newSorted);
      setSortingParams(getSortingParams(newSorted));
    },
    [setSortingParams, setSorted]
  );

  // reset page to first page on page size change
  const onPageSizeChange = useCallback(pageSize => setPageSize(pageSize), [setPageSize]);

  return (
    <Column width='100%' position='relative'>
      <LoadingContainer
        hasNoData={!data || !data?.docs?.length}
        isLoading={isLoading || showLoader}
        NoDataComponent={hasLoadingFinished && NoDataComponent}
        width={1}
      >
        <BasicTable
          columns={columns}
          data={data?.docs || []}
          defaultPageSize={defaultPageSize}
          defaultSortDesc={false}
          filterable={false}
          manual={true} // Forces table not to paginate or sort automatically, so we can handle it server-side
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortedChange={onSortedChange}
          page={currentPage}
          pages={data?.pages || PAGINATION_CONFIG.DEFAULT_PAGE_SIZE} // the number of rows per page to be displayed
          pageSize={pageSize}
          showPagination={data?.total > pageSize}
          sorted={sorted}
          sortIconsVisible={true}
          {...props}
        />
      </LoadingContainer>
    </Column>
  );
};
