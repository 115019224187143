import * as React from 'react';
import styled from 'styled-components/macro';

import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

type TContainerProps = LayoutProps & SpaceProps;

const Container: React.FC<TContainerProps> = props => <StyledBox {...props} />;

const StyledBox = styled.div<LayoutProps>`
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  ${layout};
  ${space};
`;

export default Container;
