import Card from '../../components/Card';
import styled, { css } from 'styled-components/macro';

export const itemsAlignLeftStyle = css({
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

export const itemsAlignCenterStyle = css({
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledPage = styled(Card)(
  {
    margin: '32px auto',
    display: 'flex',
    padding: '50px',
    maxWidth: '1000px',
    lineHeight: 1.5,
  },
  (props: any) => ({
    textAlign: props.textAlign === 'center' ? 'center' : 'left',
  }),
  (props: any) => (props.itemsAlign === 'center' ? itemsAlignCenterStyle : itemsAlignLeftStyle)
);
