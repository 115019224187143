import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Moment } from 'moment';

export const BASE_DATE_FORMAT = 'DD.MM.YYYY';
export const BASE_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATES_PDF_FORMAT = 'DD-MM-YYYY-HH-mm';

export const formatDate = (
  date: Date | string | Moment = moment(),
  inputFormat: string = '',
  outputFormat: string = BASE_DATE_TIME_FORMAT
) => moment(date, inputFormat).format(outputFormat);

export const formatToDate = (date: Date | string | Moment = moment(), inputFormat: string = '') =>
  moment(date, inputFormat).format(BASE_DATE_FORMAT);

export const formatToDateUtc = (
  date: Date | string | Moment = moment(),
  inputFormat: string = '',
  outputFormat: string = BASE_DATE_FORMAT
) =>
  moment(date, inputFormat)
    .utc()
    .format(outputFormat);

export const getDateForTimezone = (
  timezone: string = 'Europe/Prague',
  date: Date | string | Moment = moment(),
  inputFormat: string = '',
  outputFormat: string = BASE_DATE_TIME_FORMAT
) =>
  momentTz(date, inputFormat)
    .tz(timezone)
    .format(outputFormat);

export const getNow = (timezone?: string) => getDateForTimezone(timezone);
