import React from 'react';

import { Column } from 'app/components/Column';
import { InfoMessage } from 'app/components/InfoMessage/InfoMessage';
import Button from 'app/components/Button';
import { formatDate } from 'app/utils/dates';
import { checkViewMode } from 'app/utils';

interface IVerifyAgainMessageProps {
  lastCheckedAt: string;
  vin: string;
}

export const VerifyAgainMessage: React.FC<IVerifyAgainMessageProps> = ({ lastCheckedAt, vin }) => {
  const isPdfMode = checkViewMode('pdf');
  return (
    <InfoMessage
      colorBg='primary'
      text={`Výsledek ověření je platný k ${formatDate(lastCheckedAt)}.`}
      position='relative'
    >
      {!isPdfMode && (
        <Column position={['relative', 'absolute']} right={[0, '10px']} mt={['5px', 0]}>
          <Button fullWidth={false} bgColor='highlight' to={`/vin/${vin}/registry/platba`}>
            Ověřit znovu
          </Button>
        </Column>
      )}
    </InfoMessage>
  );
};
