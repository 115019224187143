import React from 'react';
import { isNumber } from 'lodash-es';

import {
  IconSmartKmOk,
  IconSmartKmFail,
  IconSmartStateFail,
  IconSmartStateImportant,
  IconSmartStateSuccess,
  IconSmartThief,
  IconSmartThiefMesh,
  IconSmartWindow,
  IconSmartWindow2,
  IconQuestionMark,
  IconSmartSafebox,
  IconSmartMap,
} from 'app/utils/icons';

export const cardsTextsMap = {
  czOrigin: {
    category: 'Původ vozidla',
    titleSuccess: 'Vozidlo pochází z ČR',
    titleFail: 'Vozidlo nepochází z ČR',
    titleNeutral: 'Původ nelze ověřit',
    descSuccess:
      'Jelikož tento vůz pochází z ČR je u něj snažší ověřit jeho servisní historii než u vozidel dovezených ze zahraničí. I přesto doporučujeme před koupí ověřit maximum informací.',
    descFail:
      'Jelikož byl vůz dovezen ze zahraničí jako ojetý, nelze spolehlivě ověřit jeho historii. Doporučujeme se proto důsledně ptát na jeho minulost v zahraničí.',
    descNeutral:
      'Buďte obezřetní na původ vozidla, tzn. kde bylo poprvé přihlášeno do provozu. S dovozem ze zahraničí stoupá riziko manipulace tachometru a zakrývání nehod či jiných poruch. K tomuto vozidlu bohužel nemáme žádné záznamy.',
  },
  km: {
    category: 'Ověření nájezdu',
    titleSuccess: 'Vozidlo má standardní průběh nájezdu',
    titleFail: 'Nájezd vozidla výrazně kolísal',
    titleNeutral: 'Nájezd nelze ověřit',
    descSuccess:
      'U tohoto vozidla jsme nezjistili žádné podezřelé výkyvy v nájezdu, což by mohlo značit, že u něj nedošlo k manipulaci s tachometrem.',
    descFail:
      'Zjistili jsme výrazné výkyvy nájezdu v průběhu historie tohoto vozidla. Ověřte důvody těchto výkyvů, jelikož tento jev může být jedním z průvodních jevů manipulace s tachometrem.',
    descNeutral:
      'Analýza počtu najetých kilometrů může napovědět mnohé k historii vozidla. K tomuto vozu bohužel nemáme dostatek záznamů abychom jeho nájezd ověřili.',
  },
  hasAd: {
    category: 'Prodej vozidla',
    titleSuccess: 'Vozidlo nebylo nabízeno v inzerci',
    titleFail: 'Vozidlo již bylo nabízeno v inzerci',
    descSuccess:
      'Nenalezli jsme žádný záznam v databázi inzertních serverů což může znamenat, že vozidlo je relativně nové nebo dosud mělo jen jednoho majitele.',
    descFail:
      'Našli jsme záznam o vozidle v inzerci. Doporučujeme ověřit, zda údaje z inzerce jsou ve shodě s aktuálním popisem u prodejce.',
  },
  stolen: {
    category: 'Databáze odcizených vozidel',
    titleSuccess: 'Vozidlo není hlášeno jako odcizené',
    titleFail: 'Vozidlo je hlášeno jako odcizené',
    titleNeutral: 'Není hlášeno jako odcizené?',
    descSuccess:
      'Ověřili jsme toto vozidlo v celkem 14 zemích EU a to jak v policejních databázích, tak v databázích sdružení majitelů odcizených vozidel.',
    descFail:
      'Toto vozidlo ja nahlášeno jako odcizené. Pokud víte, kde se toto vozidlo nachází, doporučujeme kontaktovat Policii ČR na lince 158.',
    descNeutral:
      'Ověříme policejní registry ve 14 zemích EU. Nestaňte se obětí podvodu a ověřte si důkladně zda vozidlo nepochází z trestné činnosti!',
  },
  leasing: data => {
    const companiesLength = data?.length || 23;
    return {
      category: 'Databáze úvěrů a leasingů',
      titleSuccess: 'Vozidlo není předmětem financování',
      titleFail: 'Vozidlo je předmětem financování',
      titleNeutral: 'Blokováno financováním?',
      descSuccess: `Žádná z ${companiesLength} finanční institucí nemá vozidlo v evidenci. Prodej tak neblokuje žádná z ověřovaných společností.`,
      descFail: `Ověřením u ${companiesLength} finančních institucí jsme zjistili, že u některé z nich je vozidlo v evidenci. To typicky znamená zástavní právo úvěrové společnosti znemožňující prodej vozidla. Pro více informací kontaktujte danou instituci.`,
      descNeutral: `Ověříme až ${companiesLength} finančních institucí zda u některé z nich není vozidlo v evidenci. To typicky znamená zástavní právo úvěrové společnosti znemožňující prodej vozidla.`,
    };
  },
};

export const cardsStateIconsMap = {
  success: <IconSmartStateSuccess />,
  fail: <IconSmartStateFail />,
  important: <IconSmartStateImportant />,
  neutral: <IconQuestionMark />,
};

export const cardsThumbnailsMap = {
  czOrigin: {
    success: <IconSmartMap />,
    fail: <IconSmartMap />,
    neutral: <IconSmartMap />,
  },
  km: {
    success: <IconSmartKmOk />,
    fail: <IconSmartKmFail />,
  },
  hasAd: {
    success: <IconSmartWindow />,
    fail: <IconSmartWindow2 />,
    neutral: <IconSmartWindow />,
  },
  stolen: {
    success: <IconSmartThief />,
    fail: <IconSmartThiefMesh />,
    neutral: <IconSmartThiefMesh />,
  },
  leasing: {
    success: <IconSmartSafebox />,
    fail: <IconSmartSafebox />,
    neutral: <IconSmartSafebox />,
  },
};

export const thumbBgColorMap = {
  success: 'highlight',
  fail: 'red',
  important: 'mustard',
  neutral: 'greyNeutral',
};

export const checkIsStolenCar = stolenCars =>
  [...Object.values(stolenCars?.policeDbs), ...Object.values(stolenCars?.userDbs)].some(
    value => value
  );

export const checkHasKmManipulated = kmHistory =>
  kmHistory?.kmManipulation &&
  isNumber(kmHistory?.kmManipulation) &&
  kmHistory?.kmManipulation > 1.5;
