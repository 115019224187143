import * as React from 'react';
import { colors } from 'styles/shared.styles';
import * as S from './Logo.styles';

interface ILogoProps {
  color: string,
}

const Logo: React.SFC<ILogoProps> = ({ color }) => (
  <S.Wrapper>
    <svg viewBox='0 0 175 41'>
      <mask id='a' fill={colors.white}>
        <path d='m0 .26474157h33.7131103v33.51062923h-33.7131103z' fill={colors.white} fillRule='evenodd'/>
      </mask>
      <mask id='b' fill={colors.white}>
        <path d='m.09242308.3448875h14.79834612v14.311125h-14.79834612z' fill={colors.white} fillRule='evenodd'/>
      </mask>
      <g fill='none' fillRule='evenodd'>
        <g fill={color}>
          <path d='m12.2587195 20.6425843c2.3147357 0 5.3899771 1.2278202 5.3899771 2.7417753 0 1.5116629-1.8785977 2.1366516-4.1937242 2.1366516-2.3166896 0-4.19528734-.6249887-4.19528734-2.1366516 0-1.5139551.68195402-2.7417753 2.99903444-2.7417753zm7.4405288-20.37784273c-2.4335403 0-4.7138851.89125843-6.2563908 2.44532585-2.941977 2.96564045-5.01089658 5.67685393-6.4490575 8.45262918l-.86016092-.681146c-.238-.1818427-.53266667-.2800225-.83593103-.2762023h-4.09094253c-.66593104 0-1.2068046.4465843-1.2068046.9951686v2.3070337c0 .526809.49593103.9611685 1.13098851.9955505l4.20583908.2181349c-.63505748 1.53-1.13294253 3.8125842-1.13294253 7.0307415 0 2.7918203.67804597 4.6259101 1.85397701 5.813618v5.3765843c0 .4607191.45255172.833191 1.0117931.833191h4.65448281c.5592413 0 1.0117931-.3724719 1.0117931-.833191v-3.3381124l16.4274712-.0049663c-.9875632-1.3577078-1.8414712-2.8265842-2.4909885-4.3928764h-3.1791954c-1.3256092 0-2.4050115-.8908764-2.4050115-1.9842247.0328276-.3323596.3317931-.5810562.6737471-.5546966l4.0479541.0042022c-.4916322-1.7928314-.7577701-3.6807865-.7577701-5.6252809 0-2.1370337.4091724-4.2133258.9969425-6.1620225l-13.9685288.0141349c1.19-1.87458429 2.7758851-3.80074159 4.8162759-5.8594607.7456552-.71896629 1.7554943-1.1135955 2.8024598-1.09564044l10.1316092.00649438c1.0567356-1.36420225 2.5300689-2.60577528 3.8822528-3.68498877z' mask='url(#a)'/>
          <path d='m77.0062378 19.4538165c0-2.7721877-2.0775828-4.6199198-4.5216374-4.6199198-2.4436647 0-4.4908382 1.8477321-4.4908382 4.6199198 0 2.8028457 2.0471735 4.6816288 4.4908382 4.6816288 2.4440546 0 4.5216374-1.8787831 4.5216374-4.6816288m-12.0062378 0c0-4.5586039 3.4214425-7.4538165 7.4846004-7.4538165s7.5153996 2.8952126 7.5153996 7.4538165-3.4522417 7.5461835-7.5153996 7.5461835-7.4846004-2.9875796-7.4846004-7.5461835'/>
          <path d='m94 13-6.0556422 14h-2.8883193l-6.0560385-14h3.2608523l4.2238897 10.1927039 4.2234934-10.1927039z'/>
          <path d='m105.111386 18.5460981c-.265542-2.1808528-1.856913-3.3755377-3.978991-3.3755377-2.1217024 0-3.7138253 1.0753689-4.1260547 3.3755377zm-5.6884641-7.8859119-3.2417925-4.6601862h2.5049702l2.2104674 2.59864946 2.210466-2.59864946h2.53506l-3.241792 4.6601862zm8.5180271 10.2455482h-10.9643223c.383268 2.2704351 2.0340659 3.3759189 4.2738703 3.3759189 1.591747 0 3.094353-.687306 3.860889-1.9715732l2.298855 1.1649512c-1.208479 2.3299025-3.684111 3.5249687-6.307184 3.5249687-3.9789906 0-7.103057-2.8978925-7.103057-7.3186843 0-4.421173 3.1827414-7.229102 7.103057-7.229102 3.920316 0 6.896943 2.807929 6.896943 7.1989871 0 .3884441-.029714.8070032-.059051 1.2545336z'/>
          <path d='m119 13.0604548-.492695 2.8183041c-.753837-.3635025-1.449232-.4849278-2.00037-.4849278-2.463473 0-3.623081 2.6972655-3.623081 6.5152012v5.0909677h-2.869243v-14.1514593h2.869243v3.1818064c.695765-2.4845778 2.057703-3.484983 3.79693-3.484983.927687 0 1.884224.2428506 2.319216.5150907zm-6.81191-2.3329896-3.18809-4.7274652h2.463473l2.173849 2.6365528 2.173849-2.6365528h2.493064l-3.188459 4.7274652z'/>
          <path d='m130.975127 19.4538165c0-2.8948196-2.351985-4.5892618-4.551538-4.5892618-2.443991 0-4.429515 1.8787831-4.429515 4.5892618 0 2.6794277 1.985524 4.6816288 4.429515 4.6816288 2.413582 0 4.551538-1.7864161 4.551538-4.6816288zm3.024873-7.1456646v14.3836962h-2.994074v-2.1558839c-.947344 1.6323402-2.994074 2.4640358-4.918391 2.4640358-3.879821 0-7.087535-2.9565286-7.087535-7.5155255 0-4.5892619 3.207714-7.4844745 7.056737-7.4844745 2.016711 0 4.001845.8010377 4.949189 2.4337709v-2.125619z'/>
          <path d='m149 12v14.6853848h-2.915395v-2.2328045c-.802872 1.7925841-3.034212 2.5474197-4.49153 2.5474197-3.510242 0-5.622005-2.4214131-5.592775-6.603708v-8.396292h2.915394v8.1759812c0 2.3897108 1.36849 3.7733754 3.15303 3.7733754 1.81453 0 4.015881-1.0690495 4.015881-4.0879906v-7.861366z'/>
          <path d='m160 15.6061937h-3.19142v11.3938063h-3.127885v-11.3938063h-2.680695v-2.4268818h2.680695v-5.1793119h3.127885v5.1793119h3.19142z'/>
          <path d='m11.9370385 7.4563875c0-2.644875-2.04961542-4.40775-4.46076927-4.40775-2.41076923 0-4.43 1.762875-4.43 4.40775 0 2.674125 2.01923077 4.466625 4.43 4.466625 2.41115385 0 4.46076927-1.7925 4.46076927-4.466625m-11.84461542 0c0-4.34925 3.375-7.1115 7.38384615-7.1115 4.00884617 0 7.41461537 2.76225 7.41461537 7.1115s-3.4057692 7.199625-7.41461537 7.199625c-4.00884615 0-7.38384615-2.850375-7.38384615-7.199625' mask='url(#b)' transform='translate(160 12)'/>
        </g>
        <path d='m61 17.5c0 9.1125447-7.6110149 16.5-16.999805 16.5-9.3887902 0-17.000195-7.3874553-17.000195-16.5 0-9.11254473 7.6114048-16.5 17.000195-16.5 9.3887901 0 16.999805 7.38745527 16.999805 16.5' fill={colors.primary} />
        <path d='m66.9419901 34.8368138-8.8356823-8.8368138c-1.3165037 2.0476262-3.0587619 3.789168-5.1063078 5.1057234l8.8364671 8.8368138c1.4106798 1.4099504 3.6979824 1.4099504 5.105523 0 1.4106799-1.4083807 1.4106799-3.6953806 0-5.1057234' fill={colors.primary} />
        <path d='m44 27.4997623c-5.7900342 0-10.4998098-4.7094592-10.4998098-10.4995721s4.7097756-10.49995246 10.4998098-10.49995246 10.5001902 4.70983956 10.5001902 10.49995246-4.710156 10.4995721-10.5001902 10.4995721m14-10.4999525c0-7.73118147-6.2685432-13.9998098-14-13.9998098s-14 6.26862833-14 13.9998098c0 7.7319422 6.2685432 14.0001902 14 14.0001902s14-6.268248 14-14.0001902' fill={colors.white} />
        <path d='m36 17h2.2853417c0-3.1504425 2.5640691-5.7140196 5.7146583-5.7140196v-2.2859804c-4.4104523 0-8 3.5893805-8 8' fill={colors.white} />
      </g>
    </svg>
  </S.Wrapper>
)

export default Logo;
