export const colors = {
  black: '#4A4A4A',
  blackDark: '#373A3C',
  blackTrue: '#000',
  blue: '#1780FA',
  blueDark: '#0275D8',
  blueSuperDark: '#0D47A1',
  bgGray: '#4A5561',
  bodyColor: '#F0F2F6',
  bodyColorPrint: '#F5F5F5',
  buttonPrimaryColor: '#6196FF',
  cardBorderColor: '#E1E8F5',
  cardBoxShadowColor: 'rgba(214,214,214,0.5)',
  darkGreen: '#3DB39E',
  darkRed: '#D0021B',
  green: '#E75A70',
  grey: '#8B9198',
  greyMedium: '#D9D9D9',
  greyDark: '#7A7A7A',
  greyDark1: '#D8D8D8',
  greyLight: '#737373',
  highlight: '#46DA8C',
  iconColorBase: '#737373',
  inputBorderColor: '#cccccc',
  lightGreen: 'rgba(70,218,140, 0.4)',
  linkBlue: '#6196FF',
  orange: '#FF9F00',
  primary: '#1780FA',
  purple: '#DF57AA',
  red: '#E2574C',
  textDefault: '#4A5561',
  textSecondary: '#7B828E',
  textGrey: '#707479',
  transparentLightGrey: 'rgba(240, 242, 246, 0.5)',
  white: '#FFFFFF',
  yellow: '#BFC849',
  mustard: '#E2B84C',
  greyNeutral: '#979797',
  bgPrimary: '#4A5560',
};
