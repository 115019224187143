import * as React from 'react';
import { colors } from '../../../styles/shared.styles';

// import styled from 'styled-components/macro';

// const Wrapper = styled('div')({
//   width: '8px',
//   height: '8px',
// });

const CancelIcon = props => (
  <svg height='8' viewBox='0 0 5 5' width='8'>
    <path
      className='svg-cancel-color'
      d='m3.08325803 2.4910117 1.77635524-1.7762682c.16351564-.16350762.16351564-.42860516 0-.59208939-.16351563-.16350762-.42860278-.16350762-.59211841 0l-1.77637863 1.77629158-1.77637864-1.77631497c-.16351563-.16350763-.42860278-.16350763-.59211841 0-.16349224.16350762-.16349224.42860516 0 .59208939l1.77637863 1.77626819-1.77637863 1.77629159c-.16349224.16350762-.16349224.42860517 0 .59208939.16351563.16350763.42860278.16350763.59211841 0l1.77637864-1.77629158 1.77637863 1.77629158c.16349224.16350763.42860278.16350763.59211841 0 .16351564-.16350762.16351564-.42858177 0-.59208939z'
      fill={colors.textDefault}
      fillRule='evenodd'
      transform='matrix(-1 0 0 -1 4.98225 4.982)'
    />
  </svg>
);

export default CancelIcon;
