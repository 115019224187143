import Heading from './../Heading';
import styled from 'styled-components/macro';
import { flex, mediaQuery, fontSize, fontFamily } from '../../../styles/shared.styles';

export const AdPreviewContainer = styled('div')({
  backgroundColor: '#FAF9F9;',
  border: '1px solid #DADADA',
  boxShadow: '0 3px 4px 0 rgba(0,0,0,0.01)',
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
  [mediaQuery.medium]: {
    padding: '9px',
  },
  [mediaQuery.print]: {
    padding: '20px',
  },
});

export const AdPreviewContent = styled('div')({
  display: 'flex',
  ...flex.centerHorizontal,
  flexDirection: 'column',
  width: '100%',
  [mediaQuery.medium]: {
    justifyContent: 'space-between',
    width: 'inherit',
  },
  [mediaQuery.extraLarge]: {
    flexDirection: 'row',
    minWidth: '1200px',
  },
  [mediaQuery.print]: {
    flexDirection: 'column',
    minWidth: 'auto',
  },
});

export const RightSideWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  ...flex.centerHorizontal,
  flexWrap: 'wrap',
  width: '100%',
  [mediaQuery.medium]: {
    flexWrap: 'initial',
    width: 'auto',
    flexDirection: 'row',
    ...flex.initial,
  },
});

export const LeftSideWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  ...flex.alignChildrensLeft,
  width: '100%',
  [mediaQuery.medium]: {
    marginBottom: '20px',
    flexDirection: 'row',
    ...flex.initial,
    width: 'auto',
  },
  [mediaQuery.extraLarge]: {
    marginBottom: 0,
  },
  [mediaQuery.print]: {
    marginBottom: '20px',
  },
});

export const ImageContainer = styled('div')({
  ...flex.centerHorizontal,
  borderRadius: '9px',
  display: 'flex',
  height: '86px',
  margin: '0 0 20px 0',
  overflow: 'hidden',
  width: '145px',
  [mediaQuery.medium]: {
    margin: '0 26px 0 0',
  },
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  ...flex.alignChildrensLeft,
  flexDirection: 'column',
  maxWidth: '455px',
  width: '70%',
  paddingRight: '10px',
  [mediaQuery.medium]: {
    paddingRight: 0,
    alignItems: 'flex-start',
    width: 'auto',
  },
});

export const CappedHeading = styled(Heading)({
  fontFamily: fontFamily.medium,
  fontSize: fontSize.base,
  marginBottom: '5px',
  textAlign: 'left',
  [mediaQuery.medium]: {
    fontSize: fontSize.titleBase,
    marginBottom: '8px',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const StyledHeading = styled(Heading)({
  fontFamily: fontFamily.medium,
  fontSize: fontSize.base,
  [mediaQuery.medium]: {
    fontSize: fontSize.titleBase,
  },
});

export const BadgesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
});

export const BadgeWrapper = styled('div')({
  marginRight: '5px',
  '&:last-child': {
    marginRight: 0,
  },
});

export const AdLink = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  margin: '3px 0 15px 0',
  [mediaQuery.medium]: {
    margin: '6px 0 0 0',
  },
});

export const BrandLogoContainer = styled.div`
  max-width: 20px;
  float: left;
  margin-right: 10px;
  ${mediaQuery.medium} {
    display: none;
  }
`;
