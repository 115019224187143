import { redirectRoute } from 'app/routes';

export const openUrlInTab = url => window.open(url, '_blank');

export const redirectLocation = url =>
  ((window as any).location = `${url}${(window as any).location.search}`);

export const redirectTo = (url: string, inNewTab?: boolean) => {
  if (!url) return;
  const isExternalRoute = url?.includes('http');
  if (!isExternalRoute) return redirectRoute(url);
  if (inNewTab) return openUrlInTab(url);
  return redirectLocation(url);
};
