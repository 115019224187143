import * as CONSTANTS from 'app/constants/enums';

export function loadEnums() {
  return {
    type: CONSTANTS.LOAD_ENUMS,
    payload: {
      request: {
        method: 'GET',
        url: '/enums',
      },
    },
  };
}

export function loadModelEnums(brand: string) {
  return {
    type: CONSTANTS.LOAD_MODEL_ENUMS,
    payload: {
      request: {
        method: 'GET',
        url: '/enums/model',
        params: {
          brand,
        },
      },
    },
  };
}
