import styled from 'styled-components/macro';
import { mediaQuery, flex, colors } from 'styles/shared.styles';
import Typography from 'app/components/Typography';

import { space, SpaceProps } from 'styled-system';

export const Container = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .tabs__tab-list {
    display: none !important;
  }

  .tabs__panel {
    margin-top: 28px;
  }

  margin-bottom: 30px;

  ${mediaQuery.print} {
    margin-bottom: 0;
  }
`;

export const LoaderContainer = styled.div`
  max-width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  position: relative;
`;

export const NoResultsContainer = styled.div`
  max-width: 1200px;
  margin: 20px auto 0;
  position: relative;
`;

export const SectionHeaderContainer = styled.div<SpaceProps>`
  max-width: 1200px;
  margin: 0 auto 20px;
  width: 100%;
  ${space};
`;

export const BlurryImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const RoundedBlurryImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 20px 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  margin: 20px 0 50px 0;
`;

export const IconContainer = styled.div`
  margin: 0 0 0 7px;
`;

export const FullWidthImage = styled.img`
  width: 100%;
`;

export const CappedText = styled.div`
  width: ${({ maxWidth }: any) => (maxWidth ? maxWidth : '1200px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ParametersRow = styled.div(
  {
    ...flex.vertical,
    ...flex.spaceBetween,
    ...flex.alignChildrensCenter,
    lineHeight: '40px',
    padding: 0,
    letterSpacing: '-0.32px',
    backgroundColor: 'transparent',
    [mediaQuery.small]: {
      padding: '0 10px',
    },
    [mediaQuery.medium]: {
      padding: '0 20px',
      ...flex.horizontal,
    },
    [mediaQuery.print]: {
      padding: '0 10px',
    },
  } as any,
  ({ striped }: any) => ({
    [mediaQuery.medium]: {
      backgroundColor: striped ? 'transparent' : colors.white,
    },
    [mediaQuery.print]: {
      backgroundColor: striped ? colors.bodyColor : colors.white,
    },
  })
);

export const ParametersColumn = styled.div({
  ...flex.horizontal,
  ...flex.spaceBetween,
  width: '100%',
  maxWidth: '350px',
  [mediaQuery.extraLarge]: {
    marginRight: 0,
  },
  [`${mediaQuery.medium}, ${mediaQuery.print}`]: {
    marginRight: '10px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  [mediaQuery.print]: {
    marginRight: '15px',
    maxWidth: '32%',
  },
} as any);

export const ParametersTitleTypography = styled(Typography)({
  color: colors.textGrey,
  textTransform: 'capitalize',
} as any);

export const ParametersValueTypography = styled(Typography)({
  textTransform: 'capitalize',
  textAlign: 'right',
});

export const ParametersTitle = styled.div({});

export const ParametersValue = styled.div<{ maxWidth?: any }>`
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth || 'initial'};
`;

export const SectionWrapperWithPageBreak = styled.div`
  ${mediaQuery.print} {
    display: block;
    page-break-after: always;
  }
`;

export const ResponsiveImage = styled.img`
  width: 100%;
`;
