import React from 'react';
import { useDispatch } from 'react-redux';

import { Row } from 'app/components/Row';
import Typography from 'app/components/Typography';
import Button from 'app/components/Button';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';
import { showLoginDialog } from 'app/actions/app';

interface ILoginBannerProps {
  text?: string;
  btnText?: string;
}

export const LoginBanner: React.FC<ILoginBannerProps> = ({
  text = 'Dostupné údaje se zobrazují pouze přihlášeným uživatelům',
  btnText = 'Přihlásit se',
}) => {
  const dispatch = useDispatch();
  const openLoginDialog = () => dispatch(showLoginDialog());
  const signInUser = () =>
    MobileAppUtils.isInApp() ? MobileAppUtils.gotoLogin() : openLoginDialog();

  return (
    <Row
      alignContent='center'
      alignItems='center'
      display='flex'
      flexDirection='row'
      height='100%'
      justifyContent='center'
      left='0'
      position='absolute'
      top='0'
      width='100%'
      zIndex={1}
      data-cy='login-banner'
    >
      <Row width={['100vw', '400px']} ml={['-10px', 0]} mr={['-10px', 0]}>
        <Row bg='grey' padding='11px'>
          <Typography text={text} color='white' size='small' lineHeight='18px' />
        </Row>
        <Row bgColor='primary'>
          <Button onClick={signInUser} borderRadius={0} height='100%' minWidth='140px'>
            <Typography text={btnText} color='white' size='small' weight='medium' />
          </Button>
        </Row>
      </Row>
    </Row>
  );
};
