import { log } from 'app/utils';

interface ICheckoutResult {
  id: number;
  url: string;
}

const extractOrderId = paymentUrl => {
  if (!paymentUrl) return '';
  return paymentUrl.split('?id=')?.[1] || '';
};

export const checkout = (
  gwUrl: string,
  cb?: (err, result: ICheckoutResult | null) => any
): void => {
  if (!gwUrl) {
    cb?.(null, null);
    return;
  }

  (window as any)._gopay.checkout({ gatewayUrl: gwUrl, inline: true }, checkoutResult => {
    const orderId = extractOrderId(checkoutResult?.url);
    const result = {
      url: checkoutResult?.url || '',
      id: orderId,
    };
    log.debug('checkout checkoutResult :: ', result);
    cb?.(null, result);
  });
};
