import styled from 'styled-components/macro';
import { mediaQuery } from '../../../../styles/shared.styles';

export const AdPreviewItemContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '50%',
  [mediaQuery.medium]: {
    width: 'auto',
    marginRight: '45px',
    '&:last-child': {
      marginRight: '0',
    },
  },
});

export const Row = styled('div')({
  display: 'flex',
});

export const TitleRow = styled(Row)({
  marginBottom: '7px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TitleWrapper = styled('div')({
  marginRight: '5px',
});

export const ProgressRow = styled(Row)({
  minHeight: '10px',
  width: '112px',
});

export const ValueRow = styled(Row)({
  marginBottom: '15px',
});
