import { useTypedSelector } from 'app/store';

/**
 * Check if user is authenticated
 */
export const useAuthenticated = () => {
  const accessToken = useTypedSelector(state => state.auth.accessToken || '');
  const userId = useTypedSelector(state => state.auth.user?.id || '');
  const isAuthenticated = !!accessToken;
  return {
    accessToken,
    isAuthenticated,
    userId,
  };
};
