import styled from 'styled-components/macro';
import Button from 'app/components/Button';
import Link from 'app/components/Link';
import { mediaQuery } from 'styles/shared.styles';

export const Container = styled.div``;

export const StyledLink = styled(Link)`
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  ${mediaQuery.print} {
    display: none;
  }
`;

export const PrintLink = styled(Link)`
  display: none;
  width: 100%;

  ${mediaQuery.print} {
    display: inline-block;
  }
`;

export const PrintButton = styled(Button)`
  display: none;

  &:hover {
    text-decoration: none;
  }

  ${mediaQuery.print} {
    padding: 0 10px;
    display: block;
  }
`;

export const StyledButton = styled(Button)`
  ${mediaQuery.print} {
    display: none;
  }
`;
