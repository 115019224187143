export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_INJECT = 'LOGIN_INJECT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const FB_LOGIN = 'FB_LOGIN';
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAIL = 'FB_LOGIN_FAIL';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const FB_SIGNUP = 'FB_SIGNUP';
export const FB_SIGNUP_SUCCESS = 'FB_SIGNUP_SUCCESS';
export const FB_SIGNUP_FAIL = 'FB_SIGNUP_FAIL';
export const GOOGLE_SIGNUP = 'GOOGLE_SIGNUP';
export const GOOGLE_SIGNUP_SUCCESS = 'GOOGLE_SIGNUP_SUCCESS';
export const GOOGLE_SIGNUP_FAIL = 'GOOGLE_SIGNUP_FAIL';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const LOAD_AD_DETAILS = 'LOAD_AD_DETAILS';
export const LOAD_AD_DETAILS_SUCCESS = 'LOAD_AD_DETAILS_SUCCESS';
export const LOAD_AD_DETAILS_FAIL = 'LOAD_AD_DETAILS_FAIL';
export const LOAD_RESIDUAL_VALUE = 'LOAD_RESIDUAL_VALUE';
export const LOAD_RESIDUAL_VALUE_SUCCESS = 'LOAD_RESIDUAL_VALUE_SUCCESS';
export const LOAD_RESIDUAL_VALUE_FAIL = 'LOAD_RESIDUAL_VALUE_FAIL';
export const LOAD_RESIDUAL_VALUE_BY_VIN = 'LOAD_RESIDUAL_VALUE_BY_VIN';
export const LOAD_RESIDUAL_VALUE_BY_VIN_SUCCESS = 'LOAD_RESIDUAL_VALUE_BY_VIN_SUCCESS';
export const LOAD_RESIDUAL_VALUE_BY_VIN_FAIL = 'LOAD_RESIDUAL_VALUE_BY_VIN_FAIL';
export const LOAD_ESTIMATE_CAR = 'LOAD_ESTIMATE_CAR';
export const LOAD_ESTIMATE_CAR_SUCCESS = 'LOAD_ESTIMATE_CAR_SUCCESS';
export const LOAD_ESTIMATE_CAR_FAIL = 'LOAD_ESTIMATE_CAR_FAIL';
export const LOAD_HIGHEST_EQUIPMENT_LIST = 'LOAD_HIGHEST_EQUIPMENT_LIST';
export const LOAD_HIGHEST_EQUIPMENT_LIST_SUCCESS = 'LOAD_HIGHEST_EQUIPMENT_LIST_SUCCESS';
export const LOAD_HIGHEST_EQUIPMENT_LIST_FAIL = 'LOAD_HIGHEST_EQUIPMENT_LIST_FAIL';
export const LOAD_ADS_BY_VIN = 'LOAD_ADS_BY_VIN';
export const LOAD_ADS_BY_VIN_SUCCESS = 'LOAD_ADS_BY_VIN_SUCCESS';
export const LOAD_ADS_BY_VIN_FAIL = 'LOAD_ADS_BY_VIN_FAIL';
export const RESET_ADS_BY_VIN = 'RESET_ADS_BY_VIN';
export const LOAD_KM_HISTORY = 'LOAD_KM_HISTORY';
export const LOAD_KM_HISTORY_SUCCESS = 'LOAD_KM_HISTORY_SUCCESS';
export const LOAD_KM_HISTORY_FAIL = 'LOAD_KM_HISTORY_FAIL';
export const RESET_KM_HISTORY = 'RESET_KM_HISTORY';
export const LOAD_KM_HISTORY_NOT_FOUND_VIN = 'LOAD_KM_HISTORY_NOT_FOUND_VIN';
export const LOAD_KM_HISTORY_NOT_FOUND_VIN_SUCCESS = 'LOAD_KM_HISTORY_NOT_FOUND_VIN_SUCCESS';
export const LOAD_KM_HISTORY_NOT_FOUND_VIN_FAIL = 'LOAD_KM_HISTORY_NOT_FOUND_VIN_FAIL';
export const RESET_KM_HISTORY_NOT_FOUND_VIN = 'RESET_KM_HISTORY_NOT_FOUND_VIN';
export const LOAD_PDF_EXPORT = 'LOAD_PDF_EXPORT';
export const LOAD_PDF_EXPORT_SUCCESS = 'LOAD_PDF_EXPORT_SUCCESS';
export const LOAD_PDF_EXPORT_FAIL = 'LOAD_PDF_EXPORT_FAIL';
export const LOAD_PRICE_HISTORY = 'LOAD_PRICE_HISTORY';
export const LOAD_PRICE_HISTORY_SUCCESS = 'LOAD_PRICE_HISTORY_SUCCESS';
export const LOAD_PRICE_HISTORY_FAIL = 'LOAD_PRICE_HISTORY_FAIL';
export const LOAD_ADS_LISTS = 'LOAD_ADS_LISTS';
export const LOAD_ADS_LISTS_SUCCESS = 'LOAD_ADS_LISTS_SUCCESS';
export const LOAD_ADS_LISTS_FAIL = 'LOAD_ADS_LISTS_FAIL';
export const LOAD_VIN_DECODE = 'LOAD_VIN_DECODE';
export const LOAD_VIN_DECODE_SUCCESS = 'LOAD_VIN_DECODE_SUCCESS';
export const LOAD_VIN_DECODE_FAIL = 'LOAD_VIN_DECODE_FAIL';
export const LOAD_VIN_LEASING_CHECK = 'LOAD_VIN_LEASING_CHECK';
export const LOAD_VIN_LEASING_CHECK_SUCCESS = 'LOAD_VIN_LEASING_CHECK_SUCCESS';
export const LOAD_VIN_LEASING_CHECK_FAIL = 'LOAD_VIN_LEASING_CHECK_FAIL';
export const LOAD_STOLEN_CARS = 'LOAD_STOLEN_CARS';
export const LOAD_STOLEN_CARS_SUCCESS = 'LOAD_STOLEN_CARS_SUCCESS';
export const LOAD_STOLEN_CARS_FAIL = 'LOAD_STOLEN_CARS_FAIL';
export const RESET_MY_CARS = 'RESET_MY_CARS';
export const LOAD_MY_CARS = 'LOAD_MY_CARS';
export const LOAD_MY_CARS_SUCCESS = 'LOAD_MY_CARS_SUCCESS';
export const LOAD_MY_CARS_FAIL = 'LOAD_MY_CARS_FAIL';
export const REMOVE_MY_CARS = 'REMOVE_MY_CARS';
export const REMOVE_MY_CARS_SUCCESS = 'REMOVE_MY_CARS_SUCCESS';
export const REMOVE_MY_CARS_FAIL = 'REMOVE_MY_CARS_FAIL';
export const SET_FAVORITE_MY_CARS = 'SET_FAVORITE_MY_CARS';
export const SET_FAVORITE_MY_CARS_SUCCESS = 'SET_FAVORITE_MY_CARS_SUCCESS';
export const SET_FAVORITE_MY_CARS_FAIL = 'SET_FAVORITE_MY_CARS_FAIL';
export const LOAD_AD_BY_VIN = 'LOAD_AD_BY_VIN';
export const LOAD_AD_BY_VIN_SUCCESS = 'LOAD_AD_BY_VIN_SUCCESS';
export const LOAD_AD_BY_VIN_FAIL = 'LOAD_AD_BY_VIN_FAIL';

export const LOAD = 'LOAD';
export const RESET_AD_DETAILS = 'RESET_AD_DETAILS';
export const UPDATE_AD_ID = 'UPDATE_AD_ID';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_VIN = 'SET_VIN';
export const RESET_ESTIMATE_CAR = 'RESET_ESTIMATE_CAR';
export const VEHICLE_REGISTER_LOADING = 'VEHICLE_REGISTER_LOADING';
export const VEHICLE_REGISTER_DATA = 'VEHICLE_REGISTER_DATA';
export const VEHICLE_REGISTER_FETCHED = 'VEHICLE_REGISTER_FETCHED';
