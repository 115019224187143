import React from 'react';

import { SmartCards } from './components/SmartCards';
import { SmartLoader } from './components/SmartLoader';
import { SectionHeaderContainer } from 'app/components/_shared_';
import SectionHeader from 'app/components/SectionHeader';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

export const SmartReport: React.FC = () => (
  <>
    <SectionHeaderContainer px={MobileAppUtils.isInApp() ? '25px' : null}>
      <SectionHeader
        title='Chytrý rádce'
        subheaderText='Automatickou analýzou jsme pro vás připravili shrnutí těch nejdůležitějších informací.'
      />
    </SectionHeaderContainer>

    <SmartLoader>
      <SmartCards />
    </SmartLoader>
  </>
);
