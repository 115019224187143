import * as React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BorderRadiusProps, LayoutProps, SpaceProps } from 'styled-system';

import Typography from 'app/components/Typography';
import { FontSize, FontWeight } from 'app/components/Typography';
import { colors } from 'styles/shared.styles';
import { IconContainer } from 'app/components/_shared_';
import * as S from './Badge.styles';

interface IOwnProps {
  text: string;
  size?: 'normal' | 'small';
  textSize?: FontSize;
  textWeight?: FontWeight;
  type?: string;
  icon?: IconDefinition;
  iconColor?: string;
  bgColor?: string;
  borderRadius?: string;
}

export type TBadgeProps = IOwnProps & BorderRadiusProps & LayoutProps & SpaceProps;

const Badge: React.SFC<TBadgeProps> = ({
  text,
  icon,
  size,
  textSize,
  textWeight,
  bgColor,
  type = 'positive',
  iconColor = '#ffffff',
  borderRadius,
  ...props
}) => {
  return (
    <S.BadgeContainer
      type={type}
      size={size}
      bgColor={bgColor}
      borderRadius={borderRadius}
      {...props}
    >
      <Typography text={text} color={colors.white} size={textSize} weight={textWeight} />
      {icon && (
        <IconContainer>
          <FontAwesomeIcon icon={icon} style={{ color: iconColor }} />
        </IconContainer>
      )}
    </S.BadgeContainer>
  );
};

Badge.defaultProps = {
  textSize: 'base',
  textWeight: 'bold',
  size: 'normal',
};

export default Badge;
