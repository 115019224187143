export const PAGINATION_CONFIG = {
  DEFAULT_PAGE: 0,
  DEFAULT_PAGE_SIZE: 15,
  DEFAULT_SORTED: [],
  DEFAULT_PAGE_SIZES: [15, 20, 30, 50, 100],
};

// API params form: &sort_column=orderStatus&sort_order=asc
export const getSortingParams = sorted => {
  if (!sorted || !sorted.length) return {};
  return {
    sort_column: sorted && sorted[0] && sorted[0].id,
    sort_order: sorted && sorted[0] && sorted[0].desc ? 'desc' : 'asc',
  };
};
