import styled from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const Wrapper = styled('div')({
  padding: '0 20px 8px 20px',
  [mediaQuery.medium]: {
    padding: '0 0 8px 0',
  },
});

export const Text = styled('div')({
  padding: '16px 0',
});
