import { AxiosResponse } from 'axios';
import { get } from 'lodash-es';

export const downloadFile = async (response: AxiosResponse, fileName: string = 'download') => {
  const data = get(response, 'payload.data', null) || get(response, 'data', null);
  if (data instanceof Blob) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}-${+new Date()}.pdf`;
    link.click();
  }
};

export const openFile = async (
  response: AxiosResponse,
  fileName: string = 'download',
  fileType = 'pdf'
) => {
  const data = get(response, 'data.data', null) || get(response, 'data', null);
  if (!(data instanceof Blob)) return;

  const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
  const fileUrl = URL.createObjectURL(blob);

  // IE
  if ((window as any).navigator.msSaveOrOpenBlob) {
    (window as any).navigator.msSaveOrOpenBlob(blob, `${fileName}.${fileType}`);
    return;
  }

  // Chrome, FF
  if (/chrome/i.test(navigator.userAgent) || /firefox/i.test(navigator.userAgent)) {
    const w = window.open(fileUrl, '_blank');
    w && w.focus();
    return;
  }

  // Safari & Opera iOS
  window.location.href = fileUrl;
};

export const scrollToTop = (behavior: ScrollBehavior = 'auto') =>
  window.scrollTo({ top: 0, behavior });
