import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { OG_META_IMAGE } from 'app/constants/meta-tags';
import MyCarsList from 'app/components/MyCars/MyCarsList';

interface IVerifyVinProps {
  isAuthorized: boolean;
  adsByVinListIsLoading: boolean;
}

class MyCars extends React.Component<IVerifyVinProps> {
  public render() {
    return (
      <>
        <Helmet defer={false}>
          <title>Moje vozy</title>
          <meta
            name='description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta
            property='og:description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta
            property='twitter:description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta property='og:title' content='Ověření VIN kódu | OvěřAuto.cz' />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:image' content={OG_META_IMAGE} />
        </Helmet>
        <MyCarsList />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCars);
