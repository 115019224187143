import * as React from 'react';
import { connect } from 'react-redux';
import { loginInject } from 'app/actions/auth';

interface ILoginInjectProps {
  injectLogin: (authData) => void;
}

class LoginInject extends React.Component<ILoginInjectProps> {
  public UNSAFE_componentWillMount() {
    this.inject();
    (window as any).injectAuth = this.inject;
  }

  public render() {
    return null;
  }

  protected inject = () => {
    if ((window as any).auth) {
      this.props.injectLogin((window as any).auth);
    }
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    injectLogin: (authData) => dispatch(loginInject(authData))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginInject);
