import * as React from 'react';
import styled from 'styled-components/macro';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import 'antd/lib/select/style/index.css';

import { ButtonInputCss } from 'app/components/Elements/Button/ButtonInputCss';

import iconDropdown from 'assets/images/icon-dropdown.svg';
import iconDropdownWhite from 'assets/images/icon-dropdown-white.svg';

const StyledSelectElement = styled<SelectProps>(props => <Select {...props} />)(ButtonInputCss, {
  marginRight: '0',
  '.ant-select': {
    transition: 'none',
  },
  '&.ant-select:hover .ant-select-arrow:before': {
    backgroundImage: `url(${iconDropdownWhite}) !important`,
  },
  '.ant-select-selection': {
    background: 'none',
    border: 'none',
    width: 'auto !important',
    boxShadow: 'none',
    transition: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.ant-select-dropdown-menu-item': {
    textTransform: 'capitalize',
  },
  '.ant-select-selection-selected-value': {
    outline: 'none',
    paddingRight: '25px',
    textTransform: 'capitalize',
  },
  '.ant-select-open': {
    boxShadow: 'none',
  },
  '.ant-select-selection__rendered': {
    margin: 0,
  },
  '.ant-select-arrow': {
    top: '58%',
    right: '-2px',
  },
  '.ant-select-arrow:before': {
    content: `' '`,
    display: 'block',
    backgroundImage: `url(${iconDropdown})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    transition: 'background-image 0.2s ease-in-out',
    position: 'relative',
    top: 0,
    left: 0,
    width: '12px',
    height: '7px',
  },
  '.ant-select-arrow-icon': {
    display: 'none',
  },
});

const StyledSelect: React.SFC<SelectProps> = props => (
  <StyledSelectElement {...props}>{props.children}</StyledSelectElement>
);

export default StyledSelect;
