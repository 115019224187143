import React, { useCallback } from 'react';

import { PageTemplate } from 'app/templates/PageTemplate';
import Typography from 'app/components/Typography';
import { useAuthenticated } from 'app/hooks';
import { apiGetUserPayments, apiDownloadInvoice } from 'app/api/payments';
import { TablePaginated } from 'app/components/Table';
import { formatDate } from 'app/utils/dates';
import Link from 'app/components/Link';
import { log } from 'app/utils/log';
import { openFile } from 'app/utils/browser.utils';
import SectionHeader from 'app/components/SectionHeader';
import { ResponsiveSection } from 'app/components/ResponsiveSection';

export const UserPaymentsTable: React.FC = () => {
  const { userId, accessToken, isAuthenticated } = useAuthenticated();
  const loadRestData = useCallback(
    (pageSize = 20, page = 0) => apiGetUserPayments(page, pageSize, userId, accessToken),
    [userId, accessToken]
  );

  const downloadInvoice = useCallback(async (id, accessToken) => {
    try {
      const response = await apiDownloadInvoice(id, accessToken);
      log.debug('response', response);
      openFile(response, `overauto-cz-faktura-${id}`);
    } catch (err) {
      log.error(err);
    }
  }, []);

  if (!isAuthenticated) return <Unauthenticated />;

  return (
    <PageTemplate userSelect='initial'>
      <TablePaginated
        sectionTitle='Moje platby'
        columns={[
          {
            Header: props => <Typography text='ID' weight='bold' />,
            accessor: 'orderId',
          },
          {
            Header: props => <Typography text='Aktivní od' weight='bold' />,
            id: 'activeSince',
            accessor: d => d && formatDate(d.activeSince),
          },
          {
            Header: props => <Typography text='Aktivní do' weight='bold' />,
            id: 'activeTo',
            accessor: d => d && formatDate(d.activeTo),
          },
          {
            Header: props => <Typography text='Částka' weight='bold' />,
            id: 'amount',
            accessor: d => (d?.amount ? `${d?.amount / 100} Kč` : ''),
          },
          {
            Header: props => <Typography text='Položka' weight='bold' />,
            id: 'vin',
            minWidth: 110,
            accessor: d =>
              d && (
                <Link size='small' to={`/vin/${d?.vin}`}>
                  {d?.vin}
                </Link>
              ),
          },
          {
            Header: props => <Typography text='Faktura' weight='bold' />,
            id: 'fakturoidInvoiceId',
            accessor: d =>
              d?.fakturoidInvoiceId ? (
                <Link
                  size='small'
                  onClick={() => downloadInvoice(d?.fakturoidInvoiceId, accessToken)}
                >
                  {d?.fakturoidInvoiceId}
                </Link>
              ) : null,
          },
        ]}
        loadRestData={loadRestData}
        minRows={1}
        noDataText={'Litujeme, ale neevidujeme žádné záznamy.'}
        getDataFromResponse={response => response.data?.allPayments}
        sortable={false}
      />
    </PageTemplate>
  );
};

const Unauthenticated = () => (
  <PageTemplate userSelect='initial'>
    <SectionHeader title='Moje platby' />
    <ResponsiveSection mt='30px'>Prosím, přihlašte se</ResponsiveSection>
  </PageTemplate>
);
