import styled from 'styled-components/macro';
import { DialogContent } from 'app/components/Dialog/Dialog.styles';
import { flex, mediaQuery } from 'styles/shared.styles';

export const CenteredContainer = styled.div`
  align-items: flex-start;
  height: 100%;
  display: flex;
  position: absolute;
  width: 100%;
  ${mediaQuery.medium} {
    ${{ ...(flex.centerHorizontal as any) }};
  }
`;

export const CenteredDialogContent = styled(DialogContent)``;
