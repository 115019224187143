import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { VerifyVinNavBar } from './components/VerifyVinNavBar';
import { VerifyVinPublicContainer } from './components/VerifyVinPublic';
import { VerifyVinPrivate } from './components/VerifyVinPrivate';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { checkViewMode, checkVinValidity } from 'app/utils';
import { OG_META_IMAGE } from 'app/constants/meta-tags';
import LoginInject from '../LoginInject';
import { DeviceLoader } from './components/DeviceLoader';

interface IVerifyVinProps {
  isAuthorized: boolean;
  vin: string;
  pdfExportIsLoading: boolean;
}

class VerifyVinMobileApp extends React.Component<IVerifyVinProps> {
  public render() {
    const isPdfMode = checkViewMode('pdf');
    const { vin, pdfExportIsLoading, isAuthorized } = this.props;

    if (pdfExportIsLoading) {
      return (
        <>
          <VerifyVinNavBar vin={vin} />
          <AdLoader text={EnumAdLoaderTexts.PDF_IN_PROGRESS} fullscreen={true} />
        </>
      );
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Ověření VIN kódu</title>
          <meta
            name='description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta
            property='og:description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta
            property='twitter:description'
            content='Ověříme historii vybraného vozu dle vloženého VIN kódu'
          />
          <meta property='og:title' content='Ověření VIN kódu | OvěřAuto.cz' />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:image' content={OG_META_IMAGE} />
          <style>
            {`
              body {
                background: #F0F2F6 !important;
              }
            `}
          </style>
        </Helmet>
        <LoginInject />
        <DeviceLoader>
          {isAuthorized ? (
            <VerifyVinPrivate vin={vin} isValidVin={checkVinValidity(vin)} isPdfMode={isPdfMode} />
          ) : (
            <VerifyVinPublicContainer vin={vin} isValidVin={checkVinValidity(vin)} />
          )}
        </DeviceLoader>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    pdfExportIsLoading: state.ads.pdfExportIsLoading,
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyVinMobileApp);
