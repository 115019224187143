import styled from 'styled-components/macro';
import { position, color, borderRadius, space, boxShadow } from 'styled-system';
import { colors } from 'styles/shared.styles';

const bgColorMap = {
  light: colors.white,
  dark: colors.bgGray,
  primary: colors.primary,
};

const textColorMap = {
  light: colors.textDefault,
  dark: colors.white,
  primary: colors.white,
};

export const ContentOverlay = styled.div(
  {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: '0',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  props => ({
    backgroundColor: props.overlayColor,
  }),
  props =>
    !props.overlay
      ? {
          transform: ' translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          height: 'auto',
        }
      : null,
  ({ floating }) =>
    floating
      ? {
          position: 'relative',
          top: 0,
          left: 0,
          marginBottom: '25px',
          marginTop: '25px',
        }
      : null,
  position,
  space,
  color,
  borderRadius
);

export const Wrapper = styled.div(
  {
    borderRadius: '5px',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1)',
    lineHeight: 1.5,
    padding: '8px 16px',
    textAlign: 'center',
    width: '80%',
    zIndex: 10,
    maxWidth: '250px',
  },
  ({ type, theme, bgColor }) => ({
    backgroundColor: theme.colors[bgColor] || bgColorMap[type] || bgColor,
    color: textColorMap[type],
  }),
  ({ type }) => (type === 'dark' ? { border: '1px solid #D8D8D8' } : null),
  ({ floating }) =>
    floating
      ? {
          width: 'auto',
          maxWidth: '100%',
        }
      : null,
  boxShadow
);
