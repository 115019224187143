import * as Constants from 'app/constants/user';
import {
  IEstimateCarHistoryLog,
  IEstimateCarHistoryLogsResult,
} from 'app/models/EstimateCarHistory';

interface IUserReducer {
  forgotPasswordSuccess: boolean;
  forgotPasswordInProgress: boolean;
  forgotPasswordError: boolean;
  resetPasswordSuccess: boolean;
  resetPasswordInProgress: boolean;
  resetPasswordError: boolean;
  estimateCarHistoryLogs: IEstimateCarHistoryLogsResult | null;
  estimateCarHistoryLogsInProgress: boolean;
  estimateCarHistoryLogsError: boolean;
  estimateCarHistoryLog: IEstimateCarHistoryLog | null;
  estimateCarHistoryLogInProgress: boolean;
  estimateCarHistoryLogError: boolean;
  updateEstimateCarHistoryLog: any;
  updateEstimateCarHistoryLogInProgress: boolean;
  updateEstimateCarHistoryLogError: boolean;
}

const initialState: IUserReducer = {
  forgotPasswordSuccess: false,
  forgotPasswordInProgress: false,
  forgotPasswordError: false,
  resetPasswordSuccess: false,
  resetPasswordInProgress: false,
  resetPasswordError: false,
  estimateCarHistoryLogs: null,
  estimateCarHistoryLogsInProgress: false,
  estimateCarHistoryLogsError: false,
  estimateCarHistoryLog: null,
  estimateCarHistoryLogInProgress: false,
  estimateCarHistoryLogError: false,
  updateEstimateCarHistoryLog: null,
  updateEstimateCarHistoryLogInProgress: false,
  updateEstimateCarHistoryLogError: false,
};

const user = (state = initialState, action: any): IUserReducer => {
  switch (action.type) {
    case Constants.FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordInProgress: true,
        forgotPasswordError: false,
      };
    }
    case Constants.FORGOT_PASSWORD_SUCCESS: {
      const { status } = action.payload;

      return {
        ...state,
        forgotPasswordSuccess: status === 200,
        forgotPasswordInProgress: false,
        forgotPasswordError: false,
      };
    }
    case Constants.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordInProgress: false,
        forgotPasswordError: true,
      };
    }
    case Constants.RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordSuccess: false,
        resetPasswordInProgress: true,
        resetPasswordError: false,
      };
    }
    case Constants.RESET_PASSWORD_SUCCESS: {
      const { status } = action.payload;

      return {
        ...state,
        resetPasswordSuccess: status === 200,
        resetPasswordInProgress: false,
        resetPasswordError: false,
      };
    }
    case Constants.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        resetPasswordSuccess: false,
        resetPasswordInProgress: false,
        resetPasswordError: true,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOGS: {
      return {
        ...state,
        estimateCarHistoryLogs: null,
        estimateCarHistoryLogsInProgress: true,
        estimateCarHistoryLogsError: false,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOGS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        estimateCarHistoryLogs: data,
        estimateCarHistoryLogsInProgress: false,
        estimateCarHistoryLogsError: false,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOGS_FAIL: {
      return {
        ...state,
        estimateCarHistoryLogs: null,
        estimateCarHistoryLogsInProgress: false,
        estimateCarHistoryLogsError: true,
      };
    }
    case Constants.RESET_ESTIMATE_CAR_HISTORY_LOGS: {
      return {
        ...state,
        estimateCarHistoryLogs: null,
        estimateCarHistoryLogsInProgress: false,
        estimateCarHistoryLogsError: false,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOG: {
      return {
        ...state,
        estimateCarHistoryLog: null,
        estimateCarHistoryLogInProgress: true,
        estimateCarHistoryLogError: false,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOG_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        estimateCarHistoryLog: data,
        estimateCarHistoryLogInProgress: false,
        estimateCarHistoryLogError: false,
      };
    }
    case Constants.LOAD_ESTIMATE_CAR_HISTORY_LOG_FAIL: {
      return {
        ...state,
        estimateCarHistoryLog: null,
        estimateCarHistoryLogInProgress: false,
        estimateCarHistoryLogError: true,
      };
    }
    case Constants.CREATE_ESTIMATE_CAR_HISTORY_LOG: {
      return {
        ...state,
        estimateCarHistoryLogInProgress: true,
        estimateCarHistoryLogError: false,
      };
    }
    case Constants.CREATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        estimateCarHistoryLog: data,
        estimateCarHistoryLogInProgress: false,
        estimateCarHistoryLogError: false,
      };
    }
    case Constants.CREATE_ESTIMATE_CAR_HISTORY_LOG_FAIL: {
      return {
        ...state,
        estimateCarHistoryLog: null,
        estimateCarHistoryLogInProgress: false,
        estimateCarHistoryLogError: true,
      };
    }
    case Constants.UPDATE_ESTIMATE_CAR_HISTORY_LOG: {
      return {
        ...state,
        updateEstimateCarHistoryLogInProgress: true,
        updateEstimateCarHistoryLogError: false,
      };
    }
    case Constants.UPDATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        updateEstimateCarHistoryLog: data,
        updateEstimateCarHistoryLogInProgress: false,
        updateEstimateCarHistoryLogError: false,
      };
    }
    case Constants.UPDATE_ESTIMATE_CAR_HISTORY_LOG_FAIL: {
      return {
        ...state,
        updateEstimateCarHistoryLog: null,
        updateEstimateCarHistoryLogInProgress: false,
        updateEstimateCarHistoryLogError: true,
      };
    }
    default:
      return state;
  }
};

export default user;
