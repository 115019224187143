import * as React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as FaSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import Typography from 'app/components/Typography';
import { colors } from 'styles/shared.styles';
import * as S from './Table.styles';

export interface ITableCol<T> {
  title?: string;
  fieldName: string;
  flex?: number;
  width?: string;
  render?: (row: T, filedName: string, rowIndex: number, colIndex: number) => React.ReactNode;
}

type IRowRenderer = (key: any, children: React.ReactNode) => React.ReactNode;

interface ITableProps<T> {
  columns: Array<ITableCol<T>>;
  data: any[];
  dataIsLoading?: boolean;
  fluid?: boolean;
  idField: string;
  indexColumn?: boolean;
  indexColumnName?: string;
  rowExtra?: string;
  stripped?: boolean;
  className?: string;
  rowDecorator?: (row: T) => IRowRenderer | null;
  renderFooter?: () => React.ReactNode;
}

interface ITableState<T> {
  columns: Array<ITableCol<T>>;
}

class Table<T> extends React.Component<ITableProps<T>, ITableState<T>> {
  public static defaultProps = {
    stripped: false,
    indexColumnName: 'Order',
    fluid: false,
  };

  constructor(props) {
    super(props);

    const { columns, indexColumn, indexColumnName } = this.props;

    if (indexColumn) {
      this.state = {
        columns: [
          {
            title: indexColumnName as string,
            fieldName: 'index',
          },
          ...columns,
        ],
      };
    } else {
      this.state = {
        columns,
      };
    }
  }

  public componentDidUpdate(prevProps: ITableProps<T>) {
    if (this.props.columns !== prevProps.columns) {
      this.setState({
        columns: this.props.columns,
      });
    }
  }

  public render() {
    const { renderFooter, dataIsLoading, className = '' } = this.props;

    return (
      <S.StyledTable className={className}>
        <S.TableLayout>
          <S.TableHeader>{this.renderHeader()}</S.TableHeader>
          <div>{this.renderBody()}</div>
          {renderFooter && <S.TableFooter>{renderFooter()}</S.TableFooter>}
        </S.TableLayout>
        {dataIsLoading && (
          <S.TableOverflow>
            <FontAwesomeIcon icon={FaSpinner} style={{ color: colors.white }} spin={true} />
          </S.TableOverflow>
        )}
      </S.StyledTable>
    );
  }

  private getColumnStyle(column: ITableCol<T>) {
    return {
      flex: column.flex ? column.flex : 1,
    };
  }

  private renderHeader = () => {
    const columns = this.state.columns;
    const headerCells = columns.map((col, index) => (
      <S.TableColumn key={index} style={this.getColumnStyle(col)} width={col?.width}>
        {col.title && <Typography text={col.title} weight='bold' />}
      </S.TableColumn>
    ));
    return <S.TableRow header={true}>{headerCells}</S.TableRow>;
  };

  private renderBody = () => {
    const { data, idField, fluid, stripped, rowDecorator } = this.props;

    const columns = this.state.columns;
    return data.map((item, index) => {
      item.index = index + 1;
      const rowContent = columns.map((col, colIndex) => (
        <S.TableColumn
          key={`${item.index} ${colIndex}`}
          style={this.getColumnStyle(col)}
          data-cy={`table-column-${item[idField]}-${item[col.fieldName]}-${item.index}`}
          width={col?.width}
        >
          {col.render ? (
            col.render(item, col.fieldName, index, colIndex)
          ) : (
            <Typography text={item[col.fieldName]} size={'small'} />
          )}
        </S.TableColumn>
      ));
      if (rowDecorator) {
        const decorator = rowDecorator(item);
        if (decorator) return decorator(item[idField], rowContent);
      }
      return (
        <S.TableRow
          key={`${item[idField]}-${index}`}
          data-cy={`table-row-${item[idField]}-${index}`}
          fluid={fluid}
          striped={stripped && index % 2 === 0}
        >
          {rowContent}
        </S.TableRow>
      );
    });
  };
}

export default Table;
