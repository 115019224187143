import styled from 'styled-components/macro';
import { flex, mediaQuery, colors } from 'styles/shared.styles';

export const Header = styled.div`
  ${{ ...(flex.vertical as any) }};
  ${{ ...flex.centerHorizontalV }};
  ${{ ...flex.spaceBetween }};
  padding: 10px;
  border-bottom: ${(props: any) =>
    props.allowBottomBorder ? '1px solid rgba(151, 151, 151, 0.5)' : 'none'};
  background-color: ${(props: any) => (props.bgColor ? props.bgColor : 'transparent')};

  ${mediaQuery.medium} {
    ${{ ...(flex.horizontal as any) }};
    padding: 20px 0;
  }

  ${mediaQuery.print} {
    padding: 20px 0;
    ${{ ...(flex.horizontal as any) }};
    ${{ ...flex.centerHorizontalV }};
  }
`;

export const HeaderLeft = styled.div({
  ...flex.vertical,
  ...flex.centerHorizontal,
  [mediaQuery.small]: {
    ...flex.alignChildrensLeft,
  },
  [mediaQuery.medium]: {
    ...flex.horizontal,
    ...flex.centerHorizontalV,
  },
  [mediaQuery.print]: {
    ...flex.horizontal,
    ...flex.centerHorizontalV,
  },
} as any);

export const HeadingContainer = styled.div({
  [mediaQuery.medium]: {
    marginRight: '30px',
  },
  [mediaQuery.print]: {
    marginRight: '30px',
    flex: '0 0 auto',
  },
  h2: {
    marginBottom: 0,
  },
});

export const SubHeaderTitleContainer = styled.div({
  color: colors.textGrey,
  margin: '0 10px 10px 0',
  letterSpacing: '-0.32px',
  [mediaQuery.medium]: {
    marginBottom: '0',
  },
  [mediaQuery.print]: {
    marginBottom: '0',
  },
});

export const SubHeaderValueContainer = styled.div({
  backgroundColor: colors.white,
  borderRadius: '1px',
  color: colors.textDefault,
  letterSpacing: '-0.32px',
  lineHeight: '28px',
  padding: '1px 12px',
  [mediaQuery.medium]: {
    marginBottom: '0',
  },
  [mediaQuery.print]: {
    marginBottom: '0',
  },
});

export const SubheaderSuffixTextContainer = styled.div({
  marginLeft: '10px',
});

export const HeaderRight = styled.div({
  maxWidth: '349px',
  lineHeight: '17px',
  textAlign: 'left',
});

export const SubheaderContainer = styled.div({
  ...(flex.horizontal as any),
  ...flex.centerVertical,
});
