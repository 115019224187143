import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { PageTemplate } from 'app/templates/PageTemplate';
import { MakePayment } from 'app/components/MakePayment';
import { useVin, useAuthenticated, useCheckLoggedIn /*, useNotification*/ } from 'app/hooks';
import { createPayment } from 'app/api';
import { EnumPaymentCategory } from 'app/models/Payment';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { formatToDateUtc } from 'app/utils/dates';

export const PagePayVinReport: React.FC = () => {
  useCheckLoggedIn('Pro tuto akci je nutné se přihlásit.');
  const vin = useVin();
  const [isMakePaymentLoading, setIsMakePaymentLoading] = useState(false);
  const { accessToken } = useAuthenticated();

  const { data, isLoading, isError } = useQuery(
    `api-create-payment-vin-premium-${accessToken}-${vin}`,
    () =>
      createPayment(vin, EnumPaymentCategory.VIN_PREMIUM, accessToken).catch(err =>
        Promise.reject(err)
      ),
    {
      retry: false,
    }
  );

  if (isError) {
    return (
      <PageTemplate mt={['20px', '110px']}>
        <div>
          Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte.
          Děkujeme!
        </div>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate mt={['20px', '110px']}>
      <LoadingContainer isLoading={isLoading || isMakePaymentLoading}>
        {!isError ? (
          <MakePayment
            gwUrl={data?.gwUrl}
            setLoading={setIsMakePaymentLoading}
            vin={vin}
            paymentInfo={`Po zakoupení vás ihned přesměrujeme na PREMIUM report. Report bude dostupný do ${formatToDateUtc(
              data?.activeTo
            )}.`}
            paymentType={EnumPaymentCategory.VIN_PREMIUM}
          />
        ) : (
          <div>
            Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte.
            Děkujeme!
          </div>
        )}
      </LoadingContainer>
    </PageTemplate>
  );
};
