import React from 'react';

import { useTypedSelector } from 'app/store';
import { DataLoading } from 'app/components/DataLoading';
import { useAuthenticated } from 'app/hooks';

export const SmartLoader: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthenticated();
  const adDetailsIsLoading = useTypedSelector(state => state.ads.adsByVinListIsLoading);
  const kmHistoryIsLoading = useTypedSelector(state => state.ads.kmHistoryIsLoading);
  const kmHistoryNotFoundVinIsLoading = useTypedSelector(
    state => state.ads.kmHistoryNotFoundVinIsLoading
  );
  const residualValueByVinIsLoading = useTypedSelector(
    state => state.ads.residualValueByVinIsLoading
  );
  const stolenCarsIsLoading = useTypedSelector(state => state.ads.stolenCarsIsLoading);
  const isDataLoading =
    adDetailsIsLoading ||
    kmHistoryIsLoading ||
    kmHistoryNotFoundVinIsLoading ||
    residualValueByVinIsLoading ||
    stolenCarsIsLoading;

  if (!isAuthenticated) return <>{children}</>;
  return isDataLoading ? <DataLoading /> : <>{children}</>;
};
