import React from 'react';

import { TextBadge } from 'app/components/TextBadge';
import Typography from 'app/components/Typography';
import { Row, TRowProps } from 'app/components/Row';
import { colors } from 'styles/shared.styles';

interface IVinTextBadgeProps extends TRowProps {
  vin: string;
  theme?: 'light' | 'dark';
}

export const VinTextBadge: React.FC<IVinTextBadgeProps> = ({
  vin,
  theme = 'dark',
  ...containerProps
}) => {
  const badgeTextColor = theme === 'dark' ? 'white' : 'textDefault';
  const badgeBgColor = theme === 'dark' ? 'bgGray' : 'white';
  const vinTextColor = theme === 'dark' ? colors.textSecondary : colors.white;
  return (
    <Row {...containerProps}>
      <TextBadge textColor={badgeTextColor} bgColor={badgeBgColor}>
        VIN
      </TextBadge>
      <Typography
        text={vin}
        size='base'
        color={vinTextColor}
        margin='0 0 0 8px'
        data-cy='summary-availability-vin'
      />
    </Row>
  );
};
