import * as React from 'react';
import SectionHeader from 'app/components/SectionHeader';
import Typography from 'app/components/Typography';
import { Container, ContentWrapper, NoResultsContainer } from 'app/components/_shared_';
import { colors } from 'styles/shared.styles';

interface IDataLoadingErrorProps {
  text?: string;
  sectionHeaderText?: string;
}

export const DataLoadingError: React.SFC<IDataLoadingErrorProps> = ({
  text = 'Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte. Děkujeme!',
  sectionHeaderText = '',
}) => {
  return (
    <Container>
      <ContentWrapper>
        {sectionHeaderText && <SectionHeader title={sectionHeaderText} />}
        <NoResultsContainer>
          <Typography text={text} size='base' weight='medium' color={colors.textDefault} />
        </NoResultsContainer>
      </ContentWrapper>
    </Container>
  );
};

DataLoadingError.defaultProps = {};
