import * as React from 'react';
import Heading from '../components/Heading';
import Page from '../components/Page/Page';

class PrivacyPolicy extends React.Component {
  public render() {
    return (
      <Page
        textAlign='left'
        itemsAlign='left'
        componentName='privacy-policy'
        documentTitle='Zásady ochrany osobních údajů'
      >
        <div className='row'>
          <div className='col-xs-offset-2 col-xs-8'>
            <Heading level={1}>Souhlas s poskytnutím osobních údajů</Heading>
            <div className='page__section'>
              <Heading level={2}>Správce osobních údajů</Heading>
              <p className='page__paragraph'>
                Správcem osobních údajů je: Galli Company s.r.o. (IČ: 04522818), se sídlem
                Radimovická 1773/15, 149 00 Praha 4.
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>Zpracovatel osobních údajů</Heading>
              <p className='page__paragraph'>
                Zpracovatelem osobních údajů je: Galli Company s.r.o. (IČ: 04522818), se sídlem
                Radimovická 1773/15, 149 00 Praha 4. Osobní údaje je oprávněn také zpracovávat
                kterýkoliv zaměstnanec správce.
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>1. Text souhlasu</Heading>
              <p className='page__paragraph'>
                <strong className='page__strong'>
                  Zaškrtnutím políčka před odesláním objednávky
                </strong>{' '}
                ve smyslu ust. § 5 odst. 2 zákona č. 101/2000 Sb., o ochraně osobních údajů, ve
                znění pozdějších předpisů (dále též jako{' '}
                <strong className='page__strong'>„zákon o ochraně osobních údajů</strong>“), a v
                souladu s čl. 6 odst. 1 písm. a) nařízení Evropského Parlamentu a Rady (EU) 2016/679
                ze dne 27. dubna 2016, o ochraně fyzických osob v souvislosti se zpracováním
                osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné
                nařízení o ochraně osobních údajů), které nabude účinnosti dne 25. 5. 2018,{' '}
                <strong className='page__strong'>
                  souhlasím se shromažďováním, uchováním a zpracováním mých osobních údajů mnou
                  poskytnutých správci.
                </strong>
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>2. Jaké údaje budou nebo mohou být zpracovány</Heading>
              <ul className='page__list'>
                <li>Jméno a příjmení, popřípadě obchodní firma,</li>
                <li>bydliště (ulice, č. p./č. o, město, PSČ, stát) nebo sídlo,</li>
                <li>datum narození,</li>
                <li>e-mailová adresa,</li>
                <li>telefonní číslo,</li>
                <li>číslo bankovního účtu,</li>
                <li>IP adresa</li>
              </ul>
            </div>
            <div className='page__section'>
              <Heading level={2}>
                3. K jakým účelům budou osobní údaje zpracovány a uchovány
              </Heading>
              <h4 className='page__h4'>3.1. Osobní údaje, kterými jsou:</h4>
              <ul>
                <li>jméno a příjmení, popřípadě obchodní firma,</li>
                <li>bydliště (ulice, č. p./č. o, město, PSČ, stát) nebo sídlo,</li>
                <li>e-mailová adresa,</li>
                <li>telefonní číslo,</li>
                <li>číslo bankovního účtu,</li>
                <li>IP adresa,</li>
              </ul>
              <p>
                <strong className='page__strong'>
                  bude správce shromažďovat, uchovávat a zpracovávat pro následující účely:{' '}
                </strong>
                <span>pro zasílání obchodních a marketingových nabídek a oznámení správce.</span>
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>4. Doba, po kterou budou osobní údaje uchovány</Heading>
              <p className='page__paragraph'>
                Správce bude uchovávat osobní údaje po dobu nezbytně nutnou. Nejdéle bude správce
                uchovávat osobní údaje po dobu 10 roků.
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>
                5. Jakým způsobem budou osobní údaje zpracovány a uchovány?
              </Heading>
              <p className='page__paragraph'>Osobní údaje budou zpracovány:</p>
              <p className='page__paragraph'>
                strojově (automatizovaně) prostřednictvím počítačů a počítačových programů; v
                písemné podobě;
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>
                6. Práva subjektu údajů, informace a přístup k osobním údajům
              </Heading>
              <p className='page__paragraph'>
                Jako subjekt údajů mám právo na{' '}
                <strong className='page__strong'>přístup k mým osobním údajům</strong>, mám právo na
                jejich <strong className='page__strong'>opravu nebo výmaz</strong>, popřípadě{' '}
                <strong className='page__strong'>omezení zpracování</strong>. Mám právo požadovat
                informaci,{' '}
                <strong className='page__strong'>jaké osobní údaje jsou zpracovávány</strong> a mám
                právo požadovat{' '}
                <strong className='page__strong'>
                  vysvětlení ohledně zpracování osobních údajů
                </strong>
                .
              </p>
              <p className='page__paragraph'>
                Dále mám právo vznést{' '}
                <strong className='page__strong'> námitku proti zpracování</strong>, jakož i práva
                na <strong className='page__strong'>přenositelnost údajů</strong> (tj. právo získat
                osobní údaje, které se mne týkají, které jsem poskytl správci, ve strukturovaném,
                běžně používaném a strojově čitelném formátu, blíže viz. čl. 20 GDPR).
              </p>
              <p className='page__paragraph'>
                Mám právo <strong className='page__strong'>souhlas kdykoli odvolat</strong>, aniž by
                tím byla dotčena zákonnost zpracování založená na souhlasu uděleném před jeho
                odvoláním, pokud je zpracování založeno na čl. 6 odst. 1 písm. a) nebo čl. 9 odst. 2
                písm. a) GDPR. To znamená, že takové právo nemám zejména tehdy, pokud je zpracování
                nezbytné pro splnění právní povinnosti, která se na správce vztahuje (zejména
                povinnosti vztahující se k LVTČ).
              </p>
              <p className='page__paragraph'>
                Jako subjekt údajů jsem informován o tom, že souhlas s poskytnutím údajů mohu
                odvolat vyplněním formuláře na internetových stránkách na adrese{' '}
                <a
                  className='page__link'
                  target='_blank'
                  href='http://www.gallico.cz'
                  rel='noopener noreferrer'
                >
                  www.gallico.cz
                </a>{' '}
                nebo odesláním e-mailu na adresu{' '}
                <a className='page__link' href='mailto:info@overauto.cz'>
                  info@overauto.cz
                </a>
                .
              </p>
              <p className='page__paragraph'>
                Jako subjekt údajů mám právo požádat o informaci o zpracování mých osobních údajů,
                přičemž tuto informaci je správce povinen mi bez zbytečného odkladu předat. Obsah
                informace je dán ustanovením § 12 odst. 2 zákona č. 101/2000 Sb., o ochraně osobních
                údajů, ve znění pozdějších předpisů. Správce má právo za poskytnutí informace
                požadovat přiměřenou úhradu nepřevyšující náklady nezbytné na poskytnutí informace.
              </p>
              <p className='page__paragraph'>
                Jako subjekt údajů mám v případě pochybností o dodržování povinností souvisejících
                se zpracováním osobních údajů právo obrátit se na správce nebo na Úřad pro ochranu
                osobních údajů.
              </p>
              <p className='page__paragraph'>
                Správce prohlašuje, že při zpracování{' '}
                <strong className='page__strong'> nedochází k automatizovanému rozhodování</strong>{' '}
                včetně <strong className='page__strong'>profilování</strong>, uvedenému v čl. 22
                odst. 1 a 4 GDPR. Správce prohlašuje, že osobní údaje nejsou zpracovávány pro účely
                vědeckého či historického výzkumu nebo pro statistické účely. Správce prohlašuje, že
                bude osobní údaje zpracovávat: strojově (automatizovaně) prostřednictvím počítačů a
                počítačových programů; v písemné podobě.
              </p>
            </div>
            <div className='page__section'>
              <Heading level={2}>7. Závěrečná ustanovení</Heading>
              <p className='page__paragraph'>
                Jako subjekt údajů prohlašuji, že jsem si vědom(a) svých práv podle ust. § 12 a § 21
                zákona o ochraně osobních údajů a svých práv podle GDPR.
              </p>
              <p className='page__paragraph'>
                Prohlašuji, že všechny poskytnuté údaje jsou přesné a pravdivé a jsou poskytovány
                dobrovolně.
              </p>
              <p className='page__paragraph'>
                Správce prohlašuje, že bude shromažďovat osobní údaje v rozsahu nezbytném pro
                naplnění účelu a zpracovávat je bude pouze v souladu s účelem, k němuž byly
                shromážděny.
              </p>
              <p className='page__paragraph'>
                Tento souhlas je svobodný a vědomý projev vůle subjektu údajů, jehož obsahem je
                svolení subjektu údajů se zpracováním osobních údajů.
              </p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default PrivacyPolicy;
