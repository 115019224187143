import { colors, flex, fontFamily, mediaQuery } from '../../../styles/shared.styles';
import styled from 'styled-components/macro';

export const SegmentedControlContainer = styled<any>('div')(
  {
    ...flex.flex,
    '.tabs__tab-list-item': {
      backgroundColor: colors.white,
      minWidth: '120px',
      textAlign: 'center',
      borderRight: '1px solid rgba(151, 151, 151, 0.5)',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '.tabs__trigger': {
      padding: '10px 30px',
      fontFamily: fontFamily.medium,
      color: '#AAAFB5',
      fontSize: '17px',
      textDecoration: 'none',
      '&.is-selected': {
        color: '#0091FF',
      },
    },
    '.tabs__panel': {
      '&.is-hidden': {
        display: 'none',
      },
    },
    '.tabs__tab-list': {
      ...flex.horizontal,
      ...flex.centerHorizontal,
      boxShadow: '0 2px 20px 0 rgba(0,0,0,0.04)',
      borderRadius: '18px',
      overflow: 'hidden',
      margin: '28px 0',
    } as any,
    '.tabs': {
      ...flex.flex,
      ...flex.vertical,
      ...flex.alignChildrensCenter,
      width: '100%',
    } as any,
    '.tabs__panels, .tabs__panel': {
      width: '100%',
    },
  },
  ({ tablistClickEnabled }: any) =>
    !tablistClickEnabled
      ? {
          '.tabs__trigger': {
            pointerEvents: 'none',
          },
        }
      : null,
  ({ theme }) => ({
    [mediaQuery.print]: {
      '.tabs__tab-list': {
        border: theme.borders.base,
      },
    },
  })
);
