import styled from 'styled-components/macro';
import { colors } from 'styles/shared.styles';
import { IStyledButtonLinkProps } from './LinkButton';

export const StyledLinkButton = styled('button')(
  {
    color: colors.primary,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: '0',
    textOverflow: 'ellipsis',
    padding: 0,
    ':hover': {
      textDecoration: 'underline',
    },
  },
  (props: IStyledButtonLinkProps) =>
    props.size === 'small' ? { fontSize: '0.8rem' } : { fontSize: '1rem' },
  (props: IStyledButtonLinkProps) =>
    props.btnStyle === 'secondary' ? { color: colors.white, textDecoration: 'underline' } : null
);
