import styled from 'styled-components/macro';
import defaultAdImageUrl from 'assets/images/default-car-preview@2x.png';

interface ICarImageProps {
  src: string;
}

const CarImage = styled('div')((props: Partial<ICarImageProps>) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#A0A3A8',
  // backgroundImage: `url(${props.src}), url(${defaultAdImageUrl})`,
  backgroundImage: `url(${defaultAdImageUrl})`, // temp solution to hide sauto images
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  overflow: 'hidden',
  borderRadius: '10px 10px 0 0',
}));

export default CarImage;
