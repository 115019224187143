import { usePremiumRoute } from './usePremiumRoute';
import { useAuthenticated } from './useAuthenticated';
import { useVin } from './useVin';
import { checkVinValidity } from 'app/utils';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

interface IVerifyVinRouteHook {
  accessToken: string;
  isAuthenticated: boolean;
  isMobileApp: boolean;
  isPremiumRoute: boolean;
  isValidVin: boolean;
  isPremiumVersion: boolean;
  vin: string;
}

export const useVerifyVinRoute = (): IVerifyVinRouteHook => {
  const vin = useVin();
  const { isAuthenticated, accessToken } = useAuthenticated();
  const isMobileApp = MobileAppUtils.isInApp();
  const isPremiumRoute = usePremiumRoute();
  const isValidVin = checkVinValidity(vin);
  const isPremiumVersion = isPremiumRoute || isMobileApp;
  return {
    accessToken,
    isAuthenticated,
    isMobileApp,
    isPremiumRoute,
    isValidVin,
    isPremiumVersion,
    vin,
  };
};
