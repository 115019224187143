import styled from 'styled-components/macro';
import Heading from 'app/components/Heading';
import { colors } from 'styles/shared.styles';
import { mediaQuery } from 'styles/shared.styles';

export const MarketPriceContainer = styled.div`
  border-radius: 9px;
  background-color: #4a5560;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 26px;
  color: ${colors.white};
  position: relative;

  ${mediaQuery.print} {
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 0.5);
    box-shadow: none;
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${colors.white};

  ${mediaQuery.print} {
    color: ${colors.textDefault};
  }
`;
