export class MobileAppUtils {
  public static isInApp(): boolean {
    return !!(window as any).ReactNativeWebView /*|| true*/;
  }

  public static gotoLogin() {
    MobileAppUtils.sendMessage({ action: 'authorization' });
  }

  public static loadingCarDone() {
    MobileAppUtils.sendMessage({ action: 'loading-done' });
  }

  public static loadingCarFailed(error: string) {
    MobileAppUtils.sendMessage({
      action: 'loading-failed',
      loadingFailed: {
        error,
      },
    });
  }

  protected static sendMessage(message: any) {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }
}
