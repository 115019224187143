import * as React from 'react';
import * as S from './EstimateCarHistoryLogs.styles';
import * as moment from 'moment';
import { Box, Flex } from 'reflexbox/styled-components';
import { BasicTable } from 'app/components/Table';

import Link from 'app/components/Link';
import Typography from 'app/components/Typography';
import SectionHeader from 'app/components/SectionHeader';
import { withSpaces } from 'app/utils/number';
import { IEstimateCarHistoryLogsResult } from 'app/models/EstimateCarHistory';

interface IEstimateCarHistoryLogsProps {
  data: IEstimateCarHistoryLogsResult;
  loadData: (from: number, limit: number, page: number) => Promise<any>;
}

interface IEstimateCarHistoryLogsState {}

export class EstimateCarHistoryLogs extends React.Component<
  IEstimateCarHistoryLogsProps,
  IEstimateCarHistoryLogsState
> {
  public static defaultProps: Partial<IEstimateCarHistoryLogsProps> = {
    data: { docs: [], total: 0, from: 0, limit: 50, pages: 1, page: 0 },
  };

  public render() {
    const { data } = this.props;
    if (!data) {
      return (
        <>
          <SectionHeader title='Historie ocenění' />
          <Flex width='100%'>
            <Box my={20} width='100%' alignItems='center'>
              <Typography text='Litujeme, ale neevidujeme žádné záznamy.' />
            </Box>
          </Flex>
        </>
      );
    }

    return (
      <S.Container>
        <S.TableContainer>
          <BasicTable
            sectionTitle='Historie ocenění'
            columns={[
              {
                Header: props => <Typography text='Datum' weight='bold' />,
                id: 'createdAt',
                accessor: d =>
                  d &&
                  moment(d.createdAt)
                    .local()
                    .format('DD.MM.YYYY'),
              },
              {
                Header: props => <Typography text='Značka' weight='bold' />,
                accessor: 'brand',
              },
              {
                Header: props => <Typography text='Model' weight='bold' />,
                accessor: 'model',
              },
              {
                Header: props => <Typography text='Rok' weight='bold' />,
                accessor: 'year',
              },
              {
                Header: props => <Typography text='Najeto [km]' weight='bold' />,
                id: 'km',
                accessor: d => d && withSpaces(d.km),
              },
              {
                Header: props => <Typography text='Cena [Kč]' weight='bold' />,
                id: 'marketPrice',
                accessor: d => (d && withSpaces(d.marketPrice)) || '-',
              },
              {
                Header: props => <Typography text='Detail' weight='bold' />,
                id: 'detail',
                accessor: d =>
                  d && (
                    <Link to={`/oceneni/${d._id}`} size='small'>
                      otevřít
                    </Link>
                  ),
              },
            ]}
            data={data.docs}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            page={data.page}
            pages={data.pages}
            pageSize={data.limit}
            resizable={false}
          />
        </S.TableContainer>
      </S.Container>
    );
  }

  private onPageChange = page => {
    const { loadData } = this.props;
    const from = page * this.props.data.limit;
    const limit = this.props.data.limit;
    loadData(from, limit, page);
  };

  private onPageSizeChange = (pageSize, page) => {
    const { loadData } = this.props;
    const from = page * pageSize;
    const limit = pageSize;
    loadData(from, limit, page);
  };
}
