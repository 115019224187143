import * as React from 'react';
import styled from 'styled-components/macro';
import { Transition, animated } from 'react-spring/dist/web.js';
import Typography from './Typography';
import { colors } from '../../styles/shared.styles';

const StyledErrorMessage = styled('div')(
  {
    paddingTop: '4px',
    minHeight: '20px',
  },
  (props: IErrorMessageProps) =>
    props.textAlign === 'right' ? { textAlign: 'right' } : { textAlign: 'left' }
);

export interface IErrorMessageProps {
  message?: string;
  name?: string;
  isVisible?: boolean;
  textAlign?: 'left' | 'right';
}

class ErrorMessage extends React.PureComponent<IErrorMessageProps, any> {
  public static defaultProps: Partial<IErrorMessageProps> = {
    textAlign: 'right',
  };

  public render() {
    const { message, textAlign, isVisible, ...props } = this.props;

    return (
      <StyledErrorMessage textAlign={textAlign} {...props}>
        <Transition
          native={true}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          {...this.props}
        >
          {isVisible && message ? this.renderMessage : null}
        </Transition>
      </StyledErrorMessage>
    );
  }

  private renderMessage = style => {
    const { message, textAlign, name } = style;

    return (
      <animated.div style={{ ...style }}>
        <Typography
          text={message}
          size='small'
          color={colors.red}
          weight='bold'
          textAlign={textAlign}
          data-cy={`error-msg-${name}`}
        />
      </animated.div>
    );
  };
}

export default ErrorMessage;
