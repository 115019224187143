import * as React from 'react';

export class DeviceLoader extends React.Component {
  public readonly state = {
    startLoading: false,
  };

  public componentDidMount() {
    if ((window as any).deviceId) {
      this.setState({
        startLoading: true,
      });
    } else {
      (window as any).startLoading = () => {
        this.setState({
          startLoading: true,
        });
      };
    }
  }

  public render() {
    if (this.state.startLoading) {
      return this.props.children;
    }
    return null;
  }
}
