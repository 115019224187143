import React from 'react';
import { ReactQueryCacheProvider, ReactQueryConfigProvider } from 'react-query';
import { queryConfig } from 'app/api/rest';

import { AppRouter, DocumentHead } from 'app/routes';
import { GlobalStyle } from 'styles/global.styles';
import { usePageViews } from 'app/hooks';
import { queryCache } from 'app/api';

export const App: React.FC = () => {
  usePageViews();
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <GlobalStyle />
        <DocumentHead />
        <AppRouter />
      </ReactQueryCacheProvider>
    </ReactQueryConfigProvider>
  );
};
