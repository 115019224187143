import Heading from '../../Heading';
import styled from 'styled-components/macro';
import { flex, mediaQuery, fontSize } from '../../../../styles/shared.styles';

import iconDropdownWhite from 'assets/images/icon-dropdown-white.svg';

export const Wrapper = styled('div')({
  width: '100%',
  backgroundColor: 'white',
  boxShadow: '0 3px 4px 0 rgba(0,0,0,0.01)',
  userSelect: 'none',
  [mediaQuery.print]: {
    display: 'none',
  },
  [mediaQuery.medium]: {
    minHeight: '64px',
    height: '64px',
  },
});

export const CarWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  [mediaQuery.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StyledHeading = styled(Heading)({
  display: 'flex',
  alignItems: 'center',
  [mediaQuery.medium]: {
    marginBottom: 0,
  },
});

export const NavigationWrapper = styled('ul')({
  display: 'none',
  flexWrap: 'wrap',
  // ...flex.centerHorizontalH,
  ...flex.centerHorizontalV,
  padding: '0 10px',
  transition: '0.2s ease-in-out',
  [mediaQuery.medium]: {
    display: 'flex !important',
    transition: 'none',
    justifyContent: 'initial',
    marginLeft: '24px',
    padding: 0,
    flexWrap: 'initial',
  },
});

export const BrandLogoTitleWrapper = styled('div')({
  display: 'none',
  [mediaQuery.medium]: {
    ...flex.flex,
    ...flex.centerHorizontal,
    minHeight: '64px',
    justifyContent: 'initial',
    flexDirection: 'row',
    flex: '1 1 0',
    maxWidth: '400px',
  },
});

export const SelectedTabTitleContainer = styled.div`
  ${{ ...flex.flex }};
  ${{ ...flex.centerHorizontal }};
  height: 48px;
  background-color: #1780fa;
  width: 100%;
  ${mediaQuery.medium} {
    display: none;
  }
`;

export const SelectedTabTitle = styled.div`
  ${{ ...flex.flex }};
  ${{ ...flex.centerHorizontalV }};
  color: white;
  height: 29px;
  width: 200px;
  cursor: pointer;
  padding: 5px 17px;
  border-radius: 13px;
  margin: 0 auto;
  border: 1px solid #fff;
  font-size: ${fontSize.small};
  position: relative;
`;

export const SelectedTabIcon = styled.div`
  width: 12px;
  height: 14px;
  position: absolute;
  right: 17px;
  top: 10px;
  background: url(${iconDropdownWhite}) transparent no-repeat;
`;
