import * as React from 'react';
import * as zenscroll from 'zenscroll';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';
import { Flex, Box } from 'reflexbox/styled-components';
import * as faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';
import * as faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { typography, TypographyProps } from 'styled-system';

import NotificationType from '../models/NotificationType';
import { colors, fontSize, fontFamily, mediaQuery } from 'styles/shared.styles';

import { popNotification } from 'app/actions/app';

interface IOwnProps {
  id: string;
  text: string;
  type: string; // NotificationType
  size?: 'medium' | 'normal' | 'small' | 'mini' | 'big';
  timeout?: number;
  bgColor?: string;
  rightComponent?: React.ReactNode;
  sticky?: boolean;
  textFontFamily?: string;
  centered?: boolean;
  className?: string;
}

interface IStateDispatchProps {
  popNotification: (key: string) => void;
}

type TProps = IOwnProps & IStateDispatchProps & TypographyProps;

class Notification extends React.Component<TProps> {
  public static defaultProps: { size: IOwnProps['size']; type: IOwnProps['type'] } = {
    size: 'normal',
    type: NotificationType.info,
  };

  public notificationRef: React.ReactNode;

  public setNotificationRef = el => (this.notificationRef = el);

  public componentDidMount() {
    this.scrollToNotification();
    if (this.props.timeout && this.props.timeout > 0) {
      window.setTimeout(() => {
        this.props.popNotification(this.props.id);
      }, this.props.timeout);
    }
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text || prevProps.type !== this.props.type) {
      this.scrollToNotification();
    }
  }

  public render() {
    const {
      text,
      size,
      type,
      bgColor,
      rightComponent,
      sticky = false,
      textFontFamily = fontFamily.regular,
      centered = false,
      lineHeight,
      id,
      textAlign,
      className
    } = this.props;
    let faIcon;
    switch (type) {
      case NotificationType.error:
        faIcon = faExclamationCircle;
        break;
      case NotificationType.warning:
        faIcon = faExclamationCircle;
        break;
      case NotificationType.info:
        faIcon = faInfoCircle;
        break;
      default:
        faIcon = null;
    }

    return (
      <StyledNotification
        innerRef={this.setNotificationRef}
        size={size}
        type={type}
        bgColor={bgColor}
        sticky={sticky}
        textFontFamily={textFontFamily}
        data-cy={`notification-${id}`}
        lineHeight={lineHeight}
        className={className}
      >
        <Flex
          width='100%'
          flexDirection={['column', 'row', 'row']}
          alignItems='center'
          justifyContent={centered ? 'center' : ['center', 'space-between', 'space-between']}
          p={['20px 10px', '10px']}
          maxWidth='1200px'
          margin='0 auto'
        >
          <Flex
            width={[1, rightComponent ? '80%' : '100%']}
            justifyContent={centered ? 'center' : 'flext-start'}
          >
            {faIcon && (
              <IconWrapper>
                <FontAwesomeIcon icon={faIcon} color={colors.white} />
              </IconWrapper>
            )}
            <Text
              dangerouslySetInnerHTML={{ __html: text }}
              data-cy={`notification-${id}-text`}
              textAlign={textAlign}
            />
          </Flex>
          {rightComponent && (
            <Flex
              width={[1, '20%']}
              justifyContent={['center', 'flex-end', 'flex-end']}
              mt={[10, 0, 0]}
            >
              <Box>{<>{rightComponent}</>}</Box>
            </Flex>
          )}
        </Flex>
      </StyledNotification>
    );
  }

  private scrollToNotification = () => {
    if (this.notificationRef) {
      zenscroll.to(this.notificationRef);
    }
  };
}

const smallSizeStyle = css({
  lineHeight: '40px',
  fontSize: '1rem',
});

const miniSizeStyle = css({
  lineHeight: '30px',
  fontSize: '0.9rem',
});

const bigSizeStyle = css({
  lineHeight: '21px',
  fontSize: fontSize.medium,
  fontFamily: fontFamily.regular,
  minHeight: '75px',
});

const errorTypeStyle = css({
  background: '#E75A70',
  path: {
    fill: colors.white,
  },
});

const IconWrapper = styled.div`
  margin-right: 10px;
  display: inline-block;
`;

const Text = styled.span`
  ${typography};
`;

const stickyStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 1000,
};

const StyledNotification = styled.div(
  {
    color: 'white',
    fontSize: '1rem',
    lineHeight: '50px',
    width: '100%',
    '&-with-icon': {
      padding: '0 5px',
      '.svg-inline--fa': {
        color: 'white',
      },
    },
    display: 'flex',
    flexDirection: 'row',
    p: {
      marginRight: '10px',
    },
    a: {
      textDecoration: 'underline',
    },
  },
  props => ({
    [mediaQuery.large]: props.sticky ? stickyStyle : null,
  }),
  (props: any) =>
    props.size === 'small'
      ? smallSizeStyle
      : null || props.size === 'mini'
      ? miniSizeStyle
      : null || props.size === 'big'
      ? bigSizeStyle
      : null,
  (props: any) => (props.size === 'medium' ? { lineHeight: 2 } : null || null),
  ({ type }) => (type === 'error' ? errorTypeStyle : null),
  ({ bgColor, theme }) =>
    bgColor
      ? { background: theme.colors[bgColor] || bgColor }
      : { background: 'linear-gradient(136.55deg, #00C8D9 0%, #00A7E5 100%)' },
  ({ textFontFamily }) => (textFontFamily ? { fontFamily: textFontFamily } : null),
  typography
);

const mapDispatchToProps = dispatch => {
  return {
    popNotification: (key: string) => dispatch(popNotification(key)),
  };
};

export default connect<{}, IStateDispatchProps, IOwnProps>(null, mapDispatchToProps)(Notification);
