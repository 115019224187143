import styled from 'styled-components/macro';
import { Box, Flex } from 'reflexbox/styled-components';
import { mediaQuery } from 'styles/shared.styles';

export const SubmitBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const CustomFlex = styled(Flex)`
  flex-direction: column;
  ${mediaQuery.medium} {
    flex-direction: row;
  }
`;

export const CustomBox = styled(Box)`
  margin-bottom: 15px;
  ${mediaQuery.medium} {
    margin-bottom: 3px;
  }
`;
