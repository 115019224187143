import * as React from 'react';

import Button from 'app/components/Button';
import * as S from './RedirectButton.styles';

interface IRedirectButtonProps {
  id: string;
  external: boolean;
  visible: boolean;
  onClick: () => void;
}

export const RedirectButton: React.SFC<IRedirectButtonProps> = ({
  id,
  external,
  onClick,
  visible,
}) => {
  return (
    <>
      {id ? (
        <>
          <S.StyledLink to={`/auto/${id}/detail`} external={external}>
            <S.StyledButton fullWidth={true}>Zobrazit kartu vozidla</S.StyledButton>
          </S.StyledLink>
          <S.PrintLink to={`/auto/${id}/detail`} external={external}>
            {/*<S.PrintButton fullWidth={true}>{`overauto.cz/auto/${id}`}</S.PrintButton>*/}
            <S.PrintButton fullWidth={true}>Odkaz</S.PrintButton>
          </S.PrintLink>
        </>
      ) : (
          <Button onClick={onClick} fullWidth={true} visible={visible}>
            Zobrazit kartu vozidla
          </Button>
      )}
    </>
  );
};

RedirectButton.defaultProps = {};
