import * as React from 'react';
import SectionHeader from 'app/components/SectionHeader';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { Container, ContentWrapper, LoaderContainer } from 'app/components/_shared_';

interface IDataLoadingProps {
  loaderText?: string;
  sectionHeaderText?: string;
  sectionSubheaderText?: string;
  sectionSubheaderSuffixText?: string;
  sectionSubheaderValue?: string | number;
  fullscreen?: boolean;
}

export const DataLoading: React.SFC<IDataLoadingProps> = ({
  loaderText = EnumAdLoaderTexts.LOADING_DATA,
  sectionHeaderText = '',
  sectionSubheaderText = '',
  sectionSubheaderSuffixText = '',
  sectionSubheaderValue = '',
  fullscreen = true,
}) => {
  return (
    <Container>
      <ContentWrapper>
        {sectionHeaderText && (
          <SectionHeader
            title={sectionHeaderText}
            subheaderText={sectionSubheaderText}
            subheaderValue={sectionSubheaderValue}
            subheaderSuffixText={sectionSubheaderSuffixText}
          />
        )}
        <LoaderContainer>
          <AdLoader text={loaderText} fullscreen={fullscreen} />
        </LoaderContainer>
      </ContentWrapper>
    </Container>
  );
};

DataLoading.defaultProps = {};
