import * as React from 'react';
import { connect } from 'react-redux';

import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import PriceAdvisor from './components/PriceAdvisor';
import Ad from 'app/models/Ad';
import Benchmark from 'app/models/Benchmark';
import { showLoginDialog } from 'app/actions/app';
import { loadResidualValue } from 'app/actions/ads';

interface IPriceAdvisorContainerProps {
  ad: Ad;
  isAuthorized: boolean;
  residualValue: any;
  residualValueIsLoading: boolean;
  adBenchmark: Benchmark;
  pdfExportIsLoading: boolean;
  loadResidualValue: (adId: number, adBenchmark: Benchmark) => void;
  showLoginDialog: () => void;
}

class PriceAdvisorContainer extends React.Component<IPriceAdvisorContainerProps> {
  public componentDidMount() {
    const { ad, adBenchmark } = this.props;
    if (ad) {
      this.props.loadResidualValue(ad._id, adBenchmark);
    }
  }

  public componentDidUpdate(prevProps: IPriceAdvisorContainerProps) {
    const { ad, adBenchmark } = this.props;
    if (ad && (!prevProps.ad || ad._id !== prevProps.ad._id)) {
      this.props.loadResidualValue(ad._id, adBenchmark);
    }
  }

  public render() {
    const {
      isAuthorized,
      residualValue,
      residualValueIsLoading,
      ad,
      pdfExportIsLoading,
    } = this.props;
    if (pdfExportIsLoading) {
      return <AdLoader text={EnumAdLoaderTexts.PDF_IN_PROGRESS} fullscreen={true} />;
    }

    return (
      <PriceAdvisor
        listType='price-advisor'
        genericBadgeText='Váš inzerát'
        searchedAd={ad}
        data={residualValue}
        isAuthorized={isAuthorized}
        isLoadingData={residualValueIsLoading}
        onShowLoginDialog={this.props.showLoginDialog}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    ad: state.ads.adDetails ? state.ads.adDetails.ad : null,
    residualValue: state.ads.residualValue ? state.ads.residualValue : [],
    residualValueIsLoading: state.ads.residualValueIsLoading,
    adBenchmark: state.ads.adDetails.benchmark,
    pdfExportIsLoading: state.ads.pdfExportIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
    loadResidualValue: (adId: number, adBenchmark: Benchmark) =>
      dispatch(loadResidualValue(adId, adBenchmark)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceAdvisorContainer);
