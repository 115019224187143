import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import AdEquipment from 'app/components/AdEquipment/AdEquipment';
import { AdParameters } from 'app/components/AdParameters';
import LinkButton from 'app/components/LinkButton';
import Paragraph from 'app/components/Paragraph';
import SectionHeader from 'app/components/SectionHeader';
import Ad from 'app/models/Ad';
import * as S from './AdDetails.styles';

import { showLoginDialog } from 'app/actions/app';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

interface IStateProps {
  adDetails: any;
  isAuthorized: boolean;
}

interface IStateDispatchProps {
  showLoginDialog: () => void;
}

type TProps = IStateProps & IStateDispatchProps & RouteComponentProps;

class AdDetailsContainer extends React.Component<TProps> {
  public render() {
    const { adDetails, isAuthorized } = this.props;
    const { ad } = adDetails;

    return (
      <div>
        {isAuthorized && (
          <SectionHeader
            title='Karta vozidla'
            subheaderText={ad.isActive && 'Stáří inzerátu'}
            subheaderValue={ad.isActive && Ad.getAdAgeDaysTitle((ad && ad.adAge) || 0)}
            subheaderRightText='V celkovém hodnocení vozidla bereme v úvahu všechny zobrazené parametry dle zadaných kritérií.'
          />
        )}

        {!isAuthorized && (
          <div>
            <SectionHeader title='Karta vozidla' />
            <S.LoginMessage>
              <Paragraph>
                Pro zobrazení detailního hodnocení je potřeba se{' '}
                <LinkButton onClick={this.login}>přihlásit</LinkButton>.
              </Paragraph>
            </S.LoginMessage>
          </div>
        )}

        <S.ContentSection>
          <AdParameters ad={ad} />
        </S.ContentSection>

        <>
          <SectionHeader
            title='Výbava'
            subheaderText={'Položek'}
            subheaderValue={ad.equipmentCount}
          />
          <S.ContentSection>
            <AdEquipment equipment={ad.equipment} equipmentCount={ad.equipmentCount} />
          </S.ContentSection>
        </>
      </div>
    );
  }

  private login = () => {
    if (MobileAppUtils.isInApp()) {
      MobileAppUtils.gotoLogin();
      return;
    }
    this.props.showLoginDialog();
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    adDetails: state.ads.adDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
  };
};

export const AdDetails = withRouter(
  connect<IStateProps, IStateDispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(AdDetailsContainer)
);
