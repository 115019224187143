import NotificationType from '../models/NotificationType';
import { pushNotification } from '../actions/app';
import * as NOTIFICATIONS_CONSTANTS from 'app/constants/notifications';
import * as API_CONSTANTS from 'app/constants/api';

export function login(email: string, password: string) {
  return {
    type: API_CONSTANTS.LOGIN,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/login',
        data: {
          email,
          password,
        },
      },
    },
  };
}

export function logout() {
  return {
    type: API_CONSTANTS.LOGOUT,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/logout',
        data: {},
      },
    },
  };
}

export function fbLogin(accessToken: string, gdprConfirmed: boolean = true) {
  return {
    type: API_CONSTANTS.FB_LOGIN,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/facebook/login',
        data: {
          accessToken,
        },
      },
    },
  };
}

export function googleLogin(code: string, gdprConfirmed: boolean = true) {
  return {
    type: API_CONSTANTS.GOOGLE_LOGIN,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/google/login',
        data: {
          code,
        },
      },
    },
  };
}

export function fbSignup(accessToken: string, gdprConfirmed: boolean = true) {
  return {
    type: API_CONSTANTS.FB_SIGNUP,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/facebook/signup',
        data: {
          accessToken,
          gdprConfirmed,
        },
      },
    },
  };
}

export function googleSignup(code: string, gdprConfirmed: boolean, accessToken?: string) {
  return {
    type: API_CONSTANTS.GOOGLE_SIGNUP,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/google/signup',
        data: {
          code,
          gdprConfirmed,
          accessToken,
        },
      },
    },
  };
}

export function expireAuthSession() {
  return (dispatch, getState) => {
    const NOTIFICATION_DISMISS_DELAY = 7000;
    dispatch(logout()).then(() => {
      dispatch(
        pushNotification(
          NOTIFICATIONS_CONSTANTS.AUTH_TOKEN_EXPIRED,
          'Platnost vašeho přihlášení vypršela. Prosím, přihlašte se znovu.',
          NotificationType.warning,
          NOTIFICATION_DISMISS_DELAY
        )
      );
    });
  };
}

export function loginInject(authData: {
  email: string;
  lastLogin: string;
  _id: string;
  token: string;
  firstname: string;
  surname: string;
}) {
  return {
    type: API_CONSTANTS.LOGIN_INJECT,
    payload: {
      ...authData,
    },
  };
}
