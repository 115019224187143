import * as React from 'react';
import { ReactNode } from 'react';
import * as moment from 'moment';
import Table from 'app/components/Table';
import { withSpaces } from 'app/utils/number';
import Ad from 'app/models/Ad';
import * as S from './AdHistoryTable.styles';

import defaultAdImageUrl from 'assets/images/default-car-preview@2x.png';

const renderMatchResult = (row: Ad, col) => {
  const data = row[col];
  const colIsValue = col === 'oldValue' || col === 'newValue';
  const isImage =
    data &&
    typeof data === 'string' &&
    (data.indexOf('.jpg') > -1 || data.indexOf('.png') > -1 || data.indexOf('.jpeg') > -1);
  const isImageChange = colIsValue && isImage;
  const isDate =
    data &&
    typeof data === 'string' &&
    moment(new Date(data)) &&
    moment(new Date(data), 'DD.MM.YYYY HH:mm', true).isValid();
  if (isImageChange) {
    return <img src={defaultAdImageUrl} alt='img' />; // temp sauto img copyright issue fix
    // return <img src={`https://${data}`} alt='car-image' />;
  }

  if (colIsValue && typeof data === 'number') {
    return <S.StyledParagraph>{withSpaces(data)}</S.StyledParagraph>;
  }

  if ((col === 'newValue' || col === 'oldValue') && isDate) {
    return (
      <S.StyledParagraph>{moment(data, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')}</S.StyledParagraph>
    );
  }

  return <S.StyledParagraph>{data}</S.StyledParagraph>;
};

const columns = [
  {
    title: 'Datum',
    fieldName: 'changedAt',
    render: (row, col, data) => {
      return renderMatchResult(row, col);
    },
  },
  {
    title: 'Změneno',
    fieldName: 'fieldName',
    render: (row, col, data) => {
      return renderMatchResult(row, col);
    },
  },
  {
    title: 'Původní hodnota',
    fieldName: 'oldValue',
    render: (row, col, data) => {
      return renderMatchResult(row, col);
    },
  },
  {
    title: 'Nová hodnota',
    fieldName: 'newValue',
    render: (row, col, data) => {
      return renderMatchResult(row, col);
    },
  },
];

export interface IColumnConfig {
  title: string;
  fieldName: string;
  render?: (row: any, col: any, data: any) => ReactNode;
}

interface IAdHistoryTableProps {
  history: any[];
  columnsConfig?: IColumnConfig[]; // fieldName: one of 'changedAt | fieldName | oldValue | newValue'
  className?: string; // custom styled-components styling via className
  dataCy?: string; // cypress selector
}

const defaultProps: Partial<IAdHistoryTableProps> = {
  history: [],
};

const AdHistoryTable: React.SFC<IAdHistoryTableProps> = ({
  history,
  columnsConfig,
  className,
  ...props
}) => {
  const tableCols =
    columnsConfig && columnsConfig.length
      ? columnsConfig.filter(
          column => (column.render = (row, col, data) => renderMatchResult(row, col))
        )
      : columns;
  return (
    <React.Fragment>
      <Table
        idField={'fieldName'}
        data={history}
        columns={tableCols}
        fluid={true}
        stripped={true}
        className={className}
        {...props}
      />
    </React.Fragment>
  );
};

AdHistoryTable.defaultProps = defaultProps;

export default AdHistoryTable;
