import styled from 'styled-components/macro';
import { flex, mediaQuery, colors } from 'styles/shared.styles';
import { Menu } from 'antd';

const MenuItem = Menu.Item;

export const StyledMenu = styled(Menu)({
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15) !important',
  position: 'relative',
  top: '-7px',
  backgroundColor: colors.white,
});

export const StyledMenuItem = styled(MenuItem)`
  height: auto !important;
  line-height: 1.4 !important;
  min-height: 45px !important;
  border-bottom: 1px solid #e8e8e8;
  ${{ ...flex.flex }};
  ${{ ...flex.centerHorizontalV }};
  &:last-child {
    border-bottom: none;
  }
`;

export const FirstnameContainer = styled.div`
  ${mediaQuery.medium} {
    display: none;
  }
`;
export const FirstnameAndEmailMenuItem = styled(StyledMenuItem)`
  ${{ ...flex.alignChildrensLeft }};
  ${{ ...flex.vertical } as any};
  ${mediaQuery.medium} {
    ${{ ...flex.centerHorizontalH }};
  }
`;
