import * as React from 'react';
import AppDescription from 'app/components/AppDescription/AppDescription';
import SimilarAds from 'app/components/SimilarAds';
import { NotFound } from 'app/components/NotFound';
import {
  EstimateCarHistoryContainer,
  EstimateCarHistoryDetailContainer,
} from 'app/components/EstimateCarHistory';
import { connect } from 'react-redux';
import { Route, Redirect, RouteComponentProps, withRouter, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { OG_META_IMAGE } from 'app/constants/meta-tags';

interface IHomeProps {}

class Home extends React.Component<IHomeProps & RouteComponentProps<any>> {
  public render() {
    return (
      <>
        <Helmet defer={false}>
          <meta property='og:url' content={window.location.href} />
          <meta property='twitter:url' content={window.location.href} />
          <meta property='og:image' content={OG_META_IMAGE} />
        </Helmet>
        <Switch>
          <Route path='/' exact={true} component={AppDescription} />
          {/*<Route path='/auto/:id/podobne' exact={true} component={SimilarAds} />*/}
          <Route path='/auto/:id/detail' exact={true} component={SimilarAds} />
          <Route path='/auto/:id/statistiky' exact={true} component={SimilarAds} />
          <Route path='/auto/:id/cenovy-radce' exact={true} component={SimilarAds} />
          <Route path='/oceneni' exact={true} component={EstimateCarHistoryContainer} />
          <Route path='/oceneni/:id' exact={true} component={EstimateCarHistoryDetailContainer} />
          <Route
            exact={true}
            path='/auto/:id'
            // render={props => <Redirect to={`/auto/${props.match.params.id}/podobne`} />}
            render={props => <Redirect to={`/auto/${props.match.params.id}/detail`} />}
          />
          <Route component={NotFound} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
