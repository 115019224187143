import { values } from 'lodash-es';

interface IMediaQueryProps {
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
  print: string;
}

export const breakpoints = {
  small: 480,
  medium: 768,
  large: 1024,
  extraLarge: 1200,
};

const breakPointsMap = Object.keys(breakpoints).reduce((acc, label) => {
  const emSize = breakpoints[label] / 16;
  acc[label] = `@media only print, only screen and (min-width: ${emSize}em)`;
  return acc;
}, {} as IMediaQueryProps);

export const mediaQuery: IMediaQueryProps = {
  ...breakPointsMap,
  print: '@media only print',
};

// @rebass/grid breakpoints
// width={[500px, 1000px, 2000px]} => min-widths for 426px, 992px and 1439px breakpoints
// https://github.com/rebassjs/grid#theming
export const breakpointsPx = values(breakpoints).map(value => `${value}px`);
