import * as React from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import { get } from 'lodash-es';

import { DummyTable } from 'app/components/DummyTable';
import { BgImageNameType } from 'app/components/DummyTable/DummyTable';
import { DummyChart } from 'app/components/DummyChart';
import LinkButton from 'app/components/LinkButton';
import { DataNoResults } from 'app/components/DataNoResults';
import AdHistoryTable, { IColumnConfig } from './AdHistoryTable';
import SectionHeader from 'app/components/SectionHeader';
import AreaChart from 'app/components/Charts/AreaChart/AreaChart';
import { IHistoryDb } from '../AdHistory.container';

import { withSpaces } from 'app/utils/number';
import { mediaQuery } from 'styles/shared.styles';
import { ChartWrapper } from 'app/components/_shared_';
import * as S from './AdHistory.styles';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

export interface IAdHistoryProps {
  history: IHistory;
  isAuthorized?: boolean;
  chartPriceTitle?: string;
  chartKmTitle?: string;
  chartPriceSubheaderText?: string;
  chartPriceSubheaderValue?: string | number;
  chartPriceSubheaderSuffixText?: string;
  chartKmSubheaderText?: string;
  chartKmSubheaderValue?: string | number;
  chartKmSubheaderSuffixText?: string;
  tableTitle?: string;
  tableSubheaderText?: any;
  tableSubheaderSuffixText?: any;
  tableColumnsConfig?: IColumnConfig[];
  dummyTableBgImageName?: BgImageNameType;
  showLoginDialog: () => void;
}

export interface IHistoryTableData {
  oldValue: string;
  newValue: string;
  changedAt: string;
  fieldName: {
    cz: string;
    en: string;
  };
}

export interface IHistoryChartData {
  priceHistory: Array<{
    date: string;
    value: number;
  }>;
  kmHistory: Array<{
    date: string;
    value: number;
  }>;
}

export interface IHistory {
  initialData: IHistoryDb | null;
  tableData: IHistoryTableData[];
  chartData: IHistoryChartData;
  tableDataLength: number;
}

class AdHistory extends React.Component<IAdHistoryProps> {
  public static defaultProps: Partial<IAdHistoryProps> = {
    history: {
      initialData: null,
      tableData: [],
      tableDataLength: 0,
      chartData: {
        priceHistory: [],
        kmHistory: [],
      },
    },
  };

  public render() {
    const {
      history,
      isAuthorized,
      chartPriceTitle = '',
      chartKmTitle = '',
      chartPriceSubheaderText,
      chartPriceSubheaderValue,
      chartPriceSubheaderSuffixText,
      chartKmSubheaderText,
      chartKmSubheaderValue,
      chartKmSubheaderSuffixText,
      tableTitle = '',
      tableSubheaderText,
      tableSubheaderSuffixText,
      tableColumnsConfig,
      dummyTableBgImageName = 'adHistoryTable',
    } = this.props;
    return (
      <React.Fragment>
        <Flex width='100%' px={['10px', 0, 0]} flexDirection='column'>
          <Flex width='100%' alignItems='center' flexDirection='column'>
            {history && history.chartData && history.chartData.priceHistory && (
              <>
                <Box width={1}>
                  <SectionHeader
                    title={chartPriceTitle}
                    subheaderText={chartPriceSubheaderText}
                    subheaderValue={chartPriceSubheaderValue}
                    subheaderSuffixText={chartPriceSubheaderSuffixText}
                  />
                </Box>
                <Box width={1}>{this.renderPriceHistoryChart()}</Box>
              </>
            )}
            {history && history.chartData && history.chartData.kmHistory && (
              <>
                <Box width={1}>
                  <SectionHeader
                    title={chartKmTitle}
                    subheaderText={chartKmSubheaderText}
                    subheaderValue={chartKmSubheaderValue}
                    subheaderSuffixText={chartKmSubheaderSuffixText}
                  />
                </Box>
                <Box width={1}>{this.renderKmHistoryChart()}</Box>
              </>
            )}
          </Flex>

          <Flex
            width='100%'
            alignItems='center'
            flexDirection='column'
            sx={{
              [mediaQuery.print]: {
                display: 'none',
              },
            }}
          >
            <Box width={1}>
              <SectionHeader
                title={tableTitle}
                subheaderValue={tableSubheaderText || history.tableDataLength || '0'}
                subheaderSuffixText={tableSubheaderSuffixText}
              />
            </Box>
            <Box width={1}>
              <S.TableSectionContent>
                {!isAuthorized ? (
                  this.renderDummyTable(dummyTableBgImageName)
                ) : history && history.tableData && history && history.tableData.length ? (
                  <S.Table>
                    <AdHistoryTable
                      history={(history && history.tableData) || []}
                      columnsConfig={tableColumnsConfig}
                    />
                  </S.Table>
                ) : null}
              </S.TableSectionContent>
            </Box>
          </Flex>
        </Flex>
      </React.Fragment>
    );
  }

  private renderDummyChart = () => {
    return <DummyChart />;
  };

  private login = () => {
    if (MobileAppUtils.isInApp()) {
      MobileAppUtils.gotoLogin();
      return;
    }
    this.props.showLoginDialog();
  };

  private renderDummyTable = (dummyTableBgImageName: BgImageNameType) => {
    const { showLoginDialog } = this.props;
    return (
      <DummyTable
        showLoginDialog={showLoginDialog}
        bgImageName={dummyTableBgImageName}
        overlayContent={
          <>
            Pro zobrazení kompletní historie se musíte{' '}
            <LinkButton onClick={this.login} btnStyle='secondary'>
              přihlásit
            </LinkButton>
            .
          </>
        }
      />
    );
  };

  private formatTooltipValue(e: any, type: string, unit: string): any {
    const hasTooltipData = e.active && e.payload != null && e.payload[0] != null;
    const value =
      e &&
      e.payload &&
      e.payload.length &&
      e.payload[0] &&
      e.payload[0].payload &&
      e.payload[0].payload[type];
    if (hasTooltipData) {
      return (
        <S.TooltipWrapper>
          <span>{withSpaces(value)}</span>
          <span> {unit}</span>
        </S.TooltipWrapper>
      );
    }
    return '';
  }

  private formatAxisValue(value: any, unit: any): string {
    return `${withSpaces(value)}` || '';
  }

  private renderPriceHistoryChart = () => {
    const chartData = get(this.props, 'history.chartData.priceHistory');

    if (!this.props.isAuthorized) {
      this.renderDummyChart();
    }

    return chartData && chartData.length ? (
      <ChartWrapper>
        <AreaChart
          data={chartData}
          lineDataKey='value'
          xAxisDataKey='date'
          yAxisUnit=' Kč'
          yAxisTickFormatter={this.formatAxisValue}
          tooltipContent={e => this.formatTooltipValue(e, 'value', 'Kč')}
        />
      </ChartWrapper>
    ) : (
      <DataNoResults />
    );
  };

  private renderKmHistoryChart = () => {
    const chartData = get(this.props, 'history.chartData.kmHistory');

    if (!this.props.isAuthorized) {
      this.renderDummyChart();
    }

    return chartData && chartData.length ? (
      <ChartWrapper>
        <AreaChart
          data={chartData}
          lineDataKey='value'
          xAxisDataKey='date'
          yAxisUnit=' km'
          yAxisTickFormatter={this.formatAxisValue}
          tooltipContent={e => this.formatTooltipValue(e, 'value', 'km')}
        />
      </ChartWrapper>
    ) : (
      <DataNoResults />
    );
  };
}

AdHistory.defaultProps = {
  chartPriceTitle: 'Vývoj ceny v inzerátu',
  chartKmTitle: 'Vývoj tachometru v inzerátu',
  chartPriceSubheaderText: '',
  chartPriceSubheaderValue: '',
  chartPriceSubheaderSuffixText: '',
  chartKmSubheaderText: '',
  chartKmSubheaderValue: '',
  chartKmSubheaderSuffixText: '',
  tableTitle: 'Historie změn',
  tableSubheaderSuffixText: 'změn',
  tableColumnsConfig: [],
};

export default AdHistory;
