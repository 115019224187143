import * as React from 'react';
import Logo from './components/Logo';
import AuthBox from './components/AuthBox';
import Nav from './components/Nav';
import * as S from './Header.styles';

const Header = props => (
  <S.Wrapper light={props.light}>
    <S.Container>
      <S.Navigation>
        <S.LeftWrapper>
          <S.LogoWrapper to='/'>
            <Logo color={'white'} />
          </S.LogoWrapper>
          <Nav />
        </S.LeftWrapper>
        <S.RightWrapper>
          <AuthBox />
        </S.RightWrapper>
      </S.Navigation>
    </S.Container>
  </S.Wrapper>
)

export default Header;
