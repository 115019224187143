import * as React from 'react';
import { RefObject } from 'react';
import { get, findIndex, each } from 'lodash-es';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import qs from 'query-string';

import Container from 'app/components/Container';
import Tabs from 'app/components/Tabs/Tabs';
import TabPane from 'app/components/Tabs/components/TabPane';
import { AdDetails } from 'app/components/AdDetails/AdDetails';
import AdPreview from 'app/components/AdPreview/AdPreview';
import AdHistoryContainer from 'app/components/AdHistory';
import { AdsListsContainer } from 'app/components/AdsLists';
// import AdComparison from 'app/components/AdComparison';
import Statistics from 'app/components/Statistics/Statistics';
// import { FilterBar, FilterHeader } from 'app/components/Filter';
import { /*ListSwitcher, */ ListType } from 'app/components/ListSwitcher';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import PriceAdvisor from 'app/components/PriceAdvisor';
import SectionHeader from 'app/components/SectionHeader';
import Typography from 'app/components/Typography';
import { NavVerifyVinBtn, NavDownloadReportBtn } from './components';

import AdFilter from 'app/models/AdFilter';

import { checkViewMode, dates } from 'app/utils';

import { loadAdDetails, updateAdId, updateFilter } from 'app/actions/ads';
import { setSelectedTab, showLoginDialog } from 'app/actions/app';
import { OG_META_IMAGE } from 'app/constants/meta-tags';

import { colors } from 'styles/shared.styles';
import * as S from './SimilarAds.styles';

interface ISimilarAdsProps {
  adDetails: any;
  adDetailsIsLoading: boolean;
  pdfExportIsLoading: boolean;
  filter: AdFilter;
  isAuthorized: boolean;
  listType: ListType;
  selectedTab: ITabRoute;
  loadAdDetails: (listType: ListType) => void;
  setSelectedTab: (selectedTab: ITabRoute) => void;
  updateAdId: (adId: string) => void;
  updateFilter: (filter: Partial<AdFilter>) => void;
  showLoginDialog: () => void;
}

interface ITabRoute {
  pathname: string;
  title: string;
  routePath: string;
}

class SimilarAds extends React.Component<ISimilarAdsProps & RouteComponentProps<any>> {
  public adHistoryElRef: RefObject<HTMLDivElement>;

  public tabRoutes: ITabRoute[] = [
    // { pathname: 'podobne', title: 'Podobné inzeráty', routePath: '/auto/:id/podobne' },
    { pathname: 'detail', title: 'Karta vozidla', routePath: '/auto/:id/detail' },
    { pathname: 'cenovy-radce', title: 'Cenový rádce', routePath: '/auto/:id/cenovy-radce' },
    { pathname: 'statistiky', title: 'Statistiky', routePath: '/auto/:id/statistiky' },
  ];

  public async componentDidMount() {
    this.initSelectedTab();
    this.updateFilter();
    await this.loadAd();
  }

  public componentDidUpdate(prevProps: ISimilarAdsProps & RouteComponentProps<any>) {
    const adId = get(this.props, 'match.params.id', null);
    if (adId && prevProps.match.params.id !== adId) {
      this.loadAd();
    }
  }

  public render() {
    const {
      adDetails,
      location: { pathname },
      selectedTab,
      adDetailsIsLoading,
      pdfExportIsLoading,
      isAuthorized,
    } = this.props;

    if (adDetailsIsLoading || pdfExportIsLoading) {
      const loaderText = pdfExportIsLoading
        ? EnumAdLoaderTexts.PDF_IN_PROGRESS
        : // : EnumAdLoaderTexts.SEARCHING_CARS;
          EnumAdLoaderTexts.LOADING_DATA;
      return <AdLoader fullscreen={true} text={loaderText} />;
    }

    if (!adDetails) {
      return null;
    }

    const { ad /*, listResultsCount*/ } = adDetails;
    const defaultTabIndex = this.getTabRouteIndexByPath(this.tabRoutes, pathname);
    const documentTitle = (selectedTab && selectedTab.title) || 'Podobné inzeráty';
    const isPdfMode = checkViewMode('pdf');
    return (
      <>
        <Helmet titleTemplate='%s | OvěřAuto.cz' defer={false}>
          <title>{`${documentTitle} – ${ad.titleShort}`}</title>
          <meta property='og:title' content={`${documentTitle} – ${ad.titleShort} | OvěřAuto.cz`} />
          <meta property='og:url' content={window.location.href} />
          {/*<meta property='og:image' content={`https://${ad.img}`} />*/}
          <meta property='og:image' content={OG_META_IMAGE} />
          <meta
            property='twitter:title'
            content={`${documentTitle} – ${ad.titleShort} | OvěřAuto.cz`}
          />
          <meta property='twitter:url' content={window.location.href} />
          {/*<meta property='twitter:image' content={`https://${ad.img}`} />*/}
          <meta property='twitter:image' content={OG_META_IMAGE} />
          <meta property='og:image:width' content='500' />
          {/*<meta property='og:image:height' content='375' />*/}
          <meta property='og:image:height' content='350' />
        </Helmet>
        <Tabs
          selectedTab={selectedTab}
          onTabIndexChanged={this.updateTabIndex}
          defaultSelectedIndex={defaultTabIndex}
          currentAd={ad}
        >
          {/*<TabPane title={'Podobné inzeráty'} key={'podobne'}>
                      <AdPreview />
                      <S.SimilarAdsContainer>
                        <Container>
                          <FilterHeader numberOfResults={listResultsCount} />
                          <S.FilterAndListTypeWrapper>
                            <FilterBar />
                            <ListSwitcher />
                          </S.FilterAndListTypeWrapper>
                        </Container>
                        <AdComparison />
                      </S.SimilarAdsContainer>
                    </TabPane>*/}
          <TabPane title={'Karta vozidla'} key={'detail'}>
            {isPdfMode && (
              <SectionHeader
                title='REPORT INZERCE - KOMPLET'
                bgColor={colors.white}
                rightComponent={<Typography text={dates.getDateForTimezone('Europe/Prague')} />}
                allowBottomBorder={false}
              />
            )}
            <AdPreview isPdfMode={isPdfMode} />
            <Container>
              <AdDetails />
              <S.AdHistoryContainerContainer>
                <AdHistoryContainer />
              </S.AdHistoryContainerContainer>
              <S.AdsListsContainer>
                <AdsListsContainer isPdfMode={isPdfMode} />
              </S.AdsListsContainer>
              {isPdfMode && <PriceAdvisor />}
            </Container>
          </TabPane>
          <TabPane title={'Cenový rádce'} key={'cenovy-radce'}>
            <Container>
              <PriceAdvisor />
            </Container>
          </TabPane>
          <TabPane title={'Statistiky'} key={'statistiky'}>
            <AdPreview />
            <Container>
              <Statistics />
            </Container>
          </TabPane>
          <TabPane
            title={'Stáhnout report'}
            key={'stahnout-report'}
            navigationContent={
              <NavDownloadReportBtn
                adId={ad._id}
                isAuthorized={isAuthorized}
                showLoginDialog={this.props.showLoginDialog}
              />
            }
          />
          <TabPane
            title={'Ověřit VIN'}
            key={'overit-vin'}
            navigationContent={
              <NavVerifyVinBtn
                vin={ad.vin}
                isAuthorized={isAuthorized}
                showLoginDialog={this.props.showLoginDialog}
              />
            }
          />
        </Tabs>
      </>
    );
  }

  private getTabRouteIndexByPath = (tabRoutes: ITabRoute[], pathname: string): number => {
    const index = findIndex(tabRoutes, tabRoute => pathname.indexOf(tabRoute.pathname) > 0);
    return index === -1 ? 0 : index;
  };

  private updateUrl = (tabIndex: number) => {
    const { history, location } = this.props;
    const adId = get(this.props, 'match.params.id', null);
    const selectedTab = this.tabRoutes[tabIndex];
    const selectedTabTitle = selectedTab.pathname;

    if (adId && selectedTabTitle) {
      history.push(`/auto/${adId}/${selectedTabTitle}${location.search}`);
    }
  };

  private initSelectedTab = (): void => {
    const currentRoutePath = get(this.props, 'match.path', null);
    const selectedTab = this.tabRoutes.filter(
      (tabRoute: ITabRoute) => tabRoute && tabRoute.routePath === currentRoutePath
    )[0];
    this.props.setSelectedTab(selectedTab);
  };

  private loadAd = async (): Promise<void> => {
    const adDetails = get(this.props, 'adDetails', null);
    const adId = get(this.props, 'match.params.id', null);
    const listType = get(this.props, 'listType', null);
    const isFirstLoad = !adDetails && adId;
    const adChanged = adId && adDetails && adDetails.ad._id !== adId;
    if (isFirstLoad || adChanged) {
      this.props.updateAdId(adId);
      return await this.props.loadAdDetails(listType);
    }
  };

  private updateFilter = (): void => {
    const rawQuery = qs.parse(this.props.location.search);
    const query = {};
    each(rawQuery, (value, key) => (query[key] = value === '0' ? 0 : parseInt(value, 10) || value));

    if (Object.getOwnPropertyNames(query).length > 0) {
      for (const key of Object.getOwnPropertyNames(query)) {
        if (/^true$|^false$/.test(query[key])) {
          query[key] = query[key] === 'true';
        }
      }
      this.props.updateFilter(query as Partial<AdFilter>);
    }
  };

  private updateTabIndex = (tabIndex: number) => {
    const selectedTab = this.tabRoutes[tabIndex];
    this.updateUrl(tabIndex);
    this.props.setSelectedTab(selectedTab);
  };
}

const mapStateToProps = state => {
  return {
    adDetails: state.ads.adDetails,
    adDetailsIsLoading: state.ads.adDetailsIsLoading,
    filter: state.ads.filter,
    isAuthorized: state.auth.user,
    listType: state.ads.listType,
    selectedTab: state.app.selectedTab,
    pdfExportIsLoading: state.ads.pdfExportIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAdDetails: (listType: ListType) => dispatch(loadAdDetails(listType)),
    updateAdId: (adId: string) => dispatch(updateAdId(adId)),
    updateFilter: (filter: Partial<AdFilter>) => dispatch(updateFilter(filter)),
    setSelectedTab: (selectedTab: ITabRoute) => dispatch(setSelectedTab(selectedTab)),
    showLoginDialog: () => dispatch(showLoginDialog()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimilarAds));
