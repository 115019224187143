import { css } from 'styled-components/macro';

const print = css`
  @media print {
    @page {
      size: A4;
      width: 210mm;
      height: 297mm;
      margin: 0 5mm 3mm 5mm;
    }

    html,
    body,
    .app__container {
      width: 210mm;
      height: 297mm;
    }

    .app__main--container {
      background: white !important;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

export default print;
