import React from 'react';
import styled from 'styled-components/macro';

import { Column, TColumnProps } from 'app/components/Column';
import Typography from 'app/components/Typography';
import { mediaQuery } from 'styles/shared.styles';

interface ISmartCardProps extends TColumnProps {
  title: string;
  description: string;
  category: string;
  stateIcon: React.ReactNode;
  thumb: React.ReactNode;
  thumbBgColor?: string;
  descEnabled?: boolean;
  titleEnabled?: boolean;
}

export const SmartCard: React.FC<ISmartCardProps> = ({
  title,
  description,
  stateIcon,
  category,
  thumb,
  thumbBgColor = 'highlight',
  descEnabled = true,
  titleEnabled = true,
  ...columnProps
}) => (
  <Container
    width={['190px', '220px']}
    minWidth={['190px', '230px']}
    background='white'
    boxShadow='0px 4px 4px rgba(0, 0, 0, 0.02)'
    borderRadius='10px'
    p={['8px', '10px']}
    printStyles={{
      border: '1px solid rgba(151,151,151,0.5)',
      boxShadow: 'none',
    }}
    {...columnProps}
  >
    <ThumbContainer
      backgroundColor={thumbBgColor}
      justifyContent='center'
      alignItems='center'
      minHeight='104px'
      borderRadius='10px'
      position='relative'
    >
      <StateIconContainer position='absolute' top='6px' right='9px'>
        {stateIcon}
      </StateIconContainer>
      {thumb}
    </ThumbContainer>
    <Content px={['5px', '8px']} pt='10px' pb='13px' printStyles={{ paddingBottom: 0 }}>
      <Typography text={category} fontSize='0.75rem' color='rgba(74,85,96,0.5)' lineHeight='14px' />
      {titleEnabled && (
        <Typography
          text={title}
          weight='bold'
          color='#4A5560'
          mt='2px'
          lineHeight={['16px', '19px']}
          minHeight='39px'
          fontSize={['0.812rem', '1rem']}
          printStyles={{
            minHeight: '32px',
          }}
        />
      )}
      {descEnabled && (
        <Typography text={description} size='0.75rem' lineHeight='1rem' color='#4A5560' mt='10px' />
      )}
    </Content>
  </Container>
);

const Container = styled(Column)`
  ${mediaQuery.print} {
    width: 31% !important;
    margin: 0 5px 10px 5px;
  }
`;
const ThumbContainer = styled(Column)``;
const StateIconContainer = styled(Column)``;
const Content = styled(Column)``;
