import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from 'lodash-es';
import { setVin } from 'app/actions/ads';

import VerifyVinForm from './components/VerifyVinForm';

interface IVerifyVinContainerProps {
  isAuthorized: boolean;
  vin: string;
  setVin: (vin: string) => void;
}

interface IVerifyVinContainerState {}

class VerifyVinContainer extends React.Component<
  IVerifyVinContainerProps & RouteComponentProps<{ history: any }>,
  IVerifyVinContainerState
> {
  public componentDidMount() {
    const vinUrl = get(this.props, 'match.params.vin', null);
    if (vinUrl) this.props.setVin(vinUrl);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const vinHasChanged = this.props.vin && this.props.vin !== nextProps.vin;
    if (vinHasChanged) {
      this.props.setVin(this.props.vin);
    }
  }

  public render() {
    const { isAuthorized } = this.props;
    return <VerifyVinForm isAuthorized={isAuthorized} onSubmit={this.onSubmit} />;
  }

  private onSubmit = (vin: string) => {
    const { history } = this.props;
    history.push(`/vin/${vin}`);
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    vin: state.ads.vin,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setVin: (vin: string) => dispatch(setVin(vin)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyVinContainer));
