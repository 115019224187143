import * as React from 'react';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  system,
  position,
  PositionProps,
  display,
  DisplayProps,
  margin,
  MarginProps,
  color,
  ColorProps,
  minHeight,
  MinHeightProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { fontFamily } from 'styles/shared.styles';
import { mediaQuery } from 'styles/shared.styles';
import { FontSize, FontWeight } from './';
import { TFnWithArgs } from 'app/types';

interface IOwnProps {
  text?: string;
  weight?: FontWeight;
  size?: FontSize | string;
  textAlign?: string;
  margin?: string;
  color?: string;
  blurred?: boolean;
  textTransform?: CSSProperties['textTransform'];
  className?: string;
  onClick?: TFnWithArgs;
  wordBreak?: string;
  textDecoration?: string;
  whiteSpace?: string;
  printStyles?: CSSProperties;
}

type TTypographyProps = IOwnProps &
  PositionProps &
  MarginProps &
  DisplayProps &
  ColorProps &
  MinHeightProps &
  TypographyProps;

const Typography: React.FC<TTypographyProps> = ({
  size = 'base',
  weight = 'regular',
  color = '#616161',
  textTransform = 'initial',
  textAlign,
  lineHeight = 1.5,
  blurred = false,
  printStyles,
  ...props
}) =>
  props?.text ? (
    <Text
      size={size}
      weight={weight}
      textAlign={textAlign}
      color={color}
      lineHeight={lineHeight}
      blurred={blurred}
      textTransform={textTransform}
      printStyles={printStyles}
      {...props}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  ) : null;

const Text = styled.div<TTypographyProps>(
  {
    display: 'inline-block',
    color: '#616161',
    fontSize: '16px',
    lineHeight: 1.5,
    userSelect: 'text',
  },
  ({ blurred }) => (blurred ? { filter: 'blur(4px)' } : null),
  ({ margin }) => (margin ? { margin } : null),
  ({ size }) =>
    // prettier-ignore
    size === 'nano' ? { fontSize: '9px' } : null ||
      size === 'micro' ? { fontSize: '11px' } : null ||
      size === 'mini' ? { fontSize: '13px' } : null ||
      size === 'small' ? { fontSize: '14px' } : null ||
      size === 'base' ? { fontSize: '16px' } : null ||
      size === 'medium' ? { fontSize: '18px' } : null ||
      size === 'big' ? { fontSize: '20px' } : null ||
      size === 'extra-big' ? { fontSize: '32px' } : { fontSize: size },
  ({ weight }) =>
    // prettier-ignore
    weight === 'thin' ? { fontFamily: fontFamily.thin } : null ||
      weight === 'extra-light' ? { fontFamily: fontFamily.extraLight } : null ||
      weight === 'light' ? { fontFamily: fontFamily.light } : null ||
      weight === 'regular' ? { fontFamily: fontFamily.regular } : null ||
      weight === 'medium' ? { fontFamily: fontFamily.medium } : null ||
      weight === 'bold' ? { fontFamily: fontFamily.bold } : null ||
      weight === 'extra-bold' ? { fontFamily: fontFamily.extraBold } : null ||
      weight === 'heavy' ? { fontFamily: fontFamily.heavy } : null,
  ({ textTransform }) => (textTransform ? { textTransform } : null),
  ({ printStyles }) =>
    printStyles
      ? css({
          [mediaQuery.print]: {
            ...printStyles,
          },
        })
      : null,
  (props: any) => ({ textAlign: props.textAlign || 'left' }),
  system({
    wordBreak: true,
    textDecoration: true,
    whiteSpace: true,
  }),
  typography,
  position,
  margin,
  display,
  color,
  minHeight
);

export default Typography;
