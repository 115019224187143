import styled, { css } from 'styled-components/macro';
import { colors, mediaQuery } from '../../../styles/shared.styles';

export const DialogWrapper = styled('div')`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  transform: none !important;
  width: 100%;
  z-index: 9999;
  overflow: auto;
  ${mediaQuery.medium} {
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    padding: 0;
  }
`;

export const DialogContent = styled('div')({
  position: 'relative',
  maxWidth: '820px',
  boxShadow: '0 0 14px 0 rgba(214, 214, 214, 0.5)',
  backgroundColor: colors.white,
  border: 'solid 1px #e1e8f5',
});

export const backdropStyle = css({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: 0.46,
  backgroundColor: '#939393',
  transition: 'all ease-out',
  zIndex: 10,
  backfaceVisibility: 'hidden',
  transform: 'translateZ(0)',
  WebkitFontSmoothing: 'subpixel-antialiased',
});

export const IconClose = styled('img')({
  position: 'absolute',
  top: '15px',
  right: '15px',
  cursor: 'pointer',
  zIndex: 1,
});
