export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const LOAD_ESTIMATE_CAR_HISTORY_LOGS = 'LOAD_ESTIMATE_CAR_HISTORY_LOGS';
export const LOAD_ESTIMATE_CAR_HISTORY_LOGS_SUCCESS = 'LOAD_ESTIMATE_CAR_HISTORY_LOGS_SUCCESS';
export const LOAD_ESTIMATE_CAR_HISTORY_LOGS_FAIL = 'LOAD_ESTIMATE_CAR_HISTORY_LOGS_FAIL';
export const RESET_ESTIMATE_CAR_HISTORY_LOGS = 'RESET_ESTIMATE_CAR_HISTORY_LOGS';
export const LOAD_ESTIMATE_CAR_HISTORY_LOG = 'LOAD_ESTIMATE_CAR_HISTORY_LOG';
export const LOAD_ESTIMATE_CAR_HISTORY_LOG_SUCCESS = 'LOAD_ESTIMATE_CAR_HISTORY_LOG_SUCCESS';
export const LOAD_ESTIMATE_CAR_HISTORY_LOG_FAIL = 'LOAD_ESTIMATE_CAR_HISTORY_LOG_FAIL';
export const CREATE_ESTIMATE_CAR_HISTORY_LOG = 'CREATE_ESTIMATE_CAR_HISTORY_LOG';
export const CREATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS = 'CREATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS';
export const CREATE_ESTIMATE_CAR_HISTORY_LOG_FAIL = 'CREATE_ESTIMATE_CAR_HISTORY_LOG_FAIL';
export const UPDATE_ESTIMATE_CAR_HISTORY_LOG = 'UPDATE_ESTIMATE_CAR_HISTORY_LOG';
export const UPDATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS = 'UPDATE_ESTIMATE_CAR_HISTORY_LOG_SUCCESS';
export const UPDATE_ESTIMATE_CAR_HISTORY_LOG_FAIL = 'UPDATE_ESTIMATE_CAR_HISTORY_LOG_FAIL';
