import React from 'react';
import styled from 'styled-components/macro';

import Badge from 'app/components/Badge';
import BlurredText from 'app/components/BlurredText';
import FormattedValue from 'app/components/FormattedValue';
import { Column } from 'app/components/Column';
import { Row } from 'app/components/Row';
import { colors } from 'styles/shared.styles';

export interface IInfoCardItem {
  name: string;
  value: any;
  active?: boolean;
  blurred?: boolean;
  unit?: string;
}

export interface IInfoCardProps {
  badgeText?: string;
  badgeBgColor?: string;
  title?: string;
  selected?: boolean;
  data: IInfoCardItem[];
}

export const InfoCard: React.FC<IInfoCardProps> = ({
  selected = false,
  badgeText,
  badgeBgColor,
  data,
}) => (
  <Container selected={selected}>
    {badgeText && (
      <Column left='0px' alignSelf='flex-start' mt='15px'>
        <Badge bgColor={badgeBgColor} text={badgeText} borderRadius='0px 4px 4px 0' />
      </Column>
    )}
    {data && (
      <Column height='100%' p='16px' fontSize='1rem' lineHeight={1.4} position='relative'>
        {data.map((item, idx) => (
          <RowItem {...item} key={idx} />
        ))}
      </Column>
    )}
  </Container>
);

const Container = ({ selected, children }) => (
  <Column
    bg='white'
    borderRadius='10px'
    boxShadow='0 -4px 18px 0 rgba(0,0,0,0.12)'
    position='relative'
    width={1}
    minWidth={['285px', '350px']}
    border={selected ? `3px solid ${colors.highlight}` : 'none'}
    height='100%'
    printStyles={{
      boxShadow: 'none',
      border: '1px solid rgba(151,151,151,0.5)',
    }}
  >
    {children}
  </Column>
);

const RowItem: React.FC<IInfoCardItem> = ({ name, value, unit, active, blurred }) => (
  <RowItemContainer active={active}>
    <Row p='4px 8px' justifyContent='space-between'>
      <Column width='49%' color='#8B9198'>
        {name}
      </Column>
      <Column width='49%'>
        <BlurredText enabled={!!blurred}>
          <FormattedValue
            value={value}
            unit={unit || ''}
            size='base'
            weight='medium'
            color={colors.textDefault}
          />
        </BlurredText>
      </Column>
    </Row>
  </RowItemContainer>
);

const RowItemContainer = styled(Column)`
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.lightGreen : theme.colors.grey1};
  }
`;
