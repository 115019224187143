import * as React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import SectionHeader from 'app/components/SectionHeader';
import { Container, ContentWrapper, SectionHeaderContainer } from 'app/components/_shared_';
import { DataLoading } from 'app/components/DataLoading';

interface IDataResultsProps {
  children: React.ReactNode;
  dataIsLoading?: boolean;
  sectionHeaderText?: string;
  sectionSubheaderText?: string;
  sectionSubheaderValue?: string | number;
  sectionSubheaderSuffixText?: string;
  loaderText?: string;
}

export const DataResults: React.SFC<IDataResultsProps> = ({
  children,
  sectionHeaderText = '',
  sectionSubheaderText = '',
  sectionSubheaderValue = '',
  sectionSubheaderSuffixText = '',
  loaderText = '',
  dataIsLoading,
}) => {
  if (typeof dataIsLoading !== 'undefined' && dataIsLoading) {
    return (
      <DataLoading
        sectionHeaderText={sectionHeaderText}
        sectionSubheaderText={sectionSubheaderText}
        sectionSubheaderValue={sectionSubheaderValue}
        sectionSubheaderSuffixText={sectionSubheaderSuffixText}
        loaderText={loaderText}
      />
    );
  }

  return (
    <Container>
      <ContentWrapper>
        {sectionHeaderText && (
          <SectionHeaderContainer>
            <SectionHeader
              title={sectionHeaderText}
              subheaderText={sectionSubheaderText}
              subheaderValue={sectionSubheaderValue}
              subheaderSuffixText={sectionSubheaderSuffixText}
            />
          </SectionHeaderContainer>
        )}
        <Flex width={1}>
          <Box width={1}>{children}</Box>
        </Flex>
      </ContentWrapper>
    </Container>
  );
};

DataResults.defaultProps = {};
