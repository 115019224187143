import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components/macro';
import { Flex, BoxProps } from 'reflexbox/styled-components';
import {
  system,
  position,
  PositionProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  border,
  BorderProps,
  background,
  BackgroundProps,
  display,
  DisplayProps,
  borderRadius,
  BorderRadiusProps,
  layout,
  LayoutProps,
} from 'styled-system';

import { mediaQuery } from 'styles/shared.styles';

export interface IOwnProps {
  children?: React.ReactNode;
  color?: string;
  withHover?: boolean;
  printStyles?: CSSProperties;
}

export type TRowProps = IOwnProps &
  BoxProps &
  PositionProps &
  BoxShadowProps &
  ColorProps &
  BackgroundProps &
  DisplayProps &
  BorderProps &
  BorderRadiusProps &
  LayoutProps;

export const Row: React.FC<TRowProps> = props => (
  <StyledFlex minWidth='initial' {...props}>
    {props.children}
  </StyledFlex>
);

const StyledFlex = styled(Flex)<{ withHover?: boolean }>`
  ${border};
  ${color};
  ${background};
  ${position};
  ${boxShadow};
  ${display};
  ${borderRadius};
  ${layout};
  ${system({
    transform: true,
    userSelect: true,
    display: true,
    lineHeight: true,
  })};

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};

  ${({ printStyles }) =>
    printStyles &&
    css`
      ${mediaQuery.print} {
        ${printStyles};
      }
    `};
`;
