import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import NotificationComponent from '../Notification';
import Notification from '../../models/Notification';
import * as S from './NotificationBar.styles';

interface IStateProps {
  notifications: Notification[];
}

type TProps = IStateProps & RouteComponentProps;

class NotificationBar extends React.Component<TProps> {
  public render() {
    const notifications = this.props.notifications.map((notification, idx) => (
      <NotificationComponent
        id={notification.key}
        text={notification.text}
        type={notification.type}
        size='small'
        timeout={notification.timeout}
        key={idx}
      />
    ));
    return <S.Container>{notifications}</S.Container>;
  }
}

const mapStateToProps = state => {
  return {
    notifications: Object.keys(state.app.notifications).map(k => state.app.notifications[k]),
  };
};

export default withRouter(connect<IStateProps, null, {}>(mapStateToProps)(NotificationBar));
