import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { EventHint } from '@sentry/types';
import { get } from 'lodash-es';

import { buildInfo } from 'app/utils/build-info';
import { getEnv, isLocalEnv } from 'app/utils/env';

const opts = {
  attachStacktrace: true,
  beforeSend: beforeSendToSentry, // filter sending of logs to sentry
  debug: false, // print useful debug information
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: getEnv(),
  integrations: [new Integrations.BrowserTracing()],
  release: `hci@${buildInfo.COMMITHASH}`,
  tracesSampleRate: 1.0,
  allowUrls: [/https?:\/\/((beta|www)\.)?overauto\.cz/],
  ignoreErrors: [
    /^ResizeObserver loop$/,
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',

    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',

    // reported non-app-related bugs
    /^global code:$/,
    /^react-dom\/cjs\/react-dom.production.min.js$/,
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};

export const initSentry = () => Sentry.init(opts);

export const captureException = (
  err: string | Error,
  extra: any = 'error',
  tag?: string,
  user?: any
) =>
  Sentry.withScope(scope => {
    scope.setExtra(extra, err);
    if (tag) scope.setTag(tag, user);
    if (user) scope.setUser(user);
    if (err instanceof Error) {
      Sentry.captureException(err, scope);
      return;
    }
    Sentry.captureMessage(err, scope);
  });

function shouldIgnoreThrownError(statusCode: number, errMessage: string, validatorErrName: string) {
  // skip bad login response
  if (statusCode === 401 || (errMessage && errMessage.indexOf('NOT_FOUND') > -1)) return true;
  return false;
}

export function beforeSendToSentry(event: Event, hint: EventHint): Event | null {
  const statusCode = get(hint, 'originalException.response.status');
  const errMessage =
    get(hint, 'originalException.response.data.error.message') ||
    get(hint, 'originalException.response.data.message');
  const validatorErrName = get(hint, 'originalException.response.data.name');
  const shouldSkipReport =
    shouldIgnoreThrownError(statusCode, errMessage, validatorErrName) || isLocalEnv;
  if (shouldSkipReport) return null;
  return event;
}
