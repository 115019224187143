import * as React from 'react';

import { PrintFlex } from 'app/components/PrintFlex';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

interface IResponsiveSectionProps {
  children: React.ReactNode;
  [flexOption: string]: any;
}

export const ResponsiveSection: React.SFC<IResponsiveSectionProps> = ({ children, ...props }) => (
  <PrintFlex
    justifyContent={['center', 'space-between', 'space-between']}
    flexDirection={['column', 'row', 'row']}
    mt='20px'
    mx={[MobileAppUtils.isInApp() ? '25px' : '10px', 0, 0]}
    mb={['20px', '30px', '70px']}
    {...props}
  >
    {children}
  </PrintFlex>
);
