import * as React from 'react';
import * as moment from 'moment';
import { get, capitalize } from 'lodash-es';
import { Flex, Box } from 'reflexbox/styled-components';
import { EnumCompactAdsListItemLayout } from '../CompactAdsList';

import FormattedValue from 'app/components/FormattedValue';
import Typography from 'app/components/Typography';
import Ad from 'app/models/Ad';
import { checkViewMode } from 'app/utils';

import { mediaQuery } from 'styles/shared.styles';
import * as S from './CompactAdsListItem.styles';

interface ICompactAdsListItemProps {
  _id: string;
  adAge: number;
  badgeValuePropName: string;
  brand: string;
  fuel: string;
  id: number;
  img: string;
  km: number;
  kw: number;
  lastRun: Date;
  model: string;
  price: number;
  title: string;
  transmission: string;
  withBackground: boolean;
  year: number;
  allowRedirectButton?: boolean;
  badgeBgColor?: string;
  badgeText?: string;
  layout?: 'horizontal' | 'vertical';
  thumbnail?: boolean;
  name?: string;
  badgeValueFormatter?: (value) => any;
}

const CompactAdsListItem: React.SFC<ICompactAdsListItemProps> = props => {
  const {
    _id,
    adAge,
    badgeBgColor,
    badgeValueFormatter,
    badgeValuePropName,
    brand,
    fuel,
    id,
    img,
    km,
    kw,
    layout,
    model,
    price,
    transmission,
    withBackground,
    year,
    badgeText = '',
    allowRedirectButton = true,
    thumbnail = true,
    name,
  } = props;
  const isPdfMode = checkViewMode('pdf');

  const propValue = get(props, badgeValuePropName);
  return (
    <S.Container withBackground={withBackground} thumbnail={thumbnail}>
      <Flex
        width='100%'
        height='100%'
        flexDirection={[
          'column',
          layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column-reverse',
          layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column-reverse',
        ]}
        sx={{
          [mediaQuery.print]: {
            flexDirection:
              layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column-reverse',
          },
        }}
        data-cy={`compact-ads-list-item compact-ads-list-item-${name}`}
      >
        <Box>
          <S.ImageContainer thumbnail={thumbnail}>
            {thumbnail && <S.StyledCarImage src={`https://${img}`} />}
            <S.StyledBadge bgColor={badgeBgColor} thumbnail={thumbnail}>
              {badgeText && (
                <span>
                  {badgeText}
                  {!isPdfMode && <br />}
                </span>
              )}
              {badgeValueFormatter ? (
                <span>{badgeValueFormatter(propValue)}</span>
              ) : (
                <span>{moment(propValue).format('DD.MM.YYYY')}</span>
              )}
            </S.StyledBadge>
          </S.ImageContainer>
        </Box>
        <Flex
          width='100%'
          height='100%'
          alignItems='flex-start'
          flexDirection='column'
          justifyContent='space-between'
          py={10}
          pl={25}
        >
          <Box width='100%' mb={layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 0 : 12}>
            {/*<Typography text={title} weight='bold' size='small' />*/}
            <Typography
              text={`${capitalize(brand)} ${capitalize(model)}`}
              weight='bold'
              size='small'
            />
          </Box>
          <Box width='100%'>
            <Flex
              width='100%'
              justifyContent='space-between'
              flexDirection={[
                'column',
                layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column',
                layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column',
              ]}
              sx={{
                [mediaQuery.print]: {
                  flexDirection: 'row',
                },
              }}
            >
              <Box width='100%'>
                <FormattedValue value={price} unit='Kč' weight='bold' />
              </Box>
              <Box alignSelf='flex-start' width='100%'>
                <FormattedValue value={km} unit='km' />
              </Box>
              <Box alignSelf='flex-start' width='100%'>
                <FormattedValue value={transmission} textTransform='capitalize' />
              </Box>
            </Flex>

            <Flex
              width='100%'
              justifyContent='space-between'
              flexDirection={[
                'column',
                layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column',
                layout === EnumCompactAdsListItemLayout.HORIZONTAL ? 'row' : 'column',
              ]}
              sx={{
                [mediaQuery.print]: {
                  flexDirection: 'row',
                },
              }}
            >
              <Box width='100%'>
                <FormattedValue value={year} formattedWithSpaces={false} />
              </Box>
              <Box alignSelf='flex-start' width='100%'>
                <FormattedValue value={fuel} textTransform='capitalize' />
              </Box>
              <Box alignSelf='flex-start' width='100%'>
                <FormattedValue value={kw} unit='kW' />
              </Box>
              {typeof adAge !== 'undefined' && (
                <Box alignSelf='flex-start' width='100%'>
                  <FormattedValue value={`${Ad.getAdAgeDaysTitle(adAge)} v inzerci`} />
                </Box>
              )}
            </Flex>
          </Box>

          {allowRedirectButton && (
            <Box>
              {id && (
                <>
                  <S.StyledLink to={`/auto/${_id}/detail`} external={true}>
                    Zobrazit podrobnosti
                  </S.StyledLink>
                  <S.PrintLink to={`/auto/${_id}/detail`} external={true}>
                    {/*`overauto.cz/auto/${id}`*/ `Odkaz`}
                  </S.PrintLink>
                </>
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </S.Container>
  );
};

CompactAdsListItem.defaultProps = {
  badgeValuePropName: 'lastRun',
};

export default CompactAdsListItem;
