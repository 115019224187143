import React from 'react';

import { Row, TRowProps } from 'app/components/Row';
import { ComparatorCard } from '../ComparatorCard';
import { comparatorConfig } from '../../ReportsComparator.helpers';

interface IOwnProps {
  vin?: string;
}

type TComparatorCardsProps = IOwnProps & TRowProps;

export const ComparatorCards: React.FC<TComparatorCardsProps> = ({ vin, ...props }) => {
  const config = comparatorConfig(vin);
  return (
    <Row
      width={1}
      justifyContent={['flex-start', 'center']}
      mb='95px'
      py={['40px', 0]}
      overflowX={['auto', 'initial']}
      data-cy='comparator-cards'
      {...props}
    >
      <ComparatorCard {...config.overautoFree} vin={vin} />
      <ComparatorCard {...config.overautoPremium} vin={vin} />
    </Row>
  );
};
