/**
 * Example
 * `safe(() => round(this.someNumber, 8), 'N/A')`
 */
export function safe<T>(expression: () => T, defaultValue: any = null) {
  try {
    const value = expression();
    if (typeof value !== 'undefined' && value !== null) {
      return value;
    } else {
      return defaultValue;
    }
  } catch (e) {
    return defaultValue;
  }
}

export function mustBeSet(value: any): any {
  if (!value) {
    throw new Error('Values was not set');
  }
  return value;
}
