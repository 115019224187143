import * as React from 'react';
import Heading from './../Heading';
import styled from 'styled-components/macro';
import Typography from './../Typography';

const StyledBlock = styled('div')({
  height: '100px',
  border: '1px solid #e9e9e9',
  padding: '16px',
});

interface IStatisticsBlockProps {
  title: string;
  value: number | string;
  units?: string;
}

class StatisticsBlock extends React.Component<IStatisticsBlockProps> {
  public render() {
    const { title, value, units } = this.props;

    return (
      <StyledBlock>
        <Heading level={3}>{title}</Heading>
        <div>
          <Typography text={value.toString()} weight={'bold'} />
          {units && (
            // Replace '\u00A0' by '&nbsp;' when refactoring Typography component
            <Typography text={`\u00A0${units}`} />
          )}
        </div>
      </StyledBlock>
    );
  }
}

export default StatisticsBlock;
