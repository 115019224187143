import { WebSockets } from 'app/utils/websockets';
import User from 'app/models/User';
import { TError } from 'app/types';
import {
  FB_LOGIN,
  FB_LOGIN_FAIL,
  FB_LOGIN_SUCCESS,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_FAIL,
  GOOGLE_LOGIN_SUCCESS,
  FB_SIGNUP,
  FB_SIGNUP_FAIL,
  FB_SIGNUP_SUCCESS,
  GOOGLE_SIGNUP,
  GOOGLE_SIGNUP_FAIL,
  GOOGLE_SIGNUP_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN_INJECT,
} from '../constants/api';

interface IAuthState {
  accessToken?: string | null;
  user?: User | null;
  loginInProgress?: boolean;
  loginError?: TError;
  logoutInProgress?: boolean;
  logoutError?: TError;
  fbLoginInProgress?: boolean;
  fbLoginError?: TError;
  fbSignupInProgress?: boolean;
  fbSignupError?: TError;
  googleLoginInProgress?: boolean;
  googleLoginError?: TError;
  googleSignupInProgress?: boolean;
  googleSignupError?: TError;
  signupInProgress?: boolean;
  signupError?: TError;
}

const initialState: IAuthState = {};

export default function(state: IAuthState = initialState, action: any): IAuthState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loginInProgress: true,
      };
    case LOGIN_SUCCESS: {
      const { email, lastLogin, _id, token, firstname, surname } = action.payload.data;
      WebSockets.connect(token);
      // TODO save token to app, if web is running in webview
      return {
        ...state,
        loginInProgress: false,
        loginError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          lastLogin: new Date(lastLogin),
        },
      };
    }
    case LOGIN_FAIL: {
      const { message } = action.error;
      return {
        ...state,
        loginInProgress: false,
        loginError: message,
      };
    }
    case LOGOUT:
      // WebSockets.disconnect();
      return {
        ...state,
        logoutInProgress: true,
      };
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        logoutInProgress: false,
        logoutError: null,
        accessToken: null,
        user: null,
      };
    }
    case LOGOUT_FAIL: {
      const { message } = action.error;
      return {
        ...state,
        logoutInProgress: false,
        logoutError: message,
      };
    }
    case FB_LOGIN: {
      return {
        ...state,
        fbLoginInProgress: true,
      };
    }
    case FB_LOGIN_SUCCESS: {
      const {
        email,
        surname,
        gdprConfirmed,
        lastLogin,
        lastLogout,
        role,
        token,
        _id,
        firstname,
        facebook,
        google,
      } = action.payload.data;
      WebSockets.connect(token);

      // TODO save token to app, if web is running in webview

      return {
        ...state,
        fbLoginInProgress: false,
        fbLoginError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          role,
          lastLogin: new Date(lastLogin),
          lastLogout: new Date(lastLogout),
          gdprConfirmed,
          facebook,
          google,
        },
      };
    }
    case FB_LOGIN_FAIL: {
      const { message } = action.error;

      return {
        ...state,
        fbLoginInProgress: false,
        fbLoginError: message,
      };
    }
    case FB_SIGNUP: {
      return {
        ...state,
        fbSignupInProgress: true,
      };
    }
    case FB_SIGNUP_SUCCESS: {
      const {
        email,
        surname,
        gdprConfirmed,
        lastLogin,
        lastLogout,
        role,
        token,
        _id,
        firstname,
        facebook,
        google,
      } = action.payload.data;
      WebSockets.connect(token);

      // TODO save token to app, if web is running in webview

      return {
        ...state,
        fbSignupInProgress: false,
        fbSignupError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          role,
          lastLogin: new Date(lastLogin),
          lastLogout: new Date(lastLogout),
          gdprConfirmed,
          facebook,
          google,
        },
      };
    }
    case FB_SIGNUP_FAIL: {
      const { message } = action.error;

      return {
        ...state,
        fbSignupInProgress: false,
        fbSignupError: message,
      };
    }
    case GOOGLE_LOGIN: {
      return {
        ...state,
        googleLoginInProgress: true,
      };
    }
    case GOOGLE_LOGIN_SUCCESS: {
      const {
        email,
        surname,
        gdprConfirmed,
        lastLogin,
        lastLogout,
        role,
        token,
        _id,
        firstname,
        facebook,
        google,
      } = action.payload.data;
      WebSockets.connect(token);

      // TODO save token to app, if web is running in webview

      return {
        ...state,
        googleLoginInProgress: false,
        googleLoginError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          role,
          lastLogin: new Date(lastLogin),
          lastLogout: new Date(lastLogout),
          gdprConfirmed,
          facebook,
          google,
        },
      };
    }
    case GOOGLE_LOGIN_FAIL: {
      const { message } = action.error;

      return {
        ...state,
        googleLoginInProgress: false,
        loginError: message,
      };
    }

    case GOOGLE_SIGNUP: {
      return {
        ...state,
        googleSignupInProgress: true,
      };
    }
    case GOOGLE_SIGNUP_SUCCESS: {
      const {
        email,
        surname,
        gdprConfirmed,
        lastLogin,
        lastLogout,
        role,
        token,
        _id,
        firstname,
        facebook,
        google,
      } = action.payload.data;
      WebSockets.connect(token);

      // TODO save token to app, if web is running in webview

      return {
        ...state,
        googleSignupInProgress: false,
        googleSignupError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          role,
          lastLogin: new Date(lastLogin),
          lastLogout: new Date(lastLogout),
          gdprConfirmed,
          facebook,
          google,
        },
      };
    }
    case GOOGLE_SIGNUP_FAIL: {
      const { message } = action.error;

      return {
        ...state,
        googleSignupInProgress: false,
        loginError: message,
      };
    }
    case CREATE_USER: {
      return {
        ...state,
        signupInProgress: true,
      };
    }
    case CREATE_USER_SUCCESS: {
      const {
        user: { email, lastLogin, _id, firstname, surname },
        token,
      } = action.payload.data;
      WebSockets.connect(token);

      // TODO save token to app, if web is running in webview

      return {
        ...state,
        signupInProgress: false,
        signupError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          lastLogin: new Date(lastLogin),
        },
      };
    }
    case CREATE_USER_FAIL: {
      return {
        ...state,
        signupInProgress: false,
        signupError: null,
      };
    }

    case LOGIN_INJECT: {
      const { email, lastLogin, _id, token, firstname, surname } = action.payload;
      return {
        ...state,
        loginInProgress: false,
        loginError: null,
        accessToken: token,
        user: {
          firstname,
          surname,
          id: _id,
          email,
          lastLogin: new Date(lastLogin),
        },
      };
    }

    default:
      return state;
  }
}
