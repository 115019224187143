import * as React from 'react';
import * as S from './AdLoader.styles';
import { colors } from '../../../styles/shared.styles';
import { Loader } from 'app/components/Loader';
import Typography from '../Typography';

export interface IAdLoaderProps {
  size?: number;
  fullscreen?: boolean;
  text?: string;
}

const defaultProps: Partial<IAdLoaderProps> = {
  fullscreen: false,
  size: 60,
  text: 'Hledáme pro vás všechny vozy...',
};

const AdEquipment: React.SFC<IAdLoaderProps> = ({
  size,
  fullscreen,
  text = 'Hledáme pro vás všechny vozy...',
  ...props
}) => (
  <S.AdLoaderContainer fullscreen={fullscreen}>
    <S.LoaderWrapper fullscreen={fullscreen}>
      <Loader size={size} singleColor={colors.primary} {...props} />
    </S.LoaderWrapper>
    <S.TextWrapper>
      <Typography text={text} weight='medium' size='small' />
    </S.TextWrapper>
  </S.AdLoaderContainer>
);

AdEquipment.defaultProps = defaultProps;

export default AdEquipment;
