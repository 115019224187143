import * as React from 'react';
import Select from 'react-select';
import { ISelectOption } from './';
import { customStyles/*, DropdownIndicator*/, LabelAndSelectContainer } from './Select.styles';
import ErrorMessage from '../../ErrorMessage';
import { FormLabel } from '../Form';
import { FormikErrors, FormikTouched } from 'formik';

interface ISelectComponentProps {
  [key: string]: any;
  options: ISelectOption[]|string[]|number[];
  onChange?: (value?: string) => void;
  error?: FormikErrors<any>|string;
  label?: string;
  touched?: FormikTouched<any>|boolean;
}

interface ISelectComponentState {
  selectedOption: string | number | null;
}

class SelectComponent extends React.Component<ISelectComponentProps, ISelectComponentState> {
  public static defaultProps: Partial<ISelectComponentProps> = {
    options: [
      { value: '', label: '' },
    ],
  };

  public state = {
    selectedOption: this.props.defaultValue || null,
  };

  public handleChange = (selectedOption: string) => {
    const { handleChange } = this.props;
    this.setState({ selectedOption });

    if (typeof handleChange === 'function') {
      handleChange(selectedOption);
    }
  }

  public render() {
    const { options, error, label, touched, ...props } = this.props;
    const { selectedOption } = this.state;

    return (
      <>
        <LabelAndSelectContainer>
          {
            label &&
            <FormLabel>
              {label}
            </FormLabel>
          }
          <Select
            styles={customStyles}
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            {...props}
          />
        </LabelAndSelectContainer>
        {
          error && touched &&
          <ErrorMessage
            textAlign='left'
            isVisible={true}
            message={error.toString()}
          />
        }
      </>
    );
  }
}

export default SelectComponent;
        // components={{ DropdownIndicator }}
