import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from 'lodash-es';

import VerifyVinKmHistory from './VerifyVinKmHistory';
import { showLoginDialog } from 'app/actions/app';
import {
  loadKmHistoryByVin,
  loadKmHistoryForNotFoundVin,
  setVin,
  resetKmHistoryByVin,
  resetKmHistoryForNotFoundVin,
} from 'app/actions/ads';
import Ad from 'app/models/Ad';

import { IKmHistory } from 'app/components/AdHistory/AdHistory.container';

interface IVerifyVinKmHistoryContainerProps {
  ad: Ad;
  vin: string;
  isAuthorized: boolean;
  kmHistory: IKmHistory;
  kmHistoryIsLoading: boolean;
  kmHistoryError: string;
  kmHistoryNotFoundVin: IKmHistory;
  kmHistoryNotFoundVinIsLoading: boolean;
  kmHistoryNotFoundVinError: string;
  showLoginDialog: () => void;
  setVin: (vin: string) => void;
  loadKmHistoryByVin: (vin: string) => Promise<any>;
  loadKmHistoryForNotFoundVin: (vin: string) => Promise<any>;
  resetKmHistoryByVin: () => void;
  resetKmHistoryForNotFoundVin: () => void;
}

interface IVerifyVinKmHistoryContainerState {}

class VerifyVinKmHistoryContainer extends React.Component<
  IVerifyVinKmHistoryContainerProps & RouteComponentProps<{ history: any }>,
  IVerifyVinKmHistoryContainerState
> {
  public componentDidMount() {
    const vinUrl = get(this.props, 'match.params.vin', null);
    this.loadKmHistoryByVin(vinUrl);
    this.props.setVin(vinUrl);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IVerifyVinKmHistoryContainerProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const {
      kmHistoryError,
      kmHistoryNotFoundVin,
      kmHistoryNotFoundVinError,
      kmHistoryNotFoundVinIsLoading,
      vin,
    } = nextProps;
    if (authHasChanged) {
      this.loadKmHistoryByVin(vin);
    }

    const vinNotFoundError = kmHistoryError === 'vinNotFoundError';
    const shouldLoadData =
      vinNotFoundError &&
      !kmHistoryNotFoundVin &&
      !kmHistoryNotFoundVinError &&
      !kmHistoryNotFoundVinIsLoading;
    if (shouldLoadData) {
      this.loadKmHistoryForNotFoundVin(vin);
    }
  }

  public componentWillUnmount() {
    this.props.resetKmHistoryByVin();
    this.props.resetKmHistoryForNotFoundVin();
  }

  public render() {
    const {
      vin,
      isAuthorized,
      kmHistory,
      kmHistoryIsLoading,
      kmHistoryError,
      kmHistoryNotFoundVin,
      kmHistoryNotFoundVinIsLoading,
      kmHistoryNotFoundVinError,
      ad,
    } = this.props;

    return (
      <VerifyVinKmHistory
        vin={vin}
        data={kmHistory || kmHistoryNotFoundVin || []}
        isAuthorized={isAuthorized}
        showLoginDialog={this.props.showLoginDialog}
        dataIsLoading={kmHistoryIsLoading}
        kmHistoryNotFoundVinIsLoading={kmHistoryNotFoundVinIsLoading}
        kmHistoryError={kmHistoryNotFoundVinError || kmHistoryError}
        countryOrigin={ad?.countryOrigin}
      />
    );
  }

  private loadKmHistoryByVin = (vin: string): Promise<any> => {
    if (!vin) {
      return Promise.reject(null);
    }
    return this.props
      .loadKmHistoryByVin(vin)
      .then(response => response)
      .catch(err => err);
  };

  private loadKmHistoryForNotFoundVin = (vin: string): Promise<any> => {
    if (!vin) {
      return Promise.reject(null);
    }
    return this.props
      .loadKmHistoryForNotFoundVin(vin)
      .then(response => response)
      .catch(err => err);
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    ad: state.ads.adsByVinList?.ad,
    vin: state.ads.vin,
    kmHistory: state.ads.kmHistory,
    kmHistoryError: state.ads.kmHistoryError,
    kmHistoryIsLoading: state.ads.kmHistoryIsLoading,
    kmHistoryNotFoundVin: state.ads.kmHistoryNotFoundVin,
    kmHistoryNotFoundVinIsLoading: state.ads.kmHistoryNotFoundVinIsLoading,
    kmHistoryNotFoundVinError: state.ads.kmHistoryNotFoundVinError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setVin: (vin: string) => dispatch(setVin(vin)),
    showLoginDialog: () => dispatch(showLoginDialog()),
    loadKmHistoryByVin: (vin: string) => dispatch(loadKmHistoryByVin(vin)),
    loadKmHistoryForNotFoundVin: (vin: string) => dispatch(loadKmHistoryForNotFoundVin(vin)),
    resetKmHistoryByVin: () => dispatch(resetKmHistoryByVin()),
    resetKmHistoryForNotFoundVin: () => dispatch(resetKmHistoryForNotFoundVin()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyVinKmHistoryContainer)
);
