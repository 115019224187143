import styled from 'styled-components/macro';
import { AdHistoryTable } from 'app/components/AdHistory';
import AreaChart from 'app/components/Charts/AreaChart/AreaChart';

export const StyledAdHistoryTable = styled(AdHistoryTable)`
  width: 100%;
`;

export const StyledAreaChart = styled(AreaChart)`
  width: 100%;
`;
