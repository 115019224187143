import React from 'react';

import { Helmet } from 'react-helmet';
import { OG_META_IMAGE } from 'app/constants/meta-tags';

export const DocumentHead: React.FC = () => (
  <Helmet titleTemplate='%s | OvěřAuto.cz' defer={false}>
    <meta charSet='utf-8' />
    <title>Ověříme historii vozu podle VIN</title>
    <link rel='canonical' href='https://www.overauto.cz' />
    <meta property='og:title' content='Ověříme historii vozu podle VIN | OvěřAuto.cz' />
    <meta
      property='og:description'
      content='Stačí zadat VIN vozu a najdeme všechny dostupné záznamy o daném vozidle. To vše rychle a kompletně online.'
    />
    <meta property='og:url' content={window.location.href} />
    <meta property='og:image' content={OG_META_IMAGE} />
    <meta property='twitter:title' content='Ověříme historii vozu podle VIN | OvěřAuto.cz' />
    <meta
      property='twitter:description'
      content='Stačí zadat VIN vozu a najdeme všechny dostupné záznamy o daném vozidle. To vše rychle a kompletně online.'
    />
    <meta property='twitter:url' content={window.location.href} />
    <meta property='twitter:image' content={OG_META_IMAGE} />
  </Helmet>
);
