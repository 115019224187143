import * as Constants from 'app/constants/enums';
import { IEnums, IModelEnums } from 'app/models/Enums';

interface IEnumsReducer {
  enums: IEnums | null;
  enumsInProgress: boolean;
  enumsError: boolean;
  modelEnums: IModelEnums | null;
  modelEnumsInProgress: boolean;
  modelEnumsError: boolean;
}

const initialState: IEnumsReducer = {
  enums: null,
  enumsInProgress: false,
  enumsError: false,
  modelEnums: null,
  modelEnumsInProgress: false,
  modelEnumsError: false,
};

const enums = (state = initialState, action: any): IEnumsReducer => {
  switch (action.type) {
    case Constants.LOAD_ENUMS: {
      return {
        ...state,
        enumsInProgress: true,
        enumsError: false,
        enums: null,
      };
    }
    case Constants.LOAD_ENUMS_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        enums: data,
        enumsInProgress: false,
        enumsError: false,
      };
    }
    case Constants.LOAD_ENUMS_FAIL: {
      return {
        ...state,
        enums: null,
        enumsInProgress: false,
        enumsError: true,
      };
    }
    case Constants.LOAD_MODEL_ENUMS: {
      return {
        ...state,
        modelEnums: null,
        modelEnumsError: false,
        modelEnumsInProgress: true,
      };
    }
    case Constants.LOAD_MODEL_ENUMS_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        modelEnums: data,
        modelEnumsInProgress: false,
        modelEnumsError: false,
      };
    }
    case Constants.LOAD_MODEL_ENUMS_FAIL: {
      return {
        ...state,
        modelEnums: null,
        modelEnumsInProgress: false,
        modelEnumsError: true,
      };
    }
    default:
      return state;
  }
};

export default enums;
