import * as React from 'react';
import { get, round, isNumber } from 'lodash-es';
import { withSpaces, toPercent } from '../../utils/number';
import AreaChart from '../Charts/AreaChart/AreaChart';
import { ChartWrapper } from 'app/components/_shared_';

export interface IResidualValueChartsValue {
  value: number;
  date: string;
}

interface IResidualValueChartsProps {
  data: any;
}

class ResidualValueCharts extends React.Component<IResidualValueChartsProps> {
  public static defaultProps: Partial<IResidualValueChartsProps> = {
    data: [],
  };

  public render() {
    const { data } = this.props;
    return (
      <>
        {this.renderPriceResidualValueChart(data)}
        {this.renderPercentageResidualValueChart(data)}
      </>
    );
  }

  private renderPriceResidualValueChart = data => {
    return (
      <ChartWrapper data-cy='residual-value-chart-price'>
        <AreaChart
          data={(data['20000km'] && data['20000km'].data) || []}
          lineDataKey='value'
          lineDataKeySecondary='idealPrice'
          xAxisDataKey='newYear'
          yAxisUnit=' Kč'
          legendTitlePrimary='Tržní cena'
          legendTitleSecondary='Nejnižší cena'
          yAxisTickFormatter={this.formatAxisValue}
          tooltipContent={e => this.formatTooltipValue(e, 'Kč', 'normal')}
        />
      </ChartWrapper>
    );
  };

  private renderPercentageResidualValueChart = data => {
    return (
      <ChartWrapper data-cy='residual-value-chart-percentage'>
        <AreaChart
          data={(data['20000km'] && data['20000km'].data) || []}
          lineDataKey='valuePerc'
          lineDataKeySecondary='idealPricePerc'
          xAxisDataKey='newYear'
          yAxisUnit=' %'
          legendTitlePrimary='Tržní cena [%]'
          legendTitleSecondary='Nejnižší cena [%]'
          yAxisTickFormatter={this.formatAxisValue}
          tooltipContent={e => this.formatTooltipValue(e, 'Kč', 'percentual')}
        />
      </ChartWrapper>
    );
  };

  private formatAxisValue(value) {
    return `${withSpaces(value)}` || '';
  }

  private formatTooltipValue(e: any, unit: string, type: string): any {
    const hasTooltipData = e.active && e.payload != null && e.payload[0] != null;
    const tooltipData = get(e, 'payload[0].payload', null);
    const value = tooltipData && tooltipData.value;
    const valuePerc = tooltipData && tooltipData.valuePerc;
    const idealPrice = tooltipData && tooltipData.idealPrice;
    const idealPricePerc = tooltipData && tooltipData.idealPricePerc;
    const calculationAccuracy = tooltipData && tooltipData.calculationAccuracy;
    const documentsUsed = tooltipData && tooltipData.documentsUsed;
    if (hasTooltipData) {
      return (
        <div>
          {type === 'normal' && <div>Tržní cena: {withSpaces(round(value, -3))} Kč</div>}
          {type === 'percentual' && isNumber(valuePerc) && (
            <div>
              Tržní cena [%]: <strong>{valuePerc} %</strong>
            </div>
          )}
          {type === 'normal' && isNumber(idealPrice) && (
            <div>
              Nejnižší cena: <strong>{withSpaces(round(idealPrice, -3))} Kč</strong>
            </div>
          )}
          {type === 'percentual' && isNumber(idealPricePerc) && (
            <div>
              Nejnižší cena [%]: <strong>{idealPricePerc} %</strong>
            </div>
          )}
          {isNumber(calculationAccuracy) && (
            <div>
              Přesnost výpočtu: <strong>{toPercent(calculationAccuracy)}</strong>
            </div>
          )}
          {isNumber(documentsUsed) && (
            <div>
              Pro výpočet bylo použito <strong>{documentsUsed}</strong> inzerátů.
            </div>
          )}
        </div>
      );
    }
    return '';
  }
}

export default ResidualValueCharts;
