import React from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';

import { InfoCards } from 'app/components/InfoCards';
import SectionHeader from 'app/components/SectionHeader';
import { SectionHeaderContainer } from 'app/components/_shared_';
import { VerifyVinBaseLayout } from 'app/components/VerifyVin/components/VerifyVinBaseLayout';
import { Row } from 'app/components/Row';

import { loadVehicleRegister } from 'app/api';
import { useVerifyVinRoute } from 'app/hooks';
import {
  actionSetVehicleRegisterLoading,
  actionSetVehicleRegisterFetched,
  actionSetVehicleRegisterData,
} from 'app/actions/ads';
import vehicleRegisterBlurredImg from 'assets/images/vehicle-register-blurred.jpg';
import Ad from 'app/models/Ad';
import { TVehicleRegisterResult } from 'app/types';

const SECTION_TITLE = 'Záznamy v registru vozidel';

export const VehicleRegister: React.FC = () => {
  const dispatch = useDispatch();
  const { accessToken, vin, isAuthenticated, isPremiumRoute, isMobileApp } = useVerifyVinRoute();
  const { data, error, isFetched, isLoading } = useQuery<TVehicleRegisterResult, AxiosError>(
    `loadVehicleRegister-${accessToken}-${vin}`,
    () => loadVehicleRegister(vin, accessToken)
  );

  const setVehicleRegisterData = data => dispatch(actionSetVehicleRegisterData(data));
  const setVehicleRegisterLoading = loading => dispatch(actionSetVehicleRegisterLoading(loading));
  const setVehicleRegisterFetched = fetched => dispatch(actionSetVehicleRegisterFetched(fetched));

  const cardsData = data?.data ? data.data : [];
  const cardsDataLen = data?.total || 0;

  setVehicleRegisterData(cardsData);
  setVehicleRegisterLoading(isLoading);
  setVehicleRegisterFetched(isFetched);

  return (
    <VerifyVinBaseLayout
      blurredImgSrc={vehicleRegisterBlurredImg}
      data={isFetched && cardsData}
      dataError={error?.response?.data.message || ''}
      dataIsLoading={isLoading}
      isAuthorized={isAuthenticated}
      sectionHeaderText={SECTION_TITLE}
      signInText='Dostupné údaje se zobrazují pouze přihlášeným uživatelům'
      subheaderSuffixText={Ad.getNumberOfRecordsTitle(cardsDataLen)}
      subheaderValue={cardsDataLen}
      vin={vin}
    >
      <>
        <SectionHeaderContainer>
          <SectionHeader
            title={SECTION_TITLE}
            subheaderValue={cardsDataLen}
            subheaderSuffixText={Ad.getNumberOfRecordsTitle(cardsDataLen)}
          />
        </SectionHeaderContainer>
        {!isMobileApp && !isPremiumRoute && (
          <Row>
            <img src={vehicleRegisterBlurredImg} alt='blurred-img' />
          </Row>
        )}
        {(isMobileApp || isPremiumRoute) && <PremiumLayout cardsData={cardsData} />}
      </>
    </VerifyVinBaseLayout>
  );
};

const PremiumLayout = ({ cardsData }) => <InfoCards data={cardsData} />;
