import styled from 'styled-components/macro';

interface ITextBadge {
  bgColor?: string;
  textColor?: string;
  lineHeight?: string | number;
}

export const TextBadge = styled.div<ITextBadge>`
  background-color: ${({ theme, bgColor }) =>
    theme.colors[bgColor] || bgColor || theme.colors.bgGray};
  border-radius: 20px;
  font-weight: 600;
  color: ${({ theme, textColor }) => theme.colors[textColor] || textColor || theme.colors.white};
  text-transform: capitalize;
  line-height: ${({ theme, lineHeight }) => lineHeight || '18px'};
  font-size: 0.687rem; //11px
  padding: 3px 10px;
`;
