import * as React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { RadioGroup as ReactRadioGroup, Radio } from 'react-radio-group';
import { FormLabel } from '../Form';
import ErrorMessage from '../../ErrorMessage';

import * as S from './RadioGroup.styles';
import { IRadioGroupOption } from './';

interface IRadioGroup {
  values: IRadioGroupOption[];
  name: string;
  selectedValue: any;
  onChange: (value) => void;
  label?: string;
  touched?: FormikTouched<any>|boolean;
  error?: FormikErrors<any>|string;
}

const RadioGroup: React.SFC<IRadioGroup> = ({
  values,
  name,
  selectedValue,
  onChange,
  error,
  touched,
  label = '',
  ...props
}) => (
  <ReactRadioGroup
    name={name}
    selectedValue={selectedValue}
    onChange={onChange}
  >
    {
      label &&
      <FormLabel>
        {label}
      </FormLabel>
    }
    {
      values && values.length && values.map((item: IRadioGroupOption) => (
        <S.StyledLabel key={item.value}>
          <Radio value={item.value} />{item.label}
        </S.StyledLabel>
      ))
    }
    {
      error && touched &&
      <ErrorMessage
        textAlign='left'
        isVisible={true}
        message={error.toString()}
      />
    }
  </ReactRadioGroup>
);

export default RadioGroup;
