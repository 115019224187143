import Typography from './Typography';

// 32px/2em  |  20px |   18px   |  16px  |  14px  |   13px |   11px  |  9px
export type FontSize =
  | 'extra-big'
  | 'big'
  | 'medium'
  | 'base'
  | 'small'
  | 'mini'
  | 'micro'
  | 'nano';
export type FontWeight =
  | 'thin'
  | 'extra-light'
  | 'light'
  | 'regular'
  | 'medium'
  | 'bold'
  | 'extra-bold'
  | 'heavy';

export default Typography;
