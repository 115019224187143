import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

import { mediaQuery } from 'styles/shared.styles';

// on screen mode flexbox, on print mode 'display: block'
export const PrintFlex = styled(Flex)`
  ${mediaQuery.print} {
    display: block;
  }
`;
