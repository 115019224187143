import * as React from 'react';
import Link from 'app/components/Link';
import Button from 'app/components/Button';
import * as S from '../SimilarAds.styles';

interface INavVerifyVinBtnProps {
  isAuthorized: boolean;
  vin: string;
  showLoginDialog: () => void;
}

export const NavVerifyVinBtn: React.SFC<INavVerifyVinBtnProps> = ({
  isAuthorized,
  vin,
  showLoginDialog,
}) => {
  return isAuthorized ? (
    <S.NavButtonContainer>
      <Link to={`/vin/${vin}`} external={true}>
        <Button visible={true}>Ověřit VIN</Button>
      </Link>
    </S.NavButtonContainer>
  ) : (
    <S.NavButtonContainer>
      <Button onClick={showLoginDialog}>Ověřit VIN</Button>
    </S.NavButtonContainer>
  );
};
