import React from 'react';
import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';

import PremiumBanner from 'app/components/PremiumBanner';
import { LeasingCheck } from 'app/components/VerifyVin/components/LeasingCheck';

export const LeasingNoLookupsError: React.FC = () => (
  <Box width={[1]} sx={{ position: 'relative' }}>
    <PremiumBanner type='dark' overlay={false} bgColor='red'>
      Při ověření došlo k chybě. Kontaktujte nás na{' '}
      <MailToLink href='mailto:info@overauto.cz'>info@overauto.cz</MailToLink>.<br />
      Děkujeme!
    </PremiumBanner>
    <LeasingCheck />
  </Box>
);

const MailToLink = styled.a`
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`;
