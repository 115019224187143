import * as React from 'react';
import Dialog from '../Dialog';
import Button from '../Button';
import * as S from './GdprDialog.styles';

interface IGdprDialogProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface IGdprDialogState { }

class GdprDialog extends React.Component<IGdprDialogProps, IGdprDialogState> {
  public static defaultProps: Partial<IGdprDialogProps> = {
    visible: false,
  };

  public render() {
    const {
      visible,
      onClose,
      onConfirm,
    } = this.props;

    return (
      <Dialog
        visible={visible}
        onClose={onClose}
      >
        <S.Container>
          <S.Content>
            <p>
              Souhlasím se <a href='/privacy-policy' target='_blank'>zpracováním osobních údajů</a><br />dle podmínek <a href='/terms-of-service' target='_blank'>používání aplikace</a>, v souladu s GDPR.
            </p>
          </S.Content>
          <S.ButtonContainers>
            <S.ButtonContainer>
              <Button
                kind={'submit'}
                text={'Souhlasím'}
                fullWidth={true}
                onClick={onConfirm}
                size='normal'
              />
            </S.ButtonContainer>
            <S.ButtonContainer>
              <Button
                kind={'submit'}
                text={'Nesouhlasím'}
                fullWidth={true}
                onClick={onClose}
                size='normal'
              />
            </S.ButtonContainer>
          </S.ButtonContainers>
        </S.Container>
      </Dialog>
    )
  }
}

export default GdprDialog;
