import * as React from 'react';
import { noop, get } from 'lodash-es';
import { Flex, Box } from 'reflexbox/styled-components';

import { InfoMessage } from 'app/components/InfoMessage';
import { AdsListItem } from 'app/components/AdsList/components';
import { ComparedObject } from 'app/models/ComparedObject';
import { DummyChart } from 'app/components/DummyChart';
import AreaChart from 'app/components/Charts/AreaChart/AreaChart';
import Typography from 'app/components/Typography';
import Link from 'app/components/Link';
import Heading from 'app/components/Heading';
import { formatDate, BASE_DATE_FORMAT } from 'app/utils/dates';
import { withSpaces } from 'app/utils/number';
import { SectionWrapperWithPageBreak } from 'app/components/_shared_';
import { PrintFlex } from 'app/components/PrintFlex';

interface IOwnProps {
  data: ComparedObject[];
  dataIsLoading: boolean;
  isAuthorized: boolean;
  isValidVin?: boolean;
  totalPriceChanges?: number;
  firstLastPriceDiff?: number;
  showLoginDialog: () => void;
  setVin: (vin: string) => void;
}

export const AdsListWithPriceHistory: React.SFC<IOwnProps> = ({
  data,
  dataIsLoading,
  isAuthorized,
  showLoginDialog,
  isValidVin,
  setVin,
  totalPriceChanges,
  firstLastPriceDiff,
}) => {
  const hasData = data && !!data.length;
  const hasPriceChanges =
    isValidVin && typeof totalPriceChanges === 'number' && totalPriceChanges > 0;
  if (!hasData) return <Typography text='Litujeme, údaje z inzerce jsou nedostupné.' />;

  return (
    <PrintFlex
      justifyContent={['center', 'flex-start', 'flex-start']}
      flexDirection='column'
      data-cy='ads-list-price-history'
    >
      {hasPriceChanges && (
        <InfoMessage text={getPriceChangeText(totalPriceChanges, firstLastPriceDiff)} />
      )}

      {hasData &&
        data.map((ad, idx) => {
          const chartData = get(ad, 'historyChanges.price.chartData');
          const firstRunFormatted = formatDate(ad.firstRun, '', BASE_DATE_FORMAT);
          const lastRunFormatted = formatDate(ad.lastRun, '', BASE_DATE_FORMAT);
          const badgeText = `${firstRunFormatted} - ${lastRunFormatted}`;
          const key = `${ad.firstRun}${ad.lastRun}${idx}`;

          return (
            <SectionWrapperWithPageBreak
              key={key}
              data-cy={`ads-list-item ads-list-item-${ad.vin}`}
            >
              <Flex mb={hasData ? ['24px', '12px', '12px'] : 0} flexDirection='column'>
                {typeof isValidVin !== 'undefined' && !isValidVin && (
                  <Flex
                    width={1}
                    my={['5px', '10px']}
                    flexDirection='row'
                    alignItems='baseline'
                    sx={{ position: 'relative', zIndex: 100 }}
                  >
                    <Box mr='20px'>
                      <Heading level={3}>{ad.vin}</Heading>
                    </Box>
                    <Link to={`/vin/${ad.vin}`} size='small'>
                      <span onClick={() => setVin(ad.vin)}>ověřit</span>
                    </Link>
                  </Flex>
                )}
                <Flex
                  justifyContent={['center', 'flex-start', 'flex-start']}
                  flexDirection={['column', 'row', 'row']}
                >
                  <Box
                    width={[1, 'auto', 'auto']}
                    mr={[0, '6px', '6px']}
                    mb={['6px', 0, 0]}
                    minWidth='254px'
                  >
                    <AdsListItem
                      adId={ad._id}
                      allowRedirectButton={false}
                      className={'swiper-slide'}
                      currentAdPrice={0}
                      discount={ad.props && ad.props.discount}
                      firstRun={ad.firstRun}
                      fuel={ad.props && ad.props.fuel}
                      id={ad.props && ad.props.id}
                      idx={idx}
                      img={`https://${ad.img}`}
                      isActive={ad.isActive}
                      isAuthorized={isAuthorized}
                      genericBadgeText={badgeText}
                      km={ad.props && ad.props.km}
                      kw={ad.props && ad.props.kw}
                      lastRun={ad.lastRun}
                      listType={'mini'}
                      loading={dataIsLoading}
                      name={`${ad.titleShort}`}
                      onRowMouseEnter={noop}
                      onRowMouseLeave={noop}
                      orderBadgeEnabled={false}
                      price={ad.props && ad.props.price}
                      selected={false}
                      showLoginDialog={showLoginDialog}
                      showBenchmark={false}
                      thumbnail={false}
                      total={ad.props && ad.props.total}
                      transmission={ad.props && ad.props.transmission}
                      year={ad.props && ad.props.year}
                    />
                  </Box>
                  <Box
                    width={[1, 'auto', 'auto']}
                    flex='1 1 auto'
                    ml={[0, '6px', '6px']}
                    minWidth='254px'
                  >
                    {isAuthorized ? (
                      chartData && !!chartData.length ? (
                        <>
                          <AreaChart
                            data={chartData}
                            lineDataKey='value'
                            xAxisDataKey='date'
                            yAxisUnit=' Kč'
                            style={{
                              height: '100%',
                            }}
                          />
                        </>
                      ) : (
                        <Typography text='Litujeme, pro zadané parametry jsme nenašli výsledek.' />
                      )
                    ) : (
                      <DummyChart />
                    )}
                  </Box>
                </Flex>
              </Flex>
            </SectionWrapperWithPageBreak>
          );
        })}
    </PrintFlex>
  );
};

function getPriceChangeText(totalPriceChanges, firstLastPriceDiff) {
  if (!totalPriceChanges) return '';
  const priceLowerHigherText = firstLastPriceDiff < 0 ? 'nižší' : 'vyšší';
  const priceDiff = withSpaces(Math.abs(firstLastPriceDiff));
  return `Vůz měl ${totalPriceChanges}x změněnou cenu. Výsledná cena je o ${priceDiff} Kč ${priceLowerHigherText}.`;
}
