export function round(value: number, decimals: number): number {
  const fractionSize = 10 ** decimals;
  return Math.round(value * fractionSize) / fractionSize;
}

export function roundUp(value: number, decimals: number): number {
  const fractionSize = 10 ** decimals;
  return Math.ceil(value * fractionSize) / fractionSize;
}

export function sign(value: number | string): string {
  const stringValue = value.toString();
  if (stringValue.indexOf('+') < 0 && stringValue.indexOf('-') < 0 && stringValue !== '0') {
    return '+' + stringValue;
  }
  return stringValue;
}

export function withSpaces(value: number | string): string {
  if (typeof value !== 'number') {
    return '';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
}

export function leadingZero(value: number): string {
  return value > 9 ? value.toString() : '0' + value;
}

export function toPercent(value: number, decimals: number = 2): string {
  const result = round(value * 100, decimals) + ' %';
  return result;
}

export function roundWithRange(value: number, range: number[], roundFloor = false): number {
  if (range.indexOf(value) !== -1) {
    return value;
  }
  const diffs = range
    .map((item, index) => {
      const diff = item - value;
      return { diff, value: item, index };
    })
    .sort((a, b) => Math.abs(a.diff) - Math.abs(b.diff));
  const closest = diffs.slice(0, 2);
  return roundFloor
    ? Math.min(closest[0].value, closest[1].value)
    : Math.max(closest[0].value, closest[1].value);
}
