import * as React from 'react';
import { CSSProperties } from 'react';
import {
  ResponsiveContainer,
  AreaChart as RechartsAreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
} from 'recharts';
import * as S from './AreaChart.styles';
import * as ChS from '../Charts.styles';
import { colors } from '../Charts.colors.styles';
import Heading from '../../Heading';
import { withSpaces } from 'app/utils/number';
import { checkViewMode } from 'app/utils';
import { colors as commonColors, fontFamily } from 'styles/shared.styles';

interface IAreaChartProps {
  data: any[];
  title?: string;
  containerHeight?: number;
  lineDataKey: string;
  xAxisDataKey: string;
  yAxisUnit: string;
  lineDataKeySecondary?: string;
  yAxisWidth?: number;
  legendTitlePrimary?: string;
  legendTitleSecondary?: string;
  className?: string;
  style?: CSSProperties;
  tooltipContent?: (tooltipProps: any) => void;
  yAxisTickFormatter?: (value: any, unit: any) => string;
  onChartClick?: (e?: any) => void;
  legendContent?: (e?: any) => void;
}

const defaultProps: Partial<IAreaChartProps> = {
  data: [],
  yAxisTickFormatter: formatAxisValue,
};

const AreaChart: React.SFC<IAreaChartProps> = ({
  title,
  data,
  lineDataKey,
  lineDataKeySecondary,
  tooltipContent,
  xAxisDataKey,
  yAxisUnit,
  yAxisTickFormatter,
  yAxisWidth = 100,
  containerHeight = 280,
  onChartClick,
  legendContent,
  legendTitlePrimary,
  legendTitleSecondary,
  className = '', // custom container styling via styled-components className
  style = {},
}) => {
  const isPdfMode = checkViewMode('pdf');
  return (
    <ChS.ChartContainer className={className} style={style}>
      <ChS.ChartTitle>
        <Heading level={3}>{title}</Heading>
      </ChS.ChartTitle>
      <ResponsiveContainer height={containerHeight} width={'100%'} minWidth={isPdfMode ? 690 : 200}>
        <RechartsAreaChart
          data={data}
          margin={isPdfMode ? ChS.ChartMarginPrint : ChS.ChartMargin}
          onClick={onChartClick}
        >
          <defs>
            <linearGradient id='gradientColorPrimary' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={colors.areaChartStopColorPrimary} stopOpacity={0.8} />
              <stop offset='85%' stopColor={colors.areaChartStopColorPrimary} stopOpacity={0} />
            </linearGradient>
            {lineDataKeySecondary && (
              <linearGradient id='gradientColorSecondary' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='0%'
                  stopColor={colors.areaChartStopColorSecondary}
                  stopOpacity={0.8}
                />
                <stop offset='85%' stopColor={colors.areaChartStopColorSecondary} stopOpacity={0} />
              </linearGradient>
            )}
          </defs>
          <CartesianGrid
            stroke={colors.cartesianGridStrokeColor}
            fillOpacity={0.5}
            vertical={false}
            strokeWidth={1}
          />
          <XAxis
            dataKey={xAxisDataKey}
            axisLine={false}
            tickLine={false}
            height={isPdfMode ? 100 : 50}
            dy={isPdfMode ? 50 : 20}
            dx={0}
            wrapperStyle={{ fontFamily: fontFamily.medium }}
            interval={isPdfMode ? 0 : 'preserveEnd'}
            angle={isPdfMode ? -90 : 0}
            padding={isPdfMode ? { right: 10 } : {}}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickCount={8}
            interval={0}
            width={yAxisWidth}
            unit={yAxisUnit}
            tickFormatter={yAxisTickFormatter}
            wrapperStyle={{ fontFamily: fontFamily.bold }}
          />
          <Tooltip
            content={
              tooltipContent ? tooltipContent : e => formatTooltipValue(e, lineDataKey, yAxisUnit)
            }
          />
          {lineDataKey && lineDataKeySecondary && (
            <Legend
              align='right'
              verticalAlign='top'
              height={36}
              iconSize={16}
              iconType='circle'
              content={legendContent}
            />
          )}
          <Area
            name={legendTitlePrimary}
            type='monotone'
            dataKey={lineDataKey}
            stroke={commonColors.primary}
            fillOpacity={1}
            fill='url(#gradientColorPrimary)'
            dot={{ strokeWidth: 5, r: 5, fill: commonColors.primary }}
          />
          {lineDataKeySecondary && (
            <Area
              name={legendTitleSecondary}
              type='monotone'
              dataKey={lineDataKeySecondary}
              stroke={colors.lineStrokeColorSecondary}
              fillOpacity={1}
              fill='url(#gradientColorSecondary)'
              dot={{ strokeWidth: 5, r: 5, fill: colors.lineStrokeColorSecondary }}
            />
          )}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </ChS.ChartContainer>
  );
};

AreaChart.defaultProps = defaultProps;

export default AreaChart;

/**
 * Default values
 */

function formatTooltipValue(e: any, type: string, unit: string): any {
  const hasTooltipData = e.active && e.payload != null && e.payload[0] != null;
  const value =
    e &&
    e.payload &&
    e.payload.length &&
    e.payload[0] &&
    e.payload[0].payload &&
    e.payload[0].payload[type];
  if (hasTooltipData) {
    return (
      <S.TooltipWrapper>
        <span>{withSpaces(value)}</span>
        <span> {unit}</span>
      </S.TooltipWrapper>
    );
  }
  return '';
}

function formatAxisValue(value: any, unit: any): string {
  return `${withSpaces(value)}` || '';
}
