import styled from 'styled-components/macro';
import { ButtonInputCss } from 'app/components/Elements/Button/ButtonInputCss';

export const Wrapper = styled('div')(
  {
    marginBottom: '10px',
    textTransform: 'capitalize',
  },
  ButtonInputCss
);

export const CancelIconWrapper = styled('div')({
  paddingLeft: '8px',
});
