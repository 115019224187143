import * as React from 'react';
import * as S from './PremiumBanner.styles';

import {
  PositionProps,
  SpaceProps,
  ColorProps,
  BorderRadiusProps,
  BoxShadowProps,
} from 'styled-system';

export type PremiumBannerThemeType = 'light' | 'dark' | 'primary';

interface IOwnProps {
  overlay?: boolean;
  floating?: boolean;
  type?: PremiumBannerThemeType;
  overlayColor?: string;
  bgColor?: string;
}

type TPremiumBannerProps = IOwnProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  BorderRadiusProps &
  BoxShadowProps;

const PremiumBanner: React.SFC<TPremiumBannerProps> = ({
  overlay = false,
  floating = false,
  children,
  overlayColor,
  type,
  bgColor,
  boxShadow,
  ...props
}) => (
  <S.ContentOverlay floating={floating} overlay={overlay} overlayColor={overlayColor} {...props}>
    <S.Wrapper
      floating={floating}
      type={type}
      bgColor={bgColor}
      data-cy={`premium-banner premium-banner-${type}`}
      boxShadow={boxShadow}
    >
      {children}
    </S.Wrapper>
  </S.ContentOverlay>
);

PremiumBanner.defaultProps = {
  overlayColor: 'transparent',
};

export default PremiumBanner;
