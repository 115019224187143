import 'app/utils/polyfills'; // For IE 11 support
import * as React from 'react';
import { Router } from 'react-router-dom';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { App } from 'app/App';
import { baseTheme } from 'styles/theme';
import { history } from 'app/routes/history';
import { configureStore } from 'app/store/configureStore';
import { initSentry } from 'app/utils/sentry';

initSentry();

const store = configureStore({});

const MainApp = (
  <Provider store={store}>
    <ThemeProvider theme={baseTheme}>
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);

const root = document.getElementById('root') as HTMLElement;
if (root.hasChildNodes()) {
  hydrate(MainApp, root); // prerendered mode
} else {
  render(MainApp, root);
}
