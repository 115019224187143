import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { EstimateCarHistoryLogs } from './components/EstimateCarHistoryLogs';
import Container from 'app/components/Container';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';

import { loadEstimateCarHistoryLogs, resetEstimateCarHistoryLogs } from 'app/actions/user';
import { IEstimateCarHistoryLogsResult } from 'app/models/EstimateCarHistory';
import User from 'app/models/User';

interface IEstimateCarHistoryContainerProps {
  user: User;
  isAuthorized: boolean;
  estimateCarHistoryLogsInProgress: boolean;
  estimateCarHistoryLogs: IEstimateCarHistoryLogsResult;
  loadEstimateCarHistoryLogs: (
    userId: string,
    from?: number,
    limit?: number,
    page?: number
  ) => Promise<any>;
  resetEstimateCarHistoryLogs: () => void;
}

interface IEstimateCarHistoryContainerState {}

class EstimateCarHistoryContainer extends React.Component<
  IEstimateCarHistoryContainerProps & RouteComponentProps<{ history: any }>,
  IEstimateCarHistoryContainerState
> {
  public componentDidMount() {
    const { user } = this.props;
    if (user && user.id) {
      this.props.loadEstimateCarHistoryLogs(user.id, 0, 20, 0); // userId, from, limit, page
      return;
    }
    this.redirectToHomepage();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IEstimateCarHistoryContainerProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    if (authHasChanged) {
      const { user } = nextProps;
      if (user && user.id) {
        this.props.loadEstimateCarHistoryLogs(user.id, 0, 20, 0); // userId, from, limit, page
        return;
      }
      this.redirectToHomepage();
    }
  }

  public render() {
    const { estimateCarHistoryLogs, estimateCarHistoryLogsInProgress } = this.props;

    if (estimateCarHistoryLogsInProgress) {
      return <AdLoader text={EnumAdLoaderTexts.LOADING_DATA} fullscreen={true} />;
    }

    return (
      <Container>
        <EstimateCarHistoryLogs data={estimateCarHistoryLogs} loadData={this.loadTableData} />
      </Container>
    );
  }

  private loadTableData = async (from: number = 0, limit: number = 30, page: number) => {
    const { user } = this.props;
    await this.props.loadEstimateCarHistoryLogs(user.id, from, limit, page);
  };

  private redirectToHomepage = () => {
    const { history } = this.props;
    history.push('');
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    estimateCarHistoryLogs: state.user && state.user.estimateCarHistoryLogs,
    estimateCarHistoryLogsInProgress: state.user && state.user.estimateCarHistoryLogsInProgress,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetEstimateCarHistoryLogs: () => dispatch(resetEstimateCarHistoryLogs()),
    loadEstimateCarHistoryLogs: (userId: string, from?: number, limit?: number, page?: number) =>
      dispatch(loadEstimateCarHistoryLogs(userId, from, limit, page)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EstimateCarHistoryContainer)
);
