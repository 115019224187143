import styled from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const AdsListContainer = styled('div')({
  display: 'flex',
  marginBottom: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '.swiper-slide': {
    flexShrink: 'initial',
    margin: '0 0 15px 0',
    ':last-child': {
      margin: 0,
    },
  },
  [mediaQuery.medium]: {
    flexDirection: 'row',
    marginBottom: '30px',
    '.swiper-slide': {
      margin: '0 7px',
    },
  },
});
