import styled from 'styled-components/macro';
import { fontSize } from 'styles/shared.styles';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

export const IconActive = styled(FontAwesomeIcon)`
  font-size: 21px;
  color: #ffd700;
`;

export const IconInactive = styled(FontAwesomeIcon)`
  font-size: 21px;
  color: #7b828e;
`;

export const MyCarListStyled = styled.div({
  whiteSpace: 'nowrap',
});

export const MyCarListSpinnerStyled = styled.div({
  margin: '0 auto',
  width: '40px',
  marginTop: '40px',
});

export const MyCarListEmptyHeaderStyled = styled.span({
  display: 'block',
  fontSize: fontSize.titleBase,
  marginTop: '40px',
  marginBottom: '20px',
  textAlign: 'center',
});

export const MyCarListEmptyTextStyled = styled.p({
  display: 'block',
  fontSize: fontSize.medium,
  textAlign: 'center',
});

export const MyCarItemStyled = styled.div({
  display: 'inline-block',
  background: '#fff',
  borderRadius: '10px',
  padding: '40px 30px 20px',
  position: 'relative',
  width: 'calc(25% - 24px)',
  overflow: 'hidden',
  boxShadow: '0px -4px 18px rgba(0, 0, 0, 0.122509)',
  margin: '16px',
  marginBottom: '32px',
  marginTop: '0',
  ':first-child': {
    marginLeft: 0,
  },
  ':last-child': {
    marginRight: 0,
  },
  '&.size-1': {
    width: 'calc(100% - 32px)',
    margin: '16px',
  },
  '&.size-2': {
    width: 'calc(50% - 16px)',
  },
});

export const MyCarItemColorStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '20px',
});

export const MyCarItemColorWhiteStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '20px',
  borderBottom: '1px solid #7B828E',
});

export const MyCarItemFavoriteBox = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '22px',
  height: '22px',
  cursor: 'pointer',
});

export const MyCarItemTimeStyled = styled.div({
  color: '#7B828E',
  fontSize: fontSize.small,
  marginBottom: '14px',
  marginTop: '6px',
});

export const MyCarItemCarNameStyled = styled.div({
  marginBottom: '4px',
});

export const MyCarItemKeyValueStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '27px',
  fontSize: fontSize.medium,
});

export const MyCarItemKeyStyled = styled.div({
  width: '48%',
  color: '#8B9198',
  fontSize: fontSize.base,
});

export const MyCarItemButtonStyled = styled.a({
  backgroundColor: '#1780FA',
  lineHeight: '37px',
  fontSize: fontSize.medium,
  color: '#fff',
  textAlign: 'center',
  margin: '30px auto 0 auto',
  borderRadius: '4px',
  display: 'block',
  ':hover': {
    color: '#fff',
    textDecoration: 'underline',
  },
});

export const MyCarItemButtonRemoveStyled = styled.a({
  margin: '20px auto 0 auto',
  color: '#4A5560',
  fontSize: fontSize.base,
  textDecoration: 'underline',
  display: 'inline-block',
});

export const MyCarButtonPlace = styled.div({
  marginTop: '23px',
  textAlign: 'center',
});

export const MyCarButtonMoreStyled = styled.a({
  backgroundColor: '#1780FA',
  height: '40px',
  lineHeight: '40px',
  fontSize: fontSize.medium,
  color: '#fff',
  padding: '0 30px',
  cursor: 'pointer',
  textAlign: 'center',
  margin: '30px auto 0 auto',
  borderRadius: '5px',
  display: 'inline-block',
});
