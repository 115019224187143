import { css } from 'styled-components/macro';

const fonts = css`
  @font-face {
    font-family: 'core_sans_a_15_thinregular';
    src: url('/fonts/coresans/CoreSansA15-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA15-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_25_extralightRg';
    src: url('/fonts/coresans/CoreSansA25-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA25-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_35_lightregular';
    src: url('/fonts/coresans/CoreSansA35-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA35-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_45_regularregular';
    src: url('/fonts/coresans/CoreSansA45-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA45-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_55_mediumregular';
    src: url('/fonts/coresans/CoreSansA55-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA55-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_65_boldregular';
    src: url('/fonts/coresans/CoreSansA65-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA65-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_75_extraboldRg';
    src: url('/fonts/coresans/CoreSansA75-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA75-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'core_sans_a_85_heavyregular';
    src: url('/fonts/coresans/CoreSansA85-webfont.woff2') format('woff2'),
      url('/fonts/coresans/CoreSansA85-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

export default fonts;
