import * as React from 'react';
import * as moment from 'moment';
import { get, capitalize, round } from 'lodash-es';

import CarImage from 'app/components/CarImage';
import BlurredText from 'app/components/BlurredText';
import { LoginBanner } from 'app/components/LoginBanner';
import { RedirectButton } from './RedirectButton';
import { ListType } from 'app/components/ListSwitcher';
import { toPercent } from 'app/utils/number';
import { ComparedProperty } from 'app/models/ComparedProperty';
import * as S from './AdsListItem.styles';
import { colors } from 'styles/shared.styles';
import FormattedValue from '../../FormattedValue';

export interface IAdsListItemProps {
  adId: string;
  allowRedirectButton?: boolean;
  className?: string;
  currentAdPrice: number;
  discount: ComparedProperty;
  firstRun: string | undefined;
  fuel: ComparedProperty;
  id: ComparedProperty;
  idx: number;
  img: string;
  isActive: boolean;
  isAuthorized: boolean;
  km: ComparedProperty;
  kw: ComparedProperty;
  lastRun: string | undefined;
  listType: ListType;
  loading?: boolean;
  name: string;
  orderBadgeEnabled?: boolean;
  price: ComparedProperty;
  selected?: boolean;
  showBenchmark?: boolean;
  thumbnail?: boolean;
  total: ComparedProperty;
  transmission: ComparedProperty;
  year: ComparedProperty;
  genericBadgeText?: string;
  onRowMouseEnter?: (rowClassName: string, isAuthorized: boolean) => void;
  onRowMouseLeave?: (rowClassName: string, isAuthorized: boolean) => void;
  showLoginDialog: () => void;
}

export interface IRowProps {
  isBetterOrSame: boolean;
  selected: boolean | undefined;
}

export interface IColumnLoaderProps {
  large?: boolean;
}

export const AdsListItem: React.SFC<IAdsListItemProps> = (props: IAdsListItemProps) => {
  const {
    adId,
    className,
    currentAdPrice,
    firstRun,
    idx,
    img,
    isActive,
    isAuthorized,
    lastRun,
    listType,
    loading,
    name,
    onRowMouseEnter,
    onRowMouseLeave,
    selected,
    showLoginDialog,
    orderBadgeEnabled,
    total,
    genericBadgeText,
    allowRedirectButton = true,
    thumbnail = true,
    showBenchmark = true,
  } = props;
  const REGISTRATION_TEXT = 'Registrovat';

  return (
    <S.Wrapper
      selected={listType !== 'price-advisor' && listType !== 'estimate-car' && selected}
      className={className}
      data-cy={`ads-list-item ads-list-item-${listType}`}
    >
      {!isAuthorized && !selected && <LoginBanner />}
      {listType !== 'price-advisor' && listType !== 'estimate-car' && selected && (
        <S.YourOffer>Váš inzerát</S.YourOffer>
      )}
      <S.ImageWrapper thumbnail={thumbnail}>
        {thumbnail && <CarImage src={img} />}
        {orderBadgeEnabled && <S.OrderBadge>{idx + 1}</S.OrderBadge>}
        {(listType === 'price-advisor' || listType === 'estimate-car') && (
          <S.GenericBadge
            text={
              selected ? (listType === 'price-advisor' ? 'Váš inzerát' : 'Váš vůz') : 'Výhodný vůz'
            }
            bgColor={selected ? colors.primary : colors.darkGreen}
          />
        )}
        {(listType === 'price-advisor' || listType === 'estimate-car') && genericBadgeText && (
          <S.GenericBadge
            text={selected ? genericBadgeText : 'Výhodný vůz'}
            bgColor={selected ? colors.primary : colors.darkGreen}
          />
        )}
        {typeof currentAdPrice === 'number' &&
          listType === 'discount' &&
          get(props, 'discount.value', null) && (
            <S.SaleBadge>
              Sleva {toPercent(get(props, 'discount.value', null) / currentAdPrice, 0)}
            </S.SaleBadge>
          )}
        {listType === 'historical' && !isActive && (
          <S.SoldBadge>
            Prodáno <span>{moment(lastRun).format('DD.MM.YYYY')}</span>
          </S.SoldBadge>
        )}
        {listType === 'newest' && (
          <S.NewBadge>
            Přidáno <span>{moment(firstRun).format('DD.MM.YYYY')}</span>
          </S.NewBadge>
        )}

        {listType === 'mini' && (
          <S.GenericBadgeRight
            text={genericBadgeText || moment(lastRun).format('DD.MM.YYYY')}
            type=''
            bgColor={colors.primary}
          />
        )}
        {listType === 'vin' &&
          (isActive ? (
            <S.GenericBadgeRight text='Aktivní' type='positive' />
          ) : (
            <S.GenericBadgeRight
              text={moment(lastRun).format('DD.MM.YYYY')}
              type=''
              bgColor={colors.red}
            />
          ))}
      </S.ImageWrapper>
      <S.Content>
        <S.Name>{name}</S.Name>
        <S.Table>
          <S.TableBody>
            <S.Row
              isBetterOrSame={get(props, 'price.isBetterOrSame', '')}
              onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--price', isAuthorized)}
              onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--price', isAuthorized)}
              className={`ads-list-item__row--price${
                (listType === 'price-advisor' || listType === 'estimate-car') &&
                get(props, 'price.isBetterOrSame', '')
                  ? ' is-better'
                  : ''
              }`}
              selected={selected}
            >
              <S.Column>Cena</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={get(props, 'price.value', '')}
                      unit='Kč'
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            <S.Row
              isBetterOrSame={get(props, 'year.isBetterOrSame', '')}
              onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--year', isAuthorized)}
              onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--year', isAuthorized)}
              className={`ads-list-item__row--year${
                (listType === 'price-advisor' || listType === 'estimate-car') &&
                get(props, 'year.isBetterOrSame', '')
                  ? ' is-better'
                  : ''
              }`}
              selected={selected}
            >
              <S.Column>Vyrobeno</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={get(props, 'year.value', '')}
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                      formattedWithSpaces={false}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            <S.Row
              isBetterOrSame={get(props, 'km.isBetterOrSame', '')}
              onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--km', isAuthorized)}
              onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--km', isAuthorized)}
              className={`ads-list-item__row--km${
                (listType === 'price-advisor' || listType === 'estimate-car') &&
                get(props, 'km.isBetterOrSame', '')
                  ? ' is-better'
                  : ''
              }`}
              selected={selected}
            >
              <S.Column>Nájezd</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={get(props, 'km.value', '')}
                      unit='km'
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            <S.Row
              isBetterOrSame={get(props, 'fuel.isBetterOrSame', '')}
              onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--fuel', isAuthorized)}
              onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--fuel', isAuthorized)}
              className={'ads-list-item__row--fuel'}
              selected={selected}
            >
              <S.Column>Palivo</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={capitalize(get(props, 'fuel.value', ''))}
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            <S.Row
              isBetterOrSame={get(props, 'transmission.isBetterOrSame', null)}
              onMouseEnter={() =>
                onRowMouseEnter?.('.ads-list-item__row--transmission', isAuthorized)
              }
              onMouseLeave={() =>
                onRowMouseLeave?.('.ads-list-item__row--transmission', isAuthorized)
              }
              className={'ads-list-item__row--transmission'}
              selected={selected}
            >
              <S.Column>Převodovka</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={capitalize(get(props, 'transmission.value', ''))}
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            <S.Row
              isBetterOrSame={get(props, 'kw.isBetterOrSame', '')}
              onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--kw', isAuthorized)}
              onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--kw', isAuthorized)}
              className={`ads-list-item__row--kw${
                (listType === 'price-advisor' || listType === 'estimate-car') &&
                get(props, 'kw.isBetterOrSame', '')
                  ? ' is-better'
                  : ''
              }`}
              selected={selected}
            >
              <S.Column>Výkon</S.Column>
              <S.ColumnValue>
                <BlurredText enabled={!isAuthorized && !selected}>
                  {loading ? (
                    <S.ColumnLoader />
                  ) : !isAuthorized && !selected ? (
                    REGISTRATION_TEXT
                  ) : (
                    <FormattedValue
                      value={get(props, 'kw.value', '')}
                      unit='kW'
                      size='base'
                      weight='medium'
                      color={colors.textDefault}
                    />
                  )}
                </BlurredText>
              </S.ColumnValue>
            </S.Row>
            {total && showBenchmark && (
              <S.Row
                isBetterOrSame={get(props, 'total.isBetterOrSame', '')}
                onMouseEnter={() => onRowMouseEnter?.('.ads-list-item__row--total', isAuthorized)}
                onMouseLeave={() => onRowMouseLeave?.('.ads-list-item__row--total', isAuthorized)}
                className={'ads-list-item__row--total'}
                selected={selected}
              >
                <S.LastColumn>Hodnocení vozidla</S.LastColumn>
                <S.LastColumn>
                  <BlurredText enabled={!isAuthorized && !selected}>
                    {loading ? (
                      <S.ColumnLoader large={true} />
                    ) : !isAuthorized && !selected ? (
                      '0%'
                    ) : (
                      <FormattedValue
                        value={round(get(props, 'total.value', ''))}
                        unit='%'
                        size='extra-big'
                        weight='bold'
                        color={colors.textDefault}
                      />
                    )}
                  </BlurredText>
                </S.LastColumn>
              </S.Row>
            )}
          </S.TableBody>
        </S.Table>
        {allowRedirectButton && (
          <S.ButtonRowWrapper>
            <RedirectButton
              id={get(props, 'id.value', null) ? adId : ''}
              external={listType === 'price-advisor' || listType === 'estimate-car'}
              onClick={showLoginDialog}
              visible={listType !== 'estimate-car'}
            />
          </S.ButtonRowWrapper>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

AdsListItem.defaultProps = {
  orderBadgeEnabled: true,
};

export default AdsListItem;
