import React from 'react';

import Heading from 'app/components/Heading';
import Typography from 'app/components/Typography';
import { Column } from 'app/components/Column';
import { Row } from 'app/components/Row';

export const ComparatorHeader: React.FC = () => (
  <Row justifyContent='center' mb='50px'>
    <Column width={[1, 1, '840px']} alignItems='center'>
      <Heading level={2} weight='bold'>
        Vyberte si ověření na míru
      </Heading>
      <Typography
        text='Udělejte si základní přehled zdarma. V případě vážného zájmu o vozidlo doporučujeme premium report pro kompletní přehled.'
        size='medium'
        textAlign='center'
      />
    </Column>
  </Row>
);
