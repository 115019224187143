import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { mediaQuery } from 'styles/shared.styles';

interface IHeaderProps {
  light: string;
}

export const Container = styled('div')({
  maxWidth: '1200px',
  height: '100%',
  margin: '0 auto',
});

export const Wrapper = styled('div')(
  {
    width: '100%',
    position: 'relative',
    [mediaQuery.medium]: {
      minHeight: '72px',
      height: '72px',
    },
    [mediaQuery.print]: {
      display: 'none',
    },
  },
  (props: IHeaderProps) => ({
    backgroundColor: props.light ? 'transparent' : '#4A5560',
  })
);

export const Navigation = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'row',
  padding: '20px 12px',
  [mediaQuery.medium]: {
    padding: 0,
  },
});

export const LogoWrapper = styled(Link)`
  height: 100%;
  display: block;
`;

export const LeftWrapper = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'flex-start',
  flexDirection: 'column',
  [mediaQuery.medium]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const RightWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});
