import * as React from 'react';
import { Transition, animated, config } from 'react-spring/dist/web.js';

import * as S from './Dialog.styles';
import closeIcon from '../../../assets/images/auth-dialog/Close.svg';

interface IDialogProps {
  children: React.ReactNode;
  visible: boolean;
  onClickOutsideAllowed?: boolean;
  hideBackdrop?: boolean;
  contentClassName?: string;
  onClose?: () => void;
}

const defaultProps: Partial<IDialogProps> = {
  onClickOutsideAllowed: true,
  hideBackdrop: false,
};

class Dialog extends React.Component<IDialogProps> {
  public static defaultProps: Partial<IDialogProps> = defaultProps;

  private dialogRef;

  public componentDidMount() {
    document.addEventListener('click', this.docClickListener, true);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.docClickListener, true);
    this.removeActiveClass();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IDialogProps) {
    if (nextProps.visible) {
      this.addActiveClass();
    }

    if (!nextProps.visible) {
      this.removeActiveClass();
    }
  }

  public render() {
    const { visible, children, onClose, hideBackdrop } = this.props;

    return (
      <React.Fragment>
        <Transition
          native={true}
          from={{ opacity: 0 }}
          enter={{ opacity: 0.46 }}
          leave={{ opacity: 0 }}
        >
          {visible && !hideBackdrop ? this.renderBackdrop : null}
        </Transition>
        <Transition
          from={{
            opacity: 0,
            transform: 'translate3d(-50%, -100%,0)',
            backfaceVisibility: 'hidden',
            WebkitFilter: 'blur(0)',
          }}
          enter={{
            opacity: 1,
            transform: 'translate3d(-50%,-50%,0)',
            backfaceVisibility: 'hidden',
            WebkitFilter: 'blur(0)',
          }}
          leave={{
            opacity: 0,
            transform: 'translate3d(-50%,-100%,0)',
            backfaceVisibility: 'hidden',
            WebkitFilter: 'blur(0)',
          }}
          onClose={(onClose && this.onDialogClose) || null}
          content={children}
          config={{
            ...config.default,
            delay: 0,
            duration: 50,
          }}
        >
          {visible ? this.renderDialogContent : null}
        </Transition>
      </React.Fragment>
    );
  }

  private onDialogClose = () => {
    const { onClose } = this.props;
    if (!onClose) {
      return;
    }

    this.removeActiveClass();
    onClose();
  };

  private addActiveClass = () => {
    if (document.documentElement) {
      document.documentElement.classList.add('dialog--active');
    }
  };

  private removeActiveClass = () => {
    if (document.documentElement) {
      document.documentElement.classList.remove('dialog--active');
    }
  };

  private updateDialogRef = el => (this.dialogRef = el);

  private docClickListener = evt => {
    if (!this.dialogRef || this.dialogRef.contains(evt.target)) {
      return;
    }
    if (this.props.onClickOutsideAllowed && this.props.visible && this.props.onClose) {
      this.props.onClose();
    }
  };

  private renderBackdrop = style => {
    return <animated.div className={S.backdropStyle} style={{ ...style }} />;
  };

  private renderDialogContent = style => {
    const { onClose, content, contentClassName } = style;
    return (
      <S.DialogWrapper style={{ ...style }} innerRef={this.updateDialogRef}>
        <S.DialogContent className={`dialog__content ${contentClassName ? contentClassName : ''}`}>
          {onClose && <S.IconClose src={closeIcon} alt='close-icon' onClick={onClose} />}
          {content}
        </S.DialogContent>
      </S.DialogWrapper>
    );
  };
}

export default Dialog;
