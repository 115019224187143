import React, { useState } from 'react';

import { Row, TRowProps } from 'app/components/Row';
import Typography from 'app/components/Typography';
import { TFnWithArgs } from 'app/types';

interface IFilter {
  name: string;
  value: string;
}

interface IFilterBoxProps extends TRowProps {
  title?: string;
  filters: IFilter[];
  onFilterChange?: TFnWithArgs;
  defaultFilter?: string;
}

export const FilterBox: React.FC<IFilterBoxProps> = ({
  title = '',
  filters = [],
  defaultFilter = '',
  onFilterChange,
  ...props
}) => {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter || filters[0]?.name);
  const onFilterSelected = (name, value) => {
    onFilterChange?.(value, name);
    setSelectedFilter(name);
  };

  return (
    <Row alignItems='center' flexDirection={['column', 'row']} {...props}>
      <Typography size='small' text={title} mr={[0, '11px']} mb={[2, 0]} color='textDefault' />
      <Row>
        <Filters
          selectedFilter={selectedFilter}
          filters={filters}
          onFilterSelected={onFilterSelected}
        />
      </Row>
    </Row>
  );
};

const Filters = ({ selectedFilter, filters, onFilterSelected }) => (
  <>
    {filters.map((filter, idx) => (
      <FilterItem
        key={filter.name}
        name={filter.name}
        isFirst={idx === 0}
        isLast={idx === filters.length - 1}
        isSelected={selectedFilter === filter.name}
        onClick={() => onFilterSelected(filter.name, filter.value)}
      />
    ))}
  </>
);

const FilterItem = ({ name, isFirst, isLast, isSelected, onClick }) => (
  <Row
    background={isSelected ? '#1780FA' : 'rgba(2, 117, 216, 0.05)'}
    borderRadius={isFirst ? '4px 0 0 4px' : isLast ? '0 4px 4px 0' : 'none'}
    border='1px solid #1780FA'
    borderRight={isLast ? '1px solid #1780FA' : 'none'}
    onClick={onClick}
    px='20px'
    withHover={true}
    data-cy={`filter-box-item filter-box-item-${name}`}
  >
    <Typography
      color={isSelected ? 'rgba(255, 255, 255, 0.95)' : '#1780FA'}
      lineHeight='32px'
      weight='medium'
      size='mini'
      text={name}
    />
  </Row>
);
