import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { toPercent } from '../utils/number';
import { colors } from '../../styles/shared.styles';

const Wrapper = styled('div')({
  position: 'relative',
  display: 'block',
  width: '100%',
  height: '30px',
  backgroundColor: '#D8D8D8',
  borderRadius: '4px',
});

const ProgressBar = styled('div')(
  {
    backgroundColor: '#BFC849',
    position: 'absolute',
    left: '0',
    top: '0',
    height: '100%',
    borderRadius: '4px 0 0 4px',
  },
  (props: IProgressProps) =>
    props.percent <= 25
      ? { backgroundColor: colors.red }
      : null || (props.percent > 25 && props.percent <= 50)
      ? { backgroundColor: colors.yellow }
      : null || (props.percent > 50 && props.percent <= 75)
      ? { backgroundColor: colors.blue }
      : null || props.percent > 75
      ? { backgroundColor: colors.darkGreen }
      : null
);

const smallSize = css({
  height: '5px',
});

interface IProgressProps {
  percent: number;
  title?: string;
  size?: 'normal' | 'small';
}

const defaultProps: Partial<IProgressProps> = {
  size: 'normal',
  percent: 0,
};

const Progress: React.SFC<IProgressProps> = ({ percent, title, size, ...other }) => {
  const progressBarStyle = {
    width: percent + '%',
  };

  const cssClass = css(size === 'small' ? smallSize : null);

  return (
    <Wrapper className={cssClass} title={toPercent(percent / 100)} {...other}>
      <ProgressBar style={progressBarStyle} percent={percent} />
    </Wrapper>
  );
};

Progress.defaultProps = defaultProps;

export default Progress;
