import styled from 'styled-components/macro';
import NavigationLink from 'app/components/NavigationLink';
import { mediaQuery, colors, fontFamily } from 'styles/shared.styles';

export const Wrapper = styled('div')({
  display: 'none',
  [mediaQuery.medium]: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    margin: '0 0 0 20px',
  },
});

export const LinkWithBackground = styled(NavigationLink)({
  backgroundColor: '#404B55',
  color: colors.white,
  fontFamily: fontFamily.medium,
  height: '100%',
  padding: '20px',
  ':focus': {
    color: colors.white,
  },
  ':hover': {
    cursor: 'pointer',
    color: 'white',
    borderBottomColor: 'transparent',
    textDecoration: 'underline',
  },
  '&.active': {
    color: colors.white,
    borderBottomColor: 'transparent',
  },
  [mediaQuery.medium]: {
    padding: '0 21px',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: '#404B55',
    },
  },
});
