import * as React from 'react';
import * as S from './BlurredText.styles';

interface IBlurredTextProps {
  enabled: boolean;
  children: React.ReactNode;
}

const BlurredText: React.SFC<IBlurredTextProps> = ({ enabled, children, ...props }) => (
  <S.Container enabled={enabled} {...props}>
    {children}
  </S.Container>
);

export default BlurredText;
