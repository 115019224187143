import * as React from 'react';
import Typography from '../Typography';
import { AdParametersMini } from './components/AdParametersMini';
import NotSpecifiedValue from 'app/components/NotSpecifiedValue';
import Badge from 'app/components/Badge';
import { withSpaces } from 'app/utils/number';
import { safe } from 'app/utils/safe';
import Ad from 'app/models/Ad';
import { colors } from 'styles/shared.styles';
import {
  IconContainer,
  ParametersRow,
  ParametersColumn,
  ParametersTitleTypography,
  ParametersValueTypography,
  ParametersTitle,
  ParametersValue,
} from 'app/components/_shared_';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import * as faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import * as faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import * as S from './AdParameters.styles';

interface IAdParametersProps {
  ad: Ad;
  mini?: boolean;
}

export const AdParameters: React.SFC<IAdParametersProps> = ({ ad, mini }) => {
  if (!ad) {
    return null;
  }

  const currentYear = new Date().getFullYear();
  if (mini) {
    return <AdParametersMini ad={ad} />;
  }

  return (
    <S.Container>
      <ParametersRow>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Značka'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.brand, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Stav vozidla'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.stav, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Barva'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.color && ad.color.replace(/,/g, ''), <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
      </ParametersRow>

      <ParametersRow striped={true}>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Model'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.model, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Výkon'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => (ad.kw ? `${ad.kw} kW` : null), <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'První majitel'} />
          </ParametersTitle>
          <ParametersValue>
            {ad.firstOwner === true ? (
              <Badge
                icon={faCheckCircle}
                text={'Ano'}
                textSize='base'
                textWeight='medium'
                type='positive-dark'
              />
            ) : ad.firstOwner === false ? (
              <Badge
                icon={faTimesCircle}
                text={'Ne'}
                type='negative'
                textSize='base'
                textWeight='medium'
              />
            ) : (
              <NotSpecifiedValue />
            )}
          </ParametersValue>
        </ParametersColumn>
      </ParametersRow>

      <ParametersRow>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Země původu'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.countryOrigin, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <Typography text={'Najeto'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => (ad.km ? `${withSpaces(ad.km)} km` : null), <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'STK'} />
          </ParametersTitle>
          <ParametersValue>
            {ad.stk && ad.stk >= currentYear ? (
              <Badge
                icon={faCheckCircle}
                text={`${ad.stk}`}
                textSize='base'
                textWeight='medium'
                type='positive-dark'
              />
            ) : typeof ad.stk === 'number' ? (
              <Badge
                icon={faTimesCircle}
                text={`${ad.stk}`}
                textSize='base'
                textWeight='medium'
                type='negative'
              />
            ) : (
              <NotSpecifiedValue />
            )}
          </ParametersValue>
        </ParametersColumn>
      </ParametersRow>

      <ParametersRow striped={true}>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Palivo'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.fuel, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Karoserie'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.karoserie, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Datum první registrace'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.year, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
      </ParametersRow>

      <ParametersRow>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Převodovka'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.transmission, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Klimatizace'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(
                () => (!ad.ac || ad.ac.indexOf(' km') > -1 ? null : Ad.getAcTitle(ad.ac)),
                <NotSpecifiedValue text='Ne' />
              )}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Servisní knížka'} />
          </ParametersTitle>
          <ParametersValue>
            {ad.serviceBook === true ? (
              <>
                <Typography text={'Ano'} weight='medium' color={colors.darkGreen} />
                <IconContainer>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: colors.darkGreen }} />
                </IconContainer>
              </>
            ) : ad.serviceBook === false ? (
              <>
                <Typography text={'Ne'} color={colors.red} />
                <IconContainer>
                  <FontAwesomeIcon icon={faExclamationCircle} style={{ color: colors.red }} />
                </IconContainer>
              </>
            ) : (
              <NotSpecifiedValue />
            )}
          </ParametersValue>
        </ParametersColumn>
      </ParametersRow>

      <ParametersRow striped={true}>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'Počet stupňů'} />
          </ParametersTitle>
          <ParametersValue>
            <ParametersValueTypography
              text={safe(() => ad.gears, <NotSpecifiedValue />)}
              weight='medium'
            />
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={'VIN'} />
          </ParametersTitle>
          <ParametersValue>
            {ad.vin ? (
              <S.StyledLink to={`/vin/${ad.vin}`} external={true}>
                {ad.vin}
              </S.StyledLink>
            ) : (
              <NotSpecifiedValue />
            )}
          </ParametersValue>
        </ParametersColumn>
        <ParametersColumn>
          <ParametersTitle>
            <ParametersTitleTypography text={''} />
          </ParametersTitle>
          <ParametersValue />
        </ParametersColumn>
      </ParametersRow>
    </S.Container>
  );
};
