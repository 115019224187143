import { rest } from 'app/api';
import { TPaymentCategory } from 'app/types';
import { log } from 'app/utils/log';

export const apiCreatePayment = (vin: string, token: string, paymentCategory: TPaymentCategory) =>
  rest.post(
    '/payments',
    { vin, paymentCategory },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const apiGetPayment = (vin?: string, orderId?: string | null, token?: string) =>
  rest.get(`/payments/status`, {
    params: {
      vin,
      orderId,
    },
    headers: {
      Authorization: token,
    },
  });

export const apiGetUserPayments = (page = 0, pageSize = 20, userId, token?: string) =>
  rest.get(`/users/${userId}/payments`, {
    params: {
      from: pageSize * page,
      limit: pageSize,
      page,
    },
    headers: {
      Authorization: token,
    },
  });

export const apiVerifyVinPayment = (vin: string, orderId: string | null, token?: string) =>
  rest.get(`/payments/verify-vin/${vin}`, {
    params: {
      orderId,
    },
    headers: {
      Authorization: token,
    },
  });

export const apiDownloadInvoice = (invoiceId: string, token: string) =>
  rest.get(`/invoices/${invoiceId}/pdf`, {
    responseType: 'blob',
    timeout: 0,
    headers: {
      Accept: 'application/octet-stream',
      Authorization: token,
    },
  });

export const createPayment = async (
  vin: string,
  paymentType: TPaymentCategory = 'vinPremium',
  accessToken: string
) => {
  try {
    const payment = await apiCreatePayment(vin, accessToken, paymentType);
    return payment?.data?.data;
  } catch (err) {
    log.error('createPayment:: err: ', err);
    return Promise.reject(err);
  }
};
