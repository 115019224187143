import NotificationType from '../models/NotificationType';
import * as CONSTANTS from 'app/constants/app';

export function pushNotification(
  key: string,
  text: string,
  type?: NotificationType,
  timeout?: number
) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch({
      type: CONSTANTS.PUSH_NOTIFICATION,
      payload: {
        key,
        text,
        type,
        timeout,
      },
    });
  };
}

export function popNotification(key) {
  return {
    type: CONSTANTS.POP_NOTIFICATION,
    payload: {
      key,
    },
  };
}

export function clearNotifications() {
  return {
    type: CONSTANTS.CLEAR_NOTIFICATIONS,
  };
}

export function showLoginDialog() {
  return {
    type: CONSTANTS.SHOW_LOGIN_DIALOG,
  };
}

export function hideLoginDialog() {
  return {
    type: CONSTANTS.HIDE_LOGIN_DIALOG,
  };
}

export function showSignupDialog() {
  return {
    type: CONSTANTS.SHOW_SIGNUP_DIALOG,
  };
}

export function hideSignupDialog() {
  return {
    type: CONSTANTS.HIDE_SIGNUP_DIALOG,
  };
}

export function showForgotPasswordDialog() {
  return {
    type: CONSTANTS.SHOW_FORGOT_PASSWORD_DIALOG,
  };
}

export function hideForgotPasswordDialog() {
  return {
    type: CONSTANTS.HIDE_FORGOT_PASSWORD_DIALOG,
  };
}

export function setListType(listType: string = 'top') {
  return {
    type: CONSTANTS.SET_LIST_TYPE,
    payload: {
      listType,
    },
  };
}
export function setSelectedTab(selectedTab: { pathname: string; title: string }) {
  return {
    type: CONSTANTS.SET_SELECTED_TAB,
    payload: {
      selectedTab,
    },
  };
}
