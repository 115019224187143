import * as React from 'react';
import { connect } from 'react-redux';
import Heading from '../components/Heading';
import Page from './Page/Page';
import Typography from './Typography';
import LinkButton from './LinkButton';
import { showLoginDialog } from '../actions/app';

interface IOwnProps {}

interface IStateProps {
  isAuthorized: boolean;
}

interface IStateDispatchProps {
  showLoginDialog: () => void;
}

type TFeedbackProps = IOwnProps & IStateProps & IStateDispatchProps;

class Feedback extends React.Component<TFeedbackProps> {
  public render() {
    const { isAuthorized } = this.props;

    return (
      <Page
        textAlign='left'
        itemsAlign='center'
        componentName='feedback'
        documentTitle='Zpětná vazba'
      >
        <Heading level={1}>Zpětná vazba</Heading>
        {!isAuthorized && this.renderUnathorized()}
        {isAuthorized && this.renderAuthorized()}
      </Page>
    );
  }

  private renderUnathorized() {
    return (
      <div>
        <div className={'row'}>
          <div className={'col-xs-12'}>
            <Typography text={'Pro vyplnění dotazníku se musíte přihlásit'} />
            <LinkButton onClick={this.props.showLoginDialog}>přihlásit</LinkButton>.
          </div>
        </div>
      </div>
    );
  }

  private renderAuthorized() {
    return (
      <div className='row center-xs'>
        <div className={'col-xs-12'}>
          <iframe
            width={800}
            height={900}
            frameBorder={0}
            marginWidth={0}
            marginHeight={0}
            title='form'
            src={
              'https://docs.google.com/forms/d/e/1FAIpQLSfemcsYhqDisEcf5fSNlcyHYHImY4lbEN9BTrBEX24DonsOaA/viewform?embedded=true'
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthorized: !!state.auth.accessToken,
});

const mapDispatchToProps = dispatch => ({
  showLoginDialog: () => dispatch(showLoginDialog()),
});

export default connect<IStateProps, IStateDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);
