import { connect } from 'react-redux';
import * as React from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import { capitalize } from 'lodash-es';

import Ad from 'app/models/Ad';
import AdFilter from 'app/models/AdFilter';
import AvgMonthValues from 'app/models/AvgMonthValues';
import Heading from 'app/components/Heading';
import LinkButton from 'app/components/LinkButton';
import ErrorMessage from 'app/components/ErrorMessage';
import { FilterBar } from 'app/components/Filter';
import StatisticsBlock from './StatisticsBlock';
import StatisticsCharts, { IStatisticsChartsValue } from './StatisticsCharts';
import Typography from 'app/components/Typography';
import { leadingZero, withSpaces } from 'app/utils/number';
import * as S from './Statistics.styles';

import { showLoginDialog } from 'app/actions/app';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';

interface IStatisticsProps {
  ad: Ad | null;
  avgMonthValues: AvgMonthValues[];
  filter: AdFilter;
  isAuthorized: boolean;
  showLoginDialog: () => void;
}

interface IStatisticsState {
  priceHistory: IStatisticsChartsValue[];
  kmHistory: IStatisticsChartsValue[];
  ageHistory: IStatisticsChartsValue[];
  discountHistory: IStatisticsChartsValue[];
  discountPercentHistory: IStatisticsChartsValue[];
  equipmentHistory: IStatisticsChartsValue[];
  carsAddedHistory: IStatisticsChartsValue[];
  carsSoldHistory: IStatisticsChartsValue[];
}

class Statistics extends React.Component<IStatisticsProps, IStatisticsState> {
  public state: IStatisticsState = {
    priceHistory: [],
    kmHistory: [],
    ageHistory: [],
    discountHistory: [],
    discountPercentHistory: [],
    equipmentHistory: [],
    carsAddedHistory: [],
    carsSoldHistory: [],
  };

  public componentDidMount() {
    this.mapAvgValuesToChartData();
  }

  public componentDidUpdate(prevProps: IStatisticsProps) {
    if (this.props.ad && (!prevProps.ad || this.props.ad._id !== prevProps.ad._id)) {
      this.mapAvgValuesToChartData();
    }
  }

  public render() {
    const { avgMonthValues, isAuthorized, ad, filter } = this.props;

    if (!ad || !filter) {
      return null;
    }

    const avgValues = avgMonthValues[avgMonthValues.length - 1];

    if (!isAuthorized) {
      return (
        <Flex width='100%' alignItems='center' mb={4} mt={4}>
          <Box width={1}>
            <Typography text={'Pro zobrazení statistik se musíte'} />
            <LinkButton onClick={this.login}>přihlásit</LinkButton>.
          </Box>
        </Flex>
      );
    }

    return (
      <div>
        <Flex width='100%' alignItems='center' mb={4} mt={4}>
          <Box width={1}>
            <Heading level={2}>Statistiky</Heading>
            {this.getFilterString(ad.brand, ad.model)}
            <S.FilterBarContainer>
              <FilterBar />
            </S.FilterBarContainer>
          </Box>
        </Flex>

        {avgMonthValues && !!avgMonthValues.length && (
          <>
            <Flex width='100%' alignItems='center' mb={1}>
              <Box width={1}>
                <Heading level={3}>Aktuální situace na trhu</Heading>
              </Box>
            </Flex>

            <Flex width='100%' alignItems='center'>
              <Box width={[1, 1 / 3]} mr={3} mb={3}>
                <StatisticsBlock
                  title={'Průměrná cena'}
                  value={withSpaces(Math.round(avgValues.avgPrice))}
                  units={'Kč'}
                />
              </Box>
              <Box width={[1, 1 / 3]} mr={3} mb={3}>
                <StatisticsBlock
                  title={'Průměrně najeto'}
                  value={withSpaces(Math.round(avgValues.avgKm))}
                  units={'km'}
                />
              </Box>
              <Box width={[1, 1 / 3]} mb={3}>
                <StatisticsBlock
                  title={'Průměrné stáří inzerátu'}
                  value={leadingZero(Math.round(avgValues.avgAdAge))}
                  units={'dní'}
                />
              </Box>
            </Flex>

            <Flex width='100%' alignItems='center' mb={4}>
              <Box width={[1, 1 / 3]} mr={3} mb={3}>
                <StatisticsBlock
                  title={'Průměrná sleva'}
                  value={withSpaces(Math.round(avgValues.avgDiscount))}
                  units={'Kč'}
                />
              </Box>
              <Box width={[1, 1 / 3]} mr={3} mb={3}>
                <StatisticsBlock
                  title={'Podíl zlevněných vozů'}
                  value={Math.round(avgValues.discountedPercent * 100) + '%'}
                />
              </Box>
              <Box width={[1, 1 / 3]} mb={3}>
                <StatisticsBlock
                  title={'Průměrná vybavenost'}
                  value={leadingZero(Math.round(avgValues.avgEquipmentCount))}
                  units={'prvků'}
                />
              </Box>
            </Flex>

            <Flex width='100%' alignItems='center'>
              <Box width={1} mr={1}>
                <Heading level={3}>Historický vývoj</Heading>
              </Box>
            </Flex>

            <StatisticsCharts
              ageHistory={this.state.ageHistory}
              discountHistory={this.state.discountHistory}
              discountPercentHistory={this.state.discountPercentHistory}
              equipmentHistory={this.state.equipmentHistory}
              kmHistory={this.state.kmHistory}
              priceHistory={this.state.priceHistory}
              carsAddedHistory={this.state.carsAddedHistory}
              carsSoldHistory={this.state.carsSoldHistory}
            />
          </>
        )}
      </div>
    );
  }

  private login = () => {
    if (MobileAppUtils.isInApp()) {
      MobileAppUtils.gotoLogin();
      return;
    }
    this.props.showLoginDialog();
  };

  private getFilterString(brand, model) {
    const { ad, avgMonthValues } = this.props;
    if (!ad) {
      return '';
    }

    if (avgMonthValues && !avgMonthValues.length) {
      return (
        <ErrorMessage
          textAlign='left'
          isVisible={true}
          message='Pro zvolený filtr neexistují data.'
        />
      );
    }

    return (
      <Typography
        text={`Vozidla ${capitalize(brand)} ${capitalize(
          model
        )} na trhu odpovídající Vaším kritériím.`}
      />
    );
  }

  private mapAvgValuesToChartData() {
    const { avgMonthValues } = this.props;
    this.setState({
      priceHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.avgPrice),
          date: item.dateStr,
        };
      }),
      kmHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.avgKm),
          date: item.dateStr,
        };
      }),
      ageHistory: avgMonthValues
        .filter(item => item.month >= 201806)
        .map(item => {
          return {
            value: Math.round(item.avgAdAge),
            date: item.dateStr,
          };
        }),
      discountHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.avgDiscount),
          date: item.dateStr,
        };
      }),
      discountPercentHistory: avgMonthValues
        .filter(item => item.month >= 201806)
        .map(item => {
          return {
            value: Math.round(item.discountedPercent * 100),
            date: item.dateStr,
          };
        }),
      equipmentHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.avgEquipmentCount),
          date: item.dateStr,
        };
      }),
      carsAddedHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.carsAddedCount),
          date: item.dateStr,
        };
      }),
      carsSoldHistory: avgMonthValues.map(item => {
        return {
          value: Math.round(item.carsSoldCount),
          date: item.dateStr,
        };
      }),
    });
  }
}

const mapStateToProps = state => {
  return {
    ad: state.ads.adDetails ? state.ads.adDetails.ad : null,
    filter: state.ads.filter,
    filterString: state.ads.filterString,
    avgMonthValues: state.ads.adDetails ? state.ads.adDetails.avgMonthValues : [],
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
