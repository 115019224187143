import * as React from 'react';
import styled from 'styled-components/macro';
import { FormikErrors, FormikTouched } from 'formik';
import { FormLabel } from '../Form';
import ErrorMessage from '../../ErrorMessage';

import * as S from './Input.styles';

const StyledInput = styled('input')(
  {
    border: 'solid 1px #ccc',
    borderRadius: '4px',
    color: '#4A4A4A',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '200px',
    '&:focus': {
      outline: '0',
    },
    '&::placeholder': {
      color: '#737373',
    },
  },
  (props: IInputProps) => ({
    height: props.inputSize === 'small' ? '32px' : '38px',
  }),
  (props: IInputProps) => ({
    width: props.block ? '100%;' : 'auto',
  })
);

interface IInputProps {
  label?: string;
  error?: FormikErrors<any> | string;
  touched?: FormikTouched<any> | boolean;
  block?: boolean;
  size?: 'normal' | 'small';
  [key: string]: any;
}

const defaultProps: Partial<IInputProps> = {
  block: false,
  size: 'normal',
};

const Input: React.SFC<IInputProps> = ({ block, size, error, touched, label, ...other }) => {
  return (
    <>
      <S.LabelAndInputContainer>
        {label && <FormLabel>{label}</FormLabel>}
        <StyledInput block={block} inputSize={size} {...other} />
      </S.LabelAndInputContainer>
      {error && touched && (
        <ErrorMessage textAlign='left' isVisible={true} message={error.toString()} />
      )}
    </>
  );
};

Input.defaultProps = defaultProps;

export default Input;
