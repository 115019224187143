import * as React from 'react';
import { Flex } from 'reflexbox/styled-components';
import { withSpaces } from 'app/utils/number';

import Typography from 'app/components/Typography';
import BlurredText from 'app/components/BlurredText';
import * as S from './MarketPrice.styles';

const AccuracyTextMap = {
  '0.9': 'Evidujeme mnoho podobných vozů, výpočet je tedy přesný (90%).',
  '0.75': 'Podobných vozů není až tak moc, přesnost je lehce snížená (75%).',
  '0.6': 'Pro nedostatek vozů jsme rozšířili kritéria, přesnost je snížená (60%).',
  '0.5': 'Evidujeme více vozů s rozdílnými parametry, přesnost je snížená (50%).',
  '0.3': 'Těchto vozů je na trhu málo, přesnost je snížená (30%).',
  '0.2': 'Takové vozy téměř neevidujeme, výpočet je pouze orientační (20%).',
};

interface IMarketPriceProps {
  calculationAccuracy: number;
  isAuthorized: boolean;
  lowestValue: number;
  value: number;
  title?: string;
}

const MarketPrice: React.SFC<IMarketPriceProps> = ({
  calculationAccuracy,
  isAuthorized,
  lowestValue,
  value,
  title = 'Aktuální tržní cena',
}) => {
  return (
    <S.MarketPriceContainer>
      <Flex width='100%' justifyContent='space-between' flexDirection='column'>
        <Flex
          width='100%'
          justifyContent='space-between'
          flexDirection={['column', 'row', 'row']}
          mb={1}
        >
          <S.StyledHeading level={2} size='normal' weight='regular'>
            {title}
          </S.StyledHeading>
          {!!value && (
            <S.StyledHeading level={2} size='normal' weight='medium'>
              <BlurredText
                enabled={!isAuthorized}
                data-cy={`market-price-value market-price-value-${title}`}
              >
                {lowestValue && lowestValue < value ? `${withSpaces(lowestValue)} - ` : ''}
                {withSpaces(value)} Kč
              </BlurredText>
            </S.StyledHeading>
          )}
        </Flex>
        {calculationAccuracy ? (
          <Flex width='100%' flexDirection='column'>
            <Typography
              text='Přesnost výpočtu'
              weight='medium'
              color='#fff'
              printStyles={{
                color: '#4A5561',
              }}
            />
            <Typography
              text={`${AccuracyTextMap[String(calculationAccuracy)]}`}
              weight='regular'
              color='#fff'
              printStyles={{
                color: '#4A5561',
              }}
            />
          </Flex>
        ) : (
          <Typography
            text='Litujeme, pro výpočet tržní ceny nemáme dostatek dat.'
            weight='medium'
            color='#fff'
            printStyles={{
              color: '#4A5561',
            }}
          />
        )}
      </Flex>
    </S.MarketPriceContainer>
  );
};

export default MarketPrice;
