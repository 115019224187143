import * as React from 'react';
import Paragraph from 'app/components/Paragraph';
import { PriceAdvisor } from 'app/components/PriceAdvisor';
import ScrollToTop from 'app/components/ScrollToTop';
import * as S from './Result.EstimateCar.styles';

interface IResultEstimateCarProps {
  data: any;
  isAuthorized: boolean;
  isLoadingData: boolean;
  marketPriceTitleText?: string;
  subtitle?: string;
  title?: string;
  exportFilename?: string;
  onShowLoginDialog: () => void;
}

const ResultEstimateCar: React.SFC<IResultEstimateCarProps> = ({
  data,
  isAuthorized,
  isLoadingData,
  marketPriceTitleText,
  onShowLoginDialog,
  subtitle,
  title,
  exportFilename = 'cenovy-radce-export',
}) => {
  const noDataToShow =
    !data ||
    (data && !data['20000km']) ||
    (data && data.listWithSearchedAndIdealAd && !data.listWithSearchedAndIdealAd.length);
  if (noDataToShow) {
    return (
      <S.Wrapper>
        <S.Text>
          <Paragraph>Litujeme, pro zadané parametry jsme nenašli výsledek.</Paragraph>
        </S.Text>
      </S.Wrapper>
    );
  }

  let searchedAd;
  const { listWithSearchedAndIdealAd } = data;
  if (listWithSearchedAndIdealAd && listWithSearchedAndIdealAd.length) {
    searchedAd = data.listWithSearchedAndIdealAd.filter((ad, idx) => ad.isSearchedAd)[0];
  }

  return (
    <S.Wrapper>
      <ScrollToTop>
        <PriceAdvisor
          data={data}
          exportFilename={exportFilename}
          genericBadgeText='Váš vůz'
          isAuthorized={isAuthorized}
          isLoadingData={isLoadingData}
          listType='estimate-car'
          marketPriceTitleText={marketPriceTitleText}
          onShowLoginDialog={onShowLoginDialog}
          searchedAd={searchedAd || {}}
          subtitle={subtitle}
          title={title}
        />
      </ScrollToTop>
    </S.Wrapper>
  );
};

ResultEstimateCar.defaultProps = {
  title: 'Ocenění vozu',
  subtitle: 'Odkaz na toto ocenění naleznete v uživatelském menu v pravém horním rohu aplikace.',
  marketPriceTitleText: 'Aktuální tržní cena',
};

export default ResultEstimateCar;
