import * as React from 'react';
import { colors } from '../../../styles/shared.styles';

const PlusIcon = props => (
  <svg
  	enableBackground='new 0 0 10 10'
  	height='10'
  	viewBox='0 0 10 10'
  	width='10'
  	xmlns='http://www.w3.org/2000/svg'
  >
     <path
     	className='svg-plus-color'
     	d='m4.253 9.258v-3.533h-3.507v-1.476h3.507v-3.507h1.494v3.507h3.507v1.476h-3.507v3.533z'
     	fill={colors.primary}
     />
  </svg>
)

export default PlusIcon;
