import * as React from 'react';
import Heading from '../components/Heading';
import Page from '../components/Page/Page';
import Paragraph from '../components/Paragraph';

interface IAboutProps { }

const About: React.SFC<IAboutProps> = () => {
  return (
    <Page
      textAlign='left'
      itemsAlign='left'
      componentName='about'
      documentTitle='O projektu'
    >
      <div className='row'>
        <div className='col-xs-12 col-sm-offset-3 col-sm-6'>
          <div>
            <Heading level={1}>O projektu</Heading>
            <Heading level={2}>Co řešíme za problém?</Heading>
            <div>
              <Paragraph>Pomůžeme vám s koupí ojetého vozu.</Paragraph>
            </div>
            <ul className='list__basic'>
              <li className='list__basic--item'>nemusíte trávit hodiny procházením inzertních serverů</li>
              <li className='list__basic--item'>ověříme zda se jedná o dobrou či špatnou nabídku</li>
              <li className='list__basic--item'>určíme jak si vede ve srovnání s ostatními nabídkami na trhu</li>
              <li className='list__basic--item'>najdeme ty nejlepší nabídky dle vybraných parametrů</li>
              <li className='list__basic--item'>řekneme vám zda jde či nejde o ojedinělou nabídku</li>
              <li className='list__basic--item'>pomáháme chránit před podvodníky</li>
              <li className='list__basic--item'>nemusíte manuálně porovnávat inzeráty mezi sebou</li>
            </ul>
          </div>
          <div>
            <Heading level={2}>Proč to děláme?</Heading>
            <ul className='list__basic'>
              <li className='list__basic--item'>auta nás baví, stejně jako práce s “big data”</li>
              <li className='list__basic--item'>vyhledávání kvalitního ojetého vozu je časově náročné</li>
              <li className='list__basic--item'>naším cílem je hledání kvalitního ojetého vozu automatizovat</li>
            </ul>
          </div>
          <div>
            <Heading level={2}>Jak to děláme?</Heading>
            <ul className='list__basic'>
              <li className='list__basic--item'>v reálném čase monitorujeme kompletní trh ojetých vozidel</li>
              <li className='list__basic--item'>automaticky vyhodnotíme nabídku porovnáním s desítkami tisíc dalších nabídek na trhu</li>
              <li className='list__basic--item'>nabídky porovnáváme i s historií</li>
              <li className='list__basic--item'>na základě získaných dat jsme schopni identifikovat kvalitní či nekvalitní nabídku</li>
            </ul>
          </div>
        </div>
      </div>
    </Page>
  )
};

export default About;
