import React from 'react';
import Typography from 'app/components/Typography';
import { Row } from 'app/components/Row';
import { Column } from 'app/components/Column';
import PremiumBanner from 'app/components/PremiumBanner';
import { IconLocker } from 'app/utils/icons';

export const PremiumReportBanner: React.FC = () => {
  return (
    <PremiumBanner
      overlay={false}
      bgColor='grey'
      padding='0px'
      borderRadius='5px'
      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
    >
      <Row alignItems='center' justifyContent='center' padding='12px 14px'>
        <Column mr='15px'>
          <IconLocker />
        </Column>
        <Typography
          text='Tato část reportu je zamčená'
          weight='medium'
          color='white'
          lineHeight='19px'
        />
      </Row>
    </PremiumBanner>
  );
};
