import { rest } from 'app/api';
import { log } from 'app/utils/log';

export const loadVehicleRegister = async (vin: string, accessToken: string) => {
  try {
    const windowDeviceId = (window as any).deviceId;
    const deviceId = windowDeviceId ? `?deviceid=${windowDeviceId}` : ``;
    const result = await rest.get(`/vehicle-register/${vin}${deviceId}`, {
      headers: {
        Authorization: accessToken,
      },
    });
    return result?.data;
  } catch (err) {
    log.error('loadVehicleRegister:: err: ', err);
    return err;
  }
};
