import * as React from 'react';
import Typography from 'app/components/Typography';
import { TableHeader, TableRow } from 'app/components/Table';
import * as S from './VerifyVinTable.styles';

interface IVerifyVinTableHeaderProps {
  primaryTitle: string;
  secondaryTitle?: string;
  ternaryTitle?: string;
}

export const VerifyVinTableHeader: React.SFC<IVerifyVinTableHeaderProps> = ({
  primaryTitle,
  secondaryTitle,
  ternaryTitle,
}) => {
  return (
    <TableHeader>
      <TableRow header={true}>
        <S.StyledTableColumn>
          <Typography text={primaryTitle} weight='bold' />
        </S.StyledTableColumn>
        {secondaryTitle && (
          <S.StyledTableColumn>
            <Typography text={secondaryTitle} weight='bold' />
          </S.StyledTableColumn>
        )}
        {ternaryTitle && (
          <S.StyledTableColumn>
            <Typography text={ternaryTitle} weight='bold' />
          </S.StyledTableColumn>
        )}
      </TableRow>
    </TableHeader>
  );
};

VerifyVinTableHeader.defaultProps = {
  primaryTitle: '',
  secondaryTitle: '',
  ternaryTitle: '',
};
