import styled from 'styled-components/macro';
import { fontFamily } from 'styles/shared.styles';
// TODO: will be refactored
// import { components } from 'react-select';

// import { ReactComponent as IconDropdownSVG} from 'assets/images/icon-dropdown.svg'

// import iconDropdown from 'assets/images/icon-dropdown.svg';
// import iconDropdownWhite from 'assets/images/icon-dropdown-white.svg';

// const DropdownIndicator = (props) => {
//   return components.DropdownIndicator && (
//     <components.DropdownIndicator {...props}>
//       <img src={iconDropdown} />
//     </components.DropdownIndicator>
//   );
// };

// const DropdownIndicator = props => {
//   return (
//     components.DropdownIndicator && (
//       <components.DropdownIndicator {...props}>
//         <img src={iconDropdown} />
//       </components.DropdownIndicator>
//     )
//   );
// };

export const LabelAndSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const customStyles = {
  option: (styles, state) => ({
    ...styles,
    fontFamily: state.isSelected ? fontFamily.medium : fontFamily.regular,
    fontSize: '17px',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  container: (styles, state) => ({
    ...styles,
  }),
  control: (styles, state) => ({
    ...styles,
    border: 'solid 1px #ccc',
    backgroundColor: state.isDisabled ? '#ebebe4' : 'white',
    minWidth: 200,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  // dropdownIndicator: (base, state) => {
  //   const { menuIsOpen } = state.selectProps;
  //   const backgroundImage = menuIsOpen ? 'caret-up' : 'caret-down'; // demo purposes only
  //   return {
  //     ...base,
  //     backgroundImage
  //   };
  // }
  // singleValue: (styles, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...styles, opacity, transition };
  // }
};
