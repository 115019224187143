import styled from 'styled-components/macro';
import { mediaQuery, flex } from '../../../styles/shared.styles';

export const FilterAndListTypeWrapper = styled.div`
  ${flex.flex};
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 10px;
  ${mediaQuery.medium} {
    flex-direction: row;
    margin-bottom: 0;
    justify-content: space-between;
    padding: 0;
  }
`;

export const SimilarAdsContainer = styled.div`
  ${mediaQuery.medium} {
  }
`;

export const AdsListsContainer = styled.div`
  margin-top: 50px;

  ${mediaQuery.print} {
    margin-top: 0;
    display: block;
    page-break-after: always;
  }
`;

export const AdHistoryContainerContainer = styled.div`
  ${mediaQuery.print} {
    display: block;
    page-break-before: always;
    page-break-after: always;
  }
`;

export const NavButtonContainer = styled.span`
  button {
    padding: 0 15px;
    margin: 0 5px;
  }
`;
