import styled from 'styled-components/macro';

export const StyledLabel = styled.label`
  display: inline-block;
  margin: 10px 20px 10px 0;
  cursor: pointer;
  input[type='checkbox'] {
    margin: 0px 5px 0 0;
    vertical-align: bottom;
  }
`;
