import styled from 'styled-components/macro';
import { AdsList } from 'app/components/AdsList';

export const ResidualValueContainer = styled.div``;

export const SectionContent = styled.div`
  padding: 25px 0 0 0;
`;

export const StyledAdsList = styled(AdsList)`
  margin-bottom: 30px !important;
`;

export const BlurryImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100;
`;
