export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const POP_NOTIFICATION = 'POP_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
export const HIDE_LOGIN_DIALOG = 'HIDE_LOGIN_DIALOG';
export const SHOW_SIGNUP_DIALOG = 'SHOW_SIGNUP_DIALOG';
export const HIDE_SIGNUP_DIALOG = 'HIDE_SIGNUP_DIALOG';
export const SHOW_FORGOT_PASSWORD_DIALOG = 'SHOW_FORGOT_PASSWORD_DIALOG';
export const HIDE_FORGOT_PASSWORD_DIALOG = 'HIDE_FORGOT_PASSWORD_DIALOG';
export const SET_LIST_TYPE = 'SET_LIST_TYPE';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
