import styled from 'styled-components/macro';

import ErrorMessage from 'app/components/ErrorMessage';
import Input from 'app/components/FormElements/Input';
import { colors, mediaQuery } from 'styles/shared.styles';

export const FormContainer = styled('div')({
  width: '100%',
  maxWidth: '760px',
  margin: '0 auto',
  minHeight: '100px',
});

export const InputWrapper = styled('div')({
  maxWidth: '540px',
  borderRadius: '24px',
  margin: '0 auto',
  backgroundColor: colors.white,
  boxShadow: '0 3px 4px 0 rgba(0,0,0,0.31)',
  height: '53px',
  [mediaQuery.medium]: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const StyledInput = styled(Input)({
  border: 0,
  background: 'none',
  width: '100%',
  height: '100%',
  paddingLeft: '24px',
  [mediaQuery.medium]: {
    flex: '1 0 8em',
    width: 'auto',
  },
});

export const Example = styled('div')({
  textAlign: 'center',
  color: '#4A5560',
  fontSize: '0.9rem',
  paddingTop: '12px',
  display: 'none',
  userSelect: 'text',
  [mediaQuery.medium]: {
    display: 'block',
  },
});

export const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px;
`;
