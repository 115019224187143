import AdFilter from 'app/models/AdFilter';
import { withSpaces } from 'app/utils/number';
import { createSelector } from 'reselect';

const filterMap = {
  km: {
    title: 'Najeto',
    name: 'km',
    units: 'km',
    isRange: true,
    value: null,
  },
  price: {
    title: 'Cena',
    name: 'price',
    units: 'Kč',
    isRange: true,
    value: null,
  },
  kw: {
    title: 'Výkon',
    name: 'kw',
    units: 'kw',
    isRange: true,
    value: null,
  },
  year: {
    title: 'Rok',
    name: 'year',
    units: '',
    isRange: true,
    value: null,
  },
  transmission: {
    title: 'Převodovka',
    name: 'transmission',
    units: '',
    isRange: false,
    value: null,
  },
  fuel: {
    title: 'Palivo',
    name: 'fuel',
    units: '',
    isRange: false,
    value: null,
  },
  equipmentCount: {
    title: 'Výbava',
    name: 'equipmentCount',
    units: '',
    isRange: true,
    value: null,
  },
  karoserie: {
    title: 'Karoserie',
    name: 'karoserie',
    units: '',
    isRange: false,
    value: null,
  },
};

const getActiveFilters = (stateFilter: AdFilter) => {
  if (!stateFilter) {
    return [];
  }
  const rawActiveFilters: string[] = [];
  const stateFilterJS = stateFilter.toJS();
  for (let key in stateFilterJS) {
    let value = stateFilterJS[key];
    if (typeof value === 'boolean' && value === true && key) {
      rawActiveFilters.push(key);
    }
  }

  const activeFilters = rawActiveFilters.map(filter => {
    if (filter.indexOf('Included') > -1) {
      return filter.replace(/Included/, '');
    }

    return filter;
  });

  return activeFilters;
};

const getRangeValueText = (from: number, to: number, title: string, units: string): string => {
  const fromFormatted = title === 'Rok' ? from : withSpaces(from);
  const toFormatted = title === 'Rok' ? to : withSpaces(to);
  if (from && !to) {
    return `${title} od ${fromFormatted} ${units}`;
  }

  if (!from && to) {
    return `${title} do ${toFormatted} ${units}`;
  }

  return `${title} ${fromFormatted} - ${toFormatted} ${units}`;
};

const getSelectedFilterValue = (name: any, stateFilter: AdFilter) => {
  const valueTemplate = { ...filterMap[name] };
  if (valueTemplate.isRange) {
    // @ts-ignore
    const from = stateFilter.get(`${name}From`, null);
    // @ts-ignore
    const to = stateFilter.get(`${name}To`, null);
    const title = valueTemplate.title;

    const value = getRangeValueText(Number(from), Number(to), title, valueTemplate.units);
    return {
      value,
      name: valueTemplate.name,
    };
  }

  return {
    value: stateFilter.get(name, null),
    name: valueTemplate.name,
  };
};

// selector
const getSelectedFilters = (stateFilter: AdFilter) => {
  const activeFilters = getActiveFilters(stateFilter);
  const selectedFilters = activeFilters.map(name => getSelectedFilterValue(name, stateFilter));
  return selectedFilters;
};

// reselect function
export const getSelectedFiltersState = createSelector(
  [getSelectedFilters],
  selectedFilters => selectedFilters
);
