import * as React from 'react';
import * as S from './CenteredContainer.styles';

interface ICenteredContainerProps {
  children: React.ReactNode;
}

export const CenteredContainer: React.SFC<ICenteredContainerProps> = ({ children }) => {
  return (
    <S.Container>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};

CenteredContainer.defaultProps = {};
