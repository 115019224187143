import * as React from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import { get } from 'lodash-es';

import MarketPrice from './MarketPrice';
import ResidualValueCharts from 'app/components/ResidualValue/ResidualValueCharts';
import { DataNoResults } from 'app/components/DataNoResults';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import SectionHeader from 'app/components/SectionHeader';
import { PdfExportFooter } from 'app/components/PdfExport';
import { LastAddedAndSoldCars } from 'app/components/LastAddedAndSoldCars';
import JsonView from 'app/components/_DEV_/JsonView';

import Ad from 'app/models/Ad';
import { LoginBanner } from 'app/components/LoginBanner';
// import { PdfExportDownloadBtn } from 'app/components/PdfExport';
import { ListType } from 'app/components/ListSwitcher';
import { mediaQuery } from 'styles/shared.styles';
import { checkViewMode } from 'app/utils';
import { NEW_AND_SOLD_CARS } from 'app/constants/titles';
import * as S from './PriceAdvisor.styles';

import residualValueChartBlurredImg from 'assets/images/residual-value-chart-blurred.png';

interface IPriceAdvisorProps {
  data: any;
  isAuthorized: boolean;
  isLoadingData: boolean;
  listType: ListType;
  searchedAd: Ad;
  marketPriceTitleText?: string;
  subtitle?: string;
  title?: string;
  exportFilename?: string;
  genericBadgeText?: string;
  onShowLoginDialog: () => void;
}

const PriceAdvisor: React.SFC<IPriceAdvisorProps> = ({
  data,
  isAuthorized,
  isLoadingData,
  listType,
  onShowLoginDialog,
  searchedAd,
  marketPriceTitleText = 'Aktuální tržní cena',
  subtitle = 'Poradíme Vám nejen jaká je tržní hodnota vozidla, ale i ta nejnižší možná pro dané vozidlo.',
  title = 'Cenový rádce',
  exportFilename = 'cenovy-radce-export',
  genericBadgeText = 'Výhodný vůz',
}) => {
  if (isLoadingData) {
    return <AdLoader text={EnumAdLoaderTexts.LOADING_DATA} fullscreen={true} />;
  }

  const isPdfMode = checkViewMode('pdf');
  const idealAdPrice = get(data, 'listWithSearchedAndIdealAd[1].props.price.value', 0);

  const hasDataToShow =
    data && data.listWithSearchedAndIdealAd && data.listWithSearchedAndIdealAd.length;
  if (!hasDataToShow) {
    return (
      <DataNoResults
        text='Litujeme, pro zadané parametry jsme nenašli výsledek.'
        sectionHeaderText={title}
        sectionSubheaderText={subtitle}
      />
    );
  }

  const { lastSoldCars, lastAddedCars } = data;
  return (
    <React.Fragment>
      <Flex
        width='100%'
        px={['10px', 0, 0]}
        flexDirection='column'
        sx={{
          [mediaQuery.print]: {
            padding: 0,
            display: 'block',
          },
        }}
      >
        <Flex width='100%' alignItems='center'>
          <Box width={1}>
            <SectionHeader title={title} subheaderText={subtitle} />
          </Box>
        </Flex>

        {data && typeof data.marketPrice !== 'undefined' && (
          <Flex
            width='100%'
            flexDirection={'column'}
            alignItems='center'
            justifyContent='center'
            sx={{
              [mediaQuery.print]: {
                display: 'flex',
                flexDirection: 'column-reverse',
                pageBreakAfter: 'always',
              },
            }}
          >
            <Box
              width={['100%', '100%', '74.5%']}
              sx={{
                [mediaQuery.print]: {
                  maxWidth: '577px',
                  margin: '0 auto',
                },
              }}
            >
              <S.SectionContent>
                <MarketPrice
                  value={isAuthorized ? data.marketPrice.value : 'XXX XXX'}
                  title={marketPriceTitleText}
                  calculationAccuracy={data.marketPrice.calculationAccuracy}
                  isAuthorized={isAuthorized}
                  lowestValue={idealAdPrice}
                />
              </S.SectionContent>
            </Box>
            <Box width={['100%', '100%', '100%']} mb='30px'>
              <S.SectionContent>
                <S.StyledAdsList
                  ad={searchedAd}
                  allowRedirectButton={false}
                  genericBadgeText={genericBadgeText}
                  isAuthorized={isAuthorized}
                  list={data.listWithSearchedAndIdealAd}
                  listType={listType}
                  orderBadgeEnabled={false}
                  showBenchmark={false}
                  showLoginDialog={onShowLoginDialog}
                  thumbnail={false}
                />
              </S.SectionContent>
            </Box>
          </Flex>
        )}

        <LastAddedAndSoldCars
          data={{ lastSoldCars, lastAddedCars }}
          isAuthorized={isAuthorized}
          sectionTitle={NEW_AND_SOLD_CARS}
          itemsPerRow={5}
        />

        <S.ResidualValueContainer>
          <Flex width='100%' alignItems='center'>
            <Box width={1}>
              <SectionHeader
                title='Zůstatková hodnota'
                subheaderText='Orientační zůstatková hodnota při nájezdu 20 000 km ročně.'
              />
            </Box>
          </Flex>

          <S.SectionContent>
            {!isAuthorized ? (
              <S.BlurryImageContainer>
                <LoginBanner />
                <img src={residualValueChartBlurredImg} alt='blurry-img' />
              </S.BlurryImageContainer>
            ) : data['20000km'] && data['20000km'].data && !!data['20000km'].data.length ? (
              <ResidualValueCharts data={data} />
            ) : null}
          </S.SectionContent>
        </S.ResidualValueContainer>

        {isPdfMode && <PdfExportFooter />}

        <JsonView
          data={data['20000km']}
          title='Final normalized data'
          name='normalizedData'
          showMainTitle={true}
        />

        <JsonView
          data={data._DEV_residualValues}
          title='Market prices per year'
          name='marketPrices'
          collapsed={2}
        />

        <JsonView
          data={data._DEV_discountStrategies}
          title='Discount strategy per year'
          name='discountStrategies'
          collapsed={2}
        />
      </Flex>
    </React.Fragment>
  );
};

PriceAdvisor.defaultProps = {
  data: {},
  listType: 'price-advisor',
};

export default PriceAdvisor;
