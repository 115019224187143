import * as React from 'react';
import { Flex } from 'reflexbox/styled-components';
import { trim } from 'lodash-es';
import styled from 'styled-components/macro';

import Heading from 'app/components/Heading';
import Button from 'app/components/Button';
import FormattedValue from 'app/components/FormattedValue';
import { PdfExportDownloadBtn } from 'app/components/PdfExport';
import { VinTextBadge } from 'app/components/VinTextBadge';
import { Column } from 'app/components/Column';
import { Row } from 'app/components/Row';
import { Container } from 'app/components/_shared_';
import Ad from 'app/models/Ad';
import { IInfoCardProps } from 'app/components/InfoCard';
import { MobileAppUtils } from 'app/utils/MobileAppUtils';
import { useVerifyVinRoute } from 'app/hooks';
import { checkViewMode } from 'app/utils';
import { mediaQuery } from 'styles/shared.styles';

interface IVerificationSummaryProps {
  adDetailsIsLoading: boolean;
  hasAdDetailsData: boolean;
  kmHistoryIsLoading: boolean;
  hasKmHistoryData: number;
  stolenCarsIsLoading: boolean;
  hasStolenCarsData: boolean;
  hasHistoryChanges: boolean;
  historyChangesIsLoading: boolean;
  adImagesIsLoading: boolean;
  hasAdImages: boolean;
  priceAdvisorIsLoading: boolean;
  hasPriceAdvisorData: boolean;
  residualValueIsLoading: boolean;
  hasResidualValueData: boolean;
  vinDecodeIsLoadingIsLoading: boolean;
  hasManufacturersData: boolean;
  hasTechnicalParametersData: boolean;
  hasDimensionsParametersData: boolean;
  hasLeasingCheckData: boolean;
  leasingCheckIsLoading: boolean;
  isValidVin: boolean;
  adDetails: Ad;
  vin: string;
  vehicleRegisterLoading: boolean;
  vehicleRegisterFetched: boolean;
  vehicleRegisterData: IInfoCardProps[];
}

export const VerificationSummary: React.SFC<IVerificationSummaryProps> = ({
  hasAdDetailsData,
  adDetailsIsLoading,
  hasKmHistoryData,
  kmHistoryIsLoading,
  hasStolenCarsData,
  stolenCarsIsLoading,
  hasHistoryChanges,
  historyChangesIsLoading,
  adImagesIsLoading,
  hasAdImages,
  priceAdvisorIsLoading,
  hasPriceAdvisorData,
  residualValueIsLoading,
  hasResidualValueData,
  vinDecodeIsLoadingIsLoading,
  hasManufacturersData,
  hasTechnicalParametersData,
  hasDimensionsParametersData,
  hasLeasingCheckData,
  leasingCheckIsLoading,
  isValidVin,
  adDetails,
  vin,
  vehicleRegisterLoading,
  vehicleRegisterFetched,
  vehicleRegisterData,
}) => {
  if (!isValidVin) MobileAppUtils.loadingCarFailed('Vin is invalid');

  return (
    <Container>
      <Flex width='100%' flexDirection='column'>
        <SummaryHeader
          vin={vin}
          adDetailsIsLoading={adDetailsIsLoading}
          hasAdDetailsData={hasAdDetailsData}
          adDetails={adDetails}
          isValidVin={isValidVin}
        />
        {isValidVin && (
          <SummaryAvailability
            isValidVin={isValidVin}
            vinDecodeIsLoadingIsLoading={vinDecodeIsLoadingIsLoading}
            hasManufacturersData={hasManufacturersData}
            hasTechnicalParametersData={hasTechnicalParametersData}
            hasDimensionsParametersData={hasDimensionsParametersData}
            kmHistoryIsLoading={kmHistoryIsLoading}
            hasKmHistoryData={hasKmHistoryData}
            adDetailsIsLoading={adDetailsIsLoading}
            hasAdDetailsData={hasAdDetailsData}
            priceAdvisorIsLoading={priceAdvisorIsLoading}
            hasPriceAdvisorData={hasPriceAdvisorData}
            residualValueIsLoading={residualValueIsLoading}
            hasResidualValueData={hasResidualValueData}
            stolenCarsIsLoading={stolenCarsIsLoading}
            hasStolenCarsData={hasStolenCarsData}
            hasLeasingCheckData={hasLeasingCheckData}
            leasingCheckIsLoading={leasingCheckIsLoading}
            vehicleRegisterLoading={vehicleRegisterLoading}
            vehicleRegisterFetched={vehicleRegisterFetched}
            vehicleRegisterData={vehicleRegisterData}
            vin={vin}
          />
        )}
      </Flex>
    </Container>
  );
};

const SummaryHeader = ({ vin, adDetailsIsLoading, hasAdDetailsData, adDetails, isValidVin }) => {
  const { isPremiumRoute, isMobileApp } = useVerifyVinRoute();
  if (adDetailsIsLoading) return <Row height='58px' />;
  const getTitle = () => {
    if (!hasAdDetailsData) return trim(`${vin || 'Shrnutí ověřeného vozidla'}`);
    return trim(`${adDetails?.brand || ''} ${adDetails?.model || ''}`);
  };
  const title = getTitle();
  const isPdfMode = checkViewMode('pdf');

  return (
    <Row
      mb={[0, '20px']}
      alignItems='center'
      justifyContent='space-between'
      flexDirection={['column', 'row']}
      py={['30px', 0]}
    >
      {title && (
        <Heading
          level={2}
          weight='bold'
          fontSize={MobileAppUtils.isInApp() ? '1.75rem' : ['1.75rem', '2rem']}
          lineHeight='24px'
          centered={false}
          letterSpacing={MobileAppUtils.isInApp() ? '-0.3px' : ['-0.3px', 0]}
          mb={0}
        >
          {title}
        </Heading>
      )}
      {!isMobileApp && !isPdfMode && isValidVin && !isPremiumRoute && (
        <Row mt={['20px', 0]}>
          <Button to={`/vin/${vin}/platba`}>Stáhnout PDF</Button>
        </Row>
      )}
      {!isMobileApp && !isPdfMode && isValidVin && isPremiumRoute && (
        <Row mt={['20px', 0]}>
          <PdfExportDownloadBtn
            visible={true}
            btnText={'Stáhnout PDF'}
            filename={`overauto-cz-pdf-export-${vin}`}
          />
        </Row>
      )}
    </Row>
  );
};

const SummaryColum = styled(Column)`
  ${mediaQuery.print} {
    width: 50%;
  }
`;

const SummaryAvailability = ({
  isValidVin,
  vinDecodeIsLoadingIsLoading,
  hasManufacturersData,
  hasTechnicalParametersData,
  hasDimensionsParametersData,
  kmHistoryIsLoading,
  hasKmHistoryData,
  adDetailsIsLoading,
  hasAdDetailsData,
  priceAdvisorIsLoading,
  hasPriceAdvisorData,
  residualValueIsLoading,
  hasResidualValueData,
  stolenCarsIsLoading,
  hasStolenCarsData,
  hasLeasingCheckData,
  leasingCheckIsLoading,
  vin,
  vehicleRegisterLoading,
  vehicleRegisterFetched,
  vehicleRegisterData,
}) => {
  const headingText = isValidVin
    ? 'Pro zadaný VIN vozidla jsme ověřili dostupnost následujících údajů:'
    : 'Vložený VIN je neúplný, vyberte konkrétní vůz ze záznamů níže.';
  const { isPremiumVersion } = useVerifyVinRoute();

  return (
    <Column
      px='20px'
      pt='20px'
      pb='35px'
      borderRadius='10px'
      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.02)'
      background='white'
      maxWidth='700px'
      printStyles={{
        border: '1px solid rgba(151,151,151,0.5)',
        boxShadow: 'none',
        paddingBottom: '20px',
      }}
      data-cy='summary-availability-container'
    >
      <VinTextBadge vin={vin} mb='20px' />
      <Heading level={3} weight='bold' size='micro' lineHeight='18px' margin='0 0 20px 0'>
        {headingText}
      </Heading>
      <Row justifyContent='space-between' flexWrap={['wrap', 'nowrap']}>
        <SummaryColum width={[1, 0.5, 0.5, 0.4]}>
          <FormattedValue
            value='Údaje o vozidle'
            fontSize='base'
            iconType={
              vinDecodeIsLoadingIsLoading ? 'loading' : hasManufacturersData ? 'success' : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Technické parametry'
            fontSize='base'
            iconType={
              vinDecodeIsLoadingIsLoading
                ? 'loading'
                : hasTechnicalParametersData
                ? 'success'
                : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Rozměry'
            fontSize='base'
            iconType={
              vinDecodeIsLoadingIsLoading
                ? 'loading'
                : hasDimensionsParametersData
                ? 'success'
                : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Stav tachometru'
            fontSize='base'
            iconType={kmHistoryIsLoading ? 'loading' : hasKmHistoryData ? 'success' : 'fail'}
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Historie závad'
            fontSize='base'
            iconType={kmHistoryIsLoading ? 'loading' : hasKmHistoryData ? 'success' : 'fail'}
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value={
              adDetailsIsLoading || hasAdDetailsData
                ? 'Vozidlo bylo v inzerci'
                : 'Vozidlo nebylo v inzerci'
            }
            fontSize='base'
            iconType={adDetailsIsLoading ? 'loading' : hasAdDetailsData ? 'success' : 'fail'}
            constainerProps={{ mb: '5px' }}
          />
        </SummaryColum>
        <SummaryColum width={[1, 0.5, 0.5, 0.4]}>
          <FormattedValue
            value='Cenový rádce'
            fontSize='base'
            iconType={priceAdvisorIsLoading ? 'loading' : hasPriceAdvisorData ? 'success' : 'fail'}
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Zůstatková hodnota'
            fontSize='base'
            iconType={
              residualValueIsLoading ? 'loading' : hasResidualValueData ? 'success' : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Databáze odcizených vozidel'
            fontSize='base'
            iconType={
              !isPremiumVersion
                ? 'neutral'
                : stolenCarsIsLoading
                ? 'loading'
                : hasStolenCarsData
                ? 'success'
                : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Databáze úvěrů a leasingů'
            fontSize='base'
            iconType={
              !isPremiumVersion
                ? 'neutral'
                : leasingCheckIsLoading
                ? 'loading'
                : hasLeasingCheckData
                ? 'success'
                : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
          <FormattedValue
            value='Záznamy v registru vozidel'
            fontSize='base'
            iconType={
              !isPremiumVersion
                ? 'neutral'
                : vehicleRegisterLoading
                ? 'loading'
                : vehicleRegisterFetched && vehicleRegisterData
                ? 'success'
                : 'fail'
            }
            constainerProps={{ mb: '5px' }}
          />
        </SummaryColum>
      </Row>
    </Column>
  );
};
