import * as React from 'react';
import * as FaGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import Button from './Button';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)({
  backgroundColor: '#ce5542',
  ':hover': {
    backgroundColor: '#a14031',
  },
});

const GoogleButton: React.SFC<any> = props => {
  return <StyledButton icon={FaGoogle} {...props} />;
};

export default GoogleButton;
