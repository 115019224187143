import axios from 'axios';
import { QueryCache } from 'react-query';

export const rest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

export const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
      cacheTime: 10000,
    },
  },
});

export const queryConfig = {
  queries: {
    retry: 3,
    cacheTime: 10000,
    refetchOnWindowFocus: false,
  },
};
