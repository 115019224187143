import * as React from 'react';
import * as S from './AdPreviewItem.styles';
import Progress from '../../Progress';
import Typography from '../../Typography';
import Badge from '../../Badge';

interface IAdPreviewItemProps {
  title: string;
  value: string | number;
  units: string;
  indexValue: number;
  badgeText?: string;
}

const AdPreviewItem: React.SFC<IAdPreviewItemProps> = ({ title, value, indexValue, units, badgeText = '' }) => {
  return (
    <S.AdPreviewItemContainer>
      <S.TitleRow>
        <S.TitleWrapper>
          <Typography
            size='mini'
            text={title}
            weight='medium'
          />
        </S.TitleWrapper>
        {
          badgeText &&
            <Badge
              size='small'
              text={badgeText}
              textSize='micro'
            />
        }
      </S.TitleRow>
      <S.ValueRow>
        <Typography
          size='big'
          text={`${value} ${units}`}
          weight='bold'
        />
      </S.ValueRow>
      <S.ProgressRow>
        <Progress
          percent={indexValue || 0}
          size='small'
        />
      </S.ProgressRow>
    </S.AdPreviewItemContainer>
  );
}

export default AdPreviewItem;


