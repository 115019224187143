import * as React from 'react';
import { SyntheticEvent } from 'react';

import GoogleButton from '../GoogleButton';
import FacebookButton from '../FacebookButton';
import WhatYouGet from '../WhatYouGet';
import Typography from '../Typography';
import Button from '../Button';
import * as S from './SignupDialog.styles';

interface ISignupDialogProps {
  fbLoginInProgress?: boolean;
  fbSignupErrorMsg: string;
  googleLoginInProgress?: boolean;
  googleSignupErrorMsg: string;
  signupInProgress: boolean;
  visible: boolean;
  onClose: () => void;
  onFbLogin: () => void;
  onGoogleLogin: () => void;
  onSwitchToLogin: (evt: SyntheticEvent) => void;
  onSignup: (
    fistname: string,
    surname: string,
    email: string,
    password: string,
    gdprConfirmed: boolean
  ) => Promise<any>;
}

interface ISignupDialogState {
  form: {
    [key: string]: any;
  };
  errors: {
    [key: string]: string | null;
  };
  submitted?: boolean;
  userAlreadyExistsError: string | null;
}

class SignupDialog extends React.Component<ISignupDialogProps, ISignupDialogState> {
  public static defaultProps: Partial<ISignupDialogProps> = {
    visible: false,
    signupInProgress: false,
  };

  public state: ISignupDialogState = {
    form: {
      fullName: null,
      email: null,
      password: null,
      gdprConfirmed: null,
    },
    errors: {
      fullName: null,
      email: null,
      password: null,
      gdprConfirmed: null,
    },
    userAlreadyExistsError: null,
    submitted: false,
  };

  public render() {
    const {
      visible,
      signupInProgress,
      onGoogleLogin,
      googleLoginInProgress,
      onFbLogin,
      fbLoginInProgress,
    } = this.props;

    return (
      <S.StyledDialog
        visible={visible}
        onClose={this.onClose}
        contentClassName='dialog__signup-content'
      >
        <S.DialogContainer>
          <S.FormContainer>
            <form onSubmit={this.signup} noValidate={true}>
              <Typography text={'Registrace'} />
              <div>
                <div>
                  <div>
                    <div>
                      <S.StyledInput
                        placeholder={'Jméno a příjmení'}
                        block={true}
                        onChange={this.onFullNameChange}
                        data-cy='signup-fullName'
                      />
                      {this.state.submitted && this.state.errors.fullName && (
                        <S.StyledErrorMessage
                          textAlign='left'
                          isVisible={this.state.submitted}
                          message={this.state.errors.fullName}
                          name='fullName'
                        />
                      )}
                    </div>
                    <div>
                      <S.StyledInput
                        placeholder={'E-mail (bude jako uživatelské jméno)'}
                        block={true}
                        onChange={this.onEmailChange}
                        data-cy='signup-email'
                      />
                      {this.state.submitted && this.state.errors.email && (
                        <S.StyledErrorMessage
                          textAlign='left'
                          isVisible={this.state.submitted}
                          message={this.state.errors.email}
                          name='email'
                        />
                      )}
                    </div>
                    <div>
                      <S.StyledInput
                        placeholder={'Heslo'}
                        block={true}
                        type={'password'}
                        onChange={this.onPasswordChange}
                        data-cy='signup-password'
                      />
                      {this.state.submitted && this.state.errors.password && (
                        <S.StyledErrorMessage
                          textAlign='left'
                          isVisible={this.state.submitted}
                          message={this.state.errors.password}
                          name='password'
                        />
                      )}
                    </div>
                    <S.GdprContainer>
                      <S.StyledCheckbox
                        label={
                          'Souhlasím se <a href="/privacy-policy" target="_blank">zpracováním osobních údajů</a> dle podmínek <a href="/terms-of-service" target="_blank">používání aplikace</a>,<br />v souladu s GDPR.'
                        }
                        checked={this.state.form.gdprConfirmed}
                        onChange={this.onGdprConfirm}
                        name='signup-gdpr-confirm'
                      />
                      {this.state.submitted && this.state.errors.gdprConfirmed && (
                        <S.StyledErrorMessage
                          textAlign='left'
                          isVisible={this.state.submitted}
                          message={this.state.errors.gdprConfirmed}
                          name='gdprConfirmed'
                        />
                      )}
                    </S.GdprContainer>
                    {this.state.submitted && this.state.userAlreadyExistsError && (
                      <S.StyledErrorMessage
                        textAlign='left'
                        isVisible={this.state.submitted}
                        message={this.state.userAlreadyExistsError}
                      />
                    )}
                    <S.ButtonContainer>
                      <Button
                        kind={'submit'}
                        text={'Registrovat'}
                        fullWidth={true}
                        spin={signupInProgress}
                        disabled={signupInProgress}
                        data-cy='signup-submit'
                      />
                    </S.ButtonContainer>
                  </div>
                  <S.GoogleButtonContainer>
                    <GoogleButton
                      text={'Registrovat přes Google'}
                      spin={googleLoginInProgress}
                      disabled={googleLoginInProgress}
                      onClick={onGoogleLogin}
                      fullWidth={true}
                    />
                  </S.GoogleButtonContainer>
                  {this.props.googleSignupErrorMsg && this.props.googleSignupErrorMsg && (
                    <S.StyledErrorMessage
                      textAlign='left'
                      isVisible={!!this.props.googleSignupErrorMsg}
                      message={this.props.googleSignupErrorMsg}
                    />
                  )}
                  <FacebookButton
                    text={'Registrovat přes Facebook'}
                    spin={fbLoginInProgress}
                    disabled={fbLoginInProgress}
                    onClick={onFbLogin}
                    fullWidth={true}
                  />
                  {this.props.fbSignupErrorMsg && this.props.fbSignupErrorMsg && (
                    <div>
                      <S.StyledErrorMessage
                        textAlign='left'
                        isVisible={!!this.props.fbSignupErrorMsg}
                        message={this.props.fbSignupErrorMsg}
                      />
                    </div>
                  )}
                  <S.DialogDivider />
                  <div className='row center-xs'>
                    <a onClick={this.props.onSwitchToLogin} href='#overauto'>
                      Máte už účet? Přihlašte se
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </S.FormContainer>
          <S.InfoContainer>
            <WhatYouGet />
          </S.InfoContainer>
        </S.DialogContainer>
      </S.StyledDialog>
    );
  }

  private signup = evt => {
    evt.preventDefault();
    const errors = this.validateForm(this.state.form);
    const isValid =
      Object.keys(errors)
        .map(key => errors[key])
        .join('').length === 0;
    this.setState({
      errors,
      submitted: true,
    });

    const { fullName, email, password, gdprConfirmed } = this.state.form;
    if (isValid) {
      let firstname;
      let surname;
      if (fullName) {
        [firstname, surname] = fullName.split(' ');
      }
      this.props
        .onSignup(firstname, surname, email, password, gdprConfirmed)
        .then(error => {
          if (!error) this.clearForm();
        })
        .catch(error => {
          const errCode = error?.response?.data?.code;
          const userAlreadyExists = errCode && errCode === 'userExistsError';
          if (userAlreadyExists) {
            this.setState({
              userAlreadyExistsError: 'Litujeme, ale uživatel se zadaným emailem už existuje.',
            });
          }
        });
    }
  };

  private onFullNameChange = evt => {
    this.handleInputChange(evt.target.value, 'fullName');
  };

  private onEmailChange = evt => {
    this.handleInputChange(evt.target.value, 'email');
  };

  private onPasswordChange = evt => {
    this.handleInputChange(evt.target.value, 'password');
  };

  private onClose = () => {
    this.clearForm();
    this.props.onClose();
  };

  private handleInputChange = (value: any, name: string) => {
    const form = {
      ...this.state.form,
      [name]: value,
    };
    const errors = this.validateForm(form);
    this.setState({
      form,
      errors,
    });
  };

  private validateForm = (form: {
    [key: string]: any;
  }): {
    [key: string]: string | null;
  } => {
    const errors: {
      [key: string]: null | string;
    } = {
      email: null,
      password: null,
    };
    if (!form.fullName) {
      errors.fullName = 'Vložte prosím Vaše jméno';
    }
    if (!form.password) {
      errors.password = 'Vložte prosím heslo';
    }
    if (!form.email || !/[^@]+@[^@]+/.test(form.email)) {
      errors.email = 'Vložte prosím správný e-mail';
    }
    if (!form.gdprConfirmed) {
      errors.gdprConfirmed = 'Pro registraci je nutné udělit souhlas se zpracováním osobních údajů';
    }
    return errors;
  };

  private clearForm = () => {
    this.setState({
      form: {
        fullName: null,
        email: null,
        password: null,
        gdprConfirmed: null,
      },
      errors: {
        fullName: null,
        email: null,
        password: null,
        gdprConfirmed: null,
      },
      submitted: false,
      userAlreadyExistsError: null,
    });
  };

  private onGdprConfirm = (confirmed: boolean) => {
    this.handleInputChange(confirmed, 'gdprConfirmed');
  };
}

export default SignupDialog;
