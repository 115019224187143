import * as React from 'react';
import { connect } from 'react-redux';

import { GenericAdsList } from 'app/components/GenericAdsList';
import { showLoginDialog } from 'app/actions/app';
import { loadAdsLists } from 'app/actions/ads';
import { IAdsLists } from 'app/models/Api';
import { toPercent } from 'app/utils/number';
import { colors } from 'styles/shared.styles';

import topAdsListBlurredImg from 'assets/images/generic-ads-list-blurred.png';
import lastAddedCarsBlurredImg from 'assets/images/last-added-cars-blurred.png';
import lastSoldCarsBlurredImg from 'assets/images/last-sold-cars-blurred.png';

interface IAdsListsContainerStateProps {
  adId: string;
  adsLists: IAdsLists;
  adsListsError: string;
  adsListsIsLoading: boolean;
  isAuthorized: boolean;
  isPdfMode?: boolean;
  showLoginDialog: () => void;
  loadAdsLists: (adId: string) => Promise<IAdsLists>;
}

class AdsListsContainer extends React.Component<IAdsListsContainerStateProps> {
  public currentAdId: string;
  public componentDidMount() {
    this.initAdsLists();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IAdsListsContainerStateProps) {
    this.reloadAdsLists(nextProps);
  }

  public render() {
    const {
      isAuthorized,
      adsLists: { topAdsList, lastSoldCars, lastAddedCars },
      adsListsIsLoading,
      adsListsError,
      isPdfMode,
    } = this.props;

    return (
      <>
        <GenericAdsList
          badgeValueFormatter={value => toPercent(value / 100, 0)}
          badgeValuePropName='b.total'
          blurryImgSrc={topAdsListBlurredImg}
          data={topAdsList}
          dataError={adsListsError}
          dataIsLoading={adsListsIsLoading}
          isAuthorized={isAuthorized}
          sectionHeaderText='Nejlépe hodnocené vozy'
          sectionSubheaderText='Zobrazujeme 10 nejlepších podobných vozů.'
          showLoginDialog={this.props.showLoginDialog}
        />
        {!isPdfMode && (
          <GenericAdsList
            badgeBgColor={colors.darkRed}
            badgeValuePropName='lastRun'
            blurryImgSrc={lastSoldCarsBlurredImg}
            data={lastSoldCars}
            dataError={adsListsError}
            dataIsLoading={adsListsIsLoading}
            isAuthorized={isAuthorized}
            sectionHeaderText='Poslední prodané vozy'
            sectionSubheaderText='Zobrazujeme 10 naposledy prodaných podobných vozů.'
            showLoginDialog={this.props.showLoginDialog}
          />
        )}
        {!isPdfMode && (
          <GenericAdsList
            badgeBgColor={colors.highlight}
            badgeValuePropName='firstRun'
            blurryImgSrc={lastAddedCarsBlurredImg}
            data={lastAddedCars}
            dataError={adsListsError}
            dataIsLoading={adsListsIsLoading}
            isAuthorized={isAuthorized}
            sectionHeaderText='Nově přidané vozy'
            sectionSubheaderText='Zobrazujeme 10 naposledy přidaných podobných vozů.'
            showLoginDialog={this.props.showLoginDialog}
          />
        )}
      </>
    );
  }

  private loadAdsLists = (adId: string): Promise<IAdsLists> => {
    if (!adId) {
      return Promise.reject(null);
    }
    return this.props
      .loadAdsLists(adId)
      .then(response => response)
      .catch(err => err);
  };

  private initAdsLists = () => {
    const { adId } = this.props;
    const adIdHasChanged = adId !== this.currentAdId;
    this.currentAdId = adId;
    if (adIdHasChanged && adId) {
      this.loadAdsLists(adId);
    }
  };

  private reloadAdsLists = (nextProps: IAdsListsContainerStateProps) => {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const { adId } = nextProps;
    this.currentAdId = adId;
    if (authHasChanged && adId) {
      this.loadAdsLists(adId);
    }
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    adId: state.ads.adId,
    adsLists: state.ads.adsLists,
    adsListsError: state.ads.adsListsError,
    adsListsIsLoading: state.ads.adsListsIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoginDialog: () => dispatch(showLoginDialog()),
    loadAdsLists: (adId: string) => dispatch(loadAdsLists(adId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdsListsContainer);
