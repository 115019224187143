import React from 'react';

import { Row, TRowProps } from 'app/components/Row';

export type TColumnProps = TRowProps;

export const Column: React.FC<TColumnProps> = props => (
  <Row minWidth='initial' flexDirection='column' {...props}>
    {props.children}
  </Row>
);
