import React from 'react';

import { AnimatedHeight } from 'app/components/AnimatedHeight';
import { Column } from 'app/components/Column';
import { VerifyVinBaseLayout } from 'app/components/VerifyVin/components/VerifyVinBaseLayout';
import { AdHistoryTable } from 'app/components/AdHistory';
import { DataResults } from 'app/components/DataResults';
import { EnumAdLoaderTexts } from 'app/components/AdLoader';

import { useTypedSelector } from 'app/store';
import { useVerifyVinRoute } from 'app/hooks';
import { checkViewMode } from 'app/utils';
import Ad from 'app/models/Ad';
import defectsTableBlurred from 'assets/images/defect-table-blurred.jpg';

export const DefectsTable: React.FC = () => {
  const { isAuthenticated, vin } = useVerifyVinRoute();
  const isPdfMode = checkViewMode('pdf');
  const kmHistoryNotFoundVin = useTypedSelector(state => state.ads.kmHistoryNotFoundVin);
  const kmHistory = useTypedSelector(state => state.ads.kmHistory);
  const defects = kmHistoryNotFoundVin?.data?.defects || kmHistory?.data?.defects;
  const kmHistoryIsLoading = useTypedSelector(state => state.ads.kmHistoryIsLoading);
  const kmHistoryNotFoundVinIsLoading = useTypedSelector(
    state => state.ads.kmHistoryNotFoundVinIsLoading
  );
  const kmHistoryError = useTypedSelector(state => state.ads.kmHistoryError);
  const kmHistoryNotFoundVinError = useTypedSelector(state => state.ads.kmHistoryNotFoundVinError);
  const isLoading = kmHistoryIsLoading || kmHistoryNotFoundVinIsLoading;

  const dataLen = defects?.length || 0;
  const title = 'Historie závad';
  const tableColumnsConfig = [
    {
      title: 'Datum',
      fieldName: 'date',
      width: '15%',
      flex: 'initial',
    },
    {
      title: 'Typ',
      fieldName: 'inspectionType',
      width: '15%',
      flex: 'initial',
    },
    {
      title: 'Závada',
      fieldName: 'value',
      width: '70%',
      flex: 'initial',
    },
  ];

  return (
    <VerifyVinBaseLayout
      blurredImgSrc={defectsTableBlurred}
      data={defects?.length ? defects : null}
      dataError={kmHistoryError || kmHistoryNotFoundVinError}
      dataIsLoading={isLoading}
      dataLoadingText={EnumAdLoaderTexts.SEARCHING_THIRDPARTY_SERVICES}
      isAuthorized={isAuthenticated}
      sectionHeaderText={title}
      signInText='Dostupné údaje se zobrazují pouze přihlášeným uživatelům'
      subheaderSuffixText={Ad.getNumberOfRecordsTitle(defects?.length)}
      subheaderValue={dataLen}
      vin={vin}
    >
      <DataResults
        sectionHeaderText={title}
        sectionSubheaderValue={dataLen}
        sectionSubheaderSuffixText={Ad.getNumberOfRecordsTitle(dataLen)}
        dataIsLoading={isLoading}
      >
        <Column
          justifyContent='space-between'
          flexDirection='column'
          width={1}
          mr={0}
          mb={['40px', 0, 0]}
          pr={[0, '10px']}
        >
          <AnimatedHeight
            minHeight={290}
            btnTextOpen='Zobrazit další data'
            btnTextClose='Skrýt'
            enabled={isPdfMode ? false : defects?.length > 6}
          >
            <AdHistoryTable history={defects} columnsConfig={tableColumnsConfig} />
          </AnimatedHeight>
        </Column>
      </DataResults>
    </VerifyVinBaseLayout>
  );
};
