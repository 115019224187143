// const margin = {
//   horizontal: 'marginRight',
//   vertical: 'marginBottom'
// };

/**
 *
 * Usage:
 * import flex from 'styles/flex';
 * ```
 * export const Button = styled.button(
 * {
 *   ...flex.horizontal,
 *   ...flex.centerHorizontal,
 *   ...
 * }
 * ```
 */

export const flex = {
  flex: {
    display: 'flex',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  centerHorizontal: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerHorizontalH: {
    justifyContent: 'center',
  },
  centerHorizontalV: {
    alignItems: 'center',
  },
  centerVertical: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerVerticalH: {
    alignItems: 'center',
  },
  centerVerticalV: {
    justifyContent: 'center',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  horizontalReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  verticalReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  alignChildrensLeft: {
    alignItems: 'flex-start',
  },
  alignChildrensCenter: {
    alignItems: 'center',
  },
  alignChildrensRight: {
    alignItems: 'flex-end',
  },
  stretch: {
    alignItems: 'stretch',
  },
  stretchSelf: {
    alignSelf: 'stretch',
  },
  initial: {
    alignItems: 'initial',
    justifyContent: 'initial',
  },
};

export default flex;
