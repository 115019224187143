import * as React from 'react';
import { SyntheticEvent } from 'react';
import { withFormik, FormikProps } from 'formik';
import { ButtonSubmit } from 'app/components/Button';

import * as S from './SearchForm.styles';

interface IFormState {
  search: string;
}

interface ISearchFormProps {
  placeholderText: string;
  buttonText: string;
  isLoading?: boolean;
  disabled?: boolean;
  exampleText?: string;
  validationSchema?: any;
  onSubmit: (value: string) => void;
  onInputChange?: (evt: SyntheticEvent) => void;
  onInputFocus?: (evt: SyntheticEvent) => void;
}

const SearchForm: React.SFC<ISearchFormProps & FormikProps<IFormState>> = ({
  // own props
  placeholderText,
  buttonText,
  disabled,
  isLoading,
  exampleText,
  onSubmit,
  onInputChange,
  onInputFocus,
  validationSchema,

  // formik
  handleChange,
  handleSubmit,
  touched,
  errors,
  values,
  isSubmitting,
  setFieldTouched,
  ...props
}) => {
  return (
    <S.FormContainer {...props}>
      <form onSubmit={handleSubmit} noValidate={true}>
        <S.InputWrapper>
          <S.StyledInput
            type='text'
            name='search'
            onChange={evt => {
              handleChange(evt);
              onInputChange && onInputChange(evt);
            }}
            onFocus={onInputFocus}
            onKeyUp={() => setFieldTouched('search', true)}
            placeholder={placeholderText}
            touched={touched.search}
            value={values.search}
            disabled={disabled || isSubmitting}
            autoComplete='off'
          />
          <ButtonSubmit
            kind={'submit'}
            text={buttonText}
            spin={isLoading}
            disabled={disabled || isSubmitting || !!errors.search || !values.search}
            onClick={evt => {
              evt.preventDefault();
              onSubmit(values.search);
            }}
          />
        </S.InputWrapper>
      </form>
      {errors && errors.search && touched && (
        <S.StyledErrorMessage textAlign='left' isVisible={true} message={errors.search} />
      )}
      {exampleText && <S.Example>{exampleText}</S.Example>}
    </S.FormContainer>
  );
};

SearchForm.defaultProps = {
  disabled: false,
  onInputFocus: () => {},
};

const Form = withFormik<ISearchFormProps, IFormState>({
  mapPropsToValues: props => {
    return {
      search: '',
    };
  },

  validationSchema: ({ validationSchema }) => {
    if (!validationSchema) {
      return null;
    }
    return validationSchema;
  },

  handleSubmit: (values: IFormState, { props: { onSubmit }, setFieldTouched, setSubmitting }) => {
    const { search } = values;
    setFieldTouched('search', false);
    setSubmitting(true);
    onSubmit(search);
  },
})(SearchForm);

export default Form;
