import * as React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { CheckboxGroup as ReactCheckboxGroup, Checkbox } from 'react-checkbox-group';
import { FormLabel } from '../Form';
import ErrorMessage from '../../ErrorMessage';

import { ICheckboxGroupOption } from './';
import * as S from './CheckboxGroup.styles';

interface ICheckboxGroup {
  name: string;
  selectedValue: any;
  values: ICheckboxGroupOption[];
  disabled?: boolean;
  error?: (string | undefined)[] | FormikErrors<any> | string;
  label?: string;
  touched?: (boolean | undefined)[] | FormikTouched<any> | boolean;
  onChange: (value) => void;
}

const CheckboxGroup: React.SFC<ICheckboxGroup> = ({
  values,
  name,
  selectedValue,
  onChange,
  error,
  touched,
  label,
  disabled,
  ...props
}) => (
  <ReactCheckboxGroup checkboxDepth={2} name={name} value={selectedValue} onChange={onChange}>
    {label && <FormLabel>{label}</FormLabel>}
    {values &&
      values.length &&
      values.map((item: ICheckboxGroupOption) => (
        <S.StyledLabel key={item.value} disabled={disabled}>
          <Checkbox value={item.value} disabled={disabled} />
          {item.label}
        </S.StyledLabel>
      ))}
    {error && touched && (
      <ErrorMessage textAlign='left' isVisible={true} message={error.toString()} />
    )}
  </ReactCheckboxGroup>
);

CheckboxGroup.defaultProps = {
  label: '',
  disabled: false,
};

export default CheckboxGroup;
