import { Record } from 'immutable';
import { isUndefined } from 'lodash-es';
import qs from 'query-string';

export interface IAdFilterProps {
  yearFrom?: number;
  yearTo?: number;
  fuel?: string;
  karoserie?: string;
  kmFrom?: number;
  kmTo?: number;
  priceFrom?: number;
  priceTo?: number;
  transmission?: string;
  kwFrom?: number;
  kwTo?: number;
  equipmentCountFrom?: number;
  equipmentCountTo?: number;
  yearIncluded: boolean;
  priceIncluded: boolean;
  fuelIncluded: boolean;
  karoserieIncluded: boolean;
  transmissionIncluded: boolean;
  kmIncluded: boolean;
  kwIncluded: boolean;
  equipmentCountIncluded: boolean;
}

export default class AdFilter
  extends Record({
    yearFrom: 0,
    yearTo: 0,
    fuel: '',
    karoserie: '',
    kmFrom: 0,
    kmTo: 0,
    priceFrom: 0,
    priceTo: 0,
    transmission: '',
    kwFrom: 0,
    kwTo: 0,
    equipmentCountFrom: 0,
    equipmentCountTo: 0,
    yearIncluded: false,
    priceIncluded: false,
    fuelIncluded: false,
    karoserieIncluded: false,
    transmissionIncluded: false,
    kmIncluded: false,
    kwIncluded: false,
    equipmentCountIncluded: false,
  })
  implements IAdFilterProps {
  public static rangeValues = {
    price: [
      0,
      50000,
      75000,
      100000,
      150000,
      200000,
      250000,
      300000,
      350000,
      400000,
      500000,
      750000,
      1000000,
      1500000,
      2000000,
      3000000,
      5000000,
      10000000,
    ],
    km: [
      0,
      10000,
      30000,
      50000,
      75000,
      100000,
      125000,
      150000,
      200000,
      250000,
      300000,
      400000,
      500000,
      600000,
      700000,
      800000,
      1000000,
    ],
    kw: [
      0,
      50,
      60,
      70,
      80,
      95,
      110,
      130,
      160,
      190,
      220,
      250,
      300,
      350,
      400,
      450,
      500,
      600,
      700,
      800,
    ],
    equipmentCount: [0, 10, 15, 20, 25, 30, 40, 50],
  };

  public static getFilterFromSearchStringFilter = (
    queryFilter: IAdFilterProps
  ): Partial<AdFilter> => {
    const filter: Partial<IAdFilterProps> = {};

    if (!isUndefined(queryFilter.fuel)) {
      filter.fuelIncluded = true;
      // filter.set('fuelIncluded', true);
      filter.fuel = queryFilter.fuel;
    }

    if (!isUndefined(queryFilter.kmFrom) || !isUndefined(queryFilter.kmTo)) {
      filter.kmIncluded = true;
      filter.kmFrom = queryFilter.kmFrom;
      filter.kmTo = queryFilter.kmTo;
    }

    if (!isUndefined(queryFilter.kwFrom) || !isUndefined(queryFilter.kwTo)) {
      filter.kwIncluded = true;
      filter.kwFrom = queryFilter.kwFrom;
      filter.kwTo = queryFilter.kwTo;
    }

    if (!isUndefined(queryFilter.priceFrom) || !isUndefined(queryFilter.priceTo)) {
      filter.priceIncluded = true;
      filter.priceFrom = queryFilter.priceFrom;
      filter.priceTo = queryFilter.priceTo;
    }

    if (!isUndefined(queryFilter.yearFrom) || !isUndefined(queryFilter.yearTo)) {
      filter.yearIncluded = true;
      filter.yearFrom = queryFilter.yearFrom;
      filter.yearTo = queryFilter.yearTo;
    }

    if (
      !isUndefined(queryFilter.equipmentCountFrom) ||
      !isUndefined(queryFilter.equipmentCountTo)
    ) {
      filter.equipmentCountIncluded = true;
      filter.equipmentCountFrom = queryFilter.equipmentCountFrom;
      filter.equipmentCountTo = queryFilter.equipmentCountTo;
    }

    if (!isUndefined(queryFilter.transmission)) {
      filter.transmissionIncluded = true;
      filter.transmission = queryFilter.transmission;
    }

    if (!isUndefined(queryFilter.karoserie)) {
      filter.karoserieIncluded = true;
      filter.karoserie = queryFilter.karoserie;
    }

    return filter;
  };

  public static getSanitizedQueryString = (filter: IAdFilterProps): Partial<AdFilter> => {
    const sanitizedFilter: Partial<IAdFilterProps> = {};

    if (filter.fuelIncluded) {
      sanitizedFilter.fuel = filter.fuel;
    }

    if (filter.transmissionIncluded) {
      sanitizedFilter.transmission = filter.transmission;
    }

    if (filter.kmIncluded) {
      if (filter.kmFrom) {
        sanitizedFilter.kmFrom = filter.kmFrom;
      }
      if (filter.kmTo) {
        sanitizedFilter.kmTo = filter.kmTo;
      }
    }

    if (filter.kwIncluded) {
      if (filter.kwFrom) {
        sanitizedFilter.kwFrom = filter.kwFrom;
      }
      if (filter.kwTo) {
        sanitizedFilter.kwTo = filter.kwTo;
      }
    }

    if (filter.priceIncluded) {
      if (filter.priceFrom) {
        sanitizedFilter.priceFrom = filter.priceFrom;
      }
      if (filter.priceTo) {
        sanitizedFilter.priceTo = filter.priceTo;
      }
    }

    if (filter.yearIncluded) {
      if (filter.yearFrom) {
        sanitizedFilter.yearFrom = filter.yearFrom;
      }
      if (filter.yearTo) {
        sanitizedFilter.yearTo = filter.yearTo;
      }
    }

    if (filter.equipmentCountIncluded) {
      if (filter.equipmentCountFrom) {
        sanitizedFilter.equipmentCountFrom = filter.equipmentCountFrom;
      }
      if (filter.equipmentCountTo) {
        sanitizedFilter.equipmentCountTo = filter.equipmentCountTo;
      }
    }

    if (filter.karoserieIncluded) {
      sanitizedFilter.karoserie = filter.karoserie;
    }

    // TODO
    // @ts-ignore
    return qs.stringify(sanitizedFilter);
  };

  public static calcRoundedValues = (min: number, max: number, type: string): [number, number] => {
    const DEFAULT_MIN = 0;
    const DEFAULT_MAX = 100000000;
    const range = AdFilter.rangeValues[type];
    if (!range || (!min && !max)) {
      return [DEFAULT_MIN, DEFAULT_MAX];
    }

    const roundedMin = min ? AdFilter.roundWithRange(min, range, true) : DEFAULT_MIN;
    const roundedMax = max ? AdFilter.roundWithRange(max, range) : DEFAULT_MAX;
    return [roundedMin, roundedMax];
  };

  public static roundWithRange = (value: number, range: number[], roundFloor = false): number => {
    if (range.indexOf(value) !== -1) {
      return value;
    }
    const diffs = range
      ?.map((item, index) => {
        const diff = item - value;
        return { diff, value: item, index };
      })
      .sort((a, b) => Math.abs(a.diff) - Math.abs(b.diff));
    const closest = diffs.slice(0, 2);
    return roundFloor
      ? Math.min(closest[0].value, closest[1].value)
      : Math.max(closest[0].value, closest[1].value);
  };

  // public yearFrom: number;
  // public yearTo: number;
  // public fuel: string;
  // public karoserie: string;
  // public kmFrom: number;
  // public kmTo: number;
  // public priceFrom: number;
  // public priceTo: number;
  // public transmission: string;
  // public kwFrom: number;
  // public kwTo: number;
  // public equipmentCountFrom: number;
  // public equipmentCountTo: number;
  // public yearIncluded: boolean;
  // public priceIncluded: boolean;
  // public fuelIncluded: boolean;
  // public transmissionIncluded: boolean;
  // public kmIncluded: boolean;
  // public kwIncluded: boolean;
  // public equipmentCountIncluded: boolean;
  // public karoserieIncluded: boolean;

  constructor(props?: Partial<AdFilter>) {
    props ? super(props) : super();
  }

  public with(values: Partial<AdFilter>) {
    return this.mergeWith((oldVal: any, newVal: any, key: any) => {
      if (newVal !== undefined) {
        return newVal;
      }
      return oldVal;
    }, values) as this;
  }

  // public toString() {
  //   let filter = '';
  //   if (this.yearIncluded) {
  //     filter += `rok: ${this.yearFrom} - ${this.yearTo}`;
  //   }

  //   if (this.priceIncluded) {
  //     filter += `, cena: ${this.priceFrom} - ${this.priceTo}`;
  //   }

  //   if (this.fuelIncluded) {
  //     filter += `, palivo: ${this.fuel}`;
  //   }

  //   if (this.kmIncluded) {
  //     filter += `, najeté kilometry: ${this.kmFrom} - ${this.kmTo}`;
  //   }

  //   if (this.transmissionIncluded) {
  //     filter += `, převodovka: ${this.transmission}`;
  //   }

  //   if (this.kwIncluded) {
  //     filter += `, výkon minimálně: ${this.kwFrom} - ${this.kwTo}`;
  //   }

  //   if (this.equipmentCountIncluded) {
  //     filter += `, počet prvků výbavy minimálně: ${this.equipmentCountFrom} - ${
  //       this.equipmentCountTo
  //     }`;
  //   }

  //   if (this.karoserieIncluded) {
  //     filter += `, karoserie: ${this.karoserie}`;
  //   }

  //   return filter;
  // }
}
