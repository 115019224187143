import React from 'react';

import { Column } from 'app/components/Column';
import { buildInfo } from 'app/utils/build-info';

export const Version = () => <Column></Column>;

(window as any).getVersion = () => {
  const version = {
    appMode: buildInfo.APP_MODE,
    branch: buildInfo.BRANCH,
    builTime: buildInfo.BUILD_TIME,
    commit: buildInfo.COMMITHASH,
    env: buildInfo.ENV,
    publicUrl: buildInfo.PUBLIC_URL,
    version: buildInfo.VERSION,
  };
  return version;
};
