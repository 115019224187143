declare var gapi: any;

export function googleAuthorize(silent?: boolean) {
    return new Promise((resolve, reject) => {
        gapi.auth2.authorize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email profile',
            response_type: 'code permission',
            prompt: silent ? 'none' : 'select_account',
        }, response => resolve(response), error => reject(error));
    });
}
