import React from 'react';
import styled from 'styled-components/macro';
import { Flex, BoxProps } from 'reflexbox/styled-components';
import ReactTable, { TableProps } from 'react-table';

import SectionHeader from 'app/components/SectionHeader';
import { IObjectGeneric } from 'app/types';
import { PAGINATION_CONFIG } from './Table.utils';
import 'react-table/react-table.css';

interface IOwnProps {
  data: IObjectGeneric[]; // raw table data
  columns: IObjectGeneric[]; // table columns definitions
  sortIconsVisible?: boolean;
  defaultSortOrder?: string;
  children?: React.ReactNode;
  sectionTitle?: string;
}

export type TTableProps = IOwnProps & Partial<TableProps>;

/**
 * Table component via react-table
 * docs: https://github.com/tannerlinsley/react-table/tree/v6
 */
export const TableReact: React.FC<TTableProps> = ({
  defaultSortOrder = 'asc',
  columns = [],
  data = [],
  children,
  ...props
}) => (
  <Container width={1} defaultSortOrder={defaultSortOrder}>
    <ReactTable
      className='-striped -highlight'
      columns={columns}
      data={data}
      filterable={false}
      manual={true}
      minRows={1}
      nextText='Další'
      noDataText='Žádné záznamy'
      ofText='z'
      pageSizeOptions={PAGINATION_CONFIG.DEFAULT_PAGE_SIZES}
      pageText='Stránka'
      previousText='Předchozí'
      resizable={true}
      rowsText='řádků'
      showPagination={true}
      sortable={true}
      {...props}
    >
      {(state, makeTable, instance) => children && children(state, makeTable, instance)}
    </ReactTable>
  </Container>
);

export const BasicTable: React.FC<TTableProps> = ({ sectionTitle, ...props }) => (
  <>
    {sectionTitle && <SectionHeader title={sectionTitle} />}
    <TableReact {...props}>{(_state, makeTable, _instance) => makeTable()}</TableReact>
  </>
);

const Container = styled(Flex)<BoxProps & IOwnProps>`
  // react-table overrides
  .ReactTable {
    border: none;
    width: 100%;

    .-pagination {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .-sort-desc {
      box-shadow: none !important;
      &:before {
        content: '▼';
        float: right;
        margin-right: 3px;
      }
    }

    .-sort-asc {
      box-shadow: none !important;
      &:before {
        content: '▲';
        float: right;
        margin-right: 3px;
      }
    }
  }
`;
