import React from 'react';

import { ComparatorCards } from './components/ComparatorCards';
import { ComparatorHeader } from './components/ComparatorHeader';

export const ReportsComparator: React.FC = () => (
  <>
    <ComparatorHeader />
    <ComparatorCards />
  </>
);
