import styled from 'styled-components/macro';
import { mediaQuery } from 'styles/shared.styles';

export const Wrapper = styled.div`
  width: 150px;
  height: 100%;
  ${mediaQuery.medium} {
    padding: 16px 8px;
    width: 175px;
  }

  svg {
    display: block;
  }
`;
