export function ago(timestamp: number) {
  const time = timestamp;
  const timeFormats = [
    [60, 1, 'sekundou', 'sekundami'], // 60
    [3600, 60, 'minutou', 'minutami'], // 60*60, 60
    [86400, 3600, 'hodinou', 'hodinami'], // 60*60*24, 60*60
    [604800, 86400, '@Včera', 'dny'], // 60*60*24*7, 60*60*24
    [2419200, 604800, 'týdnem', 'týdny'], // 60*60*24*7*4, 60*60*24*7
    [29030400, 2419200, 'měsícem', 'měsíci'], // 60*60*24*7*4*12, 60*60*24*7*4
    [2903040000, 29030400, 'rokem', 'roky'], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
  ];

  let seconds = (Date.now() - time) / 1000;
  let token = 'Před';

  if (seconds === 0) {
    return 'Právě teď';
  }

  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
  }

  let i = 0;
  let format;

  // tslint:disable-next-line: no-conditional-assignment
  while ((format = timeFormats[i++])) {
    if (seconds < format[0]) {
      const t = Math.floor(seconds / (format[1] as number));
      const f1: string = format[2] as string;
      const f2: string = format[3] as string;

      if (t <= 1) {
        if (f1.indexOf('@') === 0) {
          return f1.substr(1);
        }
        return token + ' ' + f1;
      } else {
        if (f2.indexOf('@') === 0) {
          return t + ' ' + f2.substr(1);
        }
        return token + ' ' + t + ' ' + f2;
      }
    }
  }

  const d = new Date(timestamp);
  return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
}
