import { get } from 'lodash-es';
import * as CONSTANTS from 'app/constants/payments';
import * as paymentInterfaces from '../models/Payment';

interface IPaymentReducer {
  generatedPayment: paymentInterfaces.IPayment | null;
  generatedPaymentIsLoading: boolean;
  generatedPaymentError: string | null;
  availablePackages: paymentInterfaces.IPaymentPackages | null;
  availablePackagesIsLoading: boolean;
  availablePackagesError: string | null;
  userPaymentLimits: paymentInterfaces.IPaymentLimits | null;
  userPaymentLimitsIsLoading: boolean;
  userPaymentLimitsError: string | null;
  userPayments: paymentInterfaces.IUserPayments | null;
  userPaymentsIsLoading: boolean;
  userPaymentsError: string | null;
}

const initialState: IPaymentReducer = {
  generatedPayment: {} as paymentInterfaces.IPayment,
  generatedPaymentIsLoading: false,
  generatedPaymentError: null,
  availablePackages: {
    base: {
      id: 'base',
      title: '1 měsíc',
      period: {
        value: 1,
        unit: 'months',
      },
      price: 599,
      currency: 'CZK',
      limits: {
        vin: 20,
      },
    },
    premium: {
      id: 'premium',
      title: '3 měsíce',
      period: {
        value: 3,
        unit: 'months',
      },
      price: 1499,
      currency: 'CZK',
      limits: {
        vin: 20,
      },
    },
    elite: {
      id: 'elite',
      title: '1 rok',
      period: {
        value: 12,
        unit: 'months',
      },
      price: 4999,
      currency: 'CZK',
      limits: {
        vin: 20,
      },
    },
  },
  availablePackagesIsLoading: false,
  availablePackagesError: null,
  userPaymentLimits: {
    vin: {
      limit: 20,
      total: 0,
      summary: [],
      remaining: 20,
    },
  },
  userPaymentLimitsIsLoading: false,
  userPaymentLimitsError: null,
  userPayments: {
    activePayment: null,
    allPayments: {
      docs: [],
      total: 0,
      from: 0,
      limit: 20,
      page: 0,
      pages: 0,
    },
  },
  userPaymentsIsLoading: false,
  userPaymentsError: null,
};

const app = (state = initialState, action: any): IPaymentReducer => {
  switch (action.type) {
    case CONSTANTS.CREATE_PAYMENT:
      return {
        ...state,
        generatedPayment: null,
        generatedPaymentIsLoading: true,
        generatedPaymentError: null,
      };
    case CONSTANTS.CREATE_PAYMENT_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        generatedPayment: data,
        generatedPaymentIsLoading: false,
        generatedPaymentError: null,
      };
    }
    case CONSTANTS.CREATE_PAYMENT_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      const errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        generatedPayment: null,
        generatedPaymentIsLoading: false,
        generatedPaymentError: errCode,
      };
    }
    case CONSTANTS.LOAD_AVAILABLE_PACKAGES:
      return {
        ...state,
        availablePackages: initialState.availablePackages,
        availablePackagesIsLoading: true,
        availablePackagesError: null,
      };
    case CONSTANTS.LOAD_AVAILABLE_PACKAGES_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        availablePackages: data,
        availablePackagesIsLoading: false,
        availablePackagesError: null,
      };
    }
    case CONSTANTS.LOAD_AVAILABLE_PACKAGES_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      const errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        availablePackages: null,
        availablePackagesIsLoading: false,
        availablePackagesError: errCode,
      };
    }
    case CONSTANTS.LOAD_USER_PAYMENT_LIMITS:
      return {
        ...state,
        userPaymentLimits: initialState.userPaymentLimits,
        userPaymentLimitsIsLoading: true,
        userPaymentLimitsError: null,
      };
    case CONSTANTS.LOAD_USER_PAYMENT_LIMITS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        userPaymentLimits: data,
        userPaymentLimitsIsLoading: false,
        userPaymentLimitsError: null,
      };
    }
    case CONSTANTS.LOAD_USER_PAYMENT_LIMITS_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      const errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        userPaymentLimits: null,
        userPaymentLimitsIsLoading: false,
        userPaymentLimitsError: errCode,
      };
    }
    case CONSTANTS.RESET_USER_PAYMENT_LIMITS:
      return {
        ...state,
        userPaymentLimits: initialState.userPaymentLimits,
        userPaymentLimitsIsLoading: false,
        userPaymentLimitsError: null,
      };
    case CONSTANTS.LOAD_USER_PAYMENTS:
      return {
        ...state,
        userPayments: initialState.userPayments,
        userPaymentsIsLoading: true,
        userPaymentsError: null,
      };
    case CONSTANTS.LOAD_USER_PAYMENTS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        userPayments: data,
        userPaymentsIsLoading: false,
        userPaymentsError: null,
      };
    }
    case CONSTANTS.LOAD_USER_PAYMENTS_FAIL: {
      const code = get(action, 'error.response.data.code', null);
      const errCode = get(action, 'error.response.data.message', code);
      return {
        ...state,
        userPayments: null,
        userPaymentsIsLoading: false,
        userPaymentsError: errCode,
      };
    }
    case CONSTANTS.RESET_USER_PAYMENTS:
      return {
        ...state,
        userPayments: initialState.userPayments,
        userPaymentsIsLoading: false,
        userPaymentsError: null,
      };
    default:
      return state;
  }
};

export default app;
