import * as io from 'socket.io-client';
import { log } from 'app/utils/log';

export class WebSockets {
  public static socket: any;

  /**
   * Connect
   */
  public static connect = (accessToken: string): void => {
    // temporarily turned off
    if (new Date()) return;
    if (!accessToken) return;

    const WEBSOCKET_URL = process.env.REACT_APP_WS_BASE_URL || 'http://localhost:3333';
    const socket = io.connect(WEBSOCKET_URL, {
      forceNew: true,
      secure: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      query: { token: accessToken },
    });
    WebSockets.socket = socket;
  };

  /**
   * Disconnect
   */
  public static disconnect = (): void => {
    if (WebSockets.socket) {
      log.debug(`WebSockets.disconnect:: socket disconnected ${WebSockets.socket.id}`);
      WebSockets.socket.disconnect();
      WebSockets.socket = null;
      return;
    }
  };
}
