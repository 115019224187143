import get from 'lodash-es/get';

export const ENV = {
  prod: 'prod',
  staging: 'staging',
  stagingMobile: 'staging-mobile',
  local: 'local',
};

export const getEnv = (): string => {
  if (!window.location) return '';

  const location = get(window.location, 'host', '');
  const isProd =
    location.includes('https://www.overauto.cz') || location.includes('https://overauto.cz');
  const isStaging = location.includes('beta.overauto.cz');
  const isStagingMobile = location.includes('beta-mobile.overauto.cz');
  const isLocal = location.indexOf('localhost') > -1;

  if (isProd) return ENV.prod;
  if (isStaging) return ENV.staging;
  if (isStagingMobile) return ENV.stagingMobile;
  if (isLocal) return ENV.local;
  return '';
};

export const isLocalEnv = getEnv() === ENV.local;
export const isLocalOrStagingEnv =
  getEnv() === ENV.local || getEnv() === ENV.staging || getEnv() === ENV.stagingMobile;
export const isProdEnv = getEnv() === ENV.prod;
