import * as React from 'react';
import * as S from './LinkButton.styles';

export interface IStyledButtonLinkProps {
  size?: 'normal' | 'small';
  btnStyle?: 'primary' | 'secondary';
  onClick?: () => void;
}

const defaultProps: Partial<IStyledButtonLinkProps> = {
  size: 'normal',
  btnStyle: 'primary',
};

const LinkButton: React.SFC<IStyledButtonLinkProps> = ({ children, size, btnStyle, ...props }) => (
  <S.StyledLinkButton size={size} btnStyle={btnStyle} onTouchStart={props.onClick} {...props}>
    {children}
  </S.StyledLinkButton>
);

LinkButton.defaultProps = defaultProps;

export default LinkButton;
