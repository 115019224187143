import React from 'react';

import { Column, TColumnProps } from 'app/components/Column';

interface IIconSeparatorProps extends TColumnProps {
  icon: React.ReactNode;
}

export const IconSeparator: React.FC<IIconSeparatorProps> = ({ icon, ...props }) => (
  <Column position='relative' {...props}>
    <Column width={1} alignItems='center' zIndex={1}>
      <Column pl='5px' pr='1px' background='white'>
        {icon}
      </Column>
    </Column>
    <Column height='1px' top='8px' background='#979797' width={1} position='absolute' />
  </Column>
);
