import * as Yup from 'yup';

export const formConfig = {
  labels: {
    brand: 'Značka *',
    model: 'Model *',
    year: 'Rok výroby *',
    km: 'Najeto *',
    kw: 'Výkon *',
    fuel: 'Palivo *',
    firstOwner: 'První majitel *',
    serviceBook: 'Servisní knížka *',
    stk: 'STK *',
    transmission: 'Převodovka *',
    ac: 'Klimatizace',
    karoserie: 'Karoserie *',
    equipment: 'Výbava *',
  },
  names: {
    brand: 'brand',
    model: 'model',
    year: 'year',
    km: 'km',
    kw: 'kw',
    fuel: 'fuel',
    firstOwner: 'firstOwner',
    serviceBook: 'serviceBook',
    stk: 'stk',
    transmission: 'transmission',
    ac: 'ac',
    karoserie: 'karoserie',
    equipment: 'equipment',
  },
  placeholders: {
    brand: 'Zvolte značku',
    model: 'Zvolte model',
    year: 'Zvolte rok',
    km: 'Najeto',
    kw: 'Počet kW',
    fuel: 'Typ paliva',
    firstOwner: 'První majitel',
    serviceBook: 'Servisní knížka',
    stk: 'STK platné do...',
    transmission: 'Typ převodovky',
    ac: 'Typ klimatizace',
    karoserie: 'Typ karoserie',
    equipment: 'Zvolte vybavení',
  },
};

export const defaultFormValues = {
  brand: '',
  model: '',
  year: '',
  km: '',
  kw: '',
  fuel: '',
  firstOwner: '',
  serviceBook: '',
  stk: '',
  transmission: '',
  ac: null,
  karoserie: '',
  equipment: [],
};

export const FormSchema = Yup.object().shape({
  brand: Yup.string().required('Značka je povinný atribut'),
  model: Yup.string().required('Model je povinný atribut'),
  year: Yup.string().required('Rok je povinný atribut'),
  km: Yup.number()
    .min(0, 'Minimální hodnota je 0')
    .required('Počet kilmetrů je povinný atribut'),
  kw: Yup.number()
    .min(0, 'Minimální hodnota je 0')
    .required('Počet kw je povinný atribut'),
  fuel: Yup.string().required('Palivo je povinný atribut'),
  firstOwner: Yup.boolean().required('První majitel je povinný atribut'),
  serviceBook: Yup.boolean().required('Servisní knížka je povinný atribut'),
  stk: Yup.number()
    .min(1980, 'Minimální hodnota je 1980')
    .required('STK je povinný atribut'),
  transmission: Yup.string().required('Převodovka je povinný atribut'),
  ac: Yup.string().nullable(true),
  // .required('Klimatizace je povinný atribut'),
  karoserie: Yup.string().required('Karoserie je povinný atribut'),
  equipment: Yup.array()
    .of(Yup.string())
    .min(0)
    .required('Vybavení je povinný atribut'),
});
