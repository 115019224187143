import { get } from 'lodash-es';
import { log } from 'app/utils/log';
import { apiGetPayment } from 'app/api';
import { redirectTo } from 'app/utils/url.utils';
import { checkout } from 'app/utils/goPay';

import { EnumPaymentStates, EnumPaymentCategory } from 'app/models/Payment';
import { TPaymentCategory } from 'app/types';

export const handleCheckout = (
  gwUrl: string,
  vin: string,
  accessToken: string,
  setLoading?: (isLoading) => void
) => {
  if (!accessToken || !gwUrl || !vin) {
    log.error('checkout:: err: missing data for payment');
    throw new Error('checkout cannot be processed');
  }

  return new Promise((resolve, reject) => {
    checkout(gwUrl, async (err, checkoutResult) => {
      try {
        const paymentStatus = await apiGetPayment(vin, String(checkoutResult?.id), accessToken);
        const payment = paymentStatus?.data;
        log.debug('checkout:: paymentStatus', payment);
        const state = get(paymentStatus, 'data.data.state');
        const paymentCategory = get(paymentStatus, 'data.data.paymentCategory');
        log.debug('checkout:: payment state: ', state);
        log.debug('checkout:: payment paymentCategory: ', paymentCategory);
        setLoading?.(false);
        if (
          state === EnumPaymentStates.CANCELED ||
          state === EnumPaymentStates.CREATED ||
          paymentCategory === EnumPaymentCategory.CACHE_BUST
        ) {
          redirectTo(`/vin/${vin}`);
          resolve(payment);
          return;
        }
        redirectTo(checkoutResult?.url || '');
        resolve(payment);
      } catch (err) {
        log.error('checkout:: err: ', err);
        setLoading?.(false);
        reject(err);
      }
    });
  });
};

export const makePayment = async (
  vin: string,
  gwUrl: string,
  accessToken: string,
  paymentType: TPaymentCategory = 'vinPremium',
  setLoading?: (isLoading) => void
) => {
  setLoading?.(true);
  try {
    const result = await handleCheckout(gwUrl, vin, accessToken, setLoading);
    return result;
  } catch (err) {
    const errorCode = (err as any)?.data?.code;
    if (errorCode === 'vinAlreadyPaid') redirectTo(`/vin/${vin}/premium`);
    log.error('makePayment:: err: ', err);
    setLoading?.(false);
    redirectTo(`/vin/${vin}`);
    return err;
  }
};
