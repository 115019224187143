import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash-es';

import { useVin } from './useVin';
import { useAuthenticated } from './useAuthenticated';
import { log } from 'app/utils/log';
import { apiCreatePayment, apiGetPayment } from 'app/api';
import { redirectTo } from 'app/utils/url.utils';
import { checkout } from 'app/utils/goPay';
import { EnumPaymentStates, EnumPaymentCategory } from 'app/models/Payment';
import { TPaymentCategory } from 'app/types';

interface IUseVinPayment {
  isLoading: boolean;
  errorCode: string;
  statusCode?: number;
  data?: AxiosResponse<any>;
  error?: AxiosResponse<any> | null;
}

/**
 * Handle payment for:
 * - pay premium VIN
 * - stolen cars and leasing check cache bust payment
 */
export const useVinPayment = (paymentType: TPaymentCategory = 'vinPremium'): IUseVinPayment => {
  const vin = useVin();
  const { accessToken } = useAuthenticated();
  const { isLoading, data, error } = useQuery<AxiosResponse, AxiosError['response'] | null>(
    `createPayment-${accessToken}`,
    () => apiCreatePayment(vin, accessToken, paymentType).catch(e => Promise.reject(e.response)),
    {
      cacheTime: 5000,
      retry: 0,
    }
  );
  const errorCode = error?.data?.code;
  const statusCode = error?.status;

  useEffect(() => {
    if (errorCode === 'vinAlreadyPaid') redirectTo(`/vin/${vin}/premium`);
    if (statusCode === 401) redirectTo(`/vin/${vin}`);
  }, [errorCode, statusCode, vin]);

  useEffect(() => {
    const URL = data?.data?.data?.gwUrl;

    if (accessToken && URL)
      checkout(URL, async (err, checkoutResult) => {
        try {
          const paymentStatus = await apiGetPayment(vin, String(checkoutResult?.id), accessToken);
          const payment = paymentStatus?.data;
          log.debug('checkout paymentStatus', payment);
          const state = get(paymentStatus, 'data.data.state');
          const paymentCategory = get(paymentStatus, 'data.data.paymentCategory');
          log.debug('checkout payment state: ', state);
          log.debug('checkout payment paymentCategory: ', paymentCategory);

          if (
            state === EnumPaymentStates.CANCELED ||
            state === EnumPaymentStates.CREATED ||
            paymentCategory === EnumPaymentCategory.CACHE_BUST
          )
            return redirectTo(`/vin/${vin}`);
          redirectTo(checkoutResult?.url || '');
        } catch (err) {
          log.error('checkout err: ', err);
        }
      });
  }, [data, accessToken, vin]);

  return {
    isLoading,
    data,
    error,
    errorCode,
    statusCode,
  };
};
