import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL || '/' });

const onHistoryChange = location => {
  // reset scroll position on route change
  window.scrollTo(0, 0);
};

if ((window as any).Cypress) {
  (window as any)._appHistory = history;
}

history.listen(onHistoryChange);
