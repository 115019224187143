import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';

import { mediaQuery, flex } from '../../../styles/shared.styles';
import propositionCar from '../../../assets/images/homepage/proposition-car.jpg';
import propositionPeople from '../../../assets/images/homepage/proposition-people.jpg';

export const HomeWrapper = styled('div')({
  width: '100%',
  height: '100%',
  // position: 'relative',
});

export const PropositionImageCar = styled('div')({
  backgroundImage: `url(${propositionCar})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '400px',
  width: '400px',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'none',
  [mediaQuery.large]: {
    display: 'block',
  },
});

export const PropositionImagePeople = styled('div')({
  backgroundImage: `url(${propositionPeople})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '400px',
  width: '400px',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'none',
  [mediaQuery.large]: {
    display: 'block',
  },
});

export const ContentWrapper = styled('div')({
  position: 'relative',
});

export const TitleContainer = styled('div')({
  margin: '35px 0',
  [mediaQuery.medium]: {
    margin: '90px 0 35px 0',
  },
});

export const Image = styled('div')(
  {
    width: '100%',
    minHeight: '180px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom: '25px',
    borderRadius: '4px 4px 0 0',

    [mediaQuery.extraLarge]: {
      width: '100%',
    },
  },
  (props: any) => ({
    backgroundImage: `url(${props.src})`,
  })
);

export const TextWrapper = styled.div``;

export const Cards = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '40px',
});

export const CardContainer = styled(Box)({
  minWidth: '180px',
});

export const VerifyVinFormContainer = styled('div')({
  maxWidth: '700px',
  margin: '0 auto',
  padding: '50px 0 20px 0',
  textAlign: 'center',
});

export const EstimateCarContainer = styled.div`
  ${{ ...flex.vertical } as any};
  ${{ ...flex.centerVertical }};
  padding: 80px 0 100px 0;
`;
