import styled from 'styled-components/macro';
import Link from 'app/components/Link';
import Badge from 'app/components/Badge';
import { colors, fontFamily, flex, mediaQuery } from 'styles/shared.styles';
import { IAdsListItemProps, IRowProps, IColumnLoaderProps } from './AdsListItem';

export const Wrapper = styled('div')((props: Partial<IAdsListItemProps>) => ({
  backgroundColor: colors.white,
  borderRadius: '10px',
  boxShadow: '0 -4px 18px 0 rgba(0,0,0,0.12)',
  padding: '0',
  position: 'relative',
  width: '100%',
  maxWidth: '400px',
  border: props.selected ? `3px solid ${colors.highlight}` : 'none',
  ':first-child': {
    marginLeft: '0',
  },
  ':last-child': {
    marginRight: '0',
  },
  [mediaQuery.small]: {
    maxWidth: '285px',
  },
  [mediaQuery.print]: {
    boxShadow: 'none',
    border: '1px solid rgba(151,151,151,0.5)',
  },
}));

export const YourOffer = styled('div')((props: Partial<IAdsListItemProps>) => ({
  position: 'absolute',
  top: '-18px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '8px 24px',
  borderRadius: '16px',
  backgroundColor: colors.highlight,
  fontFamily: fontFamily.medium,
  fontSize: '0.8rem',
  color: 'white',
  userSelect: 'none',
  zIndex: 1,
}));

export const ImageWrapper = styled('div')(
  {
    width: '100%',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    overflow: 'hidden',
    position: 'relative',
  },
  (props: any) => ({ height: props.thumbnail ? '175px' : '55px' })
);

export const OrderBadge = styled('div')({
  position: 'absolute',
  top: '8px',
  left: '8px',
  width: '40px',
  height: '40px',
  backgroundColor: colors.primary,
  borderRadius: '100%',
  fontFamily: fontFamily.bold,
  fontSize: '1.1rem',
  lineHeight: '40px',
  color: 'white',
  textAlign: 'center',
  userSelect: 'none',
});

export const SoldBadge = styled('div')({
  backgroundColor: colors.darkRed,
  bottom: '0px',
  color: 'white',
  display: 'flex',
  fontFamily: fontFamily.medium,
  fontSize: '0.8rem',
  justifyContent: 'space-between',
  left: '50%',
  padding: '8px 24px',
  position: 'absolute',
  transform: 'translateX(-50%)',
  userSelect: 'none',
  width: '100%',
});

export const NewBadge = styled('div')({
  position: 'absolute',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  padding: '8px 24px',
  backgroundColor: '#4A5560',
  fontFamily: fontFamily.medium,
  fontSize: '0.8rem',
  color: 'white',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'space-between',
});

export const SaleBadge = styled('div')({
  position: 'absolute',
  top: '14px',
  right: '0px',
  padding: '8px 16px',
  backgroundColor: colors.highlight,
  fontFamily: fontFamily.medium,
  fontSize: '0.8rem',
  color: 'white',
  userSelect: 'none',
  borderRadius: '3px 0 0 3px',
});

export const GenericBadge = styled(Badge)({
  position: 'absolute',
  top: '15px',
  left: '0px',
  borderRadius: '0px 4px 4px 0',
});

export const GenericBadgeRight = styled(Badge)({
  position: 'absolute',
  top: '15px',
  right: '0px',
  borderRadius: '4px 0px 0px 4px',
});

export const Content = styled('div')({
  height: '100%',
  padding: '16px 16px',
  fontSize: '1rem',
  lineHeight: 1.4,
  color: colors.textDefault,
  position: 'relative',
  overflow: 'hidden',
});

export const Name = styled('div')({
  minHeight: '50px',
  fontSize: '1.2rem',
  fontFamily: fontFamily.medium,
  lineHeight: 1.4,
  padding: '0 8px',
  width: '96%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const Table = styled('table')({
  width: '100%',
  textAlign: 'left',
  margin: '8px 0',
  minHeight: '256px',
});

export const TableBody = styled.tbody``;

export const Row = styled('tr')((props: IRowProps) => ({
  ':hover': {
    backgroundColor: '#D8D8D8',
  },
  '&.active': {
    backgroundColor: props.isBetterOrSame ? colors.highlight : '#EFA9A9',
    ':hover': {
      backgroundColor: '#D8D8D8',
    },
  },
  '&.is-better': {
    backgroundColor: colors.lightGreen,
    ':hover': {
      backgroundColor: colors.lightGreen,
    },
  },
}));

export const Column = styled('th')({
  padding: '4px 8px',
  verticalAlign: 'middle',
  ':first-child': {
    color: '#8B9198',
  },
  ':last-child': {
    color: colors.textDefault,
    fontFamily: fontFamily.medium,
    minWidth: '120px',
  },
});

export const ColumnLoader = styled('div')((props: IColumnLoaderProps) => ({
  background: '#D6D8DD',
  width: '100%',
  height: props.large ? '36px' : '18px',
  verticalAlign: 'middle',
}));

export const LastColumn = styled('th')({
  padding: '7px 7px',
  fontFamily: fontFamily.bold,
  verticalAlign: 'middle',
  color: colors.textDefault,
  ':last-child': {
    fontSize: '2rem',
  },
});

export const ColumnValue = styled(Column)({});

export const RowWrapper = styled('div')({
  ...flex.flex,
  ...flex.spaceBetween,
  ...flex.centerHorizontalV,
});

export const ButtonRowWrapper = styled('div')({
  ...flex.flex,
  ...flex.spaceBetween,
  ...flex.centerHorizontalV,
  position: 'relative',
  zIndex: 11,
});

export const StyledLink = styled(Link)({
  width: '100%',
  ':hover': {
    textDecoration: 'none',
  },
});
