import * as React from 'react';
import { connect } from 'react-redux';

import SelectedFilter from './components/SelectedFilter';
import FilterDialog from './components/FilterDialog';
import { getSelectedFiltersState } from './selectors/getSelectedFilters.selector';

import { default as AdFilterModel } from 'app/models/AdFilter';
import * as S from './FilterBar.styles';

interface IFilterBarStateProps {
  filter: AdFilterModel;
  selectedFilters: any[];
}

interface IFilterBarState {
  filterDialogVisible: boolean;
}

class FilterBar extends React.Component<IFilterBarStateProps, IFilterBarState> {
  public state: IFilterBarState = {
    filterDialogVisible: false,
  };

  public render() {
    const { filterDialogVisible } = this.state;
    const { selectedFilters } = this.props;

    return (
      <S.Wrapper>
        {selectedFilters &&
          selectedFilters.map(selectedFilter => (
            <SelectedFilter
              key={selectedFilter.name}
              value={selectedFilter.value}
              onClick={this.showFilterDialog}
            />
          ))}
        <S.AddFilter onClick={this.showFilterDialog}>
          <S.StyledPlusIcon />
        </S.AddFilter>
        <FilterDialog
          visible={filterDialogVisible}
          onClose={this.onFilterDialogClose}
          onConfirm={this.onFilterDialogConfirm}
        />
      </S.Wrapper>
    );
  }

  private showFilterDialog = () => {
    this.setState({ filterDialogVisible: true });
  };

  private onFilterDialogClose = () => {
    this.setState({ filterDialogVisible: false });
  };

  private onFilterDialogConfirm = () => {
    this.setState({ filterDialogVisible: false });
  };
}

const mapStateToProps = state => {
  return {
    filter: state.ads.filter,
    selectedFilters: getSelectedFiltersState(state.ads.filter),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBar);
