import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { PageTemplate } from 'app/templates/PageTemplate';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { MakePayment } from 'app/components/MakePayment';
import { createPayment } from 'app/api';
import { useVin, useAuthenticated, useCheckLoggedIn } from 'app/hooks';
import { EnumPaymentCategory } from 'app/models/Payment';

interface IPagePayRegistry {
  btnDisabled: boolean;
}

export const PagePayRegistry: React.FC<IPagePayRegistry> = ({ btnDisabled = false }) => {
  useCheckLoggedIn('Pro tuto akci je nutné se přihlásit.');
  const vin = useVin();
  const [isMakePaymentLoading, setIsMakePaymentLoading] = useState(false);
  const { accessToken } = useAuthenticated();

  const { data, isLoading, isError } = useQuery(
    `api-create-payment-registry-${accessToken}-${vin}`,
    () =>
      createPayment(vin, EnumPaymentCategory.CACHE_BUST, accessToken).catch(err =>
        Promise.reject(err)
      ),
    {
      retry: false,
    }
  );

  return (
    <PageTemplate mt={['20px', '110px']}>
      <LoadingContainer isLoading={isLoading || isMakePaymentLoading}>
        {!isError ? (
          <MakePayment
            gwUrl={data?.gwUrl}
            setLoading={setIsMakePaymentLoading}
            vin={vin}
            paymentInfo='Registry kradených vozů i záznamů leasingových a úvěrových společností jsou prováděny v okamžik zaplacení PREMIUM reportu. Cena dalšího ověření těchto placených registrů je 29 Kč s DPH.'
            btnText='Ověřit registry'
            paymentType={EnumPaymentCategory.CACHE_BUST}
          />
        ) : (
          <div>
            Při zpracování požadavku došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte.
            Děkujeme!
          </div>
        )}
      </LoadingContainer>
    </PageTemplate>
  );
};
