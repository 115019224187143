import * as React from 'react';
import * as S from './BrandLogo.styles';
import logoAlfaRomeo from 'assets/images/brands/alfa_romeo.svg';
import logoAudi from 'assets/images/brands/audi.svg';
import logoBmw from 'assets/images/brands/bmw.svg';
import logoCitroen from 'assets/images/brands/citroen.svg';
import logoFiat from 'assets/images/brands/fiat.svg';
import logoFord from 'assets/images/brands/ford.svg';
import logoHonda from 'assets/images/brands/honda.svg';
import logoHyundai from 'assets/images/brands/hyundai.svg';
import logoChevrolet from 'assets/images/brands/chevrolet.svg';
import logoKia from 'assets/images/brands/kia.svg';
import logoMazda from 'assets/images/brands/mazda.svg';
import logoMercedesBenz from 'assets/images/brands/mercedes_benz.svg';
import logoMitsubishi from 'assets/images/brands/mitsubishi.svg';
import logoNissan from 'assets/images/brands/nissan.svg';
import logoOpel from 'assets/images/brands/opel.svg';
import logoPeugeot from 'assets/images/brands/peugeot.svg';
import logoRenault from 'assets/images/brands/renault.svg';
import logoSeat from 'assets/images/brands/seat.svg';
import logoSubaru from 'assets/images/brands/subaru.svg';
import logoSuzuki from 'assets/images/brands/suzuki.svg';
import logoSkoda from 'assets/images/brands/skoda.svg';
import logoToyota from 'assets/images/brands/toyota.svg';
import logoVolkswagen from 'assets/images/brands/volkswagen.svg';
import logoVolvo from 'assets/images/brands/volvo.svg';

interface IBrandLogoProps {
  brand: string;
}

export const logoSource = (brand) => {
  switch(brand) {
    case 'alfa_romeo': return <img src={logoAlfaRomeo} alt='Alfa Romeo' />;
    case 'audi': return <img src={logoAudi} alt='Audi' />;
    case 'bmw': return <img src={logoBmw} alt='Bmw' />;
    case 'citroen': return <img src={logoCitroen} alt='Citroën' />;
    case 'fiat': return <img src={logoFiat} alt='Fiat' />;
    case 'ford': return <img src={logoFord} alt='Ford' />;
    case 'honda': return <img src={logoHonda} alt='Honda' />;
    case 'hyundai': return <img src={logoHyundai} alt='Hyundai' />;
    case 'chevrolet': return <img src={logoChevrolet} alt='Chevrolet' />;
    case 'kia': return <img src={logoKia} alt='Kia' />;
    case 'mazda': return <img src={logoMazda} alt='Mazda' />;
    case 'mercedes_benz': return <img src={logoMercedesBenz} alt='Mercedes-Benz' />;
    case 'mitsubishi': return <img src={logoMitsubishi} alt='Mitsubishi' />;
    case 'nissan': return <img src={logoNissan} alt='Nissan' />;
    case 'opel': return <img src={logoOpel} alt='Opel' />;
    case 'peugeot': return <img src={logoPeugeot} alt='Peugeot' />;
    case 'renault': return <img src={logoRenault} alt='Renault' />;
    case 'seat': return <img src={logoSeat} alt='Seat' />;
    case 'subaru': return <img src={logoSubaru} alt='Subaru' />;
    case 'suzuki': return <img src={logoSuzuki} alt='Suzuki' />;
    case 'skoda': return <img src={logoSkoda} alt='Škoda' />;
    case 'toyota': return <img src={logoToyota} alt='Toyota' />;
    case 'volkswagen': return <img src={logoVolkswagen} alt='Volkswagen' />;
    case 'volvo': return <img src={logoVolvo} alt='Volvo' />;
    default: return false;
  }
};

const BrandLogo: React.SFC<IBrandLogoProps> = ({ brand }) => {
  return logoSource(brand) ? <S.Wrapper>{logoSource(brand)}</S.Wrapper> : null;
}

export default BrandLogo;
