import * as React from 'react';
import { CSSProperties } from 'react';

import AdsListItem from './AdsListItem';
import { ListType } from 'app/components/ListSwitcher';
import Ad from 'app/models/Ad';
import { ComparedObject } from 'app/models/ComparedObject';
import * as S from './AdsList.styles';

interface IAdsListProps {
  list: ComparedObject[];
  listType: ListType;
  isAuthorized: boolean;
  ad?: Ad | ComparedObject | null;
  allowRedirectButton?: boolean;
  dataIsLoading?: boolean;
  orderBadgeEnabled?: boolean;
  showBenchmark?: boolean;
  style?: CSSProperties;
  thumbnail?: boolean;
  genericBadgeText?: string;
  onRowMouseEnter?: (rowClassName: string, isAuthorized: boolean) => void;
  onRowMouseLeave?: (rowClassName: string, isAuthorized: boolean) => void;
  showLoginDialog: () => void;
}

const AdsList: React.SFC<IAdsListProps> = ({
  ad,
  dataIsLoading,
  isAuthorized,
  list,
  listType,
  onRowMouseEnter,
  onRowMouseLeave,
  orderBadgeEnabled,
  showLoginDialog,
  genericBadgeText,
  allowRedirectButton = true,
  showBenchmark = true,
  style = {},
  thumbnail = true,
}) => (
  <S.AdsListContainer style={style}>
    {list &&
      list.length > 0 &&
      list.map((listAd, idx) => (
        <AdsListItem
          adId={listAd._id}
          allowRedirectButton={allowRedirectButton}
          className={'swiper-slide'}
          currentAdPrice={ad?.price ? ad?.price : 0}
          discount={listAd?.props?.discount}
          firstRun={listAd.firstRun}
          fuel={listAd?.props?.fuel}
          id={listAd?.props?.id}
          idx={idx}
          img={`https://${listAd.img}`}
          isActive={listAd.isActive}
          isAuthorized={isAuthorized}
          genericBadgeText={genericBadgeText}
          key={listAd._id}
          km={listAd?.props?.km}
          kw={listAd?.props?.kw}
          lastRun={listAd.lastRun}
          listType={listType}
          loading={dataIsLoading}
          name={`${listAd.titleShort}`}
          onRowMouseEnter={onRowMouseEnter}
          onRowMouseLeave={onRowMouseLeave}
          orderBadgeEnabled={orderBadgeEnabled}
          price={listAd?.props?.price}
          // @ts-ignore
          selected={ad ? listAd._id === ad._id : false}
          showLoginDialog={showLoginDialog}
          showBenchmark={showBenchmark}
          thumbnail={thumbnail}
          total={listAd?.props?.total}
          transmission={listAd?.props?.transmission}
          year={listAd?.props?.year}
        />
      ))}
  </S.AdsListContainer>
);

export default AdsList;
