import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from 'lodash-es';

import Container from 'app/components/Container';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { ResultEstimateCar } from 'app/components/EstimateCar';

import { loadEstimateCar, resetEstimateCar, loadPdfExport } from 'app/actions/ads';
import { loadEstimateCarHistoryLog, updateEstimateCarHistoryLog } from 'app/actions/user';
import { IEstimateCarHistoryLog } from 'app/models/EstimateCarHistory';
import User from 'app/models/User';
import { log } from 'app/utils/log';

interface IEstimateCarHistoryDetailConmponentProps {
  estimateCar: any;
  estimateCarHistoryLog: IEstimateCarHistoryLog;
  estimateCarHistoryLogInProgress: boolean;
  estimateCarIsLoading: boolean;
  isAuthorized: boolean;
  logId: string;
  user: User;
  pdfExportIsLoading: boolean;
  resetEstimateCar: () => void;
  loadEstimateCar: (ad: any) => Promise<any>;
  loadEstimateCarHistoryLog: (userId: string, logId: string) => Promise<any>;
  loadPdfExport: (url: string, filename?: string) => Promise<void>;
  updateEstimateCarHistoryLog: (userId: string, logId: string, marketPrice: number) => Promise<any>;
}

interface IEstimateCarHistoryDetailConmponentState {}

class EstimateCarHistoryDetailConmponent extends React.Component<
  IEstimateCarHistoryDetailConmponentProps & RouteComponentProps<{ history: any }>,
  IEstimateCarHistoryDetailConmponentState
> {
  public async componentDidMount() {
    const { user } = this.props;
    const logId = get(this.props, 'match.params.id', null);
    if (!logId || !user || (user && !user.id)) {
      this.redirectToHomepage();
      return;
    }

    this.loadEstimateCarData(user.id, logId);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IEstimateCarHistoryDetailConmponentProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    if (authHasChanged) {
      const { user } = nextProps;
      const logId = get(nextProps, 'match.params.id', null);
      if (user && user.id) {
        this.loadEstimateCarData(user.id, logId);
        return;
      }
      this.redirectToHomepage();
    }
  }

  public componentWillUnmount() {
    this.props.resetEstimateCar();
  }

  public render() {
    const {
      estimateCarHistoryLogInProgress,
      estimateCarIsLoading,
      estimateCar,
      isAuthorized,
      pdfExportIsLoading,
    } = this.props;

    const isLoadingData = estimateCarHistoryLogInProgress || estimateCarIsLoading;
    if (isLoadingData) {
      return <AdLoader text={EnumAdLoaderTexts.LOADING_DATA} fullscreen={true} />;
    }

    if (pdfExportIsLoading) {
      return <AdLoader text={EnumAdLoaderTexts.PDF_IN_PROGRESS} fullscreen={true} />;
    }

    return (
      <Container>
        <ResultEstimateCar
          data={estimateCar}
          isAuthorized={isAuthorized}
          isLoadingData={false}
          marketPriceTitleText='Aktuální tržní cena'
          onShowLoginDialog={() => true}
          subtitle='Odkaz na toto ocenění naleznete v uživatelském menu v pravém horním rohu aplikace.'
          title='Ocenění vozu'
          exportFilename='oceneni-vozu-export'
        />
      </Container>
    );
  }

  private loadEstimateCarData = async (userId: string, logId: string): Promise<void> => {
    if (!userId || !logId) {
      throw new Error('Missing logId');
    }

    await this.props.loadEstimateCarHistoryLog(userId, logId);
    const { estimateCarHistoryLog } = this.props;
    if (!estimateCarHistoryLog) {
      log.debug('loadEstimateCarData:: data loading failed');
      return;
    }

    let filter;
    try {
      filter = JSON.parse(estimateCarHistoryLog.filter);
    } catch (err) {
      log.debug('loadEstimateCarData:: parsing err: ', err);
    }

    if (filter) {
      await this.props.loadEstimateCar(filter);
    }

    const { estimateCar } = this.props;
    const logMarketPriceValue = get(estimateCarHistoryLog, 'marketPrice', null);
    if (!logMarketPriceValue) {
      const marketPriceValue = get(estimateCar, 'marketPrice.value', null);
      this.props.updateEstimateCarHistoryLog(userId, logId, marketPriceValue);
    }
  };

  private redirectToHomepage = () => {
    const { history } = this.props;
    history.push('');
  };
}

const mapStateToProps = state => {
  return {
    estimateCar: state.ads.estimateCar,
    estimateCarHistoryLog: state.user && state.user.estimateCarHistoryLog,
    estimateCarHistoryLogInProgress: state.user && state.user.estimateCarHistoryLogInProgress,
    estimateCarIsLoading: state.ads.estimateCarIsLoading,
    isAuthorized: !!state.auth.accessToken,
    user: state.auth.user,
    pdfExportIsLoading: state.ads.pdfExportIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadEstimateCar: (ad: any) => dispatch(loadEstimateCar(ad)),
    resetEstimateCar: () => dispatch(resetEstimateCar()),
    updateEstimateCarHistoryLog: (userId: string, logId: string, marketPrice: number) =>
      dispatch(updateEstimateCarHistoryLog(userId, logId, marketPrice)),
    loadEstimateCarHistoryLog: (userId: string, logId: string) =>
      dispatch(loadEstimateCarHistoryLog(userId, logId)),
    loadPdfExport: (url: string, filename?: string) => dispatch(loadPdfExport(url, filename)),
  };
};

export const EstimateCarHistoryDetailContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EstimateCarHistoryDetailConmponent)
);
