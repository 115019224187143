import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AxiosResponse, AxiosError } from 'axios';

import WhatYouGet from '../WhatYouGet';
import Typography from '../Typography';
import Button from '../Button';
import * as S from './SignupDialog.styles';

interface IForgotPasswordDialogProps {
  visible: boolean;
  forgotPasswordInProgress: boolean;
  forgotPasswordSuccess: boolean;
  onClose: () => void;
  onSwitchToLogin: (evt: SyntheticEvent) => void;
  onForgotPassword: (email: string) => Promise<AxiosResponse | AxiosError>;
}

interface IForgotPasswordDialogState {
  form: {
    [key: string]: any;
  };
  errors: {
    [key: string]: string | null;
  };
  isUserNotFoundError: boolean;
  submitted?: boolean;
}

class ForgotPasswordDialog extends React.Component<
  IForgotPasswordDialogProps,
  IForgotPasswordDialogState
> {
  public static defaultProps: Partial<IForgotPasswordDialogProps> = {
    visible: false,
  };

  public state: IForgotPasswordDialogState = {
    form: {
      email: null,
    },
    errors: {
      email: null,
    },
    isUserNotFoundError: false,
    submitted: false,
  };

  public render() {
    const { visible, forgotPasswordInProgress } = this.props;

    return (
      <S.StyledDialog
        visible={visible}
        onClose={this.onClose}
        contentClassName='dialog__forgot-password-content'
      >
        <S.DialogContainer>
          <S.FormContainer>
            <form onSubmit={this.forgotPassword} noValidate={true}>
              <Typography text={'Obnova hesla'} />
              <div>
                <div>
                  <div>
                    <div>
                      <S.StyledInput
                        placeholder={'Email'}
                        block={true}
                        onChange={this.onEmailChange}
                      />
                      {this.state.submitted && this.state.errors.email && (
                        <S.StyledErrorMessage
                          textAlign='left'
                          isVisible={this.state.submitted}
                          message={this.state.errors.email}
                        />
                      )}
                    </div>
                    {this.state.submitted && this.state.isUserNotFoundError && (
                      <S.StyledErrorMessage
                        textAlign='left'
                        isVisible={this.state.submitted && this.state.isUserNotFoundError}
                        message={'Uživatel s danou adresou neexistuje'}
                      />
                    )}
                    <S.ButtonContainer>
                      <Button
                        kind={'submit'}
                        text={'Potvrdit'}
                        fullWidth={true}
                        spin={forgotPasswordInProgress}
                        disabled={forgotPasswordInProgress}
                      />
                    </S.ButtonContainer>
                  </div>
                  <S.DialogDivider />
                  <div className='row center-xs'>
                    <a onClick={this.props.onSwitchToLogin} href='#overauto'>
                      Máte už účet? Přihlašte se
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </S.FormContainer>
          <S.InfoContainer>
            <WhatYouGet />
          </S.InfoContainer>
        </S.DialogContainer>
      </S.StyledDialog>
    );
  }

  private forgotPassword = async evt => {
    try {
      evt.preventDefault();
      const errors = this.validateForm(this.state.form);
      const isValid =
        Object.keys(errors)
          .map(key => errors[key])
          .join('').length === 0;
      this.setState({
        errors,
        submitted: true,
        isUserNotFoundError: false,
      });

      if (isValid) {
        const { email } = this.state.form;
        await this.props.onForgotPassword(email);
      }
    } catch (error) {
      const err = error as AxiosError;
      const isUserNotFoundError = err?.response?.status === 404;
      if (isUserNotFoundError) this.setState({ isUserNotFoundError: true });
    }
  };

  private onEmailChange = evt => {
    this.handleInputChange(evt.target.value, 'email');
  };

  private onClose = () => {
    this.clearForm();
    this.props.onClose();
  };

  private handleInputChange = (value: any, name: string) => {
    const form = {
      ...this.state.form,
      [name]: value,
    };
    const errors = this.validateForm(form);
    this.setState({
      form,
      errors,
    });
  };

  private validateForm = (form: {
    [key: string]: any;
  }): {
    [key: string]: string | null;
  } => {
    const errors: {
      [key: string]: null | string;
    } = {
      email: null,
    };
    if (!form.email || !/[^@]+@[^@]+/.test(form.email)) {
      errors.email = 'Vložte prosím správný e-mail';
    }
    return errors;
  };

  private clearForm = () => {
    this.setState({
      form: {
        email: null,
      },
      errors: {
        email: null,
      },
      isUserNotFoundError: false,
      submitted: false,
    });
  };
}

export default ForgotPasswordDialog;
