import * as React from 'react';
import { getNow } from 'app/utils/dates';
import * as S from './PdfExportFooter.styles';

export const PdfExportFooter: React.FC = () => (
  <S.Container>
    Vygenerováno <S.DateContainer>{getNow()}</S.DateContainer> v aplikaci{' '}
    <a href='https://www.overauto.cz' target='_blank' rel='noopener noreferrer'>
      www.overauto.cz
    </a>
  </S.Container>
);
