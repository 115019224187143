import * as CONSTANTS from 'app/constants/user';

export function createUser(
  firstname: string,
  surname: string,
  email: string,
  password: string,
  gdprConfirmed: boolean = true
) {
  return {
    type: CONSTANTS.CREATE_USER,
    payload: {
      request: {
        method: 'POST',
        url: '/users',
        data: {
          firstname,
          surname,
          email,
          password,
          gdprConfirmed,
        },
      },
    },
  };
}

export function forgotPassword(email: string) {
  return {
    type: CONSTANTS.FORGOT_PASSWORD,
    payload: {
      request: {
        method: 'POST',
        url: '/users/forgot-password',
        data: {
          email,
        },
      },
    },
  };
}

export function resetPassword(newPassword: string, passwordResetToken: string) {
  return {
    type: CONSTANTS.RESET_PASSWORD,
    payload: {
      request: {
        method: 'POST',
        url: '/users/reset-password',
        data: {
          newPassword,
          passwordResetToken,
        },
      },
    },
  };
}

export function createEstimateCarHistoryLog(filter: string, userId: string) {
  return {
    type: CONSTANTS.CREATE_ESTIMATE_CAR_HISTORY_LOG,
    payload: {
      request: {
        method: 'POST',
        url: `/users/${userId}/history/estimate-car`,
        data: {
          filter,
        },
      },
    },
  };
}

export function loadEstimateCarHistoryLogs(
  userId: string,
  from?: number,
  limit?: number,
  page?: number
) {
  return {
    type: CONSTANTS.LOAD_ESTIMATE_CAR_HISTORY_LOGS,
    payload: {
      request: {
        method: 'GET',
        url: `/users/${userId}/history/estimate-car`,
        params: {
          from,
          limit,
          page,
        },
      },
    },
  };
}

export function resetEstimateCarHistoryLogs() {
  return {
    type: CONSTANTS.RESET_ESTIMATE_CAR_HISTORY_LOGS,
  };
}

export function loadEstimateCarHistoryLog(userId: string, logId: string) {
  return {
    type: CONSTANTS.LOAD_ESTIMATE_CAR_HISTORY_LOG,
    payload: {
      request: {
        method: 'GET',
        url: `/users/${userId}/history/estimate-car/${logId}`,
      },
    },
  };
}

export function updateEstimateCarHistoryLog(userId: string, logId: string, marketPrice: number) {
  return {
    type: CONSTANTS.UPDATE_ESTIMATE_CAR_HISTORY_LOG,
    payload: {
      request: {
        method: 'PUT',
        url: `/users/${userId}/history/estimate-car/${logId}`,
        data: {
          marketPrice,
        },
      },
    },
  };
}
