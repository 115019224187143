import * as React from 'react';
import { SyntheticEvent } from 'react';
import { get } from 'lodash-es';

import Button from '../Button';
import WhatYouGet from '../WhatYouGet';
import Typography from '../Typography';
import GoogleButton from '../GoogleButton';
import FacebookButton from '../FacebookButton';
import * as S from './LoginDialog.styles';
import FakeLink from '../FakeLink';

interface ILoginDialogProps {
  fbLoginErrorMsg: string;
  fbLoginInProgress?: boolean;
  googleLoginErrorMsg: string;
  googleLoginInProgress?: boolean;
  loginInProgress?: boolean;
  visible: boolean;
  onClose: () => void;
  onFbLogin: () => void;
  onForgotPassword: (evt: SyntheticEvent) => void;
  onGoogleLogin: () => void;
  onLogin: (email: string, password: string) => Promise<any>;
  onSwitchToSignup: (evt: SyntheticEvent) => void;
}

interface ILoginDialogState {
  form: {
    [key: string]: any;
  };
  errors: {
    [key: string]: string | null;
  };
  loginError: string | null;
  submitted?: boolean;
}

class LoginDialog extends React.Component<ILoginDialogProps, ILoginDialogState> {
  public static defaultProps: Partial<ILoginDialogProps> = {
    visible: false,
    loginInProgress: false,
  };

  public state: ILoginDialogState = {
    form: {
      email: null,
      password: null,
    },
    errors: {
      email: null,
      password: null,
    },
    loginError: null,
    submitted: false,
  };

  public render() {
    const {
      visible,
      onFbLogin,
      onGoogleLogin,
      loginInProgress,
      fbLoginInProgress,
      googleLoginInProgress,
    } = this.props;
    return (
      <S.StyledDialog
        visible={visible}
        onClose={this.onClose}
        contentClassName='dialog__login-content'
      >
        <S.DialogContainer>
          <S.FormContainer>
            <form onSubmit={this.onLogin} noValidate={true}>
              <Typography text={'Přihlášení'} />
              <div>
                <div>
                  <div>
                    <S.StyledInput
                      placeholder={'Uživatelské jméno'}
                      block={true}
                      onChange={this.onEmailChange}
                      name='login-username'
                    />
                    {this.state.errors.email && this.state.submitted && (
                      <S.StyledErrorMessage
                        isVisible={this.state.submitted}
                        message={this.state.errors.email}
                      />
                    )}
                  </div>
                  <div>
                    <S.StyledInput
                      placeholder={'Heslo'}
                      block={true}
                      type={'password'}
                      onChange={this.onPasswordChange}
                      name='login-password'
                    />
                    {this.state.errors.password && this.state.submitted && (
                      <S.StyledErrorMessage
                        isVisible={this.state.submitted}
                        message={this.state.errors.password}
                      />
                    )}
                  </div>
                </div>
                <S.ButtonContainer>
                  <Button
                    kind={'submit'}
                    text={'Přihlásit se'}
                    fullWidth={true}
                    spin={loginInProgress}
                    disabled={loginInProgress}
                    onClick={this.onLogin}
                    type='button'
                  />
                  <S.FakeLinkContainer>
                    <FakeLink textAlign='left' color='white' fontSize='1.2rem'>
                      <Typography
                        size='small'
                        text={'Zapomenuté heslo'}
                        onClick={this.onForgotPassword}
                        textAlign='right'
                      />
                    </FakeLink>
                  </S.FakeLinkContainer>
                </S.ButtonContainer>
                <div>
                  <S.GoogleButtonContainer>
                    <GoogleButton
                      text={'Přihlásit přes Google'}
                      spin={googleLoginInProgress}
                      disabled={googleLoginInProgress}
                      onClick={onGoogleLogin}
                      fullWidth={true}
                      type='button'
                    />
                  </S.GoogleButtonContainer>
                  {this.props.googleLoginErrorMsg && this.props.googleLoginErrorMsg && (
                    <div>
                      <S.StyledErrorMessage
                        isVisible={!!(this.props.googleLoginErrorMsg || false)}
                        message={this.props.googleLoginErrorMsg}
                      />
                    </div>
                  )}
                  <FacebookButton
                    text={'Přihlásit přes Facebook'}
                    spin={fbLoginInProgress}
                    disabled={fbLoginInProgress}
                    onClick={onFbLogin}
                    fullWidth={true}
                    type='button'
                  />
                </div>
              </div>
              {this.state.submitted && this.state.loginError && (
                <div>
                  <div>
                    <S.StyledErrorMessage
                      isVisible={this.state.submitted}
                      message={this.state.loginError}
                    />
                  </div>
                </div>
              )}
              {this.props.fbLoginErrorMsg && this.props.fbLoginErrorMsg && (
                <div>
                  <div>
                    <S.StyledErrorMessage
                      isVisible={!!this.props.fbLoginErrorMsg}
                      message={this.props.fbLoginErrorMsg}
                    />
                  </div>
                </div>
              )}
              <S.DialogDivider />
              <div className='row center-xs'>
                <a
                  onClick={this.props.onSwitchToSignup}
                  href='#overauto'
                  data-cy='login-dialog-signup'
                >
                  Nemáte účet? Zaregistrujte se
                </a>
              </div>
            </form>
          </S.FormContainer>
          <S.InfoContainer>
            <WhatYouGet />
          </S.InfoContainer>
        </S.DialogContainer>
      </S.StyledDialog>
    );
  }

  private onLogin = evt => {
    evt.preventDefault();
    const errors = this.validateForm(this.state.form);
    const isValid =
      Object.keys(errors)
        .map(key => errors[key])
        .join('').length === 0;
    this.setState({
      errors,
      submitted: true,
    });

    const { email, password } = this.state.form;
    if (isValid) {
      this.props
        .onLogin(email, password)
        .then(() => {
          this.clearForm();
        })
        .catch(({ error }) => {
          const status = get(error, 'response.status');
          if (status === 401 || status === 403) {
            this.setState({
              loginError: 'Zadali jste neplatnou e-mailovou adresu nebo heslo. Zkuste to znovu.',
            });
          }

          if (status === 404) {
            this.setState({
              loginError: 'Litujeme, uživatel s daným emailem neexistuje. Zaregistrujte se.',
            });
          }
        });
    }
  };

  private onEmailChange = evt => {
    this.handleInputChange(evt.target.value, 'email');
  };

  private onPasswordChange = evt => {
    this.handleInputChange(evt.target.value, 'password');
  };

  private handleInputChange = (value: any, name: string) => {
    const form = {
      ...this.state.form,
      [name]: value,
    };
    const errors = this.validateForm(form);
    this.setState({
      form,
      errors,
    });
  };

  private onForgotPassword = evt => {
    this.props.onClose();
    this.props.onForgotPassword(evt);
  };

  private onClose = () => {
    this.clearForm();
    this.props.onClose();
  };

  private validateForm = (form: {
    [key: string]: any;
  }): {
    [key: string]: string | null;
  } => {
    const errors: {
      [key: string]: null | string;
    } = {
      email: null,
      password: null,
    };
    if (!form.password) {
      errors.password = 'Vložte prosím heslo';
    }
    if (!form.email || !/[^@]+@[^@]+/.test(form.email)) {
      errors.email = 'Vložte prosím správný e-mail';
    }
    return errors;
  };

  private clearForm = () => {
    this.setState({
      form: {
        email: null,
        password: null,
      },
      errors: {
        email: null,
        password: null,
      },
      loginError: null,
      submitted: false,
    });
  };
}

export default LoginDialog;
