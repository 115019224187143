import styled from 'styled-components/macro';
import NavLink from 'app/components/Link';
import { colors, mediaQuery } from 'styles/shared.styles';

const NavigationLink = styled<any>(NavLink)({
  alignItems: 'center',
  borderBottom: '3px solid transparent',
  color: colors.textDefault,
  cursor: 'pointer',
  display: 'flex',
  flex: '0 0 50%',
  fontSize: '1rem',
  height: '100%',
  lineHeight: '26px',
  padding: '0 10px',
  transition: 'border-bottom .2s ease-in-out',
  userSelect: 'none',
  width: '50%',
  [mediaQuery.medium]: {
    flex: 'initial',
    flexDirection: 'row',
    width: 'auto',
    lineHeight: 'auto',
  },
  ':hover, :active, :focus, &.active': {
    borderBottom: `3px solid ${colors.primary}`,
    textDecoration: 'none',
    color: colors.primary,
  },
});

export default NavigationLink;
