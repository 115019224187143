import * as React from 'react';
import { RefObject } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as scrollToComponent from 'react-scroll-to-component';
import { Box } from 'reflexbox/styled-components';

import Ad from 'app/models/Ad';
import Container from 'app/components/Container';
import Heading from 'app/components/Heading';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import NotificationType from 'app/models/NotificationType';
import { ButtonSubmit } from 'app/components/Button';
import { VerifyVinForm } from 'app/components/VerifyVin';
import { ReportsComparator } from 'app/components/ReportsComparator';
import { ListType } from 'app/components/ListSwitcher';

import { pushNotification, popNotification } from 'app/actions/app';
import {
  resetAdDetails,
  updateAdId,
  loadAdDetails,
  updateFilter,
  resetFilter,
} from 'app/actions/ads';
import { SEARCHBOX_WRONG_URL, SEARCHBOX_WRONG_VIN } from 'app/constants/notifications';
import { log } from 'app/utils/log';
import * as S from './AppDescription.styles';

import icon1 from 'assets/images/homepage/icon1.png';
import icon2 from 'assets/images/homepage/icon2.png';
import icon3 from 'assets/images/homepage/icon3.png';

interface IAppDescriptionProps {
  ad: Ad | null;
  isAuthorized: boolean;
  adDetailsIsLoading: boolean;
  resetAdDetails: () => void;
  loadAdDetails: (listType: ListType) => Promise<any>;
  popNotification: (key: string) => void;
  pushNotification: (
    key: string,
    text: string,
    notificationType?: NotificationType,
    timeout?: number
  ) => void;
  resetFilter: () => void;
  updateAdId: (adUrl: string) => void;
  updateFilter: (filter: any) => void;
}

class AppDescription extends React.Component<IAppDescriptionProps & RouteComponentProps<any>> {
  public verifyVinElRef: RefObject<HTMLDivElement>;
  protected NOTIFICATION_DISMISS_DELAY = 6000;

  public componentDidMount() {
    this.props.resetAdDetails();
    const {
      location: { hash: currentHash },
    } = this.props;
    this.scrollToVerifyVinEl(true, currentHash);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const { hash: currentHash } = location;
    const hashChanged = currentHash !== this.props.location.hash;
    this.scrollToVerifyVinEl(hashChanged, currentHash);
  }

  public render() {
    const { /*ad, */ adDetailsIsLoading, isAuthorized } = this.props;

    // const adUrl = ad ? ad.url : '';

    if (adDetailsIsLoading) {
      return <AdLoader fullscreen={true} text={EnumAdLoaderTexts.LOADING_DATA} />;
    }

    return (
      <S.HomeWrapper>
        <S.PropositionImageCar />
        <S.PropositionImagePeople />
        <S.ContentWrapper>
          <div ref={this.setVerifyVinRef}>
            <S.VerifyVinFormContainer id='overit-vin'>
              <VerifyVinForm isAuthorized={isAuthorized} onSubmit={this.onVinSubmit} />
            </S.VerifyVinFormContainer>
          </div>

          <Container maxWidth={'1300px'} px='10px'>
            <S.TitleContainer>
              <Heading level={2} weight='bold' centered={true}>
                Proč ověřit vůz u nás?
              </Heading>
            </S.TitleContainer>
            <S.Cards>
              <S.CardContainer width={[1, 1 / 3]} p={[1, 2, 3]}>
                <S.Image src={icon1} />
                <S.TextWrapper>
                  <Heading level={3}>Ověříme jakýkoliv vůz během minuty.</Heading>
                  <p>
                    Stačí zadat VIN vozu a najdeme všechny dostupné záznamy o daném vozidle. To vše
                    rychle a kompletně online.
                  </p>
                </S.TextWrapper>
              </S.CardContainer>
              <S.CardContainer width={[1, 1 / 3]} p={[1, 2, 3]}>
                <S.Image src={icon2} />
                <S.TextWrapper>
                  <Heading level={3}>Můžete ušetřit až desítky tisíc.</Heading>
                  <p>
                    Poskytneme Vám přístup k historii vozu takže můžete odhalit zda je vůz skutečně
                    tak výhodný, jak Vám prodejce tvrdí.
                  </p>
                </S.TextWrapper>
              </S.CardContainer>
              <S.CardContainer width={[1, 1 / 3]} p={[1, 2, 3]}>
                <S.Image src={icon3} />
                <S.TextWrapper>
                  <Heading level={3}>Odhalíme jakékoliv nesrovnalosti.</Heading>
                  <p>
                    Díky informacím z různým zdrojů jsme schopni odhalit nesrovnalosti, např. v
                    deklarovaném nájezdu vozidla.
                  </p>
                </S.TextWrapper>
              </S.CardContainer>
            </S.Cards>

            <S.EstimateCarContainer>
              <Box mb={11}>
                <Heading level={1} centered={true}>
                  Prodáváte auto?
                  <br />
                  Určíme jeho cenu online.
                </Heading>
              </Box>
              <ButtonSubmit
                kind={'submit'}
                text={'Přejít k ocenění vozu'}
                fullWidth={false}
                onClick={evt => {
                  evt.preventDefault();
                  this.onEstimateBtnClick();
                }}
              />
            </S.EstimateCarContainer>

            <ReportsComparator />
          </Container>
        </S.ContentWrapper>
      </S.HomeWrapper>
    );
  }

  public onSearchSubmit = (id: string) => {
    this.hideWrongUrlNotification();
    this.props.resetFilter();
    this.props.updateAdId(id);
    this.props
      .loadAdDetails('top')
      .then(ad => {
        if (ad) {
          this.props.history.push(`/auto/${id}/detail`);
        }
      })
      .catch(err => log.debug(err));
  };

  public onVinSubmit = async (vin: string) => {
    try {
      const { history } = this.props;
      history.push(`/vin/${vin}`);
    } catch (error) {
      log.debug(error);
    }
  };

  public onEstimateBtnClick = () => {
    const { history } = this.props;
    history.push('/ocenit-vuz');
  };

  public hideWrongUrlNotification = () => {
    this.props.popNotification(SEARCHBOX_WRONG_URL);
  };

  public notifyAboutWrongUrl = () => {
    this.props.pushNotification(
      SEARCHBOX_WRONG_URL,
      'Vložte URL nebo ID inzerátu z sauto.cz',
      NotificationType.error,
      this.NOTIFICATION_DISMISS_DELAY
    );
  };

  public notifyAboutWrongVin = () => {
    this.props.pushNotification(
      SEARCHBOX_WRONG_VIN,
      'Litujeme, pro tento VIN neevidujeme žádné záznamy.',
      NotificationType.error,
      this.NOTIFICATION_DISMISS_DELAY
    );
  };

  public setVerifyVinRef = el => (this.verifyVinElRef = el);

  private removeHashFromUrl = () => {
    const { history } = this.props;
    history.push('');
  };

  private scrollToVerifyVinEl = (hashChanged, currentHash): void => {
    if (this.verifyVinElRef && hashChanged && currentHash === '#overit-vin') {
      setTimeout(() => {
        scrollToComponent(this.verifyVinElRef, {
          offset: 0,
          align: 'top',
          duration: 500,
          ease: 'inExpo',
        });
        this.removeHashFromUrl();
      }, 0);
    }
  };
}

const mapStateToProps = state => {
  return {
    ad: state.ads.adDetails ? state.ads.adDetails.ad : null,
    adDetailsIsLoading: state.ads.adDetailsIsLoading,
    isAuthorized: !!state.auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetAdDetails: () => dispatch(resetAdDetails()),
    updateAdId: (adUrl: string) => dispatch(updateAdId(adUrl)),
    popNotification: (key: string) => dispatch(popNotification(key)),
    updateFilter: (filter: any) => dispatch(updateFilter(filter)),
    resetFilter: () => dispatch(resetFilter()),
    loadAdDetails: (listType: ListType) => dispatch(loadAdDetails(listType)),
    pushNotification: (
      key: string,
      text: string,
      notificationType?: NotificationType,
      timeout?: number
    ) => dispatch(pushNotification(key, text, notificationType, timeout)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDescription));
