import * as React from 'react';
import { PageTemplate } from 'app/templates/PageTemplate';
import Heading from '../components/Heading';
import Typography from '../components/Typography';
import { Column } from '../components/Column';
import { Row } from '../components/Row';

const Contact: React.FC = () => (
  <PageTemplate mt='85px'>
    <Heading level={1}>Kontaktujte nás</Heading>
    <Column mb='50px'>
      <Heading level={2} weight='regular' mb='5px'>
        <a href='mailto:info@overauto.cz'>info@overauto.cz</a>
      </Heading>
      <Row alignItems='center'>
        <p>Nebo nám zavolejte na telefon</p>
        <Column mr='5px' />
        <a href='tel:+420777452067' target='_blank' rel='noopener noreferrer'>
          +420 777 452 067
        </a>
      </Row>
      <Heading level={2} mb='10px' mt='50px'>
        Provozovatel
      </Heading>
      <ul>
        <li>
          <Typography text='Radimovická 1773/15' />
        </li>
        <li>
          <Typography text='149 00 Praha 4' />
        </li>
        <li>
          <Typography text='IČ: 04522818‍' />
        </li>
        <li>
          <Typography text='DIČ: CZ04522818' />
        </li>
        <li>
          <Typography
            text='Společnost zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, v oddíle C,
              vložka 248468.'
          />
        </li>
      </ul>
    </Column>
  </PageTemplate>
);

export default Contact;
