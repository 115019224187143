import Link from 'app/components/Link';
import { flex, mediaQuery } from '../../../styles/shared.styles';
import styled from 'styled-components/macro';

export const Container = styled.div({
  ...flex.vertical,
  ...flex.spaceBetween,
  [mediaQuery.print]: {
    padding: 0,
    display: 'block',
  },
} as any);

export const StyledLink = styled(Link)({
  fontSize: '1rem',
});
