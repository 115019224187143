import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { get } from 'lodash-es';
import { Helmet } from 'react-helmet';

import VerifyVinAdsHistory from './VerifyVinAdsHistory';
import { showLoginDialog } from 'app/actions/app';
import { loadAdsByVin, setVin, resetAdsByVin } from 'app/actions/ads';
import { IAdsByVinApiResult } from 'app/models/Api';
import { OG_META_IMAGE } from 'app/constants/meta-tags';

interface IOwnProps {
  isValidVin: boolean;
}

interface IStateProps {
  isAuthorized: boolean;
  adsByVinList: IAdsByVinApiResult;
  adsByVinListIsLoading: boolean;
  vin: string;
}

interface IStateDispatchProps {
  loadAdsByVin: (vin: string, from?: number, limit?: number) => void;
  resetAdsByVin: () => void;
  setVin: (vin: string) => void;
  showLoginDialog: () => void;
}

type Props = IOwnProps & IStateProps & IStateDispatchProps & RouteComponentProps;

class VerifyVinAdsHistoryContainer extends React.Component<Props> {
  public componentDidMount() {
    const vin = get(this.props, 'match.params.vin', null);
    this.loadAdsByVin(vin);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const vinHasChanged = this.props.vin && this.props.vin !== nextProps.vin;
    if (authHasChanged || vinHasChanged) {
      this.loadAdsByVin(nextProps.vin);
    }
  }

  public componentWillUnmount() {
    this.props.resetAdsByVin();
  }

  public render() {
    const { adsByVinListIsLoading, adsByVinList, vin, isAuthorized, isValidVin } = this.props;
    const adMeta = (adsByVinList && adsByVinList.meta) || null;
    const metaTitle = `${adMeta && adMeta?.hasAdDetails && isValidVin ? adMeta?.adTitle : vin}`;
    const metaCompleteTitle = `Ověření VIN - ${metaTitle}`;
    return (
      <>
        <Helmet defer={false}>
          <title>{metaCompleteTitle}</title>
          <meta property='og:url' content={window.location.href} />
          <meta property='og:image' content={OG_META_IMAGE} />
          <meta property='twitter:image' content={OG_META_IMAGE} />
          <meta name='image' content={OG_META_IMAGE} />
          <meta property='og:title' content={`${metaCompleteTitle} | OvěřAuto.cz`} />
          <meta property='twitter:title' content={`${metaCompleteTitle} | OvěřAuto.cz`} />
        </Helmet>
        <VerifyVinAdsHistory
          data={adsByVinList && adsByVinList.docs}
          dataIsLoading={adsByVinListIsLoading}
          firstLastPriceDiff={adMeta?.firstLastPriceDiff}
          isAuthorized={isAuthorized}
          isValidVin={isValidVin}
          setVin={this.props.setVin}
          showLoginDialog={this.props.showLoginDialog}
          totalPriceChanges={adMeta?.totalPriceChanges}
          vin={vin}
        />
      </>
    );
  }

  private loadAdsByVin = (vin: string) => {
    this.props.setVin(vin);
    if (!vin) {
      return;
    }

    this.props.loadAdsByVin(vin);
  };
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    vin: state.ads.vin,
    adsByVinList: state.ads.adsByVinList,
    adsByVinListIsLoading: state.ads.adsByVinListIsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setVin: (vin: string) => dispatch(setVin(vin)),
    showLoginDialog: () => dispatch(showLoginDialog()),
    resetAdsByVin: () => dispatch(resetAdsByVin()),
    loadAdsByVin: (vin: string, from?: number, limit?: number) =>
      dispatch(loadAdsByVin(vin, from, limit)),
  };
};

export default withRouter(
  connect<IStateProps, IStateDispatchProps, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyVinAdsHistoryContainer)
);
