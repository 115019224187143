import * as React from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { resetPassword } from '../../actions/user';
import { pushNotification, showLoginDialog } from '../../actions/app';
import { RESET_PASSWORD_SUCCESS } from '../../constants/notifications';

import WhatYouGet from '../WhatYouGet';
import Typography from '../Typography';
import Button from '../Button';
import NotificationType from '../../models/NotificationType';
import * as SignupDialogStyles from '../AuthDialog/SignupDialog.styles';
import * as S from './ResetPasswordDialog.styles';

interface IStateProps {
  resetPasswordInProgress: boolean;
  resetPasswordSuccess: boolean;
}

interface IStateDispatchProps {
  showLoginDialog: () => void;
  notifyResetPasswordSuccess: () => void;
  resetPassword: (newPassword: string, passwordResetToken: string) => Promise<any>;
}

type TProps = IStateProps & IStateDispatchProps & RouteComponentProps<any, StaticContext>;

interface IResetPasswordDialogState {
  form: {
    [key: string]: any;
  };
  errors: {
    [key: string]: string | null;
  };
  submitted?: boolean;
}

class ResetPasswordDialog extends React.Component<TProps, IResetPasswordDialogState> {
  public static defaultProps = {
    resetPasswordInProgress: false,
    resetPasswordSuccess: false,
  };

  public state: IResetPasswordDialogState = {
    form: {
      newPassword: null,
      confirmPassword: null,
    },
    errors: {
      newPassword: null,
      confirmPassword: null,
      apiErrorMsg: '',
    },
    submitted: false,
  };

  public render() {
    const { resetPasswordInProgress, resetPasswordSuccess } = this.props;
    const { submitted } = this.state;

    return (
      <S.CenteredContainer>
        <S.CenteredDialogContent>
          <SignupDialogStyles.DialogContainer>
            <SignupDialogStyles.FormContainer>
              <form onSubmit={this.resetPassword} noValidate={true}>
                <Typography text={'Nastavení nového hesla'} />
                <div>
                  <div>
                    <div>
                      <div>
                        <SignupDialogStyles.StyledInput
                          placeholder={'Nové heslo'}
                          block={true}
                          onChange={this.onNewPasswordChange}
                          disabled={submitted && resetPasswordSuccess}
                          type='password'
                        />
                        {this.state.submitted && this.state.errors.newPassword && (
                          <SignupDialogStyles.StyledErrorMessage
                            textAlign='left'
                            isVisible={this.state.submitted}
                            message={this.state.errors.newPassword}
                          />
                        )}
                      </div>
                      <div>
                        <SignupDialogStyles.StyledInput
                          placeholder={'Potvrďte heslo'}
                          block={true}
                          onChange={this.onConfirmPasswordChange}
                          disabled={submitted && resetPasswordSuccess}
                          type='password'
                        />
                        {this.state.submitted && this.state.errors.confirmPassword && (
                          <SignupDialogStyles.StyledErrorMessage
                            textAlign='left'
                            isVisible={this.state.submitted}
                            message={this.state.errors.confirmPassword}
                          />
                        )}
                      </div>
                      {this.state.submitted &&
                        this.state.errors &&
                        this.state.errors.passwordDoNotMatch && (
                          <SignupDialogStyles.StyledErrorMessage
                            textAlign='left'
                            isVisible={this.state.submitted}
                            message={this.state.errors.passwordDoNotMatch}
                          />
                        )}
                      {this.state.submitted &&
                        this.state.errors &&
                        this.state.errors.apiErrorMsg && (
                          <SignupDialogStyles.StyledErrorMessage
                            textAlign='left'
                            isVisible={this.state.submitted}
                            message={this.state.errors.apiErrorMsg}
                          />
                        )}
                      <SignupDialogStyles.ButtonContainer>
                        <Button
                          kind={'submit'}
                          text={'Potvrdit'}
                          fullWidth={true}
                          spin={resetPasswordInProgress}
                          disabled={resetPasswordInProgress || resetPasswordSuccess}
                        />
                      </SignupDialogStyles.ButtonContainer>
                    </div>
                  </div>
                </div>
              </form>
            </SignupDialogStyles.FormContainer>
            <SignupDialogStyles.InfoContainer>
              <WhatYouGet />
            </SignupDialogStyles.InfoContainer>
          </SignupDialogStyles.DialogContainer>
        </S.CenteredDialogContent>
      </S.CenteredContainer>
    );
  }

  private resetPassword = async evt => {
    try {
      evt.preventDefault();
      const errors = this.validateForm(this.state.form);
      const isValid =
        Object.keys(errors)
          .map(key => errors[key])
          .join('').length === 0;
      this.setState({
        errors,
        submitted: true,
      });

      if (isValid) {
        const { newPassword } = this.state.form;
        // @ts-ignore
        const urlQuery = qs.parse(this.props.location.search);
        // @ts-ignore
        await this.props.resetPassword(newPassword, urlQuery.token);
        this.props.history.push(`/`);
        this.props.notifyResetPasswordSuccess();
        this.props.showLoginDialog();
      }
    } catch (error) {
      const code = (error as any)?.response?.data?.code;
      if (code === 'tokenNotFound') {
        this.setState({
          errors: {
            apiErrorMsg: 'Chyba! Neplatný kód.',
          },
        });
        return;
      }

      if (code === 'resetTokenExpired') {
        this.setState({
          errors: {
            apiErrorMsg: 'Chyba! Kód expiroval.',
          },
        });
        return;
      }
    }
  };

  private onNewPasswordChange = evt => {
    this.handleInputChange(evt.target.value, 'newPassword');
  };

  private onConfirmPasswordChange = evt => {
    this.handleInputChange(evt.target.value, 'confirmPassword');
  };

  private handleInputChange = (value: any, name: string) => {
    const form = {
      ...this.state.form,
      [name]: value,
    };
    const errors = this.validateForm(form);
    this.setState({
      form,
      errors,
    });
  };

  private validateForm = (form: {
    [key: string]: any;
  }): {
    [key: string]: string | null;
  } => {
    const errors: {
      [key: string]: null | string;
    } = {
      newPassword: null,
      confirmPassword: null,
    };
    if (!form.newPassword) {
      errors.newPassword = 'Vložte prosím heslo';
    }
    if (!form.confirmPassword) {
      errors.confirmPassword = 'Zopakujte prosím heslo';
    }
    if (form.newPassword !== form.confirmPassword) {
      errors.passwordDoNotMatch = 'Hesla se neshodují, skuste to znovu';
    }
    return errors;
  };
}

const mapStateToProps = state => {
  return {
    resetPasswordInProgress: state.user && state.user.forgotPasswordInProgress,
    resetPasswordSuccess: state.user && state.user.forgotPasswordSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  const NOTIFICATION_DISMISS_DELAY = 15000;

  return {
    resetPassword: (newPassword: string, passwordResetToken: string) =>
      dispatch(resetPassword(newPassword, passwordResetToken)),
    showLoginDialog: () => dispatch(showLoginDialog()),
    notifyResetPasswordSuccess: () =>
      dispatch(
        pushNotification(
          RESET_PASSWORD_SUCCESS,
          'Vaše heslo bylo úspěšně změněno. Nyní se můžete přihlásit.',
          NotificationType.info,
          NOTIFICATION_DISMISS_DELAY
        )
      ),
  };
};

export default withRouter(
  connect<IStateProps, IStateDispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPasswordDialog)
);
