import { useLocation } from 'react-router-dom';

/**
 * Check if route is premium
 */
export const usePremiumRoute = () => {
  const { pathname } = useLocation();
  const isPremiumRoute = pathname.includes('/premium');
  return isPremiumRoute;
};
