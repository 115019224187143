import * as React from 'react';
import styled from 'styled-components/macro';

const StyledList = styled('div')({
  display: 'block',
  width: '100%',
  position: 'relative',
});

interface IListProps {
  children: React.ReactNode;
  [key: string]: any;
}

const List: React.SFC<IListProps> = ({ children, bem }) => {
  return <StyledList>{children}</StyledList>;
};

export default List;
