import * as React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import { Redirect } from 'react-router';

import { LeasingCheck } from 'app/components/VerifyVin/components/LeasingCheck';
import { ResponsiveSection } from 'app/components/ResponsiveSection';
import { StolenCarsContainer } from 'app/components/VerifyVin/components/StolenCars';
import { VerificationSummaryContainer } from 'app/components/VerifyVin/components/VerificationSummary';
import { VerifyVinAdsHistoryContainer } from 'app/components/VerifyVin/components/VerifyVinAdsHistory';
import { VerifyVinDecodeContainer } from 'app/components/VerifyVin/components/VerifyVinDecode';
import { VerifyVinKmHistoryContainer } from 'app/components/VerifyVin/components/VerifyVinKmHistory';
import { VerifyVinPriceAdvisorContainer } from 'app/components/VerifyVin/components/VerifyVinPriceAdvisor';
import { LoginBanner } from 'app/components/LoginBanner';
import { SmartReport } from 'app/components/VerifyVin/components/SmartReport';
import { VehicleRegister } from 'app/components/VerifyVin/components/VehicleRegister';
import { DefectsTable } from 'app/components/VerifyVin/components/DefectsTable';

import Ad from 'app/models/Ad';
import { usePremiumRoute } from 'app/hooks';

interface IVerifyVinPublicProps {
  vin: string;
  isValidVin: boolean;
  adDetails: Ad;
  adDetailsIsLoading: boolean;
  adsImages: Ad['images'];
}

export const VerifyVinPublic: React.SFC<IVerifyVinPublicProps> = ({
  vin,
  adDetails,
  adDetailsIsLoading,
  adsImages,
  isValidVin,
}) => {
  const isPremiumRoute = usePremiumRoute();
  if (isPremiumRoute) return <Redirect to={`/vin/${vin}`} />;

  return (
    <>
      <Flex flexDirection='column' maxWidth='1200px' margin='0 auto'>
        <ResponsiveSection
          mt={['10px', '30px', '35px']}
          flexDirection='column'
          mb={isValidVin ? ['10px', '30px', '70px'] : ['10px', '30px', '30px']}
        >
          <Box width={[1]}>
            <VerificationSummaryContainer isValidVin={isValidVin} />
          </Box>
        </ResponsiveSection>

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <LoginBanner />
              <SmartReport />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <VerifyVinDecodeContainer />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <VerifyVinKmHistoryContainer />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <DefectsTable />
            </Box>
          </ResponsiveSection>
        )}

        <ResponsiveSection>
          <Box width={[1]}>
            <VerifyVinAdsHistoryContainer isValidVin={isValidVin} />
          </Box>
        </ResponsiveSection>

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <VerifyVinPriceAdvisorContainer vin={vin} />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <StolenCarsContainer />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]} sx={{ position: 'relative' }}>
              <LoginBanner />
              <LeasingCheck />
            </Box>
          </ResponsiveSection>
        )}

        {isValidVin && (
          <ResponsiveSection>
            <Box width={[1]}>
              <VehicleRegister />
            </Box>
          </ResponsiveSection>
        )}
      </Flex>
    </>
  );
};
