export default class Ad {
  public static getAcTitle = (ac: string): string => {
    if (!ac) {
      return 'neuvedeno';
    }

    if (ac === 'manualni' || ac === 'manuální' || ac === 'automatická') {
      return ac;
    }

    const isAutomaticAc = ac.indexOf('zónová') > -1;
    if (isAutomaticAc) {
      return `automatická - ${ac}`;
    }

    return 'bez klimatizace';
  };

  public static getEquipmentCountTitle = (equipmentCount: number): string => {
    if (equipmentCount && equipmentCount === 1) {
      return '1 položka';
    }

    if (equipmentCount && equipmentCount > 1 && equipmentCount < 5) {
      return `${equipmentCount || 0} položky`;
    }

    return `${equipmentCount || 0} položek`;
  };

  public static getAdAgeDaysTitle = (adAge: number): string => {
    if (adAge === 0 || !adAge) {
      return '0 dní';
    }

    if (adAge === 1) {
      return '1 den';
    }

    if (adAge > 1 && adAge < 5) {
      return `${adAge} dny`;
    }

    if (adAge >= 5) {
      return `${adAge} dní`;
    }

    return `${adAge || 0} dní`;
  };

  public static getNumberOfRecordsTitle = (record: number, withNumber: boolean = false): string => {
    if (record === 0 || !record) {
      return withNumber ? '0 záznamů' : 'záznamů';
    }

    if (record === 1) {
      return withNumber ? '1 záznam' : 'záznam';
    }

    if (record > 1 && record < 5) {
      return withNumber ? `${record} záznamy` : `záznamy`;
    }

    if (record >= 4) {
      return withNumber ? `${record} záznamů` : `záznamů`;
    }

    return withNumber ? `${record || 0} záznamů` : `záznamů`;
  };

  public static extractAdIdFromAdUrl(adUrl: string): string {
    const isNumber = /^-{0,1}\d+$/.test(adUrl);
    if (isNumber) {
      return adUrl;
    }

    const isUrl =
      adUrl.indexOf('http') > -1 ||
      adUrl.indexOf('https') > -1 ||
      adUrl.indexOf('www') > -1 ||
      adUrl.indexOf('m.') > -1;
    if (!adUrl || !isUrl) {
      return '';
    }

    const isMobileUrl = adUrl.indexOf('https://m.') > -1 || adUrl.indexOf('http://m.') > -1;
    if (isMobileUrl) {
      const containsId = adUrl.split('id=').pop();
      const id =
        (containsId &&
          adUrl
            .split('id=')[1]
            .split('&')
            .shift()) ||
        '';
      return id;
    }

    try {
      const splittedUrl = adUrl.split('?')[0];
      return splittedUrl.substr(adUrl.lastIndexOf('/') + 1, splittedUrl.length);
    } catch (err) {
      return '';
    }
  }

  public _id: number; // TODO: why number, it's string?
  public brand: string;
  public brand_slug?: string;
  public id: number;
  public km: number;
  public price: number;
  public title: string;
  public url: string;
  public year: number;
  public ac?: string;
  public adAge?: number;
  public adAgeText?: string;
  public color?: string;
  public countryOrigin?: string;
  public discount: { value: number; isBetterOrSame: boolean; percent: number } | null;
  public equipment?: string[];
  public equipmentCount?: number;
  public firstOwner?: boolean;
  public firstRun?: string;
  public fuel?: string;
  public gears?: number | null;
  public history?: any;
  public img?: string;
  public isActive: boolean;
  public karoserie?: string;
  public kw?: number;
  public lastRun?: string;
  public listRank: number;
  public model?: string;
  public month?: number;
  public notes?: string;
  public runCount?: number;
  public serviceBook?: boolean;
  public stav?: string;
  public stk?: number;
  public titleFull?: string;
  public titleShort?: string;
  public total: number;
  public transmission?: string;
  public vendor?: string;
  public vendorFrame?: string | null;
  public vendorPhone?: string;
  public vin?: string;
  public vinSplitId?: string;
  public vinSplitIds?: string[];
  public duplicateId?: string;
  public duplicateIdRef?: string;
  public images: { src: string; date: Date; path: string }[];
}
