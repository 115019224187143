import * as React from 'react';
import { connect } from 'react-redux';

import { setListType } from '../actions/app';
import { loadAdDetails } from '../actions/ads';

import { StyledSelect } from './FormElements/StyledSelect';
import { AntOption } from './FormElements/StyledSelect';

export type ListType =
  | 'discount'
  | 'estimate-car'
  | 'historical'
  | 'mini'
  | 'newest'
  | 'price-advisor'
  | 'top'
  | 'vin';

export enum EnumListType {
  DISCOUNT = 'discount',
  ESTIMATE_CAR = 'estimate-car',
  HISTORICAL = 'historical',
  MINI = 'mini',
  NEWEST = 'newest',
  PRICE_ADVISOR = 'price-advisor',
  TOP = 'top',
  VIN = 'vin',
}

interface IListSwitcherProps {
  listType: ListType;
  setListType: (listType: string) => void;
  loadAdDetails: (listType: string) => void;
}

class ListSwitcher extends React.Component<IListSwitcherProps> {
  public render() {
    const { listType } = this.props;

    return (
      <div>
        <StyledSelect value={listType} onChange={(value: any) => this.onBtnClick(value)}>
          <AntOption value='top'>Nejlépe hodnocené</AntOption>
          <AntOption value='historical'>Prodané</AntOption>
          <AntOption value='newest'>Nejnovější</AntOption>
          <AntOption value='discount'>Slevy</AntOption>
        </StyledSelect>
      </div>
    );
  }

  private onBtnClick = (type: ListType) => {
    this.props.setListType(type);
    this.props.loadAdDetails(type);
  };
}

const mapStateToProps = state => {
  return {
    listType: state.ads.listType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setListType: (listType: string) => dispatch(setListType(listType)),
    loadAdDetails: (listType?: string) => dispatch(loadAdDetails(listType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListSwitcher);
