import { useDispatch } from 'react-redux';
import { useVin, useAuthenticated } from 'app/hooks';
import { redirectRoute } from 'app/routes';
import NotificationType from 'app/models/NotificationType';
import { pushNotification } from 'app/actions/app';

/**
 * Check if user is logged in
 */
export const useCheckLoggedIn = (
  notifMsg: string,
  returnUrl: string = '',
  notifType: NotificationType = NotificationType.info,
  notifDelay: number = 7 * 1000
) => {
  const vin = useVin();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthenticated();

  const showNotification = () =>
    dispatch(
      pushNotification(
        returnUrl,
        notifMsg,
        NotificationType.info,
        notifDelay // 7s
      )
    );

  if (isAuthenticated) return;

  redirectRoute(returnUrl ? returnUrl : `/vin/${vin}`);
  showNotification();
};
