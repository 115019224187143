import * as React from 'react';
import { noop } from 'lodash-es';

import { AdsListWithPriceHistory } from './components/AdsListWithPriceHistory';
import SectionHeader from 'app/components/SectionHeader';
import Typography from 'app/components/Typography';
import AdLoader, { EnumAdLoaderTexts } from 'app/components/AdLoader';
import { DataResults } from 'app/components/DataResults';
import Ad from 'app/models/Ad';
import {
  NoResultsContainer,
  SectionHeaderContainer,
  LoaderContainer,
} from 'app/components/_shared_';

interface IVerifyVinAdsHistoryProps {
  data: any[];
  dataIsLoading: boolean;
  isValidVin: boolean;
  firstLastPriceDiff?: number;
  isAuthorized?: boolean;
  totalPriceChanges?: number;
  vin?: string;
  showLoginDialog: () => void;
  setVin: (vin: string) => void;
}

const sectionHeaderText = 'Záznamy z inzerce';

const VerifyVinAdsHistory: React.SFC<IVerifyVinAdsHistoryProps> = ({
  vin,
  data,
  isAuthorized,
  showLoginDialog,
  setVin,
  dataIsLoading,
  isValidVin,
  totalPriceChanges,
  firstLastPriceDiff,
}) => {
  if (!isAuthorized) {
    return (
      <ContentForUnauthorizedUser
        data={data}
        dataIsLoading={dataIsLoading}
        isValidVin={isValidVin}
        showLoginDialog={showLoginDialog}
        setVin={setVin}
      />
    );
  }

  if (dataIsLoading) {
    return (
      <NoResultsContainer>
        <SectionHeaderContainer>
          <SectionHeader title={sectionHeaderText} />
        </SectionHeaderContainer>
        <LoaderContainer>
          <AdLoader text={EnumAdLoaderTexts.LOADING_DATA} fullscreen={true} />
        </LoaderContainer>
      </NoResultsContainer>
    );
  }

  const hasData = data && data.length;
  if (!hasData) {
    return (
      <>
        <SectionHeaderContainer>
          <SectionHeader
            title={sectionHeaderText}
            subheaderValue={'0'}
            subheaderSuffixText={Ad.getNumberOfRecordsTitle(0)}
          />
        </SectionHeaderContainer>
        <NoResultsContainer>
          <Typography
            text='Litujeme, ale neevidujeme žádné záznamy.'
            size='base'
            weight='regular'
          />
        </NoResultsContainer>
      </>
    );
  }

  return (
    <DataResults
      sectionHeaderText={sectionHeaderText}
      sectionSubheaderValue={(data && data.length) || '0'}
      sectionSubheaderSuffixText={Ad.getNumberOfRecordsTitle(data.length)}
      dataIsLoading={dataIsLoading}
      loaderText={EnumAdLoaderTexts.LOADING_DATA}
    >
      <AdsListWithPriceHistory
        data={data}
        dataIsLoading={dataIsLoading || false}
        isAuthorized={true}
        isValidVin={isValidVin}
        showLoginDialog={showLoginDialog}
        setVin={setVin}
        totalPriceChanges={totalPriceChanges}
        firstLastPriceDiff={firstLastPriceDiff}
      />
    </DataResults>
  );
};

VerifyVinAdsHistory.defaultProps = {
  data: [],
  vin: '',
};

export default VerifyVinAdsHistory;

export const ContentForUnauthorizedUser: React.SFC<IVerifyVinAdsHistoryProps> = ({
  data = [],
  dataIsLoading,
  showLoginDialog,
  isValidVin,
  setVin,
}) => {
  return (
    <DataResults
      sectionHeaderText={sectionHeaderText}
      sectionSubheaderValue={(data && data.length) || '0'}
      sectionSubheaderSuffixText={Ad.getNumberOfRecordsTitle(data.length)}
      dataIsLoading={dataIsLoading}
      loaderText={EnumAdLoaderTexts.LOADING_DATA}
    >
      <AdsListWithPriceHistory
        data={data}
        dataIsLoading={dataIsLoading || false}
        isAuthorized={false}
        isValidVin={isValidVin}
        showLoginDialog={showLoginDialog || noop}
        setVin={setVin}
      />
    </DataResults>
  );
};
