import * as React from 'react';
import { SyntheticEvent } from 'react';
import { mediaQuery } from 'styles/shared.styles';
import Button from 'app/components/Button';

interface IPdfExportDownloadBtnProps {
  onClick: (evt: SyntheticEvent<Element>) => void;
  btnText?: string;
}

export const PdfExportDownloadBtn: React.SFC<IPdfExportDownloadBtnProps> = ({
  btnText,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      css={{
        [mediaQuery.print]: {
          display: 'none',
        },
      }}
    >
      {btnText}
    </Button>
  );
};

PdfExportDownloadBtn.defaultProps = {};
