import * as React from 'react';
import Paragraph from 'app/components/Paragraph';

interface INotSpecifiedValueProps {
  text?: string;
}

const NotSpecifiedValue: React.SFC<INotSpecifiedValueProps> = ({ text }) => {
  return <Paragraph>{text ? text : 'Neuvedeno'}</Paragraph>;
};

export default NotSpecifiedValue;
