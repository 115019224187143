import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink as NavLinkReactRouter } from 'react-router-dom';
import {
  system,
  position,
  PositionProps,
  display,
  DisplayProps,
  margin,
  MarginProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { colors } from '../../styles/shared.styles';

import externalIcon from '../../assets/icons/external.svg';

interface IOwnProps {
  to?: string;
  external?: boolean;
  showExternalIcon?: boolean;
  size?: 'normal' | 'small';
  wordBreak?: string;
  textDecoration?: string;
  whiteSpace?: string;
  colorHover?: string;
  onClick?: () => void;
}

interface IExternalIconProps {
  size?: 'normal' | 'small';
}

type TLinkProps = IOwnProps &
  PositionProps &
  DisplayProps &
  MarginProps &
  ColorProps &
  TypographyProps;

const Link: React.SFC<TLinkProps> = ({
  to,
  children,
  size = '1.1rem',
  external,
  showExternalIcon,
  colorHover,
  lineHeight,
  onClick,
  ...props
}) => {
  if (external) {
    const isFullUrl = /\b(http|https|mailto)/.test(to || '');
    const isMailLink = /\b(mailto)/.test(to || '');
    const link = isFullUrl ? to : `${process.env.REACT_APP_BASE_URL}${to}`;
    return (
      <NormalLink
        href={link}
        size={size}
        colorhover={colorHover}
        lineheight={lineHeight}
        onClick={onClick}
        {...props}
        target={isMailLink ? '' : '_blank'}
      >
        {children}
        {showExternalIcon && <ExternalLinkIcon size={size} />}
      </NormalLink>
    );
  }

  const isFakeLink = !to && typeof onClick === 'function';
  if (isFakeLink) {
    return (
      <FakeLink
        size={size}
        colorhover={colorHover}
        lineheight={lineHeight}
        onClick={onClick}
        {...props}
      >
        {children}
      </FakeLink>
    );
  }

  return (
    <StyledReactLink
      to={to}
      size={size}
      colorhover={colorHover}
      lineheight={lineHeight}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledReactLink>
  );
};

const baseLinkStyles = props =>
  css(
    {
      color: colors.primary,
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    ({ colorhover }) =>
      colorhover
        ? {
            ':hover': {
              color: colorhover,
            },
          }
        : null,
    ({ size }) => (size === 'small' ? { fontSize: '1.0rem' } : { fontSize: size }),
    system({
      wordBreak: true,
      textDecoration: true,
      whiteSpace: true,
    }),
    typography,
    position,
    margin,
    display,
    color
  );

const NormalLink = styled.a(baseLinkStyles);

const FakeLink = styled.span(baseLinkStyles);

const StyledReactLink = styled(NavLinkReactRouter)(baseLinkStyles);

const ExternalLinkIcon = styled.div((props: IExternalIconProps) => ({
  backgroundImage: `url(${externalIcon})`,
  display: 'inline-block',
  marginLeft: '6px',
  backgroundSize: props.size === 'small' ? '11px 11px' : '13px 13px',
  width: props.size === 'small' ? '11px' : '13px',
  height: props.size === 'small' ? '11px' : '13px',
}));

export default Link;
