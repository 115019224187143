import { colors } from './variables/colors.styles';
import { fontFamily, fontSize } from './variables/typography.styles';
import { breakpointsPx } from './variables/responsive.styles';

export interface IBaseTheme {
  borders: { [key: string]: string };
  breakpoints: string[];
  colors: { [key in keyof typeof colors]: string };
  fonts: { [key in keyof typeof fontFamily]: string };
  fontSizes: { [key in keyof typeof fontSize]: string };
  space?: number[];
}

// rebass grid space settings
const space = [0, 5, 10, 12, 15, 18, 20, 30, 50]; // p={2} => padding='10px', m={2} => margin='10px'

export const baseTheme: IBaseTheme = {
  borders: {
    base: '1px solid rgba(151, 151, 151, 0.5)',
  },
  breakpoints: breakpointsPx,
  space,
  colors,
  fonts: fontFamily,
  fontSizes: fontSize,
};
