import * as React from 'react';
import ResetPasswordDialog from '../components/ResetPasswordDialog';

export interface IResetPasswordProps {}

class ResetPassword extends React.Component<IResetPasswordProps, any> {
  public render() {
    return <ResetPasswordDialog />;
  }
}

export default ResetPassword;
