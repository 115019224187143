import React from 'react';
import styled from 'styled-components/macro';

import { Column } from 'app/components/Column';
import Typography from 'app/components/Typography';
import { ImageLogoOverauto, ImageLogoOverautoWhite } from 'app/utils/images';

interface ILogoOverautoProps {
  badgeText: string;
  bgColor?: string;
  theme?: 'dark' | 'light';
}

export const LogoOverauto: React.FC<ILogoOverautoProps> = ({
  bgColor = '#1780FA',
  badgeText,
  theme = 'dark',
}) => (
  <Column alignItems='flex-end'>
    {theme === 'dark' ? <ImageLogoOverauto /> : <ImageLogoOverautoWhite />}
    <Badge background={bgColor} px='15px' borderRadius='5px' position='relative' top='-8px'>
      <Typography text={badgeText} color='white' textTransform='uppercase' lineHeight='22px' />
    </Badge>
  </Column>
);

const Badge = styled(Column)``;
