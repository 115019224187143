import * as React from 'react';
import * as FaFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import Button from './Button';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)({
  backgroundColor: '#3f5a93',
  ':hover': {
    backgroundColor: '#2c406a',
  },
});

const FacebookButton: React.SFC<any> = props => {
  return <StyledButton icon={FaFacebookF} {...props} />;
};

export default FacebookButton;
