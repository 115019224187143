import * as React from 'react';
import Heading from './Heading';
import styled from 'styled-components/macro';
import Typography from './Typography';

import speedometerIcon from '../../assets/images/what-you-get/Speedometer.png';
import lockedIcon from '../../assets/images/what-you-get/Locked.png';
import controlsIcon from '../../assets/images/what-you-get/Controls.png';
import timeIcon from '../../assets/images/what-you-get/Time.png';

const Wrapper = styled('div')({
  height: '100%',
});

const Row = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '25px',

  'last-child': {
    marginBottom: '0',
  },
});

const Icon = styled('img')({
  flex: '1',
  marginRight: '23px',
  position: 'relative',
  top: '4px',
});

const IconContainer = styled('div')({
  minWidth: '74px',
});

interface IWhatYouGetProps {}

const WhatYouGet: React.SFC<IWhatYouGetProps> = () => {
  return (
    <Wrapper>
      <Heading level={2}>Co získáte</Heading>
      <Row>
        <IconContainer>
          <Icon src={speedometerIcon} alt='icon-bad' />
        </IconContainer>
        <Typography
          size='small'
          text={
            'Ověření VIN. Stav tachometru, data od výrobce, záznamy od úvěrových společností i policie, analýzu tržní hodnoty...'
          }
        />
      </Row>
      <Row>
        <IconContainer>
          <Icon src={timeIcon} alt='icon-bad' />
        </IconContainer>
        <Typography
          size='small'
          text={'Ušetříte čas i peníze, protože se vyhnete podvodným či nevýhodným nabídkám.'}
        />
      </Row>
      <Row>
        <IconContainer>
          <Icon src={controlsIcon} alt='icon-bad' />
        </IconContainer>
        <Typography
          size='small'
          text={
            'Poskytneme Vám přístup k celému trhu i jeho historii, můžete se tak podívat jak vypadala nabídka třeba před měsícem.'
          }
        />
      </Row>
      <Row>
        <IconContainer>
          <Icon src={lockedIcon} alt='icon-bad' />
        </IconContainer>
        <Typography
          size='small'
          text={
            'Pomůžeme Vám získat slevu a ochráníme Vás před triky prodejců i podvodnými nabídkami.'
          }
        />
      </Row>
    </Wrapper>
  );
};

export default WhatYouGet;
