import * as moment from 'moment';

export interface IGoPayPayerInfo {
  payment_card?: {
    card_number: string;
    card_expiration: string;
    card_brand: string;
    card_issuer_country: string;
    card_issuer_bank: string;
  };
  contact: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
  };
}

export interface IGoPayCreatePaymentResponse {
  id: number;
  state: string;
  amount: number;
  currency: string;
  payer: IGoPayPayerInfo;
  target: object;
  lang: string;
  gw_url: string;
  order_description: string;
  order_number: string;
  payment_instrument?: string;
  sub_state?: string;
}

export interface IPayment {
  _id?: string;
  amount: number;
  confirmationEmailSent: boolean;
  createdAt: Date;
  activeSince?: Date;
  activeTo?: Date;
  currency: string;
  gwUrl: string;
  items: {
    type: string;
    name: string;
    amount: number;
    count: number;
  }[];
  lang?: string;
  orderDescription?: string;
  orderId: number;
  orderNumber?: string;
  paymentPackage: IPaymentPackage;
  payer: IGoPayPayerInfo;
  state: string;
  userId: string;
  _version: number;
  _revisions?: object[];
}

export interface IPaymentPackages {
  [packageName: string]: IPaymentPackage;
}

export interface IPaymentPackage {
  currency: string;
  id: string;
  price: number;
  title: string;
  limits: {
    vin: number;
  };
  period: {
    unit: string;
    value: moment.unitOfTime.DurationConstructor | number;
  };
}

export interface IUserPayments {
  activePayment: IPayment | null;
  allPayments: IAllPayments;
}

export interface IAllPayments {
  docs: IPayment[];
  total: number;
  from: number;
  limit: number;
  page: number;
  pages: number;
}

export interface IPaymentLimits {
  vin: {
    limit: number;
    total: number;
    summary: {
      vin: string;
      total: number;
    }[];
    remaining: number;
  };
}

export enum EnumPaymentStates {
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  PAID = 'PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PAYMENT_METHOD_CHOSEN = 'PAYMENT_METHOD_CHOSEN',
  REFUNDED = 'REFUNDED',
  TIMEOUTED = 'TIMEOUTED',
}

export enum EnumPaymentCategory {
  VIN_PREMIUM = 'vinPremium',
  CACHE_BUST = 'cacheBust',
}

export enum EnumPaymentStatesTranslated {
  AUTHORIZED = 'Platba autorizovaná',
  CANCELED = 'Platba zrušená',
  CREATED = 'Platba zpracována',
  PAID = 'Platba zaplacená',
  PARTIALLY_REFUNDED = 'Platba částečne refundovaná',
  PAYMENT_METHOD_CHOSEN = 'Platební metoda zvolena',
  REFUNDED = 'Platba refundovaná',
  TIMEOUTED = 'Platba vypršaná',
}
