import * as React from 'react';
import { Link } from 'react-router-dom';
import { CenteredContainer } from 'app/components/CenteredContainer';
import * as S from './NotFound.styles';

interface INotFoundProps {}

export const NotFound: React.SFC<INotFoundProps> = () => {
  return (
    <CenteredContainer>
      <S.NotFoundContainer>
        Požadovaná stránka nebyla nalezena. Pokračovat můžete návratem na
        <br />
        <Link to='/'>www.overauto.cz</Link>
      </S.NotFoundContainer>
    </CenteredContainer>
  );
};
