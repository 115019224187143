import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { VerifyVinPriceAdvisor } from './VerifyVinPriceAdvisor';
import { IResidualValueResult } from 'app/models/Api';
import { loadResidualValueByVin } from 'app/actions/ads';

interface IOwnProps {
  vin: string;
}

interface IStateProps {
  isAuthorized: boolean;
  residualValueByVin: IResidualValueResult;
  residualValueByVinIsLoading: boolean;
  residualValueByVinError: string;
}

interface IStateDispatchProps {
  loadResidualValueByVin: (vin: string) => void;
}

type Props = IOwnProps & IStateProps & IStateDispatchProps & RouteComponentProps;

class VerifyVinPriceAdvisorComponent extends React.Component<Props> {
  public componentDidMount() {
    const { vin } = this.props;
    this.props.loadResidualValueByVin(vin);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const authHasChanged = this.props.isAuthorized !== nextProps.isAuthorized;
    const { vin, residualValueByVin } = nextProps;
    if (authHasChanged && !residualValueByVin) {
      this.props.loadResidualValueByVin(vin);
    }
  }

  public render() {
    const {
      residualValueByVin,
      residualValueByVinIsLoading,
      residualValueByVinError,
      isAuthorized,
    } = this.props;
    return (
      <VerifyVinPriceAdvisor
        isAuthorized={isAuthorized}
        data={residualValueByVin}
        dataIsLoading={residualValueByVinIsLoading}
        dataError={residualValueByVinError}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: !!state.auth.accessToken,
    residualValueByVin: state.ads.residualValueByVin,
    residualValueByVinIsLoading: state.ads.residualValueByVinIsLoading,
    residualValueByVinError: state.ads.residualValueByVinError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadResidualValueByVin: (vin: string) => dispatch(loadResidualValueByVin(vin)),
  };
};

export const VerifyVinPriceAdvisorContainer = withRouter(
  connect<IStateProps, IStateDispatchProps, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyVinPriceAdvisorComponent)
);
