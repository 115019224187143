import styled from 'styled-components/macro';

import Typography from 'app/components/Typography';

import { Row, TRowProps } from 'app/components/Row';
import { colors } from 'styles/shared.styles';

import React from 'react';

interface IInfoMessageProps extends TRowProps {
  text: string;
  children?: React.ReactNode;
  colorBg?: string;
  colorText?: string;
}

export const InfoMessage: React.FC<IInfoMessageProps> = ({
  text,
  colorBg,
  colorText = colors.white,
  children,
  ...props
}) => (
  <Container
    alignItems='center'
    justifyContent='center'
    colorBg={colorBg}
    mb={3}
    width='100%'
    flexDirection={['column', 'row']}
    {...props}
  >
    <Typography
      weight='medium'
      size='base'
      color={colorText}
      lineHeight='20px'
      textAlign='center'
      text={text}
    />
    {children}
  </Container>
);

const Container = styled(Row)<Partial<IInfoMessageProps>>`
  background: ${({ colorBg = colors.primary, theme }) => theme.colors[colorBg] || colorBg};
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 20px;
  min-height: 60px;
`;
