import * as React from 'react';
import { chunk, orderBy, fill, slice } from 'lodash-es';
import {
  ParametersRow,
  ParametersColumn,
  ParametersTitleTypography,
  ParametersTitle,
} from 'app/components/_shared_';
import * as AdParametersStyles from '../AdParameters/AdParameters.styles';

interface IAdEquipmentProps {
  equipment: string[];
  equipmentCount: number;
}

const defaultProps: Partial<IAdEquipmentProps> = {
  equipment: [],
  equipmentCount: 0,
};

const AdEquipment: React.SFC<IAdEquipmentProps> = ({ equipment, equipmentCount }) => {
  if (!equipmentCount) {
    return null;
  }

  const COLUMNS_PER_ROW = 3;
  const sortedEquipment = orderBy(equipment, [item => item.toLowerCase()], ['asc']);
  const rows = chunk(sortedEquipment, COLUMNS_PER_ROW);

  return (
    <AdParametersStyles.Container>
      {rows.map((row, idx) => {
        const rowWith3columns =
          row.length === 3 ? row : slice([...row, ...fill(new Array(3), '', 0, 2)], 0, 3);
        return (
          <ParametersRow key={idx} striped={idx % 2 !== 0}>
            {rowWith3columns.map((equipmentItem, idx) => (
              <ParametersColumn key={`${equipmentItem}_${idx}`}>
                <ParametersTitle>
                  <ParametersTitleTypography text={equipmentItem} />
                </ParametersTitle>
              </ParametersColumn>
            ))}
          </ParametersRow>
        );
      })}
    </AdParametersStyles.Container>
  );
};

AdEquipment.defaultProps = defaultProps;

export default AdEquipment;
