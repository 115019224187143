import * as React from 'react';
import * as S from './Form.styles';

interface IFormProps {
  onSubmit: () => void;
}

const Form: React.SFC<IFormProps> = ({ onSubmit, children, ...props }) => (
  <S.FormWrapper>
    <form onSubmit={onSubmit} {...props} noValidate>
      {children}
    </form>
  </S.FormWrapper>
)

export default Form;
