import * as React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import { noop, get } from 'lodash-es';
import styled from 'styled-components/macro';

import { AdsList } from 'app/components/AdsList';
import { DataLoading } from 'app/components/DataLoading';
import { MarketPrice } from 'app/components/PriceAdvisor';
import { DataNoResults } from 'app/components/DataNoResults';
import { LoginBanner } from 'app/components/LoginBanner';
import {
  SectionHeaderContainer,
  Container,
  RoundedBlurryImageContainer,
} from 'app/components/_shared_';
import { EnumListType } from 'app/components/ListSwitcher';
import SectionHeader from 'app/components/SectionHeader';
import { LastAddedAndSoldCars } from 'app/components/LastAddedAndSoldCars';
import ResidualValueCharts from 'app/components/ResidualValue/ResidualValueCharts';
import { PrintFlex } from 'app/components/PrintFlex';
import { SectionWrapperWithPageBreak } from 'app/components/_shared_';
import { Column } from 'app/components/Column';

import { IResidualValueResult } from 'app/models/Api';

import residualValueChartBlurredImg from 'assets/images/residual-value-chart-blurred.png';
import { PRICE_ADVISOR, NEW_AND_SOLD_CARS, RESIDUAL_VALUE } from 'app/constants/titles';

interface IOwnProps {
  data: IResidualValueResult;
  dataIsLoading: boolean;
  dataError: string;
  isAuthorized: boolean;
}

export const VerifyVinPriceAdvisor: React.SFC<IOwnProps> = ({
  data,
  dataIsLoading,
  dataError,
  isAuthorized,
}) => {
  if (dataIsLoading) return <DataLoading sectionHeaderText={PRICE_ADVISOR} />;
  if (!data || !data.searchedAd?._id || !data.idealAd?._id) return <NoResults />;

  const idealAdPrice = get(data, 'listWithSearchedAndIdealAd[1].props.price.value', 0);
  const { lastSoldCars, lastAddedCars } = data;

  const getSearchedAd = () => {
    if (!data) return null;
    const { searchedAd, listWithSearchedAndIdealAd } = data;
    if (searchedAd?._id) return searchedAd;
    const newSearchedAd = listWithSearchedAndIdealAd.find(ad => ad?._id.length < 20);
    return newSearchedAd;
  };

  return (
    <>
      <SectionHeaderContainer>
        <SectionHeader title={PRICE_ADVISOR} />
      </SectionHeaderContainer>

      <Container>
        <PrintFlex width='100%' flexDirection='column' data-cy='price-history'>
          <SectionWrapperWithPageBreak>
            <Column alignItems='center' mb='40px' position='relative'>
              {!isAuthorized && <LoginBanner />}
              <Box width={[1, '74.5%']}>
                <MarketPrice
                  calculationAccuracy={data.marketPrice.calculationAccuracy}
                  isAuthorized={isAuthorized}
                  lowestValue={idealAdPrice}
                  title={'Aktuální tržní cena'}
                  value={data.marketPrice.value}
                />
              </Box>
            </Column>

            <Flex width='100%' flexDirection='column' mb='40px'>
              <Box>
                <AdsList
                  ad={getSearchedAd()}
                  allowRedirectButton={false}
                  genericBadgeText='Ověřovaný vůz'
                  isAuthorized={isAuthorized}
                  list={data.listWithSearchedAndIdealAd}
                  listType={EnumListType.PRICE_ADVISOR}
                  orderBadgeEnabled={false}
                  showBenchmark={false}
                  showLoginDialog={noop}
                  style={{ justifyContent: 'center' }}
                  thumbnail={false}
                />
              </Box>
            </Flex>
          </SectionWrapperWithPageBreak>

          <SectionWrapperWithPageBreak>
            <LastAddedAndSoldCars
              data={{ lastSoldCars, lastAddedCars }}
              isAuthorized={isAuthorized}
              sectionTitle={NEW_AND_SOLD_CARS}
            />
          </SectionWrapperWithPageBreak>

          <SectionWrapperWithPageBreak>
            <Flex width='100%' flexDirection='column'>
              <Flex width='100%' flexDirection='row'>
                <SectionHeaderContainer>
                  <SectionHeader title={RESIDUAL_VALUE} />
                </SectionHeaderContainer>
              </Flex>

              <Flex width='100%' flexDirection='column'>
                <Box width={1}>
                  {isAuthorized ? (
                    <ResidualValueCharts data={data} />
                  ) : (
                    <BlurryImageContainer>
                      <LoginBanner />
                      <img src={residualValueChartBlurredImg} alt='blurry-img' />
                    </BlurryImageContainer>
                  )}
                </Box>
              </Flex>
            </Flex>
          </SectionWrapperWithPageBreak>
        </PrintFlex>
      </Container>
    </>
  );
};

const BlurryImageContainer = styled(RoundedBlurryImageContainer)`
  overflow: initial;
`;

const NoResults = () => (
  <>
    <DataNoResults sectionHeaderText={PRICE_ADVISOR} />
    <DataNoResults sectionHeaderText={NEW_AND_SOLD_CARS} />
    <DataNoResults sectionHeaderText={RESIDUAL_VALUE} />
  </>
);
